import { authInstance } from './axios';
let url = process.env.REACT_APP_API_URL;

export const campaignService = {
  getCampaigns: async () => {
    const response = await authInstance
      .get(`${url}/discountCodesCampaigns`)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
};

import { useEffect, useState } from 'react';
import { Input } from '../../../common/Forms/Input/Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as schemas from '../../../../assets/schemas/schemas';
import { ActionButton } from '../../../common/Buttons/Buttons';
import {
  capitalize,
  filterParams,
  getCorrectDate,
  filterOption
} from '../../../../assets/helpers/funciones';
import {
  idCodes,
  phoneCodes,
  civilStateOptions,
  genderOptions,
  affiliationTypeOptions,
  idEnums
} from '../../../../assets/helpers/options';
import { customerChangeHistoryHeader } from "../../../../assets/helpers/tables"
import {
  salesRoles
} from '../../../../assets/helpers/roles';

import { customerService } from '../../../../services/customerService';
import authService from '../../../../services/authService';
import { membershipService } from '../../../../services/membershipService';
import { broadCastService } from '../../../../services/broadCastService';
import { notify } from '../../../../assets/helpers/toast';
import { OderCardHeader } from './OrderComponents';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';
import { ChangeCustomerModal, ClientChangeHistoryModal } from "../../../common/Modals/Modals"
import './OrderComponent.scss';

export const ClientInfoCard = (props) => {
  const { label, membership, reloadMembership } = props;
  const [open, setOpen] = useState(true);
  const [isLoading, setIsloading] = useState(false);
  const [broadcastOptions, setBroadcastOptions] = useState([]);
  const [changeCustomerModal, setChangeCustomerModal] = useState({
    isOpen: false
  });
  const [customerHistoryModal, setCustomerHistoryModal] = useState({
    isOpen: false
  });


  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch
  } = useForm({
    resolver: yupResolver(schemas.orderClientInfoForm()),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset: reset2,
    watch: watch2,
    control: control2,
  } = useForm({
    resolver: yupResolver(schemas.changeCustomerModal()),
  });

  const changeCustomer = handleSubmit2(async (data) => {
    let dataToPost = { ...data };

    dataToPost.membershipId = membership?._id;

    dataToPost = filterParams(dataToPost);

    if (!isLoading) {
      setIsloading(true);
      membershipService
        .changeCustomer(dataToPost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Cambio de cliente`, 'success');
            reloadMembership();
            setIsloading(false);
            handleChangeCustomerModal()
            window.location.reload()
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });



  const editCustomer = handleSubmit(async (data) => {
    let dataToPost = { ...data };

    dataToPost.membership = membership?._id;

    dataToPost = filterParams(dataToPost);

    if (!isLoading) {
      setIsloading(true);
      customerService
        .updateCustomer(dataToPost, membership?.customer?._id)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Datos actualizados`, 'success');
            reloadMembership();
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const handleChangeCustomerModal = () => {
    setChangeCustomerModal((prevState) => {
      return {
        ...prevState,
        isOpen: !changeCustomerModal?.isOpen,
      };
    });
  }


  const handleVehicleHistoryModal = () => {
    setCustomerHistoryModal((prevState) => {
      return {
        ...prevState,
        isOpen: !customerHistoryModal?.isOpen,
      };
    });
  }




  const preloadForm = () => {
    setValue('firstName', capitalize(membership?.customer?.firstName));
    setValue('lastName', capitalize(membership?.customer?.lastName));
    setValue(
      'identificationType',
      capitalize(membership?.customer?.identificationType)
    );
    setValue(
      'identification',
      capitalize(membership?.customer?.identification)
    );
    setValue('phonePrefix', membership?.customer?.phonePrefix);
    setValue('phoneNumber', membership?.customer?.phoneNumber);
    setValue('optionalPhonePrefix', membership?.customer?.optionalPhonePrefix);
    setValue('optionalPhoneNumber', membership?.customer?.optionalPhoneNumber);
    setValue('optionalEmail', membership?.customer?.optionalEmail);
    if (membership?.customer?.birthday) {
      setValue('birthday', getCorrectDate(membership?.customer?.birthday));
    }
    setValue('email', membership?.customer?.email?.toLowerCase());
    setValue('gender', membership?.customer?.gender);
    setValue('instagram', membership?.customer?.instagram);

    Promise.allSettled([getBroadcastChannels()]).then((r) => {
      setTimeout(() => {
        setValue('broadcastChannel', membership?.customer?.broadcastChannelId);
        setValue('broadcastChannelDescription', membership?.customer?.broadcastChannelDescription);
      }, 2500);
    });

    setValue('maritalStatus', membership?.customer?.maritalStatus);
  };

  const getBroadcastChannels = () => {
    broadCastService
      .getBroadcastChannels()
      .then((response) => {
        if (response.status === 200) {
          setBroadcastOptions(response?.data?.broadcastChannels);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  useEffect(() => {
    preloadForm();
  }, [open]);

  return (
    <>
      <ChangeCustomerModal
        modal={changeCustomerModal}
        handleModal={handleChangeCustomerModal}
        // getFleets={getFleets}
        register={register2}
        errors={errors2}
        action={changeCustomer}
        reset={reset2}
        watch={watch2}
        control={control2}
        // onChange={{ getRoles: getRoles }}
        options={{
          genderOptions: genderOptions,
          phoneCodes: phoneCodes,
          civilStateOptions: civilStateOptions,
          idCodes: idCodes,
          affiliationTypeOptions: affiliationTypeOptions
        }}
      />
      <ClientChangeHistoryModal
        modal={customerHistoryModal}
        handleModal={handleVehicleHistoryModal}
        header={customerChangeHistoryHeader}
        data={membership?.customerHistory}
      />

      <LoadSpinner show={isLoading} />
      <div className="order-card">
        <OderCardHeader open={open} setOpen={setOpen} label={label} />

        {open && (
          <>
            <div className="change-flow-button-container">
              {membership?.customerHistory?.length > 0 ? <ActionButton label={"Historico de Clientes"} action={handleVehicleHistoryModal} /> : <span></span>}

              {[salesRoles?.SALES_ADMIN, salesRoles?.SALES_DEVELOPER, salesRoles?.SALES_HUNTER, salesRoles?.SALES_FARMER]?.includes(authService?.getUserType())}
              <ActionButton label={"Cambio de Cliente"} action={handleChangeCustomerModal} />
            </div>
            <br />
            <div className="buttons-container">
              <h6 style={{ margin: '6px 12px 12px 0px' }}>DATOS DEL CLIENTE</h6>
              <ActionButton label={'Guardar'} action={editCustomer} />
            </div>
            <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
            <div style={{ width: '100%' }} className="row">
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Nombre *'}
                type={'text'}
                name={'firstName'}
                register={register}
                placeholder={''}
                errors={errors?.firstName?.message}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Apellido *'}
                type={'text'}
                name={'lastName'}
                register={register}
                placeholder={''}
                errors={errors?.lastName?.message}
              />
              <Input
                bootstrap={'col-4 col-sm-1 col-md-1'}
                label={'C.I / RIF*'}
                type={'select'}
                name={'identificationType'}
                register={register}
                placeholder={''}
                options={idCodes}
                errors={errors?.identificationType?.message}
                onChange={() => setValue("identification", "")}
              />
              {
                [idEnums?.J, idEnums?.E, idEnums?.P]?.includes(watch('identificationType')) &&
                <Input
                  bootstrap={'col-8 col-sm-3 col-md-3'}
                  label={'del cliente*'}
                  type={'text'}
                  name={'identification'}
                  register={register}
                  placeholder={''}
                  errors={errors?.identification?.message}
                />
              }
              {
                ![idEnums?.J, idEnums?.E, idEnums?.P]?.includes(watch('identificationType')) &&
                <Input
                  bootstrap={'col-8 col-sm-3 col-md-3'}
                  label={'del cliente*'}
                  type={'number'}
                  name={'identification'}
                  register={register}
                  placeholder={''}
                  errors={errors?.identification?.message}
                />
              }
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Sexo *'}
                type={'select'}
                name={'gender'}
                options={genderOptions}
                register={register}
                placeholder={''}
                errors={errors?.gender?.message}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Fecha de Nacimiento *'}
                type={'date'}
                name={'birthday'}
                register={register}
                placeholder={''}
                control={control}
                errors={errors?.birthday?.message}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Estado Civil *'}
                type={'select'}
                name={'maritalStatus'}
                options={civilStateOptions}
                register={register}
                placeholder={''}
                errors={errors?.maritalStatus?.message}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Correo *'}
                type={'text'}
                name={'email'}
                register={register}
                placeholder={''}
                errors={errors?.email?.message}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Correo Opcional '}
                type={'text'}
                name={'optionalEmail'}
                register={register}
                placeholder={''}
                errors={errors?.optionalEmail?.message}
              />
              <Input
                bootstrap={'col-4 col-sm-1 col-md-1'}
                label={'Teléfono *'}
                type={'select'}
                name={'phonePrefix'}
                register={register}
                placeholder={''}
                options={phoneCodes}
                errors={errors?.phonePrefix?.message}
              />
              <Input
                bootstrap={'col-8 col-sm-3 col-md-3'}
                label={''}
                type={'number'}
                name={'phoneNumber'}
                register={register}
                placeholder={''}
                errors={errors?.phoneNumber?.message}
              />
              <Input
                bootstrap={'col-4 col-sm-1 col-md-1'}
                label={'Opcional'}
                type={'select'}
                name={'optionalPhonePrefix'}
                register={register}
                placeholder={''}
                options={phoneCodes}
                errors={errors?.optionalPhonePrefix?.message}
              />
              <Input
                bootstrap={'col-8 col-sm-3 col-md-3'}
                label={''}
                type={'number'}
                name={'optionalPhoneNumber'}
                register={register}
                placeholder={''}
                errors={errors?.optionalPhoneNumber?.message}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'¿Por dónde nos conseguiste? *'}
                type={'select'}
                options={broadcastOptions}
                name={'broadcastChannel'}
                register={register}
                placeholder={''}
                errors={errors?.broadcastChannel?.message}
              />
              {filterOption(broadcastOptions, "Otro")?._id === watch('broadcastChannel')

                &&
                <Input
                  bootstrap={'col-12 col-md-4'}
                  label={'Otro *'}
                  type={'text'}
                  options={broadcastOptions}
                  name={'broadcastChannelDescription'}
                  register={register}
                  placeholder={''}
                  errors={errors?.broadcastChannelDescription?.message}
                />
              }
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Instagram'}
                type={'text'}
                name={'instagram'}
                register={register}
                placeholder={''}
                errors={errors?.instagram?.message}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

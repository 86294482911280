import { authInstance } from './axios';
let url = process.env.REACT_APP_API_URL;

export const statesService = {
  getStates: async (data) => {
    const response = await authInstance
      .get(`${url}/states/list`)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getCities: async (stateId) => {
    const response = await authInstance
      .get(`${url}/cities/list?stateId=${stateId}`)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getMunicipalities: async (cityId) => {
    const response = await authInstance
      .get(`${url}/municipalities/list?cityId=${cityId}`)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
};

import { useEffect, useState } from 'react';
import { Input } from '../../../common/Forms/Input/Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as schemas from '../../../../assets/schemas/schemas';
import { ActionButton, AltButton } from '../../../common/Buttons/Buttons';
import {
  filterParams,
} from '../../../../assets/helpers/funciones';
import { vehicleService } from '../../../../services/vehicleService';
import { saleChannelService } from '../../../../services/saleChannelService';
import { notify } from '../../../../assets/helpers/toast';
import { OderCardHeader } from '../../AffiliationsDB/Order/OrderComponents';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';
import plusCounter from '../../../../images/plusCounter.svg';
import minusCounter from '../../../../images/minusCounter.svg';
import { enumAction } from '../../../../assets/helpers/options';
import { ListSalesChannel } from "./ListSalesChannel"
import './SalesChannels.scss';

export const SalesChannels = (props) => {
  const { label } = props;
  const [open, setOpen] = useState(true);
  const [isLoading, setIsloading] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [classCounter, setClassCounter] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset
  } = useForm({
    resolver: yupResolver(schemas.handleSaleChannelSchema()),
  });

  const createSaleChannel = handleSubmit(async (data) => {
    let dataToPost = {
      channel: data?.channel,
      shortName: data?.shortName,
    };

    if (!isLoading) {
      setIsloading(true);
      saleChannelService
        .createSaleChannel(filterParams(dataToPost))
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Canal de venta ${response?.data?.salesChannel?.channel} creado`, 'success');
            selectAction();
            reset()
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const handleCounter = (add) => {
    setClassCounter(classCounter + add);
    if (add === -1) {
      setValue(`class${classCounter}`, '');
    }
  };

  const selectAction = (actionName) => {
    setValue('brandName', '');
    setValue('brandId', '');
    for (let i = 0; i < classCounter; i++) {
      setValue(`class${i}`, '');
    }
    setClassCounter(0);
    setValue('model', '');
    setValue('actionName', actionName);
    getVehicleBrands();
  };

  const getVehicleBrands = () => {
    vehicleService
      .getVehicleBrands()
      .then((response) => {
        if (response.status === 200) {
          setBrandOptions(response?.data?.vehicleBrands);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const getSalesVehicleClasses = () => {
    vehicleService
      .getSalesVehicleClasses()
      .then((response) => {
        if (response.status === 200) {
          setClassOptions(response?.data?.classes);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  useEffect(() => {
    getVehicleBrands();
    getSalesVehicleClasses();
  }, [open]);

  return (
    <>
      <LoadSpinner show={isLoading} />
      <div className="order-card">
        <OderCardHeader open={open} setOpen={setOpen} label={label} />
        <br />
        {open && (
          <>
            <div className="buttons-center-container">
              <Button
                actionName={watch('actionName')}
                action={() => selectAction(enumAction?.listSaleChannel)}
                label={'Canales de Venta'}
                name={enumAction?.listSaleChannel}
              />
              <Button
                actionName={watch('actionName')}
                action={() => selectAction(enumAction?.createSaleChannel)}
                label={'Crear Canal de Venta'}
                name={enumAction?.createSaleChannel}
              />
            </div>
            <VehicleParametersInstructions actionName={watch('actionName')} />

            {[enumAction?.listSaleChannel].includes(watch('actionName')) && (
              <ListSalesChannel />
            )}


            <div style={{ width: '100%' }} className="row">
              {[enumAction?.createSaleChannel].includes(watch('actionName')) && (
                <Input
                  bootstrap={'col-12 col-md-4'}
                  label={'Nombre del canal de Venta *'}
                  type={'text'}
                  name={'channel'}
                  register={register}
                  placeholder={''}
                  errors={errors?.channel?.message}
                />
              )}
              {[
                enumAction?.createSaleChannel,
              ].includes(watch('actionName')) && (
                  <Input
                    bootstrap={'col-12 col-md-4'}
                    label={'Abreviatura del canal *'}
                    type={'text'}
                    name={'shortName'}
                    register={register}
                    placeholder={''}
                    errors={errors?.shortName?.message}
                  />
                )}
              {watch('actionName') === enumAction?.createSaleChannel && (
                <div className="buttons-center-container">
                  <ActionButton action={createSaleChannel} label="Guardar" />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const Button = (props) => {
  const { action, label, actionName, name } = props;

  if (actionName === name) {
    return (
      <ActionButton action={() => action(enumAction?.name)} label={label} />
    );
  } else
    return <AltButton action={() => action(enumAction?.name)} label={label} />;
};

const ClassInputComponent = (props) => {
  const { classCounter, handleCounter, register, errors, classOptions } = props;

  let data = [];

  for (let i = 0; i <= classCounter; i++) {
    data.push({
      _id: 'index' + i,
    });
  }

  return (
    <>
      {data?.map((d, i) => (
        <div key={d?._id} className="row">
          <Input
            bootstrap={'col-10'}
            label={`Clase ${i + 1} *`}
            type={'select'}
            name={`class${i}`}
            options={classOptions}
            register={register}
            placeholder={''}
            errors={errors[`class${i}`]?.message}
          />
          <div className="col-2 vehicle-parameter-class-button-container ">
            <img
              style={{ height: '24px' }}
              src={plusCounter}
              alt="plusCounter"
              onClick={() => handleCounter(+1)}
            />
            {classCounter === i && i > 0 && (
              <img
                style={{ height: '24px' }}
                src={minusCounter}
                alt="minusCounter"
                onClick={() => handleCounter(-1)}
              />
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export const VehicleParametersInstructions = (props) => {
  const { actionName } = props;
  let text = '';

  switch (actionName) {
    case enumAction?.createBrand:
      text =
        'Introduzca el nombre de la nueva marca y las clases de vehiculo a las que este asociado.';
      break;
    case enumAction?.createModel:
      text =
        'Seleccione la marca correspondiente al modelo a agregar e introduza el nombre del nuevo modelo.';
      break;
    case enumAction?.createSaleChannel:
      text =
        'Introduzca el nombre y abreviatura del canal de venta.';
      break;
    case enumAction?.listSaleChannel:
      text =
        '';
      break;
    default:
      break;
  }

  if (text) {
    return <h6>{text}</h6>;
  } else return null
};

import { authInstance } from './axios';
import { filterParams } from '../assets/helpers/funciones';
let url = process.env.REACT_APP_API_URL;

export const currencyRateService = {
  createRate: async (data) => {
    const response = await authInstance
      .post(`${url}/currencyRates`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getCurrencyRates: async (params) => {
    const response = await authInstance
      .get(
        `${url}/currencyRates/list?` + new URLSearchParams(filterParams(params))
      )
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
};

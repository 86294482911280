import { useState, useEffect, useRef } from 'react';
import { ActionButton } from '../../common/Buttons/Buttons';
import { Header } from '../../common/Header/Header.jsx';
import { SelectPerPage } from '../../common/Forms/Input/Input';
import { FleetsRegisterTable } from '../../common/Tables/Tables';
import { fleetsRegisterTableHeader } from '../../../assets/helpers/tables';
import { notify } from '../../../assets/helpers/toast';
import { fleetService } from '../../../services/fleetService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../assets/schemas/schemas';
import { useHistory } from 'react-router-dom';
import { CreateFleetModal, EditFleetModal } from '../../common/Modals/Modals';
import './FleetRegister.scss';

const itemsPerpage = [10, 25, 50, 100, 500];

export const FleetRegister = () => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const history = useHistory();

  const {
    register,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemas.filterExportableSchema(t)),
  });

  const [sinceDate, setSinceDate] = useState(new Date());
  const [untilDate, setUntilDate] = useState(new Date());
  const [createFleetModal, setCreateFleetModal] = useState({
    isOpen: false,
  });
  const [editFleetModal, setEditFleetModal] = useState({
    isOpen: false,
    selected: '',
  });

  const [dataTable, setDataTable] = useState({
    limit: itemsPerpage[2],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    sinceDate: '',
    untilDate: '',
    isLoading: true,
  });

  const handleSinceDateChange = (e) => {
    if (e > untilDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setSinceDate(e);
    }
  };
  const handleUntilDateChange = (e) => {
    if (e < sinceDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setUntilDate(e);
    }
  };

  const selectitemsPerpage = (event) => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * dataTable?.limit,
      };
    });
  };

  const redirectToOrder = (orderId) => {
    history.push(`/afiliations/order/${orderId}`);
  };

  useEffect(() => {
    getFleets();
  }, [
    dataTable?.page,
    dataTable?.offset,
    dataTable?.limit,
    dataTable?.type,
    dataTable?.sinceDate,
    dataTable?.untilDate,
  ]);

  const getFleets = () => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    let params = {
      limit: dataTable?.limit,
      offset: dataTable?.offset,
    };

    fleetService
      .getFleets(params)
      .then((response) => {
        if (response.status === 200) {
          setDataTable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.fleetData,
              count: response?.data?.total,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setDataTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setDataTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };

  const openDetail = (fleet) => {
    history.push(`/modules/fleet-register/detail/${fleet?._id}`);
  };

  const handleCreateFleetForm = () => {
    setCreateFleetModal((prevState) => {
      return {
        ...prevState,
        isOpen: !createFleetModal?.isOpen,
      };
    });
  };

  const handleEditFleetForm = (fleet) => {
    setEditFleetModal((prevState) => {
      return {
        ...prevState,
        isOpen: !editFleetModal?.isOpen,
        selected: fleet ? fleet : '',
      };
    });
  };

  return (
    <>
      <CreateFleetModal
        modal={createFleetModal}
        handleModal={handleCreateFleetForm}
        getFleets={getFleets}
      />
      <EditFleetModal
        modal={editFleetModal}
        handleModal={handleEditFleetForm}
        getFleets={getFleets}
      />
      <Header />
      <div className="service-with-container container">
        <div className="buttons-container">
          <h1 className="title-component">Flotas: {dataTable?.count}</h1>
          <ActionButton
            action={() => handleCreateFleetForm()}
            label={'+ Nueva Flota'}
          />
        </div>
        <div className="breadcrumb-container">
          {/* <div className="exportables-buttons-container"></div> */}
          <div className="exportables-buttons-container">
            <div style={{ marginTop: '12px' }}>
              <SelectPerPage
                label={'Registros por página'}
                options={itemsPerpage}
                value={dataTable?.limit}
                onChange={selectitemsPerpage}
              />
            </div>
          </div>
          <span></span>
        </div>
        <FleetsRegisterTable
          header={fleetsRegisterTableHeader}
          data={dataTable}
          handlePageChange={handlePageChange}
          redirectToOrder={redirectToOrder}
          tableRef={tableRef}
          openDetail={openDetail}
          handleEditFleetForm={handleEditFleetForm}
        />
      </div>
    </>
  );
};

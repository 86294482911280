import { useEffect, useState } from 'react';
import logo from '../../../images/logo.svg';
import { fleetLoadService } from '../../../services/fleetLoadService';
import { notify } from '../../../assets/helpers/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ActionButton, AltButton } from '../../common/Buttons/Buttons';
import {
  MembershipButton,
  WizzardProgressBar,
} from './LandingComponents/LandingComponents';
import { LinkPersonalInfoForm } from './LinkLandingForms/LinkPersonalInfoForm';
import { LinkCreateVehicleForm } from './LinkLandingForms/LinkCreateVehicleForm';
import { SuccesPurchase } from './LinkLandingForms/SuccesPurchase';
import { rateService } from '../../../services/rateService';
import { Header } from '../../common/Header/Header';
import * as schemas from '../../../assets/schemas/schemas';
import { Input } from '../../common/Forms/Input/Input';

import './Landing.scss';

export const LinkLanding = () => {
  const [curentStep, setcurentStep] = useState(1);
  const [membership, setMembership] = useState('');
  const [customer, setCustomer] = useState('');
  const [dollarRate, setDollarRate] = useState(1);
  const [selectedMembership, setSelectedMembership] = useState('');
  const [membershipOptions, setMembershipOptions] = useState({
    data: [],
    fleetLoadId: '',
    fleetLlinkValidityoadId: false,
  });
  const [isLoading, setIsloading] = useState(false);

  const { linkToken } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schemas.linkRegisterStep1Schema()),
  });

  const onSubmit = handleSubmit(async (data) => {
    if (!selectedMembership) {
      notify('Seleccione un plan', 'info');
      return null;
    }

    let dataToPost = {
      membershipId: selectedMembership?._id,
      rcv: watch('rcv'),
    };

    if (!isLoading) {
      setIsloading(true);
      fleetLoadService
        .createMembership(dataToPost, membershipOptions?.fleetLoadId)
        .then((response) => {
          if ([200, 201].includes(response?.status)) {
            // notify(`Membresia creada con exito`, 'success');
            setMembership(response?.data?._id);
            setcurentStep(2);
            setIsloading(false);
          } else {
            notify(`${response?.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const getDollarRate = () => {
    rateService
      .getDollarRate()
      .then((response) => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const selectMembership = (membership) => {
    setSelectedMembership(membership);
    setValue('rcv', membership?.rcv);
  };

  const cancel = () => {
    reset();
    setSelectedMembership('');
  };

  const clearStorageData = () => {
    localStorage.removeItem('personalData');
    localStorage.removeItem('payment');
    localStorage.removeItem('vehicle');
  };

  const validateTokenLink = () => {
    fleetLoadService
      .validateTokenLink({ token: linkToken })
      .then((response) => {
        if (response.status === 200) {
          let data = [];
          for (let i = 0; i < response?.data?.memberships?.length; i++) {
            data?.push({
              code: response?.data?.memberships[i]?.code,
              rcv: response?.data?.memberships[i]?.rcv,
              _id: response?.data?.memberships[i]?.membershipId,
            });
          }

          setMembershipOptions((prevState) => {
            return {
              ...prevState,
              data: data,
              fleetLoadId: response?.data?.fleetLoadId,
              fleetLlinkValidityoadId: response?.data?.linkValidity,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    getDollarRate();
    clearStorageData();
    validateTokenLink();
  }, []);

  return (
    <>
      <Header />
      <div className="container landing-container">
        <div className="row">
          <img src={logo} alt="logo" className="landing-logo" />
          {membershipOptions?.fleetLlinkValidityoadId ? (
            <>
              <div className="buttons-container">
                <WizzardProgressBar
                  curentStep={curentStep}
                // setcurentStep={setcurentStep}
                />
              </div>
              {curentStep === 1 && (
                <>
                  <form action="">
                    {membershipOptions?.data?.length > 0 && (
                      <>
                        {' '}
                        <h1 className="sub-title-component">
                          Selecciona tu Plan
                        </h1>
                        <div className="membership-buttons-container">
                          {membershipOptions?.data?.map((m) => (
                            <>
                              <MembershipButton
                                membership={m}
                                selectMembership={selectMembership}
                                selectedMembership={selectedMembership}
                                affiliation={true}
                              />
                            </>
                          ))}
                        </div>
                      </>
                    )}
                    <div className="row">
                      <div className="col-12 col-md-4"></div>
                      <Input
                        bootstrap={'col-12 col-md-4'}
                        label={'RCV'}
                        type={'text'}
                        name={'rcv'}
                        placeholder={''}
                        register={register}
                        errors={errors?.rcv?.message}
                        disabled={true}
                      />
                      <div className="col-12 col-md-4"></div>
                    </div>
                    <div
                      style={{ marginTop: '24px', marginBottom: '70px' }}
                      className="buttons-center-container "
                    >
                      <AltButton label={'Cancelar'} action={cancel} />
                      <ActionButton label={'Siguiente'} action={onSubmit} />
                    </div>
                  </form>
                </>
              )}
              {curentStep === 2 && (
                <LinkPersonalInfoForm
                  setcurentStep={setcurentStep}
                  selectedMembership={selectedMembership}
                  setCustomer={setCustomer}
                  membership={membership}
                  curentStep={curentStep}
                />
              )}
              {curentStep === 3 && (
                <LinkCreateVehicleForm
                  selectedMembership={selectedMembership}
                  curentStep={curentStep}
                  setcurentStep={setcurentStep}
                  membership={membership}
                  vehicle={getValues()}
                  customer={customer}
                />
              )}
              {curentStep === 4 && (
                <SuccesPurchase
                  curentStep={curentStep}
                  setcurentStep={setcurentStep}
                  membership={membership}
                  selectedMembership={selectedMembership}
                  vehicle={getValues()}
                  customer={customer}
                  dollarRate={dollarRate}
                />
              )}
            </>
          ) : (
            <UnvalidLinkMessage />
          )}
        </div>
      </div>
    </>
  );
};

export const UnvalidLinkMessage = () => {
  return (
    <div style={{ textAlign: 'center', padding: '240px' }}>
      <h5>Link ha expirado o no es valido</h5>
    </div>
  );
};

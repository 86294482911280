import { useEffect, useState } from 'react';
import arrowDown from '../../../../images/arrowDown.svg';
import { FileComponent } from '../../../common/Dropzone/Dropzone';
import {
  ActionButton,
  CancelOrderButton,
} from '../../../common/Buttons/Buttons';
import { SalesOrderImageModal } from '../../../common/Modals/Modals';
import { MembershipLogo } from '../../../common/Cards/Cards';
import {
  capitalize,
} from '../../../../assets/helpers/funciones';
import { ValidateCheckboxComponent } from '../../../common/Forms/Input/Input';
import { membershipService } from '../../../../services/membershipService';
import { notify } from '../../../../assets/helpers/toast';
import moment from 'moment';
import {
  CancelMembershipModal,
  ResendDocumentModal,
} from '../../../common/Modals/Modals';
import {
  membershipAnnulationOptions,
  planStatus,
} from '../../../../assets/helpers/options';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';
import authService from '../../../../services/authService';
import { salesRoles } from '../../../../assets/helpers/roles';
import plusZoom from '../../../../images/plusZoom.svg';
import minusZoom from '../../../../images/minusZoom.svg';
import closeIcon from '../../../../images/close.svg';
import './OrderComponent.scss';

export const Comprobante = (props) => {
  const { label, src } = props;
  return (
    <p className="order-text-separation">
      <b>{label ? label + ':' : ''}</b>{' '}
      <a href={src} target="_blank">
        Ver comprobante
      </a>
    </p>
  );
};

export const DocumentLink = (props) => {
  const { label, src } = props;

  if (src) {
    return (
      <a className="membership-document-link" href={src} target="_blank">
        <div>
          <h6>{label}</h6>
        </div>
      </a>
    );
  } else return null;
};

export const TextComponent = (props) => {
  const { label, text } = props;
  return (
    <p className="order-text-separation">
      <b>{label ? label + ':' : ''}</b> <span>{text}</span>
    </p>
  );
};

export const DiscountComponent = (props) => {
  const { code, amount, label } = props;
  return (
    <p className="order-text-separation">
      <b>{label ? label + ':' : ''}</b>{' '}
      <span>
        <b>{code}</b> {amount}
      </span>
    </p>
  );
};

export const OrderCard = (props) => {
  const { membership, refresh, } = props;

  const [verifiedPayment, setVerifiedPayment] = useState(false);
  const [verifiedDocument, setVerifiedDocument] = useState(false);
  const [saved, setSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCancelService, setShowCancelService] = useState({
    show: false,
    caseSelected: '',
    cancelOptions: membershipAnnulationOptions,
  });

  const [resendModal, setResendModal] = useState({
    show: false,
    selected: '',
  });

  const approveMembership = () => {
    if (!isLoading) {
      setIsLoading(true);
      membershipService
        .approveMembership(membership?._id)
        .then((response) => {
          if ([200, 201].includes(response?.status)) {
            notify(`Membresia aprobada`, 'success');
            refresh();
            setTimeout(() => {
              setIsLoading(false);
            }, 1500);
          } else {
            setIsLoading(false);
            notify(`${response.message}`, 'error');
          }
        })
        .catch((error) => { });
    }
  };

  const reactivateMembership = () => {
    if (!isLoading) {
      setIsLoading(true);
      membershipService
        .reativateMembership(membership?._id)
        .then((response) => {
          if ([200, 201].includes(response?.status)) {
            setIsLoading(false);
            notify(`Membresia reactivada`, 'success');
            refresh();
          } else {
            setIsLoading(false);
            notify(`${response.message}`, 'error');
          }
        })
        .catch((error) => { });
    }
  };

  const updateMembership = () => {
    let data = {
      verifiedDocuments: verifiedDocument.toString(),
      verifiedPayment: verifiedPayment.toString(),
    };

    if (!isLoading) {
      setIsLoading(true);
      membershipService
        .updateMembership(data, membership?._id)
        .then((response) => {
          if ([200, 201].includes(response?.status)) {
            setIsLoading(false);
            notify(`Cambios guardados`, 'success');
            refresh();
            setSaved(true);
          } else {
            setIsLoading(false);
            notify(`${response.message}`, 'error');
          }
        })
        .catch((error) => { });
    }
  };

  const handleCheckboxChange = (name) => {
    if (
      [
        salesRoles?.SALES_ADMIN,
        salesRoles?.SALES_DEVELOPER,
        salesRoles?.SALES_SUPERVISOR,
        salesRoles?.SALES_HUNTER,
        salesRoles?.SALES_FARMER,
        salesRoles?.SALES_COUNTER,
        salesRoles?.SALES_ADMIN_AUX,
      ].includes(authService.getUserType())
    ) {
      if (name === 'Documentos comprobados') {
        setVerifiedDocument(!verifiedDocument);
        setSaved(false);
      }
    }

    if (
      ![
        salesRoles?.SALES_SUPERVISOR,
        salesRoles?.SALES_HUNTER,
        salesRoles?.SALES_FARMER,
        salesRoles?.SALES_COUNTER,
      ].includes(authService.getUserType())
    ) {
      if (name === 'Pago comprobado') {
        setVerifiedPayment(!verifiedPayment);
      }
    }
    setSaved(false);
  };

  const handleCancelModal = () => {
    setShowCancelService((prevState) => {
      return {
        ...prevState,
        show: !showCancelService?.show,
        caseSelected: membership ? membership : '',
      };
    });
  };

  const preloadForm = () => {
    setVerifiedDocument(
      membership?.verifiedDocuments ? membership?.verifiedDocuments : false
    );
    setVerifiedPayment(
      membership?.verifiedPayment ? membership?.verifiedPayment : false
    );

    if (membership?.verifiedDocuments && membership?.verifiedPayment) {
      setSaved(true);
    }
  };

  const sendDocuments = () => {
    if (!isLoading) {
      setIsLoading(true);
      membershipService
        .sendDocuments(resendModal?.selected)
        .then((response) => {
          if ([200, 201].includes(response?.status)) {
            setIsLoading(false);
            notify(`Documentos enviados`, 'success');
            handleResendModal();
          } else {
            setIsLoading(false);
            notify(`${response.message}`, 'error');
          }
        })
        .catch((error) => { });
    }
  };

  const handleResendModal = (membershipId) => {
    setResendModal((prevState) => {
      return {
        ...prevState,
        show: !resendModal?.show,
        selected: membershipId ? membershipId : '',
      };
    });
  };

  useEffect(() => {
    preloadForm();
  }, []);

  return (
    <>
      <LoadSpinner show={isLoading} />
      <CancelMembershipModal
        showCancelService={showCancelService}
        handleClose={() => handleCancelModal()}
        refresh={refresh}
      />

      <ResendDocumentModal
        show={resendModal?.show}
        handleClose={handleResendModal}
        action={sendDocuments}
      />

      <div className={`card detail-order-card`}>
        <h5>Detalle de la Orden</h5>

        <MembershipOrdeCardHeader membership={membership} />

        <div style={{ width: '100%' }} className="row card-row-container">
          <div style={{ padding: '0px' }} className="col-2">
            {/* <MembershipLogo code={_case?.membership?.code?.code} /> */}
          </div>
          <br />
          <div className="col-12">
            {membership?.payments?.map((p, i) => {
              {
                let igtf = '';

                if (p?.currency !== 'BsS') {
                  igtf = ' Incluye IGTF';
                }

                return (
                  <div key={p?.method}>
                    <TextComponent
                      label={'Método'}
                      text={capitalize(p?.method)}
                    />
                    <TextComponent
                      label={`Pago ${i + 1} ${membership?.payments[i]?.upgrade ? '(Upgrade)' : ''
                        } USD`}
                      text={
                        p?.totalAmount
                          ? p?.totalAmount
                          : membership?.priceInfo?.finalPrice + ' USD' + igtf
                      }
                    />
                    {p?.reference && (
                      <TextComponent label={'Referencia'} text={p?.reference} />
                    )}
                    <Comprobante src={p?.referenceImg} />
                  </div>
                );
              }
            })}
            {membership?.discountCode && (
              <DiscountComponent
                label={'Cupon'}
                code={`(${membership?.discountCode})`}
                amount={`${membership?.priceInfo?.discountByCode} USD`}
              />
            )}
            {membership?.giftcardCode && (
              <DiscountComponent
                label={`Tarjeta`}
                code={`(${membership?.giftcardCode})`}
                amount={`${membership?.priceInfo?.discountByGiftcard} USD`}
              />
            )}

            {membership?.beginDate && (
              <TextComponent
                label={'Inicio del Plan'}
                text={moment.utc(membership?.beginDate).format('DD/MM/YYYY')}
              />
            )}
            {membership?.startRcv && (
              <TextComponent
                label={'Inicio RCV'}
                text={moment.utc(membership?.startRcv).format('DD/MM/YYYY')}
              />
            )}

            {membership?.endDate && (
              <TextComponent
                label={'Culminación del Plan'}
                text={moment.utc(membership?.endDate).format('DD/MM/YYYY')}
              />
            )}
            {membership?.endRcv && (
              <TextComponent
                label={'Culminación del RCV'}
                text={moment.utc(membership?.endRcv).format('DD/MM/YYYY')}
              />
            )}
          </div>

          {!['ANULADO', 'DENEGADO']?.includes(membership?.membershipStatus) && (
            <CancelOrderButton
              label={'Anular Orden'}
              action={handleCancelModal}
            />
          )}
          {membership?.membershipStatus === 'PENDIENTE DE APROBACION' && (
            <>
              <ValidateCheckboxComponent
                label={'Pago comprobado'}
                selected={verifiedPayment}
                onSelect={handleCheckboxChange}
              />
              <ValidateCheckboxComponent
                label={'Documentos comprobados'}
                selected={verifiedDocument}
                onSelect={handleCheckboxChange}
              />
            </>
          )}

          {![planStatus?.PENDING_FOR_APPROBAL, planStatus?.CANCELED, planStatus?.INCOMPLETED].includes(
            membership?.membershipStatus
          ) && (
              <div
                style={{ marginTop: '12px' }}
                className="buttons-center-container"
              >
                <ActionButton
                  label={'Reenviar Documentos'}
                  action={() => handleResendModal(membership?._id)}
                />
              </div>
            )}

          {![planStatus?.INCOMPLETED]?.includes(membership?.membershipStatus) &&
            <div className="membership-document-link-container">
              <DocumentLink
                label={'Ver cuadro Producto'}
                src={membership?.documents?.contract}
              />
              <DocumentLink
                label={'Ver poliza RCV'}
                src={membership?.documents?.rcv}
              />
              <DocumentLink
                label={'Ver carnet RCV'}
                src={membership?.documents?.rcvCard}
              />
            </div>
          }

          {['ANULADO', 'DENEGADO']?.includes(membership?.membershipStatus) && (
            <h6 className='oder-anulation-reason'>Motivo de Anulación: {membership?.annulationReason}</h6>
          )}
          <div
            style={{ marginTop: '12px' }}
            className="buttons-center-container"
          >
            {membership?.membershipStatus === 'PENDIENTE DE APROBACION' &&
              !saved &&
              (verifiedDocument || verifiedPayment ? (
                <ActionButton
                  label={'Guardar'}
                  action={() => updateMembership()}
                />
              ) : (
                ''
              ))}

            {membership?.membershipStatus === 'PENDIENTE DE APROBACION' &&
              saved &&
              (verifiedDocument && verifiedPayment ? (
                <ActionButton
                  label={
                    membership?.affiliationType === 'Renovación'
                      ? 'Renovar Orden'
                      : 'Aprobar Orden'
                  }
                  action={() => approveMembership()}
                />
              ) : (
                ''
              ))}

            {['ANULADO', 'DENEGADO']?.includes(membership?.membershipStatus) && (
              <ActionButton
                label={'Reactivar Orden'}
                action={() => reactivateMembership()}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const MembershipOrdeCardHeader = (props) => {
  const { membership } = props;
  return (
    <div className="card-membership-name-info">
      <div className="row">
        <div className="col-4 col-md-12">
          <div className="card-membership-name-info-logo-container">
            <MembershipLogo code={membership?.membershipPlan} />
          </div>
        </div>
        <div className="col-2 col-md-6 center-component">
          <p>
            <b>Plan:</b>
            <br />
            {membership?.membershipPlan}
          </p>
        </div>
        <div className="col-6 col-md-6 center-component">
          <p>
            <b>Costo del Plan:</b>
            <br />
            {membership?.priceInfo?.finalPrice.toFixed(2)}$
          </p>
        </div>
      </div>
    </div>
  );
};

export const OderCardHeader = (props) => {
  const { open, setOpen, label } = props;

  return (
    <div className="order-card-header">
      <div>
        <h5>{label}</h5>
      </div>
      {!open ? (
        <img
          style={{ rotate: '-90deg' }}
          className="arrow-down"
          src={arrowDown}
          alt="arrowDown"
          onClick={() => setOpen(!open)}
        />
      ) : (
        <img
          className="arrow-down"
          src={arrowDown}
          alt="arrowDown"
          onClick={() => setOpen(!open)}
        />
      )}
    </div>
  );
};

export const DocumentImageComponent = (props) => {
  const { showDocumetModal, openDocumentModal, zoomDocumentModal } = props;

  return (
    <div
      style={{
        height: 500 * showDocumetModal?.zoom,
        width: 500 * showDocumetModal?.zoom,
      }}
      className="document-image-component"
    >
      <div className="document-image-component-header">
        <div>
          <img
            onClick={() => zoomDocumentModal(1.3)}
            src={plusZoom}
            alt="plusZoom"
          />
          <img
            onClick={() => zoomDocumentModal(1)}
            src={minusZoom}
            alt="minusZoom"
          />
        </div>
        <div>
          <img
            onClick={() => openDocumentModal()}
            src={closeIcon}
            alt="closeIcon"
          />
        </div>
      </div>

      {showDocumetModal?.src.includes('pdf') ? (
        <iframe
          className="image-container-iframe"
          src={showDocumetModal?.src}
        ></iframe>
      ) : (
        <img src={showDocumetModal?.src} alt="" />
      )}
    </div>
  );
};

export const OrdeCard = (props) => {
  const { label, membership, refresh } = props;
  const [open, setOpen] = useState(true);
  const [showDocumetModal, setShowDocumetModal] = useState({
    show: false,
    src: '',
    zoom: 1,
  });
  const [showImageModal, setShowImageModal] = useState({
    isOpen: false,
    label: '',
    tag: '',
    message: '(PNG, JPG, JEPG, máx. 3MB)',
    selected: '',
  });

  const openDocumentModal = (src) => {
    setShowDocumetModal((prevState) => {
      return {
        ...prevState,
        show: !showDocumetModal?.show,
        src: !showDocumetModal?.src ? src : '',
      };
    });
  };

  const zoomDocumentModal = (zoom) => {
    setShowDocumetModal((prevState) => {
      return {
        ...prevState,
        zoom: zoom,
      };
    });
  };

  const opeImageModal = (label, tag) => {
    setShowImageModal((prevState) => {
      return {
        ...prevState,
        isOpen: true,
        label: label,
        tag: tag,
        message: '(PNG, JPG, JEPG, máx. 3MB)',
        selected: membership?._id,
      };
    });
  };

  const closeImageModal = () => {
    setShowImageModal((prevState) => {
      return {
        ...prevState,
        isOpen: false,
        label: '',
        tag: '',
        message: '(PNG, JPG, JEPG, máx. 3MB)',
        selected: '',
      };
    });
  };

  return (
    <>
      {showDocumetModal?.show && (
        <DocumentImageComponent
          showDocumetModal={showDocumetModal}
          openDocumentModal={openDocumentModal}
          zoomDocumentModal={zoomDocumentModal}
        />
      )}
      <div className="order-card">
        <SalesOrderImageModal
          imageModal={showImageModal}
          closeImageModal={closeImageModal}
          refresh={refresh}
        />
        <OderCardHeader open={open} setOpen={setOpen} label={label} />
        <br />
        {open && (
          <div style={{ width: '100%' }} className="row">
            <div className="col-12 col-md-4">
              <OrderImageComponent
                name={'Cedula'}
                tag={'identityDocument'}
                src={membership?.customer?.identityDocument}
                opeImageModal={opeImageModal}
                openDocumentModal={openDocumentModal}
              />
            </div>
            <div className="col-12 col-md-4">
              <OrderImageComponent
                name={'Licencia'}
                tag={'license'}
                src={
                  membership?.customer?.licenses?.length > 0
                    ? membership?.customer?.licenses[0]
                    : ''
                }
                opeImageModal={opeImageModal}
                openDocumentModal={openDocumentModal}
              />
            </div>
            <div className="col-12 col-md-4">
              <OrderImageComponent
                // name={'Carnet de Circulación'}
                name={'Título de propiedad del vehículo'}
                tag={'circulationCard'}
                src={membership?.vehicle?.circulationCard}
                opeImageModal={opeImageModal}
                openDocumentModal={openDocumentModal}
              />
            </div>

            {membership?.payments?.map((m, i) => {
              return (
                <div key={m?._id} className="col-12 col-md-4">
                  <OrderImageComponent
                    name={`Comprobante de Pago (${i + 1})`}
                    tag={`payment${i + 1}`}
                    src={m?.referenceImg}
                    opeImageModal={opeImageModal}
                    openDocumentModal={openDocumentModal}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export const OrderImageComponent = (props) => {
  const { name, src, opeImageModal, tag, openDocumentModal } = props;

  return (
    <div style={{ margin: '48px 24px' }}>
      <FileComponent file={{ name: name }} />
      <div className="buttons-center-container">
        {src && (
          // <a target="_blank" href={src}>
          <ActionButton label={'Ver'} action={() => openDocumentModal(src)} />
          // </a>
        )}
        <ActionButton
          label={src ? 'Cambiar' : 'Agregar'}
          action={() => opeImageModal(name, tag)}
        />
      </div>
    </div>
  );
};


export const RCVOrderCard = (props) => {
  const { membership, refresh, amount } = props;

  const [verifiedPayment, setVerifiedPayment] = useState(false);
  const [verifiedDocument, setVerifiedDocument] = useState(false);
  const [saved, setSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const approveMembership = () => {
    if (!isLoading) {
      setIsLoading(true);
      membershipService
        .approveMembership(membership?._id)
        .then((response) => {
          if ([200, 201].includes(response?.status)) {
            notify(`Membresia aprobada`, 'success');
            refresh();
            setTimeout(() => {
              setIsLoading(false);
            }, 1500);
          } else {
            setIsLoading(false);
            notify(`${response.message}`, 'error');
          }
        })
        .catch((error) => { });
    }
  };


  const preloadForm = () => {
    setVerifiedDocument(
      membership?.verifiedDocuments ? membership?.verifiedDocuments : false
    );
    setVerifiedPayment(
      membership?.verifiedPayment ? membership?.verifiedPayment : false
    );

    if (membership?.verifiedDocuments && membership?.verifiedPayment) {
      setSaved(true);
    }
  };


  useEffect(() => {
    preloadForm();
  }, []);


  return (
    <>
      <LoadSpinner show={isLoading} />

      <div className={`card detail-order-card`}>
        <h5>Detalle de la Orden</h5>

        <div className="card-membership-name-info">
          <div className="row">
            <div className="col-4 col-md-12">
              <div className="card-membership-name-info-logo-container">
              </div>
            </div>
            <div className="col-2 col-md-6 center-component">
              <p>
                <b>Clase de Vehículo:</b>
                <br />
                {capitalize(membership?.vehicle?.class)}
              </p>
            </div>
            <div className="col-6 col-md-6 center-component">
              <p>
                <b>Precio:</b>
                <br />
                {amount}
              </p>
            </div>
          </div>
        </div>

        <div style={{ width: '100%' }} className="row card-row-container">
          <div style={{ padding: '0px' }} className="col-2">
          </div>

          <div
            style={{ marginTop: '12px' }}
            className="buttons-center-container"
          >

            {membership?.membershipStatus === 'PENDIENTE DE APROBACION' &&
              saved &&
              (verifiedDocument && verifiedPayment ? (
                <ActionButton
                  label={
                    membership?.affiliationType === 'Renovación'
                      ? 'Renovar Orden'
                      : 'Aprobar Orden'
                  }
                  action={() => approveMembership()}
                />
              ) : (
                ''
              ))}

          </div>
        </div>
      </div>
    </>
  );
};

import { useState, useEffect, useCallback } from 'react';
import { Input } from '../../../common/Forms/Input/Input';
import { notify } from '../../../../assets/helpers/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { set, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../../assets/schemas/schemas';
import { ActionButton, AltButton } from '../../../common/Buttons/Buttons';
import {
  idCodes,
  phoneCodes,
  genderOptions,
  civilStateOptions,
} from '../../../../assets/helpers/options';
import { useParams } from 'react-router-dom';
import { broadCastService } from '../../../../services/broadCastService';
import { customerService } from '../../../../services/customerService';
import { membershipService } from '../../../../services/membershipService';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';
import {
  SalesDropzone,
  FileComponent,
} from '../../../common/Dropzone/Dropzone';
import { useDropzone } from 'react-dropzone';
import { capitalize, filterParams, filterOption, validateAndSet } from '../../../../assets/helpers/funciones';
import { idEnums, documentTypeEnum, idDocumentTypeOptions } from '../../../../assets/helpers/options';
import './LandingForms.scss';
import moment from 'moment';

export const PersonalInfoForm = (props) => {
  const { setcurentStep, membership, setCustomer, curentStep } = props;
  const t = useTranslation();

  const [idUploaded, setIdUploaded] = useState('');
  const [isLoading, setIsloading] = useState(false);
  const [cedulaError, setCedulaError] = useState(false);
  const [licenceError, setLicenceError] = useState(false);
  const { saleChannel } = useParams();

  const [licenceUploaded, setLicenceUploaded] = useState('');
  const [broadcastOptions, setBroadcastOptions] = useState([]);


  const getDocumentsData = (file, documentType) => {

    const formData = new FormData();
    formData.append("file", file);
    formData.append("documentType", documentType);

    setIsloading(true)
    membershipService
      .getDocumentsData(formData)
      .then((response) => {
        if ([200, 201].includes(response.status)) {

          if (response?.data?.passport) {
            validateAndSet("identificationType", idEnums?.P, setValue, watch)
            validateAndSet("identification", response?.data?.passport, setValue, watch)
            validateAndSet("lastName", capitalize(response?.data?.lastNames), setValue, watch)
            validateAndSet("firstName", capitalize(response?.data?.names), setValue, watch)
          }

          if (response?.data?.rif) {
            validateAndSet("identificationType", idEnums?.J, setValue, watch)
            validateAndSet("identification", response?.data?.rif?.replace("J", ""), setValue, watch)
            validateAndSet("firstName", (response?.data?.enterpriseName), setValue, watch)
          }
          validateAndSet("firstName", capitalize(response?.data?.names), setValue, watch)
          if (watch("identificationType") !== idEnums?.J) {
            validateAndSet("lastName", capitalize(response?.data?.lastNames), setValue, watch)
          }
          validateAndSet("maritalStatus", capitalize(response?.data?.maritalStatus), setValue, watch)
          validateAndSet("identificationType", capitalize(response?.data?.nationality), setValue, watch)
          validateAndSet("identification", capitalize(response?.data?.identification), setValue, watch)
          validateAndSet("gender", response?.data?.gender, setValue, watch)
          validateAndSet("birthday", response?.data?.birthday ? new Date(moment?.utc(response?.data?.birthday)?.format('ddd MMM DD YYYY 05:mm:ss ZZ')) : "", setValue, watch)
          setIsloading(false);
        } else {
          setIsloading(false);
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => {
        setIsloading(false);
      });
  }

  const onDrop1 = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents

      };
      reader.readAsArrayBuffer(file);

      if (
        !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          file.type
        )
      ) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        setIdUploaded(file);
        getDocumentsData(file, watch('documentTypeImage'))
      }
    });
  }, []);

  const onDrop2 = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents

      };
      reader.readAsArrayBuffer(file);

      if (
        !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          file.type
        )
      ) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        setLicenceUploaded(file);
        getDocumentsData(file, documentTypeEnum?.DRIVIN_LICENCE)
      }
    });
  }, []);

  const getCustomerById = () => {
    setValue("identification", watch("identification").replace(/\s/g, ""));

    if (
      watch('identificationType') &&
      watch('identification') &&
      watch('identification').length > 5
    ) {
      customerService
        .getCustomerById(watch('identification'), watch('identificationType'))
        .then((response) => {
          if (response.status === 200) {
            const {
              firstName,
              lastName,
              gender,
              maritalStatus,
              birthday,
              email,
              state,
              phonePrefix,
              broadcastChannel,
              instagram
            } = response?.data?.customer;

            setValue('firstName', firstName);
            setValue('instagram', instagram);
            setValue('lastName', lastName);
            if (gender) {
              setValue('gender', gender);
            }
            if (maritalStatus) {
              setValue('maritalStatus', maritalStatus);
            }
            if (birthday) {
              setValue('birthday', new Date(moment?.utc(birthday)?.format('ddd MMM DD YYYY 05:mm:ss ZZ')));
            }
            if (state) {
              setValue('state', state);
              Promise.allSettled([getBroadcastChannels()]).then((r) => {
                setTimeout(() => {
                  setValue('broadcastChannel', broadcastChannel);
                }, 1000);
              });
            }
            setValue('email', email?.toLowerCase());
            setValue('phonePrefix', phonePrefix);

            sessionStorage.setItem('customer', JSON.stringify(response?.data?.customer));

          }
          if (response.status === 404) {
            setValue('firstName', '');
            setValue('lastName', '');
            setValue('gender', '');
            setValue('birthday', '');
            setValue('state', '');
            setValue('broadcastChannel', '');
            setValue('email', '');
            setValue('phonePrefix', '');
            setValue('phoneNumber', '');
            setValue('instagram', '');
          }
        })
        .catch((error) => { });
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(schemas.CreateCustomerSchema(t)),
  });

  const onSubmit = handleSubmit(async (data) => {

    // Restart error colors on dropzone 
    setCedulaError(false)
    setLicenceError(false)


    data.firstName = capitalize(data?.firstName);
    data.lastName = capitalize(data?.lastName);
    data.email = data?.email?.toLowerCase();
    data.birthday = new Date(data.birthday);

    delete data?.documentTypeImage

    if (!idUploaded && !saleChannel) {
      notify('Adjunte su documento de identidad', 'error');
      setCedulaError(true)
      return null;
    }
    if (!licenceUploaded && !saleChannel) {
      notify('Adjunte su licencia de conducir', 'error');
      setLicenceError(true)
      return null;
    }

    let fileTopost = appendFile(filterParams(data));
    let user = { ...data };

    if (!isLoading) {
      setIsloading(true);
      customerService
        .createCustomer(fileTopost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            setIsloading(false);
            sessionStorage.setItem('personalData', JSON.stringify(user));
            setCustomer(response?.data?._id);
            setcurentStep(3);
          } else {
            setIsloading(false);
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const getBroadcastChannels = () => {
    broadCastService
      .getBroadcastChannels()
      .then((response) => {
        if (response.status === 200) {
          setBroadcastOptions(response?.data?.broadcastChannels);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const appendFile = (data) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    formData.append('membership', membership?._id);

    if (saleChannel) {
      formData.append('landing', 'Marqueting qr');
    }
    if (idUploaded) {
      formData.append('dni', idUploaded);
    }
    if (licenceUploaded) {
      formData.append('licenses', licenceUploaded);
    }

    return formData;
  };

  const prelaodData = () => {
    let personalData = sessionStorage.getItem('personalData');
    personalData = JSON.parse(personalData);
    if (personalData) {
      setValue('firstName', personalData?.firstName);
      setValue('lastName', personalData?.lastName);
      setValue('phonePrefix', personalData?.phonePrefix);
      setValue('phoneNumber', personalData?.phoneNumber);
      setValue('identificationType', personalData?.identificationType);
      setValue('identification', personalData?.identification);
      setValue('email', personalData?.email);
      setValue(
        'birthday',
        new Date(moment?.utc(personalData?.birthday)?.format('ddd MMM DD YYYY 05:mm:ss ZZ'))

      );
      setValue('identificationType', personalData?.identificationType);
      setValue('gender', personalData?.gender);
      setValue('maritalStatus', personalData?.maritalStatus);

      Promise.allSettled([getBroadcastChannels()]).then((r) => {
        setTimeout(() => {
          setValue('broadcastChannel', personalData?.broadcastChannel);
        }, 500);
      });

      setValue('identificationType', personalData?.identificationType);
    } else {
      getBroadcastChannels();
    }
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDrop1 });
  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({
      onDrop: onDrop2,
    });

  useEffect(() => {
    prelaodData();
  }, []);

  let validateDisabled = () => {
    return !watch('identificationType') || !watch('identification');
  };

  return (
    <>


      <LoadSpinner show={isLoading} />
      <div className="landing-form-container">
        <h5>Información Personal</h5>
        <p>(*) Campos obligatorios</p>
        <br />
        <form className='responsive-landing-form'>
          <div className="row">
            <Input
              bootstrap={'col-6'}
              label={'Tipo de Documento *'}
              type={'select'}
              name={'documentTypeImage'}
              register={register}
              placeholder={''}
              options={idDocumentTypeOptions}
              errors={errors?.documentTypeImage?.message}
            />
            <div className="col-6"></div>

            {watch("documentTypeImage") &&
              <div className="col-12 col-md-6">
                {idUploaded ? (
                  <FileComponent
                    file={idUploaded}
                    remove={() => setIdUploaded('')}
                  />
                ) : (
                  <SalesDropzone
                    instructions={'Adjuntar cédula de identidad / RIF *'}
                    maxWeight={'(JPG, PNG, PDF, máx. 3MB)'}
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    message="Arrastrar o buscar Documento"
                    error={cedulaError}
                  />
                )}
              </div>
            }
            {watch("documentTypeImage") &&
              <div className="col-12 col-md-6">
                {licenceUploaded ? (
                  <FileComponent
                    file={licenceUploaded}
                    remove={() => setLicenceUploaded('')}
                  />
                ) : (
                  <SalesDropzone
                    instructions={'Adjuntar licencia de conducir *'}
                    maxWeight={'(JPG, PNG, PDF, máx. 3MB)'}
                    getRootProps={getRootProps2}
                    getInputProps={getInputProps2}
                    message="Arrastrar o buscar Documento"
                    error={licenceError}
                  />
                )}
              </div>
            }

            {watch("documentTypeImage") &&

              <>
                <Input
                  bootstrap={'col-4 col-sm-2 col-md-2'}
                  label={'C.I / RIF*'}
                  type={'select'}
                  name={'identificationType'}
                  register={register}
                  placeholder={''}
                  options={idCodes}
                  errors={errors?.identificationType?.message}
                  onChange={() => setValue("identification", "")}
                />{' '}
                {
                  [idEnums?.J, idEnums?.E, idEnums?.P]?.includes(watch('identificationType')) &&
                  <Input
                    bootstrap={'col-8 col-sm-4 col-md-4'}
                    label={''}
                    type={'text'}
                    name={'identification'}
                    register={register}
                    placeholder={''}
                    errors={errors?.identification?.message}
                    onChange={getCustomerById}
                  />
                }
                {
                  ![idEnums?.J, idEnums?.E, idEnums?.P]?.includes(watch('identificationType')) &&
                  <Input
                    bootstrap={'col-8 col-sm-4 col-md-4'}
                    label={''}
                    type={'number'}
                    name={'identification'}
                    register={register}
                    placeholder={''}
                    errors={errors?.identification?.message}
                    onChange={getCustomerById}
                  />
                }
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Correo Electrónico *'}
                  type={'text'}
                  name={'email'}
                  register={register}
                  placeholder={''}
                  errors={errors?.email?.message}
                  disabled={validateDisabled()}
                />
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Correo Secundario'}
                  type={'text'}
                  name={'optionalEmail'}
                  register={register}
                  placeholder={''}
                  errors={errors?.optionalEmail?.message}
                  disabled={validateDisabled()}
                />
                <div className="col-12"></div>
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Nombre *'}
                  type={'text'}
                  name={'firstName'}
                  register={register}
                  placeholder={''}
                  errors={errors?.firstName?.message}
                  disabled={validateDisabled()}
                />
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Apellido *'}
                  type={'text'}
                  name={'lastName'}
                  register={register}
                  placeholder={''}
                  errors={errors?.lastName?.message}
                  disabled={validateDisabled()}
                />{' '}
                <Input
                  bootstrap={'col-4 col-sm-2 col-md-2'}
                  label={'Teléfono*'}
                  type={'select'}
                  name={'phonePrefix'}
                  register={register}
                  placeholder={''}
                  options={phoneCodes}
                  errors={errors?.phonePrefix?.message}
                  disabled={validateDisabled()}
                />{' '}
                <Input
                  bootstrap={'col-8 col-sm-4 col-md-4'}
                  label={''}
                  type={'number'}
                  name={'phoneNumber'}
                  register={register}
                  placeholder={''}
                  errors={errors?.phoneNumber?.message}
                  disabled={validateDisabled()}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={`Fecha de Nacimiento DD/MM/AAAA ${![idEnums?.J, idEnums?.R, idEnums?.G].includes(
                    watch('identificationType')
                  )
                    ? '*'
                    : ''
                    } `}
                  type={'date'}
                  name={'birthday'}
                  register={register}
                  control={control}
                  errors={errors?.birthday?.message}
                  disabled={validateDisabled()}
                />
                <Input
                  bootstrap={'col-4 col-sm-3 col-md-3'}
                  label={`Sexo  ${![idEnums?.J, idEnums?.R, idEnums?.G].includes(
                    watch('identificationType')
                  )
                    ? '*'
                    : ''
                    }`}
                  type={'select'}
                  name={'gender'}
                  register={register}
                  placeholder={''}
                  options={genderOptions}
                  errors={errors?.gender?.message}
                  disabled={validateDisabled()}
                />{' '}
                <Input
                  bootstrap={'col-4 col-sm-3 col-md-3'}
                  label={`Estado Civil  ${![idEnums?.J, idEnums?.R, idEnums?.G].includes(
                    watch('identificationType')
                  )
                    ? '*'
                    : ''
                    }`}
                  type={'select'}
                  name={'maritalStatus'}
                  register={register}
                  placeholder={''}
                  options={civilStateOptions}
                  errors={errors?.maritalStatus?.message}
                  disabled={validateDisabled()}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'¿Cómo te enteraste de nosotros? *'}
                  type={'select'}
                  options={broadcastOptions}
                  name={'broadcastChannel'}
                  placeholder={'Seleccione'}
                  register={register}
                  errors={errors?.broadcastChannel?.message}
                  disabled={validateDisabled()}
                  capitalizeOff={true}
                />{' '}

                {filterOption(broadcastOptions, "Otro")?._id === watch("broadcastChannel") &&
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Otro *'}
                    type={'text'}
                    name={'broadcastChannelDescription'}
                    register={register}
                    errors={errors?.broadcastChannelDescription?.message}
                  />
                }
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Instagram'}
                  type={'text'}
                  name={'instagram'}
                  placeholder={''}
                  register={register}
                  errors={errors?.instagram?.message}
                />{' '}
                <col className="md-6" />
              </>
            }

          </div>

          <div
            style={{ margin: '24px 0px 48px 0px' }}
            className="buttons-center-container"
          >
            <AltButton
              label={'Regresar'}
              action={() => setcurentStep(curentStep - 1)}
            />
            <ActionButton label={'Siguiente'} action={onSubmit} />
          </div>
        </form>
      </div>
    </>
  );
};

import { useEffect, useState } from 'react';
import logo from '../../../images/logo.svg';
import { membershipCatalogService } from '../../../services/membershipCatalogService';
import { membershipService } from '../../../services/membershipService';
import { notify } from '../../../assets/helpers/toast';
import { ActionButton, AltButton } from '../../common/Buttons/Buttons';
import { codeService } from '../../../services/codeService';
import { clearObject, filterParams } from '../../../assets/helpers/funciones';
import {
  freeCodeTypeOptions,
  LANDING,
  codeTypeEnum,
  planTypes,
} from '../../../assets/helpers/options.js';
import {
  MembershipButton,
  WizzardProgressBar,
} from './LandingComponents/LandingFreeComponents';
import { LandingFreeDiscountForm } from '../../common/Forms/Form';
import { FreePersonalInfoForm } from './LandingFreeForms/FreePersonalInfoForm';
import { FreeCreateVehicleForm } from './LandingFreeForms/FreeCreateVehicleForm';
import { SuccesPurchase } from './LandingFreeForms/SuccesPurchase';
import { rateService } from '../../../services/rateService';
import { Header } from '../../common/Header/Header';
import { validateCodeEnum } from '../../../assets/helpers/options.js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as schemas from '../../../assets/schemas/schemas';

import './LandingFree.scss';
import { giftCardService } from '../../../services/giftCardService.js';

export const LandingFree = () => {
  const [curentStep, setcurentStep] = useState(1);
  const [membership, setMembership] = useState('');
  const [customer, setCustomer] = useState('');
  const [giftCard, setGiftCard] = useState('');
  const [dollarRate, setDollarRate] = useState(1);
  const [selectedMembership, setSelectedMembership] = useState('');
  const [membershipOptions, setMembershipOptions] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [cashed, setCashed] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemas.validateFreeDiscountSchema()),
  });

  const createMembership = () => {
    let dataToPost = {
      membershipId: selectedMembership?._id,
      giftcard:
        watch('codeType') === codeTypeEnum?.GIFTCARD ? giftCard?._id : '',
      priceInfo: {
        finalPriceBeforeDiscounts: selectedMembership?.originalPrice,
        initialValue: selectedMembership?.originalPrice,
        finalPrice: selectedMembership?.originalPrice,
      },
      discountCode: discountCode?._id,
    };
    dataToPost = clearObject(dataToPost);

    if (!dataToPost?.rcv) {
      delete dataToPost?.rcv;
    }

    if (!isLoading) {
      setIsloading(true);
      membershipService
        .createMembership(dataToPost)
        .then(response => {
          if ([200, 201].includes(response?.status)) {
            setMembership(response?.data?.membership);
            setcurentStep(2);
            setIsloading(false);
          } else {
            notify(`${response?.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch(() => {
          setIsloading(false);
        });
    }
  };

  const transformToUppercase = () => {
    setValue('code', watch('code').toUpperCase());
    setCashed(false);
  };

  const hadleCodeType = () => {
    setValue('code', '');
    setMembershipOptions([]);
    setGiftCard('');
    setCashed(false);
  };

  const getDollarRate = () => {
    rateService
      .getDollarRate()
      .then(response => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => {});
  };

  const selectMembership = membership => {
    setSelectedMembership(membership);
    sessionStorage.removeItem('identification');
  };

  const getAvailableMemberships = (selectedMembership, autoselect) => {
    let params = {
      landing: LANDING?.MARKETING,
    };

    membershipCatalogService
      .getAvailableMemberships(filterParams(params))
      .then(response => {
        if (response.status === 200) {
          let data = [...response?.data];

          let validGiftcard = response?.data?.some(r => r?.discountByGiftCard);

          if (validGiftcard) {
            data = data?.filter(d => d?.discountByGiftCard);
          }

          setMembershipOptions(data);
          if (data?.length === 1 && autoselect) {
            setSelectedMembership(data[0]);
          }
          // If there is a membership selected apply recharge
          let [option] = data?.filter(d => d?._id === selectedMembership?._id);
          if (option) {
            setSelectedMembership(option);
          }
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => {});
  };
  const getMembershipByCode = () => {
    giftCardService
      .validateDiscount('giftcards', {
        code: watch('code'),
      })
      .then(response => {
        if (response.status === 200) {
          if (
            [planTypes?.PLUSF4, planTypes?.GOLDF4]?.includes(
              response?.data?.membership?.code
            )
          ) {
            setMembershipOptions([response?.data?.membership]);

            let data = response?.data?.membership;
            data.originalPrice = response?.data?.value;

            setGiftCard(response?.data);
            setSelectedMembership(response?.data?.membership);
          } else {
            setMembershipOptions([]);
            setSelectedMembership('');
          }
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => {});
  };

  const onSubmit = handleSubmit(async data => {
    setCashed(true);
    setDiscountCode('');

    let params = {
      code: data?.code,
      type: validateCodeEnum?.NEW,
    };

    if (!isLoading) {
      if (watch('codeType') === codeTypeEnum?.GIFTCARD) {
        getMembershipByCode();
      }
      if (watch('codeType') === codeTypeEnum?.DISCOUNT) {
        codeService
          .validateCode(filterParams(params))
          .then(response => {
            if ([200, 201].includes(response.status)) {
              setIsloading(false);
              setDiscountCode(response?.data);
              getAvailableMemberships(selectedMembership, true);
            } else {
              if ([404].includes(response.status)) {
                notify(`Codigo de descuento no encontrado o expirado`, 'info');
                setIsloading(false);
                return null;
              }
              notify(`${response.message}`, 'error');
              setIsloading(false);
            }
          })
          .catch(error => {
            setIsloading(false);
          });
      }
    }
  });

  const cancel = () => {
    setSelectedMembership('');
  };

  const clearStorageData = () => {
    sessionStorage.removeItem('personalData');
    sessionStorage.removeItem('payment');
    sessionStorage.removeItem('vehicle');
    sessionStorage.removeItem('identification');
  };

  useEffect(() => {
    getDollarRate();
    clearStorageData();
  }, []);

  useEffect(() => {}, []);

  const membershipApplied = (appliesToMemberships, options) => {
    if (watch('codeType') === codeTypeEnum?.GIFTCARD) {
      return options;
    }

    let matchingElements = [];

    if (appliesToMemberships?.length > 0 && options?.length > 0) {
      matchingElements = options?.filter(obj1 =>
        appliesToMemberships.some(obj2 => obj2._id === obj1._id)
      );
    }
    return matchingElements;
  };
  return (
    <>
      <Header />
      <div style={{ minHeight: '1000px' }}>
        <div className="container landing-container">
          <div className="row">
            <img src={logo} alt="logo" className="landing-logo" />

            <div className="buttons-container">
              <WizzardProgressBar
                curentStep={curentStep}
                // setcurentStep={setcurentStep}
              />
            </div>
            {curentStep === 1 && (
              <>
                <div action="">
                  <>
                    <h1 className="sub-title-component">
                      Canjea aquí tu código o tarjetas
                    </h1>
                    <LandingFreeDiscountForm
                      register={register}
                      errors={errors}
                      options={{
                        codeTypeOptions: freeCodeTypeOptions,
                      }}
                      onChange={{
                        transformToUppercase: transformToUppercase,
                        hadleCodeType: hadleCodeType,
                      }}
                      onSubmit={onSubmit}
                    />
                  </>
                  {membershipOptions?.length > 0 && (
                    <>
                      {membershipApplied(
                        discountCode?.appliesToMemberships,
                        membershipOptions
                      )?.length > 0 &&
                        cashed && (
                          <>
                            <h1 className="sub-title-component">
                              Selecciona tu Plan
                            </h1>
                            <div className="membership-buttons-container">
                              <MapAndPreselect
                                memberships={membershipApplied(
                                  discountCode?.appliesToMemberships,
                                  membershipOptions
                                )}
                                selectMembership={selectMembership}
                                selectedMembership={selectedMembership}
                              />
                            </div>
                          </>
                        )}
                      {membershipApplied(
                        discountCode?.appliesToMemberships,
                        membershipOptions
                      )?.length === 0 &&
                        cashed &&
                        discountCode && (
                          <h6
                            style={{
                              textAlign: 'center',
                              fontSize: '18px',
                              marginTop: '24px',
                            }}
                          >
                            No hay planes disponibles para el código ingresado
                          </h6>
                        )}
                    </>
                  )}
                  {membershipOptions?.length === 0 && cashed && (
                    <h6
                      style={{
                        textAlign: 'center',
                        fontSize: '18px',
                        marginTop: '24px',
                      }}
                    >
                      No hay planes disponibles para el código ingresado
                    </h6>
                  )}
                  {selectedMembership && (
                    <div
                      style={{ marginTop: '24px', marginBottom: '70px' }}
                      className="buttons-center-container "
                    >
                      <AltButton label={'Cancelar'} action={cancel} />
                      <ActionButton
                        label={'Siguiente'}
                        action={createMembership}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            {curentStep === 2 && (
              <FreePersonalInfoForm
                setcurentStep={setcurentStep}
                setCustomer={setCustomer}
                membership={membership}
                curentStep={curentStep}
                code={selectedMembership?.code}
              />
            )}
            {curentStep === 3 && (
              <FreeCreateVehicleForm
                curentStep={curentStep}
                setcurentStep={setcurentStep}
                membership={membership}
                customer={customer}
                code={selectedMembership?.code}
              />
            )}
            {curentStep === 4 && (
              <SuccesPurchase
                curentStep={curentStep}
                setcurentStep={setcurentStep}
                membership={membership}
                selectedMembership={selectedMembership}
                customer={customer}
                dollarRate={dollarRate}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const MapAndPreselect = props => {
  const { memberships, selectMembership, selectedMembership } = props;

  if (memberships?.length === 1) {
    selectMembership(memberships[0]);
  }

  return (
    <>
      {memberships.map(m => (
        <MembershipButton
          key={m._id}
          membership={m}
          selectMembership={selectMembership}
          selectedMembership={selectedMembership}
        />
      ))}
    </>
  );
};

export const operationsRoles = {
  operationsAdmin: 'OPERATIONS_ADMIN',
  operationsDev: 'OPERATIONS_DEVELOPER',
  operationsSupervisor: 'OPERATIONS_SUPERVISOR',
  operationsOperator: 'OPERATIONS_OPERATOR',
}

export const readRole = (role) => {
  if (role === operationsRoles.operationsDev) {
    return 'Desarrollador'
  }
  if (role === operationsRoles.operationsAdmin) {
    return 'Admin'
  }
  if (role === operationsRoles.operationsSupervisor) {
    return 'Supervisor'
  }
  if (role === operationsRoles.operationsOperator) {
    return 'Operador'
  }
}

export const salesRoles = {
  RCV: 'SALES_RCV',
  SALES_DEVELOPER: 'SALES_DEVELOPER',
  SALES_ADMIN: 'SALES_ADMIN',
  SALES_SUPERVISOR: 'SALES_SUPERVISOR',
  SALES_HUNTER: 'SALES_HUNTER',
  SALES_FARMER: 'SALES_FARMER',
  SALES_COUNTER: 'SALES_COUNTER',
  SALES_MARKETING: 'SALES_MARKETING',
  SALES_ADMIN_AUX: 'SALES_ADMIN_AUX',
}

export const transcriptRoles = (role) => {
  let src = ''
  switch (role) {
    // Operaciones
    case operationsRoles?.operationsAdmin:
      src = 'Admin de Operaciones '
      break
    case operationsRoles?.operationsDev:
      src = 'Desarrollador de Operaciones '
      break
    case operationsRoles?.operationsSupervisor:
      src = 'Supervisor de Operaciones'
      break
    case operationsRoles?.operationsOperator:
      src = 'Operador'
      break
    // Ventas
    case salesRoles?.SALES_DEVELOPER:
      src = 'Desarrollador'
      break
    case salesRoles?.SALES_ADMIN:
      src = 'Admin de Ventas'
      break
    case salesRoles?.SALES_SUPERVISOR:
      src = 'Supervisor de Ventas'
      break
    case salesRoles?.SALES_HUNTER:
      src = 'Hunter'
      break
    case salesRoles?.SALES_FARMER:
      src = 'Farmer'
      break
    case salesRoles?.SALES_COUNTER:
      src = 'Counter'
      break
    case salesRoles?.SALES_MARKETING:
      src = 'Marketing'
      break
    case salesRoles?.SALES_ADMIN_AUX:
      src = 'Aux Administrativo'
      break
    case salesRoles?.RCV:
      src = 'RCV'
      break
    default:
      src = role
  }
  return src
}

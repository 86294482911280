import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../assets/schemas/schemas';
import { LoginForm } from '../../common/Forms/Form';
import { useHistory } from 'react-router-dom';
import { LogoButton } from '../../common/Buttons/Buttons';
import logo from '../../../images/logo.svg';
import { loginService } from '../../../services/loginService.js';
import { notify } from '../../../assets/helpers/toast';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import { salesRoles } from '../../../assets/helpers/roles';
import './Login.scss';

export const Login = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schemas.LoginSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  let history = useHistory();
  // Function that handles the logout request

  const redirect = (role) => {
    const roleOptions = Object.keys(salesRoles).map((key) => salesRoles[key]);
    if (!roleOptions.includes(role)) {
      notify('Su usuario no tiene permisos para la plataforma', 'error');
      localStorage.clear();
      history.push('/login');
      return null;
    }

    if (role === salesRoles?.SALES_MARKETING) {
      history.push('/modules/giftcard-sales');
      return null
    }
    if (role === salesRoles?.RCV) {
      history.push('/dashboard/exportable');
      return null
    }
    else {
      history.push('/dashboard/analytics');
      return null
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    setIsloading(true);
    loginService
      .login(data)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem(
            `accessToken`,
            response?.data?.tokens?.accessToken
          );
          localStorage.setItem(
            `refreshToken`,
            response?.data?.tokens?.refreshToken
          );
          localStorage.setItem(`user`, JSON.stringify(response?.data?.user));
          redirect(response?.data?.user?.roles[0]?.code);
          setIsloading(false);
        } else {
          notify(`${response.message}`, 'error');
          setIsloading(false);
        }
      })
      .catch((error) => {
        setIsloading(false);
      });
  });

  const preloadInputs = () => {
    let develop = process.env.REACT_APP_DEVELOP;

    if (develop == 'true') {
      setValue('email', process.env.REACT_APP_SALES_USER);
      setValue('password', process.env.REACT_APP_SALES_PASSWORD);
    }
  };

  useEffect(() => {
    preloadInputs();
  }, []);

  return (
    <>
      <LoadSpinner label={'Iniciando Sesión'} show={isLoading} />
      <div className="container view-content">
        <div className="row">
          <div className="col-12 login-page-container">
            <div className="login-form">
              <div className="login-logo-container">
                <LogoButton link={'/'} src={logo} />
                <br />
                <h5>Módulo de Ventas </h5>
                <div className="horizontal-bar-container">
                  <div></div>
                </div>
              </div>
              <LoginForm
                t={t}
                register={register}
                errors={errors}
                action={onSubmit}
              />
              {/* <div className="register-call-to-action-container">
                ¿No tienes una cuenta? {'    '}
                <NavLink to={'/sign-up'}>Regístrate</NavLink>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React, { useCallback, useRef } from 'react';
import uploadImage from '../../../images/uploadImage.svg';
import fileImage from '../../../images/file.png';
import closeOutline from '../../../images/close-outline.svg';
import { useDropzone } from 'react-dropzone';
import { notify } from '../../../assets/helpers/toast';
import './Dropzone.scss';

export const FileComponent = props => {
  const { file, remove } = props;

  const truncateString = str => {
    const stringLength = 20;
    if (str.length > stringLength) {
      return str.substring(0, stringLength) + '...';
    }
    return str;
  };

  return (
    <div className="file-component">
      <div>
        <img src={fileImage} alt="file" className="file" />
        <h6>{truncateString(file.name)}</h6>
      </div>
      {remove && (
        <img
          onClick={() => remove()}
          src={closeOutline}
          alt="closeOutline"
          className="file-close"
        />
      )}
    </div>
  );
};

export const Dropzone = props => {
  const { getRootProps, getInputProps, message } = props;

  return (
    <div className="file-dropzone-component">
      <div {...getRootProps({ name: 'testtesttest' })}>
        <img src={uploadImage} alt="upload" />
        <input
          name="testtesttest"
          {...getInputProps({ name: 'testtesttest' })}
        />
        <p>{message}</p>
      </div>
    </div>
  );
};

export const NewDropzone = props => {
  const {
    index,
    fileUploaded,
    img,
    setFileUploaded,
    setShowImages,
    showImages,
  } = props;

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
      };
      reader.readAsArrayBuffer(file);

      if (
        !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          file.type
        )
      ) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        let files = fileUploaded;

        files[index] = acceptedFiles[0];
        setFileUploaded([...files]);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const removeFile = index => {
    let files = fileUploaded;
    files[index] = {};
    setFileUploaded([...files]);
  };

  const changeUploadedImage = index => {
    let show = showImages;
    show[index] = !show[index];
    setShowImages([...show]);
  };

  return (
    <div className="dropzone-container">
      {img?.label && <h6>{img?.label}</h6>}

      {img?.uploaded && showImages[index] ? (
        <FileComponent
          file={{ name: img?.label + 'A' }}
          remove={() => changeUploadedImage(index)}
        />
      ) : fileUploaded[index]?.name ? (
        <FileComponent
          file={fileUploaded[index]}
          remove={() => removeFile(index)}
        />
      ) : (
        <Dropzone
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          message={img?.message}
        />
      )}

      {/* {img?.label && showImages[index] ? (
        <FileComponent
          file={{ name: img?.label }}
          remove={() => changeUploadedImage(index)}
        />
      ) : fileUploaded[index]?.name ? (
        <FileComponent
          file={fileUploaded[index]}
          remove={() => removeFile(index)}
        />
      ) : (
        <Dropzone
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          message={img?.message}
        />
      )} */}
    </div>
  );
};

export const SalesDropzone = props => {
  const {
    getRootProps,
    getInputProps,
    message,
    instructions,
    maxWeight,
    error,
  } = props;

  return (
    <div className="file-dropzone-component">
      <h6>{instructions}</h6>
      <p>{maxWeight}</p>
      <div
        className={`sales-dropzone-${error}`}
        {...getRootProps({ name: 'testtesttest' })}
      >
        <img src={uploadImage} alt="upload" />
        <input
          name="testtesttest"
          {...getInputProps({ name: 'testtesttest' })}
        />
        <p>{message}</p>
      </div>
    </div>
  );
};


export const FileDropzone = (props) => {

  const { index, handleDrop, maxWeight, instructions, name, file, type, accept, message, removeFile, error } = props

  const fileInputRef = useRef(null);
  if (!file) {

    return (
      <div className="file-dropzone-component">
        <h6>{instructions || "Adjuntar Comprobante *"}</h6>
        <p>{maxWeight || '(JPG, PNG, PDF, máx. 3MB)'}</p>
        <div
          className={`sales-dropzone-${error}`}
          onClick={() => fileInputRef.current.click()}
          onDrop={(e) => handleDrop(e, index)}
          onDragOver={(e) => handleDrop(e, index)}
        >
          <img src={uploadImage} alt="upload" />
          <input
            name={name || "image"}
            type={type || 'file'}
            accept={accept || "image/*"}
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={(e) => handleDrop(e, index)}
          />
          <p>{message || "Arrastrar o buscar Documentos"}</p>
        </div>
      </div>
    )
  } else return (
    <FileComponent
      file={file}
      remove={() => removeFile(index)}
    />
  )

}
export const RenewalExportTypeBreadcrumb = props => {
  const { action } = props;
  return (
    <table style={{ width: '100%' }}>
      <tr className="export-breadcrumb-options" onClick={() => action()}>
        <td
          style={{
            padding: '3px 8px 3px 8px',
            backgroundColor: '#fff',
          }}
        >
          Completo
        </td>
      </tr>
      <tr
        className="export-breadcrumb-options"
        onClick={() => action('Tedexis')}
      >
        <td
          style={{
            padding: '3px 8px 3px 8px',
            backgroundColor: '#fff',
          }}
        >
          Tedexis
        </td>
      </tr>
      <tr
        className="export-breadcrumb-options"
        onClick={() => action('B2chat')}
      >
        <td
          style={{
            padding: '3px 8px 3px 8px',
            backgroundColor: '#fff',
          }}
        >
          B2Chat
        </td>
      </tr>
      <tr
        className="export-breadcrumb-options"
        style={{ border: 'none', margin: '4px' }}
        onClick={() => action('Brevo')}
      >
        <td
          style={{
            padding: '3px 8px 3px 8px',
            backgroundColor: '#fff',
            margin: '4px',
          }}
        >
          Brevo
        </td>
      </tr>
    </table>
  );
};

import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import autoRetail from '../../../images/autoRetail.svg';
import motoPregunta from '../../../images/motoPregunta.svg';
import camionPregunta from '../../../images/camionPregunta.svg';
import automovilPregunta from '../../../images/automovilPregunta.svg';
import arrowDownQuestion from '../../../images/arrowDownQuestion.svg';
import camionetaPegunta from '../../../images/camionetaPegunta.svg';
import infoPlanClassic from '../../../images/infoPlanClassic.svg';
import infoPlanPlus from '../../../images/infoPlanPlus.svg';
import infoPlanGold from '../../../images/infoPlanGold.svg';
import infoPlanMotorider from '../../../images/infoPlanMotorider.svg';
import infoPlanRetro from '../../../images/infoPlanRetro.svg';
import yellowTag from '../../../images/yellowTag.svg';
import logo from '../../../images/logo.svg';
import { membershipCatalogService } from '../../../services/membershipCatalogService';
import authService from '../../../services/authService';
import { notify } from '../../../assets/helpers/toast';
import {
  capitalize
} from '../../../assets/helpers/funciones';
import { Header } from '../../common/Header/Header';
import { CheckBox } from '../../common/Buttons/Buttons';
import { ActionButton, AltButton } from '../../common/Buttons/Buttons';
import './RetailLanding.scss';


const vehiclesEnum = {
  auto: "auto",
  camioneta: "camioneta",
  moto: "moto",
  camion: "camion",
  retro: "retro",
}

const plans = [
  { name: "Automóvil", img: autoRetail, value: vehiclesEnum?.auto },
  { name: "Camioneta", img: camionetaPegunta, value: vehiclesEnum?.camioneta },
  { name: "Moto", img: motoPregunta, value: vehiclesEnum?.moto },
  { name: "Camión", img: camionPregunta, value: vehiclesEnum?.camion }
]

export const RetailLanding = () => {

  const { saleChannel } = useParams();


  const [selectedPlan, setSelectedPlan] = useState("");
  const [memberships, setMemberships] = useState("");
  const [selectedMembership, setSelectedMembership] = useState("");
  const history = useHistory();

  const redirectRetail = () => {
    history?.push(`/landing/${saleChannel}`);
  }



  const handlePlan = (value) => {
    setSelectedPlan((prevState) => {
      return prevState === value ? "" : value;
    });
    setSelectedMembership("")
  }

  const handleRetro = (value) => {
    setSelectedPlan((prevState) => {
      return prevState === "retro" ? "" : "retro";
    });
    setSelectedMembership("")
  }

  const handleMembership = (value) => {
    setSelectedMembership((prevState) => {
      return prevState === value ? "" : value;
    });
  }

  const getPricesForRetail = () => {

    membershipCatalogService
      .getPricesForRetail()
      .then((response) => {
        if (response.status === 200) {
          setMemberships(response?.data);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
    return true;
  };


  useEffect(() => {
    getPricesForRetail();
  }, []);

  return (
    <>
      {authService?.getToken() ?
        <Header /> :
        <div className="header-component-retail">
          <div className="header-links-container">
            <div className="navbar-options-container">
              <img
                // onClick={() => redirectHome()}
                src={logo}
                alt="logo"
                className="header-logo"
              />
            </div>
          </div>
        </div>
      }

      <div style={{ minHeight: '1000px' }}>
        <div className="buttons-center-container">
          <img src={automovilPregunta} alt="automovilPregunta" className="automovilPregunta" />
        </div>
        <div className="buttons-center-container">
          <img src={arrowDownQuestion} alt="automovilPregunta" className="flechaPregunta" />
        </div>
        <div className="container retail-landing-container landing-container">

          <div className="membership-buttons-container">
            {plans?.map((p) => <PlanButton plan={p} selectedPlan={selectedPlan} handlePlan={handlePlan} />)}
          </div>

          {selectedPlan !== "camion" &&
            <div style={{ marginTop: "24px" }} className="buttons-center-container">
              <CheckBox button={{ name: "año del vehículo entre 1970 y 1989.", show: selectedPlan === "retro" }} action={handleRetro} />
            </div>
          }


          <div className="membership-buttons-container">
            <MembershipInfo membership={memberships} type={"classic"} selectedPlan={selectedPlan} handleMembership={handleMembership} selectedMembership={selectedMembership} />
            <MembershipInfo membership={memberships} type={"plus"} selectedPlan={selectedPlan} handleMembership={handleMembership} selectedMembership={selectedMembership} />
            <MembershipInfo membership={memberships} type={"gold"} selectedPlan={selectedPlan} handleMembership={handleMembership} selectedMembership={selectedMembership} />
            <MembershipInfo membership={memberships} type={"motorider"} selectedPlan={selectedPlan} handleMembership={handleMembership} selectedMembership={selectedMembership} />
            <MembershipInfo membership={memberships} type={"retro"} selectedPlan={selectedPlan} handleMembership={handleMembership} selectedMembership={selectedMembership} />
          </div>



          {selectedPlan === "camion" && !selectedMembership && <ChasisType type={"Corto"} weight={"3 a 5"} />}
          <div className="membership-buttons-container">
            <MembershipInfo membership={memberships} type={"camionChasisCortoClassic"} selectedPlan={selectedPlan} handleMembership={handleMembership} selectedMembership={selectedMembership} />
            <MembershipInfo membership={memberships} type={"camionChasisCortoPlus"} selectedPlan={selectedPlan} handleMembership={handleMembership} selectedMembership={selectedMembership} />
            <MembershipInfo membership={memberships} type={"camionChasisCortoGold"} selectedPlan={selectedPlan} handleMembership={handleMembership} selectedMembership={selectedMembership} />
          </div>


          {selectedPlan === "camion" && !selectedMembership && <ChasisType type={"Largo"} weight={"6 a 10"} />}
          <div className="membership-buttons-container">
            <MembershipInfo membership={memberships} type={"camionChasisLargoClassic"} selectedPlan={selectedPlan} handleMembership={handleMembership} selectedMembership={selectedMembership} />
            <MembershipInfo membership={memberships} type={"camionChasisLargoPlus"} selectedPlan={selectedPlan} handleMembership={handleMembership} selectedMembership={selectedMembership} />
            <MembershipInfo membership={memberships} type={"camionChasisLargoGold"} selectedPlan={selectedPlan} handleMembership={handleMembership} selectedMembership={selectedMembership} />
          </div>




          {selectedMembership &&
            <div style={{ marginBottom: "64px" }} className="buttons-center-container">
              <AltButton label={"Conocer más planes"} action={() => handleMembership()} />
              <ActionButton label={"Comprar mi plan"} action={() => redirectRetail()} />
            </div>
          }

        </div>
      </div >
    </>
  );
};


export const PlanButton = (props) => {

  const { plan, selectedPlan, handlePlan } = props;

  let selected = ""

  if (plan.value === selectedPlan) {
    selected = 'selected';
  }

  return (
    <div className='retail-plan-container'>
      <div onClick={() => handlePlan(plan?.value)} className={`retail-plan plan-button-container plan-button-container-${selected}`}>
        <img src={plan?.img} alt="autoRetail" />
      </div>
      <h6 style={{ textAlign: "center" }}>{plan?.name}</h6>
    </div >
  )
}


export const MembershipInfo = (props) => {

  const { membership, handleMembership, selectedMembership, type, selectedPlan } = props;

  let selected = ""

  if (type === selectedMembership) {
    selected = 'selected';
  }

  if (selectedMembership && type !== selectedMembership) {
    return null
  }

  if (!validateVehiclePlan(type, selectedPlan)) {
    return null
  }

  return (
    <div onClick={() => handleMembership(type)} className={`membership-info-component membership-info-container membership-info-container-${selected}`}>
      <img className='yellow-tag' src={yellowTag} alt="" />
      <h5 className='membership-info-price'>
        Desde <br />
        {membership[type]?.price}$</h5>
      <div style={{ borderRadius: "24px" }} className={`membership-info-title-container membership-info-color-${type}`}>
        <img src={membership?.src} alt="" />
        <LogoImageComponent type={type} />
      </div>
      <PlanMessage type={type} />
      {/* <h6 className='membership-info-text' >Ideal para personas que <br /> <b>manejan poco dentro</b> <br /> de la ciudad.</h6> */}


      {!selectedMembership &&
        <ActionButton label={"Ver más"} color={`membership-info-color-${type}`} />
      }


      <div style={{ width: "100%" }} className="buttons-container">
        {selectedMembership && membership[type]?.services?.map((s) => <Service service={s} selectedMembership={selectedMembership} />)}
      </div>

    </div>
  )
}


export const Service = (props) => {

  const { service, selectedMembership } = props;

  return (
    <div className={`retail-membership-info-service`}>
      <div className={`retail-membership-info-logo membership-info-color-${["camionChasisCortoGold", "camionChasisLargoGold", "gold"]?.includes(selectedMembership) ? "dorado" : selectedMembership}`}>
        <img src={service?.img} alt="" />
      </div>
      <p>
        {capitalize(service?.name)}
      </p>
    </div>
  )

}

export const LogoImageComponent = (props) => {
  const { type } = props;

  let src = ''
  switch (type) {
    // Operaciones
    case "classic":
      src = infoPlanClassic
      break
    case "plus":
      src = infoPlanPlus
      break
    case "gold":
      src = infoPlanGold
      break
    case "motorider":
      src = infoPlanMotorider
      break
    case "retro":
      src = infoPlanRetro
      break

    default:
      src = type
  }


  if (["camionChasisCortoClassic", "camionChasisLargoClassic"]?.includes(type)) {
    src = infoPlanClassic
  }
  if (["camionChasisCortoPlus", "camionChasisLargoPlus"]?.includes(type)) {
    src = infoPlanPlus
  }

  if (["camionChasisCortoGold", "camionChasisLargoGold"]?.includes(type)) {
    src = infoPlanGold
  }



  return (
    <img src={src} alt="src" />
  )
}

export const PlanMessage = (props) => {
  const { type } = props;

  if (["classic", "camionChasisCortoClassic", "camionChasisLargoClassic"]?.includes(type)) {
    return <h6 className='membership-info-text'>Ideal para personas que <br />
      <b>manejan poco dentro </b><br />
      de la ciudad.</h6>
  }
  if (["plus", "camionChasisCortoPlus", "camionChasisLargoPlus"]?.includes(type)) {
    return <h6 className='membership-info-text'>Ideal para personas que <br />
      <b>manejan con frecuencia</b><br />
      dentro de la ciudad y <br />
      sus alrededores</h6>
  }
  if (["gold", "camionChasisCortoGold", "camionChasisLargoGold"]?.includes(type)) {
    return <h6 className='membership-info-text'>Ideal para personas que <br />
      <b>suelen hacer viajes fuera</b><br />
      de la ciudad.</h6>
  }
  if (type === "motorider") {
    return <h6 className='membership-info-text'>Ideal para personas que <br />
      <b>manejan con frecuencia</b><br />
      <b>su moto dentro de</b><br />
      de la ciudad.</h6>
  }
  if (type === "retro") {
    return <h6 className='membership-info-text'>Ideal para personas que <br />
      <b>poseen vehículos entre 1970 y 1989.</b><br />
    </h6>
  }


  else return null

}






export const validateVehiclePlan = (type, selectedPlan) => {

  // Retro
  if ([vehiclesEnum?.retro]?.includes(selectedPlan) && ["retro"]?.includes(type)) {
    return true
  }

  // Carro y Camioneta
  if ([vehiclesEnum?.auto, vehiclesEnum?.camioneta,]?.includes(selectedPlan) && ["classic", "plus", "gold"]?.includes(type)) {
    return true
  }

  // Motorider
  if ([vehiclesEnum?.moto]?.includes(selectedPlan) && ["motorider"]?.includes(type)) {
    return true
  }

  // Camion
  if ([vehiclesEnum?.camion]?.includes(selectedPlan) && ["camionChasisCortoClassic", "camionChasisCortoPlus", "camionChasisCortoGold", "camionChasisLargoClassic", "camionChasisLargoPlus", "camionChasisLargoGold"]?.includes(type)) {
    return true
  }

}

export const ChasisType = (props) => {

  const { type, weight } = props

  return (
    <div style={{ textAlign: "center" }} >
      <h4 style={{ margin: "0px" }}>Chasis {type}</h4>
      <h5 style={{ color: "#F27730", margin: "0px" }}>({weight} Toneladas)</h5>
    </div>
  )
}
import membershipClasic from '../../../../images/membershipClasic.png';
import membershipGold from '../../../../images/membershipGold.svg';
import membershipFree from '../../../../images/membershipFree.png';
import membershipPlus from '../../../../images/membershipPlus.png';
import membershipMotorider from '../../../../images/membershipMotorider.svg';
import membershipBasico from '../../../../images/membershipBasico.svg';
import membershipRCVElite from '../../../../images/membershipRCVElite.svg';
import step1WizSelected from '../../../../images/step1WizSelected.svg';
import step2WizSelected from '../../../../images/step2WizSelected.svg';
import step3WizSelected from '../../../../images/step3WizSelected.svg';
import motoriderAV from '../../../../images/motoriderAV.svg';
import step5WizSelected from '../../../../images/step5WizSelected.svg';
import paymentMethodCash from '../../../../images/paymentMethodCash.svg';
import paymentMethodTDC from '../../../../images/paymentMethodTDC.svg';
import paymentMethodPaypal from '../../../../images/paymentMethodPaypal.svg';
import paymentMethodPagoMovil from '../../../../images/paymentMethodPagoMovil.svg';
import paymentMethodPOV from '../../../../images/paymentMethodPOV.svg';
import paymentMethodReserve from '../../../../images/paymentMethodReserve.svg';
import paymentMethodTransfer from '../../../../images/paymentMethodTransfer.svg';
import paymentMethodZelle from '../../../../images/paymentMethodZelle.svg';
import binancePayMethod from '../../../../images/binancePayMethod.svg';
import { paymentMethods } from '../../../../assets/helpers/options';

import canje from '../../../../images/canje.svg';
import './LandingComponents.scss';

export const WizzardBar = (props) => {
  const { step, currentStep } = props;
  let uncompleted = '';

  if (currentStep < step) {
    uncompleted = 'uncompleted';
  }
  return <div className={`wizzard-bar wizzard-bar-${uncompleted}`}></div>;
};

export const WizzardButton = (props) => {
  const { src, step, currentStep, setcurentStep } = props;

  let uncompleted = '';

  if (currentStep < step) {
    uncompleted = 'uncompleted';
  }

  return (
    <img
      onClick={() => setcurentStep(step)}
      className={`wizzard-logo-button  wizzard-logo-button-${uncompleted}`}
      src={src}
    />
  );
};

export const WizzardProgressBar = (props) => {
  const { curentStep, setcurentStep } = props;

  return (
    <div className={`wizzard-container`}>
      <WizzardButton
        src={step1WizSelected}
        step={1}
        currentStep={curentStep}
        setcurentStep={setcurentStep}
      />
      <WizzardBar step={2} currentStep={curentStep} />
      <WizzardButton
        src={step2WizSelected}
        step={2}
        currentStep={curentStep}
        setcurentStep={setcurentStep}
      />
      <WizzardBar step={3} currentStep={curentStep} />
      <WizzardButton
        src={step3WizSelected}
        step={3}
        currentStep={curentStep}
        setcurentStep={setcurentStep}
      />
      {/* <WizzardBar step={4} currentStep={curentStep} />
      <WizzardButton
        src={step4WizSelected}
        step={4}
        currentStep={curentStep}
        setcurentStep={setcurentStep}
      /> */}
      <WizzardBar step={4} currentStep={curentStep} />
      <WizzardButton
        src={step5WizSelected}
        step={4}
        currentStep={curentStep}
        setcurentStep={setcurentStep}
      />
    </div>
  );
};

export const MembershipButton = (props) => {
  const { membership, selectedMembership, selectMembership, affiliation } =
    props;
  let selected = '';

  if (membership?._id === selectedMembership?._id) {
    selected = 'selected';
  }

  if (!membership?.originalPrice && !affiliation) {
    return null;
  } else {
    return (
      <div
        onClick={() =>
          selectMembership(
            membership?._id === selectedMembership?._id ? '' : membership
          )
        }
        className={
          `plan-button-container` + ` plan-button-container-${selected}`
        }
      >
        <MembershipImage membership={membership} />
        {/* <h6>{membership?.code}</h6> */}
        <h6 style={{ marginTop: '12px' }}>
          {membership?.finalPrice !== membership?.originalPrice &&
            membership?.originalPrice > membership?.finalPrice ? (
            <del style={{ color: 'gray' }}>
              {'   ' + membership?.originalPrice + '$   '}
            </del>
          ) : (
            ''
          )}
          {membership?.finalPrice !== membership?.originalPrice &&
            membership?.originalPrice > membership?.finalPrice ? (
            <span
              style={{ fontSize: '18px', marginLeft: '12px', color: '#f39158' }}
            >
              {'   ' + membership?.finalPrice + '$   '}
            </span>
          ) : (
            <>{membership?.finalPrice ? membership?.finalPrice + '$' : ''} </>
          )}
        </h6>
        {membership?.yearSurcharge && (
          <h6 className="recharge-text ">
            Recargo de {membership?.yearSurcharge}$ por antiguedad del vehiculo
          </h6>
        )}
        {membership?.armorTypeSurcharge && (
          <h6 className="recharge-text ">
            Recargo de {membership?.armorTypeSurcharge}$ por blindaje del
            vehiculo
          </h6>
        )}
      </div>
    );
  }
};

export const MembershipImage = (props) => {
  const { membership } = props;
  let src = '';
  switch (membership?.code) {
    case 'CLASSIC':
      src = membershipClasic;
      break;
    case 'PLUS':
      src = membershipPlus;
      break;
    case 'GOLD':
      src = membershipGold;
      break;
    case 'MOTORIDER':
      src = membershipMotorider;
      break;
    case 'RCV':
      src = membershipBasico;
      break;
    case 'RCV_ELITE':
      src = membershipRCVElite;
      break;
    case "MOTORIDERAV":
      src = motoriderAV;
      break;
    case "PLUSF4":
      src = membershipPlus;
      break;
    case 'FREE':
      src = membershipFree;
      break;

    default:
  }
  return <img src={src} alt="plan" className="membership-button-logo" />;
};

export const GiftcardMessage = (props) => {
  const { giftCard } = props;
  return (
    <div className="gifcard-message-component">
      <img src={canje} alt="plan" />
      <h6>
        ¡Canje exitoso! Disfruta del <b>{giftCard?.value}$</b> de descuento en
        plan <b>{giftCard?.membership?.code}</b>
      </h6>
    </div>
  );
};

export const DiscountMessage = (props) => {
  const { discountCode } = props;
  return (
    <div className="gifcard-message-component">
      <img src={canje} alt="plan" />
      <h6>
        ¡Canje exitoso! <br />
        Disfruta de {discountCode?.discountNumber}
        <b>{discountCode?.discountType === 'Porcentaje' ? '%' : '$'}</b> de
        descuento en los planes plan{' '}
        <b>
          {discountCode?.appliesToMemberships?.map((m, i) => (
            <span key={m?.code}>
              {m?.code}
              {i < discountCode?.appliesToMemberships?.length - 1 ? ', ' : '.'}
            </span>
          ))}
        </b>
      </h6>
    </div>
  );
};

export const PaymentMethodCard = (props) => {
  const { method, openModal } = props;

  let src = '';
  switch (method?.name) {
    case paymentMethods?.TDC:
      src = paymentMethodTDC;
      break;
    case paymentMethods?.STRIPE:
      src = paymentMethodTDC;
      break;
    case paymentMethods?.ZELLE:
      src = paymentMethodZelle;
      break;
    case paymentMethods?.TRANSFERENCIA_MERCANTIL:
      src = paymentMethodTransfer;
      break;

    case paymentMethods?.BANK_TRANSFER:
      src = paymentMethodTransfer;
      break;
    case paymentMethods?.TRANSFERENCIA_BANESCO:
      src = paymentMethodTransfer;
      break;
    case paymentMethods?.PAGO_MÓVIL:
      src = paymentMethodPagoMovil;
      break;
    case paymentMethods?.PAGO_MÓVIL_BANCAMIGA:
      src = paymentMethodPagoMovil;
      break;
    case paymentMethods?.PAYPAL:
      src = paymentMethodPaypal;
      break;
    case paymentMethods?.RESERVE:
      src = paymentMethodReserve;
      break;
    case paymentMethods?.CASH_USD:
      src = paymentMethodCash;
      break;
    case paymentMethods?.CASH:
      src = paymentMethodCash;
      break;
    case paymentMethods?.CASH_EUR:
      src = paymentMethodCash;
      break;
    case paymentMethods?.PUNTO_DE_VENTA:
      src = paymentMethodPOV;
      break;
    case paymentMethods?.BINANCE:
      src = binancePayMethod;
      break;
    default:
  }

  return (
    <div
      onClick={() => openModal()}
      className={`membership-button-container plan-button-container`}
    >
      <img src={src} alt="plan" className="payment-method-button-logo" />
      <h6 style={{ marginTop: '12px' }}>{method?.name}</h6>
    </div>
  );
};

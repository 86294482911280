import { useState, useEffect } from 'react';
import { Input } from '../../../common/Forms/Input/Input';
import { notify } from '../../../../assets/helpers/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../../assets/schemas/schemas';
import { ActionButton, AltButton } from '../../../common/Buttons/Buttons';
import {
  idCodes,
  phoneCodes,
  planTypes
} from '../../../../assets/helpers/options';
import { customerService } from '../../../../services/customerService';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';
import { capitalize, filterParams } from '../../../../assets/helpers/funciones';
import './LandingForms.scss';
import moment from 'moment';

export const FreePersonalInfoForm = (props) => {
  const { setcurentStep, membership, setCustomer, curentStep, code } = props;
  const t = useTranslation();

  // const [idUploaded, setIdUploaded] = useState('');
  const [isLoading, setIsloading] = useState(false);

  const getCustomerById = () => {
    if (
      watch('identificationType') &&
      watch('identification') &&
      watch('identification').length > 5
    ) {
      customerService
        .getCustomerById(watch('identification'), watch('identificationType'))
        .then((response) => {
          if (response.status === 200) {
            const {
              firstName,
              lastName,
              phonePrefix,
            } = response?.data?.customer;

            setValue('firstName', capitalize(firstName));
            setValue('lastName', capitalize(lastName));
            setValue('phonePrefix', phonePrefix);
          }
          if (response.status === 404) {
            setValue('firstName', '');
            setValue('lastName', '');
            setValue('state', '');
            setValue('email', '');
            setValue('phonePrefix', '');
          }
        })
        .catch(() => { });
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemas.CreateFreeCustomerSchema(t)),
  });

  const onSubmit = handleSubmit(async (data) => {
    data.firstName = capitalize(data?.firstName);
    data.lastName = capitalize(data?.lastName);
    data.email = data?.email?.toLowerCase();


    let fileTopost = appendFile(filterParams(data));
    let user = { ...data };

    if ([planTypes?.PLUSF4, planTypes?.GOLDF4, planTypes?.GOLDENP]?.includes(code)) {
      sessionStorage?.setItem('identification', data?.identification);
    }

    if (!isLoading) {
      setIsloading(true);
      customerService
        .createCustomer(fileTopost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            setIsloading(false);
            sessionStorage.setItem('personalData', JSON.stringify(user));
            setCustomer(response?.data?._id);
            setcurentStep(3);
          } else {
            setIsloading(false);
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => {
          setIsloading(false);
        });
    }
  });


  const appendFile = (data) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    formData.append('membership', membership?._id);

    return formData;
  };

  const prelaodData = () => {
    let personalData = sessionStorage.getItem('personalData');
    personalData = JSON.parse(personalData);
    if (personalData) {
      setValue('firstName', personalData?.firstName);
      setValue('lastName', personalData?.lastName);
      setValue('phonePrefix', personalData?.phonePrefix);
      setValue('phoneNumber', personalData?.phoneNumber);
      setValue('identificationType', personalData?.identificationType);
      setValue('identification', personalData?.identification);
      setValue('email', personalData?.email);
      setValue(
        'birthday',
        moment(personalData?.birthday)?.format('YYYY-MM-DD')
      );
      setValue('identificationType', personalData?.identificationType);
      setValue('gender', personalData?.gender);
      setValue('maritalStatus', personalData?.maritalStatus);


      setValue('identificationType', personalData?.identificationType);
    }
  };

  useEffect(() => {
    prelaodData();
  }, []);

  let validateDisabled = () => {
    return !watch('identificationType') || !watch('identification');
  };

  return (
    <>
      <LoadSpinner show={isLoading} />
      <div className="landing-form-container">
        <h5>Información Personal</h5>
        <p>(*) Campos obligatorios</p>
        <br />
        <form>
          <div className="row">
            <Input
              bootstrap={'col-4 col-sm-2 col-md-2'}
              label={'C.I / RIF*'}
              type={'select'}
              name={'identificationType'}
              register={register}
              placeholder={''}
              options={idCodes}
              errors={errors?.identificationType?.message}
              onChange={getCustomerById}
            />{' '}
            <Input
              bootstrap={'col-8 col-sm-4 col-md-4'}
              label={''}
              type={'number'}
              name={'identification'}
              register={register}
              placeholder={''}
              errors={errors?.identification?.message}
              onChange={getCustomerById}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Correo Electrónico *'}
              type={'text'}
              name={'email'}
              register={register}
              placeholder={''}
              errors={errors?.email?.message}
              disabled={validateDisabled()}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Nombre *'}
              type={'text'}
              name={'firstName'}
              register={register}
              placeholder={''}
              errors={errors?.firstName?.message}
              disabled={validateDisabled()}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Apellido *'}
              type={'text'}
              name={'lastName'}
              register={register}
              placeholder={''}
              errors={errors?.lastName?.message}
              disabled={validateDisabled()}
            />{' '}
            <Input
              bootstrap={'col-4 col-sm-2 col-md-2'}
              label={'Teléfono*'}
              type={'select'}
              name={'phonePrefix'}
              register={register}
              placeholder={''}
              options={phoneCodes}
              errors={errors?.phonePrefix?.message}
              disabled={validateDisabled()}
            />{' '}
            <Input
              bootstrap={'col-8 col-sm-4 col-md-4'}
              label={''}
              type={'number'}
              name={'phoneNumber'}
              register={register}
              placeholder={''}
              errors={errors?.phoneNumber?.message}
              disabled={validateDisabled()}
            />{' '}
            {/* <div className="col-12 col-md-6">
              {idUploaded ? (
                <FileComponent
                  file={idUploaded}
                  remove={() => setIdUploaded('')}
                />
              ) : (
                <SalesDropzone
                  instructions={'Adjuntar cédula de identidad / RIF *'}
                  maxWeight={'(JPG, PNG, PDF, máx. 3MB)'}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  message="Arrastrar o buscar Documento"
                />
              )}
            </div>
            <div className="col-12 col-md-6">
              {licenceUploaded ? (
                <FileComponent
                  file={licenceUploaded}
                  remove={() => setLicenceUploaded('')}
                />
              ) : (
                <SalesDropzone
                  instructions={'Adjuntar licencia de conducir *'}
                  maxWeight={'(JPG, PNG, PDF, máx. 3MB)'}
                  getRootProps={getRootProps2}
                  getInputProps={getInputProps2}
                  message="Arrastrar o buscar Documento"
                />
              )}
            </div> */}
          </div>

          <div
            style={{ margin: '24px 0px 48px 0px' }}
            className="buttons-center-container"
          >
            <AltButton
              label={'Regresar'}
              action={() => setcurentStep(curentStep - 1)}
            />
            <ActionButton label={'Siguiente'} action={onSubmit} />
          </div>
        </form>
      </div>
    </>
  );
};

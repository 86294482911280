import { useState, useEffect } from 'react';
import { Header } from '../../common/Header/Header.jsx';
import { PricesTable } from '../../common/Tables/Tables';
import { salePricesTableheader } from '../../../assets/helpers/tables';
import { salesRoles } from '../../../assets/helpers/roles.js';
import { notify } from '../../../assets/helpers/toast';
import { membershipCatalogService } from '../../../services/membershipCatalogService';
import { currencyRateService } from '../../../services/currencyRateService';

import { rateService } from '../../../services/rateService.js';
import { ActionButton } from '../../common/Buttons/Buttons.jsx';
import { Input } from '../../common/Forms/Input/Input.jsx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as schemas from '../../../assets/schemas/schemas';
import {
  ChangeRateModal,
  CurrencyChangeHistoryModal,
} from '../../common/Modals/Modals.jsx';
import './RateDate.scss';
import moment from 'moment';
import authService from '../../../services/authService.js';
import { useHistory } from 'react-router-dom';

export const RateCard = (props) => {
  const { register, errors, onSubmit } = props;

  return (
    <div className="card indicators-card ">
      <h2>Actualizar Tasa</h2>
      <Input
        bootstrap={'col-12'}
        label={'Cambio Bs/USD: *'}
        type={'number'}
        name={'rate'}
        register={register}
        placeholder={'Tasa de cambio'}
        errors={errors?.rate?.message}
        min={1}
      />

      <div>
        <ActionButton label={'Guardar'} action={onSubmit} />
      </div>
    </div>
  );
};

export const RateDate = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemas.rateSchema()),
  });

  const history = useHistory();

  const [dollarRate, setDollarRate] = useState('');
  const [dataTable, setDataTable] = useState({
    data: [],
    isLoading: true,
  });

  const [rateModal, setRateModal] = useState(false);
  const [currencyHistoryModal, setCurrencyHistoryModal] = useState({
    isOpen: false,
    data: [],
    date: '',
  });
  const onSubmit = handleSubmit(async (data) => {
    setRateModal(true);
  });

  const getFleets = () => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    membershipCatalogService
      .getPrices()
      .then((response) => {
        if (response.status === 200) {
          setDataTable((prevState) => {
            return {
              ...prevState,
              data: response?.data,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setDataTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setDataTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };

  const createRate = async () => {
    const data = {
      currency: 'USD',
      rate: watch('rate'),
    };

    let rate = await rateService.getDollarRate().then((response) => {
      if (response.status === 200) {
        return response?.data?.rate;
      }
    });

    if (rate !== watch('rate')) {
      currencyRateService
        .createRate(data)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Tasa actualizada`, 'success');
            getDollarRate();
            getFleets();
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch((error) => {});
      return true;
    } else {
      notify(
        'La nueva tasa debe tener un valor diferente a la anterior',
        'info'
      );
    }
  };

  const getDollarRate = () => {
    rateService
      .getDollarRate()
      .then((response) => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
          setValue('rate', response?.data?.rate);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => {});
    return true;
  };

  const getCurrencyRates = () => {
    let params = {
      currency: 'USD',
      date: currencyHistoryModal?.date
        ? moment(currencyHistoryModal?.date).format('YYYY-MM-DD')
        : '',
      limit: 20,
    };

    currencyRateService
      .getCurrencyRates(params)
      .then((response) => {
        if (response.status === 200) {
          setCurrencyHistoryModal((prevState) => {
            return {
              ...prevState,
              data: response?.data?.result,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => {});
    return true;
  };

  const handleCurrencyHistoryModal = () => {
    setCurrencyHistoryModal((prevState) => {
      return {
        ...prevState,
        isOpen: !currencyHistoryModal?.isOpen,
        date: '',
      };
    });
  };
  const handleDateChange = (e) => {
    setCurrencyHistoryModal((prevState) => {
      return {
        ...prevState,
        date: e,
      };
    });
  };
  const validateUserRole = () => {
    let user = authService?.getUser();
    if (
      user?.roles[0]?.code === salesRoles?.SALES_COUNTER &&
      user?.type === 'Externo'
    ) {
      history?.push('/dashboard/analytics');
    }
  };

  useEffect(() => {
    getFleets();
    getDollarRate();
    validateUserRole();
  }, []);

  useEffect(() => {
    getCurrencyRates();
  }, [currencyHistoryModal?.date]);

  return (
    <>
      <CurrencyChangeHistoryModal
        modal={currencyHistoryModal}
        handleDateChange={handleDateChange}
        handleModal={handleCurrencyHistoryModal}
      />
      <Header />
      <ChangeRateModal
        modal={rateModal}
        handleModal={() => setRateModal(!rateModal)}
        rate={watch('rate')}
        createRate={createRate}
      />
      <div className="service-with-container container">
        <div className="buttons-container">
          <h1 className="title-component">Tasa del día</h1>
        </div>
        <div className="row">
          <div className="col-12 col-md-3">
            <RateCard register={register} errors={errors} onSubmit={onSubmit} />
            <h6
              className="open-currency-history-link"
              onClick={() => handleCurrencyHistoryModal()}
            >
              Ver historico de Tasas
            </h6>
          </div>
          <div className="col-12 col-md-9">
            <h2>Precio Planes Normales (2006)</h2>
            <PricesTable
              header={salePricesTableheader}
              data={dataTable?.data?.plansSince2006}
            />
            <h2>Precio Planes 2000 - 2005</h2>
            <PricesTable
              header={salePricesTableheader}
              data={dataTable?.data?.plans2000To2005}
            />
            <h2>Precio Planes 90s</h2>
            <PricesTable
              header={salePricesTableheader}
              data={dataTable?.data?.plans90s}
            />
          </div>
        </div>
      </div>
    </>
  );
};

import { fixResponseJSON } from '../assets/helpers/funciones'
import { authInstance } from './axios'
import axios from 'axios'
let url = process.env.REACT_APP_API_URL

export const fleetLoadService = {
  getFleetsLoads: async (params, fleetId) => {
    const response = await authInstance
      .get(
        `${url}/fleets/${fleetId}/fleetLoads/?` + new URLSearchParams(params)
      )
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },
  cancelFleetsLoads: async (data, fleetId) => {
    const response = await authInstance
      .put(`${url}/fleetLoads/${fleetId}/cancel`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },
  deleteFleet: async (fleetId) => {
    const myInit = {
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'DELETE',
    }

    const response = await fetch(`${url}/fleetLoads/${fleetId}/delete`, myInit)
      .then(async (response) => {
        return fixResponseJSON(response)
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  reactivateFleet: async (fleetId) => {
    const myInit = {
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'PUT',
    }

    const response = await fetch(
      `${url}/fleetLoads/${fleetId}/reactivate`,
      myInit
    )
      .then(async (response) => {
        return fixResponseJSON(response)
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  getById: async (fleetId) => {
    const response = await authInstance
      .get(`${url}/fleetLoads/${fleetId}`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  createFleetLoad: async (data) => {
    const myInit = {
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'POST',
      body: data,
    }

    const response = await fetch(`${url}/fleetLoads/`, myInit)
      .then(async (response) => {
        return fixResponseJSON(response)
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  renewFleetLoad: async (data) => {
    const response = await authInstance
      .post(`${url}/fleetLoads/renew`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  validateCSV: async (data) => {
    const myInit = {
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'POST',
      body: data,
    }

    const response = await fetch(`${url}/fleetLoads/validateCsv`, myInit)
      .then(async (response) => {
        return fixResponseJSON(response)
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  downloadDocuments: async (fleetLoadId) => {
    axios({
      url: `${url}/fleetLoads/${fleetLoadId}/documents`,
      method: 'GET',
      responseType: 'blob', // Set the response type to blob
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage?.getItem('accessToken')}`,
      },
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        )
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', 'zip-carga-de-flotas.zip') // Set the desired file name
        document.body.appendChild(link)
        link.click()
        return fixResponseJSON(response)
      })
      .catch((error) => {
        console.error('Error downloading zip file:', error)
      })
  },

  createFleetLoadPayment: async (data, fleetId) => {
    const myInit = {
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'POST',
      body: data,
    }

    const response = await fetch(`${url}/fleetLoads/${fleetId}/payment`, myInit)
      .then(async (response) => {
        return fixResponseJSON(response)
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  getFleetLoadDetails: async (params, fleetId) => {
    const response = await authInstance
      .get(
        `${url}/fleetLoads/${fleetId}/memberships?` +
          new URLSearchParams(params)
      )
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  validateTokenLink: async (data) => {
    const response = await authInstance
      .post(`${url}/fleetLoads/validateLink`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  createMembership: async (data, fleetId) => {
    const response = await authInstance
      .post(`${url}/fleetLoads/${fleetId}/membership`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },
}

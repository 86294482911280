import { useState, useEffect } from 'react';
import { Header } from '../../../common/Header/Header.jsx';
import { membershipService } from '../../../../services/membershipService.js';
import { notify } from '../../../../assets/helpers/toast.js';
import { RCVInfoCard } from './RCVInfoCard.jsx';
import { renameMembershipStatus } from '../../../../assets/helpers/funciones.js';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner.jsx';
import { useParams } from 'react-router-dom';
import './Order.scss';

export const RCVInfo = () => {
  const { membershipId } = useParams();
  const [membership, setMembership] = useState('');
  const [isLoading, setIsloading] = useState(false);


  const getSalesMembershipById = (membershipId) => {
    membershipService
      .getSalesMembershipById(membershipId)
      .then((response) => {
        if ([200, 201].includes(response?.status)) {
          setMembership(response?.data);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };


  const reloadMembership = () => {
    getSalesMembershipById(membershipId);
  };


  useEffect(() => {
    getSalesMembershipById(membershipId);
  }, []);

  return (
    <>
      <Header />
      <LoadSpinner show={isLoading} />
      {membership && (
        <div className="service-with-container container">
          <h1 className="title-component">
            Solicitud de RCV para: {membership?.membershipId}
          </h1>
          <div className="breadcrumb-container"></div>
          <div className="filter-button-container"></div>
          <br />
          <div className="row">
            <RCVInfoCard
              label={'INFORMACIÓN DE RCV'}
              membership={membership}
              reloadMembership={reloadMembership}
              getSalesMembershipById={getSalesMembershipById}
            />
          </div>
        </div>
      )}
    </>
  );
};

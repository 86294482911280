import historyIcon from '../../../images/historyIcon.png';
import { capitalize } from '../../../assets/helpers/options';
import { salesRoles } from '../../../assets/helpers/roles.js';
import authService from "../../../services/authService.js"
import './CraneCounter.scss';

const service = {
    AVSERVICE: { name: 'Asistente Vial', value: "'avServices'" },
    TOWSERVICES: { name: 'Grueros', value: "'towServices'" },
};

export const CraneCounterData = (props) => {
    const { data, calculateTotal } = props;

    return (
        <>
            {data?.map((c) => (
                <tr key={c?._id}>
                    <td>{capitalize(c?.contactName)}</td>
                    <td style={{ textAlign: 'center' }}>{c?.activeServices}</td>
                    <td style={{ textAlign: 'center' }}>{c?.finishedServices}</td>
                    <td style={{ textAlign: 'center', fontSize: '9px' }}>
                        {c?.activeDestinyAddress}
                    </td>
                </tr>
            ))}
            <tr>
                <td>
                    <b>Total:</b>
                </td>
                <td style={{ textAlign: 'center' }}>
                    <b>{calculateTotal(data, 'activeServices')}</b>
                </td>
                <td style={{ textAlign: 'center' }}>
                    <b>{calculateTotal(data, 'finishedServices')}</b>
                </td>
                <td></td>
            </tr>
        </>
    );
};

export const CraneCounter = (props) => {
    const { handleModal, } = props;


    if (![salesRoles?.SALES_ADMIN]?.includes(authService?.getUserType())) {
        return null
    }


    return (
        <>
            <div className="document-image-crane-button">
                <img
                    src={historyIcon}
                    alt="bikeServiceSelected"
                    onClick={() =>
                        handleModal()
                    }
                />
            </div>
        </>
    );
};

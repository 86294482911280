import { useState, useEffect } from 'react';
import { Header } from '../../../common/Header/Header.jsx';
import { useHistory, useParams } from 'react-router-dom';
import { membershipService } from '../../../../services/membershipService.js';
import { dbHistoryService } from '../../../../services/dbHistoryService.js';
import { notify } from '../../../../assets/helpers/toast.js';
import { OrdeCard, OrderCard } from './OrderComponents.jsx';
import { ClientInfoCard } from './ClientInfoCard.jsx';
import { VehicleInfoCard } from './VehicleInfoCard.jsx';
import { AfiliationCard } from './AfiliationCard.jsx';
import { PaymentsCard } from './PaymentsCard.jsx';
import { ChangeCard } from './ChangeCard.jsx';
import { SalesButtons } from '../../../common/Buttons/Buttons.jsx';
import { salesMembershipStatus } from '../../../../assets/helpers/options.js';
import {
  renameMembershipStatus,
  filterParams,
  removeBlankSpacesAndUppercase,
  defineLangindType,
} from '../../../../assets/helpers/funciones.js';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner.jsx';
import { salesRoles } from '../../../../assets/helpers/roles.js';
import authService from '../../../../services/authService.js';
import { productService } from '../../../../services/productService';
import { membershipCatalogService } from '../../../../services/membershipCatalogService';
import { giftCardService } from '../../../../services/giftCardService';
import {
  RenewModal,
  EditHistoryModal,
} from '../../../common/Modals/Modals.jsx';
import {
  planStatus,
  KMServiceOptions,
  validateCodeEnum,
  codeTypeOptions,
  rcvEnum,
  codeTypeEnum,
  planTypes,
  membershipCodes,
  LANDING,
} from '../../../../assets/helpers/options.js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CraneCounter } from '../../../common/Buttons/CraneCounter.jsx';
import * as schemas from '../../../../assets/schemas/schemas';
import moment from 'moment';
import './Order.scss';

const itemsPerpage = [10, 25, 50, 100, 500];

export const Order = () => {
  const { membershipId } = useParams();
  const [membership, setMembership] = useState('');
  const [isLoading, setIsloading] = useState(false);
  const [planOptions, setPlanOptions] = useState([]);
  const [priceInfo, setPriceInfo] = useState('');
  const [discountId, setDiscountId] = useState('');
  const [rcvOptions, setRCVOptions] = useState([]);
  const [membershipOptions, setMembershipOptions] = useState([]);
  const [editHistory, setEditHistory] = useState({
    isOpen: false,
    limit: itemsPerpage[0],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    dateFrom: moment.utc(new Date()).subtract(7, 'days').format('YYYY-MM-DD'),
    dateTo: moment.utc(new Date()).format('YYYY-MM-DD'),
    isLoading: true,
  });

  const [showRenewModal, setShowRenewModal] = useState({
    isOpen: false,
    selected: '',
  });

  const {
    register: register4,
    handleSubmit: handleSubmit4,
    formState: { errors: errors4 },
    setValue: setValue4,
    watch: watch4,
  } = useForm({
    resolver: yupResolver(schemas.autorenovationSearchSchema()),
  });

  const history = useHistory();

  const actionOverMembership = membership => {
    getAvailableMemberships(membership);
  };
  const handleRenewModal = (membership, planOptions) => {
    if (!membership) {
      setRCVOptions([]);
      setPlanOptions([]);
      // setValue2("rcv", "")
      // setValue2("code", "")
      // reset2()
      setPriceInfo('');
    }

    actionOverMembership(membership);
  };
  const selfRenew = handleSubmit4(async data => {
    let params = {
      code: watch4('code'),
      rcvProduct: watch4('rcv') === 'ninguno' ? '' : watch4('rcv'),
      discountCodeId:
        watch4('codeType') === codeTypeEnum?.DISCOUNT ? discountId : '',
      giftcardId:
        watch4('codeType') === codeTypeEnum?.GIFTCARD ? discountId : '',
      km: watch4('km') ? watch4('km') : '',
      useExtendedTow: watch4('hasExtendedTow')
        ? watch4('hasExtendedTow')
        : false,
    };

    if (!isLoading) {
      setIsloading(true);
      membershipService
        .selfRenew(filterParams(params), showRenewModal?.selected?._id)
        .then(response => {
          if ([200, 201]?.includes(response.status)) {
            if (response?.message) {
              notify(`${response.message}`, 'info');
            }
            history.push(`/afiliations/edit-order/${response?.data?._id}`);
            setIsloading(false);
            handleRenewModal();
            window?.location?.reload();
          } else {
            setIsloading(false);
            notify(`${response.message}`, 'error');
          }
        })
        .catch(() => {});
    }
  });

  const calculateRenewalPrice = (membership, planOptions) => {
    const params = {
      rcv: watch4('rcv') === 'ninguno' ? '' : watch4('rcv'),
      code: watch4('code'),
      giftcard: watch4('codeType') === codeTypeEnum?.GIFTCARD ? discountId : '',
      discountCode:
        watch4('codeType') === codeTypeEnum?.DISCOUNT ? discountId : '',
      useExtendedTow: watch4('hasExtendedTow')
        ? watch4('hasExtendedTow')
        : false,
    };

    const [selectedOption] = planOptions?.filter(
      p => p?._id === watch4('code')
    );

    if ([membershipCodes?.GOLD]?.includes(selectedOption?.name)) {
      if (!watch4('km')) {
        setValue4('km', 200);
        params.km = 200;
      } else {
        params.km = watch4('km');
      }
    }

    if (!isLoading) {
      setIsloading(true);
      membershipService
        .calculateRenewalPrice(
          filterParams(params),
          showRenewModal?.selected?._id || membership?._id
        )
        .then(response => {
          if (response.message === 'Success') {
            if (response.status === 200) {
              setPriceInfo(response?.data);
              setIsloading(false);
            }
            return null;
          }
          if (
            response.status === 400 &&
            [
              'discountCode contains an invalid value',
              'giftcard contains an invalid value',
            ]?.includes(response?.message)
          ) {
            notify(`Codigo Invalido`, 'info');
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch(() => {});
    }
  };

  const getAvailableMemberships = membership => {
    setShowRenewModal(prevState => {
      return {
        ...prevState,
        isOpen: !showRenewModal?.isOpen,
        selected: membership ? membership : '',
      };
    });

    let params = {};

    params.landing = LANDING?.SELF_RENEWAL;
    params.class = membership?.vehicle?.classId;
    params.year = membership?.vehicle?.year;

    membershipCatalogService
      .getAvailableMemberships(filterParams(params))
      .then(response => {
        if (response.status === 200) {
          let data = [...response?.data];

          if (membership?.membershipStatus === planStatus?.INCOMPLETED) {
            data = data?.filter(
              d => ![membershipCodes?.FREE]?.includes(d?.code)
            );
          }

          data = data?.filter(
            d =>
              ![membershipCodes?.RCV, membershipCodes?.RCV_ELITE]?.includes(
                d?.code
              )
          );

          for (let i = 0; i < data.length; i++) {
            data[i].name = data[i]?.code;
          }

          setMembershipOptions(filterPlanType(data));

          if (membership) {
            const [option] = data?.filter(
              p => p?._id === membership?.membershipCode
            );
            setValue4('code', option?._id);
            getRCVs(membership, data);
          }
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => {});
  };

  const validateCode = () => {
    let discountType = '';

    if (watch4('codeType') === codeTypeEnum?.DISCOUNT) {
      discountType = 'discountCodes';
    }
    if (watch4('codeType') === codeTypeEnum?.GIFTCARD) {
      discountType = 'giftcards';
    }

    let params = {
      code: watch4('codigo'),
      type:
        watch4('codeType') === codeTypeEnum?.DISCOUNT
          ? validateCodeEnum?.RENEWAL
          : '',
    };

    if (params?.code) {
      giftCardService
        .validateDiscount(discountType, filterParams(params))
        .then(response => {
          if ([200, 201].includes(response.status)) {
            setDiscountId(response?.data?._id);
            calculateRenewalPrice('', planOptions);
          } else {
            if ([404].includes(response.status)) {
              notify(`Codigo no encontrado o expirado`, 'info');
              return null;
            }

            notify(`${response.message}`, 'error');
          }
        })
        .catch(() => {});
    }
  };

  const handleDiscountCode = () => {
    removeBlankSpacesAndUppercase(watch4('codigo'), 'codigo', setValue4);

    if (watch4('codigo').length === 6 && watch4('code')) {
      validateCode();
    }

    if (watch4('codigo').trim().length > 6) {
      notify('Ingrese un código válido', 'info');
      setValue4('codigo', watch4('codigo').slice(0, 6));
    }
  };

  const filterPlanType = plans => {
    if ([planTypes?.PLUSF4]?.includes(membership?.membershipPlan)) {
      plans = plans?.filter(
        p =>
          ![membershipCodes?.FREE, membershipCodes?.MOTORIDERAV].includes(
            p?.code
          )
      );
      return plans;
    }

    if ([planStatus?.INCOMPLETED].includes(membership?.membershipStatus)) {
      plans = plans?.filter(
        p =>
          ![
            membershipCodes?.FREE,
            membershipCodes?.RCV,
            membershipCodes?.RCV_ELITE,
          ].includes(p?.code)
      );
      return plans;
    } else return plans;
  };

  const handleCodeChange = () => {
    setValue4('codigo', '');
    setDiscountId('');
    calculateRenewalPrice('', planOptions);
  };

  const getRCVs = (membership, planOptions) => {
    if (watch4('code')) {
      const [selectedOption] = planOptions?.filter(
        p => p?._id === watch4('code')
      );

      if ([membershipCodes?.GOLD]?.includes(selectedOption?.name)) {
        if (!watch4('km')) {
          setValue4('km', 150);
        }
      } else {
        setValue4('km', '');
      }

      productService
        .getRCVs(watch4('code'))
        .then(response => {
          if (response.status === 200) {
            let data = [{ _id: 'ninguno', name: 'N/A' }, ...response?.data];
            let [basico] = data?.filter(p => p?.name === rcvEnum?.RCV_BASIC);

            setRCVOptions(data);
            if (basico) {
              setValue4('rcv', basico?._id);
            }
            setValue4('codigo', '');
            setValue4('codeType', '');
            setDiscountId('');

            if (showRenewModal?.selected?._id || membership) {
              calculateRenewalPrice(membership, planOptions);
            }
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => {});
    }
  };

  const getSalesMembershipById = membershipId => {
    membershipService
      .getSalesMembershipById(membershipId)
      .then(response => {
        if ([200, 201].includes(response?.status)) {
          setMembership(response?.data);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => {});
  };

  const handlePageChange = (event, value) => {
    setEditHistory(prevState => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * editHistory?.limit,
      };
    });
  };

  const handleEditModal = () => {
    setEditHistory(prevState => {
      return {
        ...prevState,
        isOpen: !editHistory?.isOpen,
      };
    });
  };

  const handleDateFrom = e => {
    setEditHistory(prevState => {
      return {
        ...prevState,
        dateFrom: e,
      };
    });
  };
  const handleDateTo = e => {
    setEditHistory(prevState => {
      return {
        ...prevState,
        dateTo: e,
      };
    });
  };

  const getHistory = membershipId => {
    let params = {
      page: editHistory?.page,
      limit: editHistory?.limit,
      from: editHistory?.dateFrom,
      to: editHistory?.dateTo,
    };

    dbHistoryService
      .getMembershipHistory(membershipId, params)
      .then(response => {
        if ([200, 201].includes(response?.status)) {
          setEditHistory(prevState => {
            return {
              ...prevState,
              data: response?.data?.history,
              count: response?.data?.total,
            };
          });
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => {});
  };

  const reloadMembership = () => {
    getSalesMembershipById(membershipId);
  };

  const upgradeOrder = orderId => {
    history.push(`/afiliations/upgrade-order/${orderId}`);
  };
  const rcvOrder = membership => {
    history.push(`/afiliations/rcv/${membership?._id}`);
  };

  const membershipName = membership => {
    let status = renameMembershipStatus(membership?.membershipStatus);
    let id = 'Nro. ' + membership?.membershipId + ' ';

    if (status !== 'INCOMPLETO') {
      status = ' (' + status + ') ';
    } else {
      status = ' Por Renovacion';
      id = '';
    }

    return <span>{id + status}</span>;
  };

  useEffect(() => {
    getSalesMembershipById(membershipId);
  }, []);

  useEffect(() => {
    getHistory(membershipId);
  }, [
    editHistory?.page,
    editHistory?.limit,
    editHistory?.dateFrom,
    editHistory?.dateTo,
  ]);

  return (
    <>
      <CraneCounter handleModal={handleEditModal} />
      <EditHistoryModal
        handleDateTo={handleDateTo}
        handleDateFrom={handleDateFrom}
        modal={editHistory}
        handlePageChange={handlePageChange}
        handleModal={handleEditModal}
      />
      <RenewModal
        modal={showRenewModal}
        watch={watch4}
        register={register4}
        errors={errors4}
        close={actionOverMembership}
        options={{
          planOptions: membershipOptions,
          rcvOptions: rcvOptions,
          codeTypeOptions: codeTypeOptions,
          KMServiceOptions: KMServiceOptions,
        }}
        onChange={{
          getRCVs: getRCVs,
          handleCodeChange: handleCodeChange,
          handleDiscountCode: handleDiscountCode,
          calculateRenewalPrice: calculateRenewalPrice,
        }}
        priceInfo={priceInfo}
        action={selfRenew}
      />
      <Header />
      <LoadSpinner show={isLoading} />
      {membership && (
        <div className="service-with-container container">
          <h1 className="title-component">
            Orden {membershipName(membership)}
          </h1>
          <div className="breadcrumb-container"></div>
          <div className="filter-button-container"></div>
          <br />
          <div className="row">
            <div className="col-12 col-lg-9">
              <ClientInfoCard
                label={'INFORMACIÓN DEL CLIENTE'}
                membership={membership}
                reloadMembership={reloadMembership}
              />
              <AfiliationCard
                label={'DATOS DE AFILIACIÓN'}
                membership={membership}
                reloadMembership={reloadMembership}
              />
              <PaymentsCard
                label={'RESUMEN DE PAGOS'}
                membership={membership}
                reloadMembership={reloadMembership}
              />
              <ChangeCard
                label={'RESUMEN DE VUELTOS'}
                membership={membership}
                reloadMembership={reloadMembership}
              />
              <VehicleInfoCard
                label={'INFORMACIÓN DEL VEHÍCULO'}
                membership={membership}
                reloadMembership={reloadMembership}
              />
              <OrdeCard
                label={'DOCUMENTOS DEL TITULAR Y DEL PAGO'}
                refresh={() => getSalesMembershipById(membershipId)}
                membership={membership}
              />
            </div>
            <div className="col-12 col-lg-3">
              <OrderCard
                membership={membership}
                refresh={() => getSalesMembershipById(membershipId)}
              />{' '}
              {membership?.membershipStatus ===
                salesMembershipStatus.VENCIDO && (
                <SalesButtons
                  label={'Rescatar Plan'}
                  active={true}
                  action={() => handleRenewModal(membership)}
                />
              )}
              {[
                salesMembershipStatus.POR_RENOVACION,
                salesMembershipStatus.POR_VENCER,
              ].includes(membership?.membershipStatus) && (
                <SalesButtons
                  label={'Renovar Plan'}
                  active={true}
                  action={() => handleRenewModal(membership)}
                />
              )}
              <UpgradeButton
                upgradeOrder={upgradeOrder}
                membership={membership}
              />
              <RCVButton action={rcvOrder} membership={membership} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const UpgradeButton = props => {
  const { membership, upgradeOrder } = props;

  if (['GOLD'].includes(membership?.membershipPlan) && membership?.km === 300) {
    return null;
  }

  if (
    [
      salesRoles?.SALES_ADMIN,
      salesRoles?.SALES_SUPERVISOR,
      salesRoles?.SALES_HUNTER,
      salesRoles?.SALES_FARMER,
      salesRoles?.SALES_COUNTER,
      salesRoles?.SALES_FARMER,
    ].includes(authService.getUserType()) &&
    [
      salesMembershipStatus.ACTIVO,
      salesMembershipStatus.POR_RENOVACION,
    ].includes(membership?.membershipStatus) &&
    ['CLASSIC', 'PLUS', 'GOLD'].includes(membership?.membershipPlan)
  )
    return (
      <SalesButtons
        label={'Upgrade Plan'}
        active={true}
        action={() => upgradeOrder(membership?._id)}
      />
    );
  else return null;
};

export const RCVButton = props => {
  const { membership, action } = props;

  if (
    membership?.rcv === rcvEnum?.NA &&
    ![membershipCodes?.F2, membershipCodes?.PLUSF4]?.includes(
      membership?.membershipPlan
    )
  ) {
    return (
      <SalesButtons
        label={'Solicitud RCV'}
        active={true}
        action={() => action(membership)}
      />
    );
  } else return null;
};

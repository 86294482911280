import { authInstance } from './axios'
import { fixResponseJSON } from '../assets/helpers/funciones'
let url = process.env.REACT_APP_API_URL

export const paymentsService = {
  createMembershipPayment: async (data) => {
    const response = await authInstance
      .post(`${url}/payments/createMembershipPayment`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  editPayment: async (data) => {
    const response = await authInstance
      .put(`${url}/payments/update`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  editCash: async (file, id) => {
    const myInit = {
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'PUT',
      body: file,
    }

    const response = await fetch(`${url}/paymentChange/update/${id}`, myInit)
      .then(async (response) => {
        return fixResponseJSON(response)
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  createPendingPayment: async (data) => {
    const response = await authInstance
      .post(`${url}/payments/createMembershipPendingPayment`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },
}

import { useEffect, useState } from 'react';
import { Input } from '../../../common/Forms/Input/Input';
import { set, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as schemas from '../../../../assets/schemas/schemas';
import { ActionButton } from '../../../common/Buttons/Buttons';
import {
  campaignActionOptions,
  paymentType,
  rcvEnum,
  KMOderOptions,
  rcvOptions,
  paymentTypeOptions,
  planStatus,
  membershipCodes,
  paymentModalTypes,
  userType,
  planTypes,
  paymentMethods as metodosDePago,
} from '../../../../assets/helpers/options';
import { usersService } from '../../../../services/usersService';
import { saleChannelService } from '../../../../services/saleChannelService';
import { PaymentModal } from '../../../common/Modals/Modals';
import { membershipService } from '../../../../services/membershipService';
import { paymentsService } from '../../../../services/paymentsService';
import { rateService } from '../../../../services/rateService';
import { productService } from '../../../../services/productService';
import { membershipCatalogService } from '../../../../services/membershipCatalogService';
import { currencyRateService } from '../../../../services/currencyRateService';
import { notify } from '../../../../assets/helpers/toast';
import {
  filterParams,
  MethodValidation,
  getCorrectDate,
  NaNtoNumber,
  defineLangindType,
  filterOption,
  filterOptionById,
  returnIGTF,
} from '../../../../assets/helpers/funciones';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';
import { OderCardHeader } from './OrderComponents';
import authService from '../../../../services/authService';
import { salesRoles } from '../../../../assets/helpers/roles';
import moment from 'moment';
import './OrderComponent.scss';

const allowAdmin = () => {
  if ([salesRoles.SALES_ADMIN].includes(authService?.getUserType())) {
    return false;
  } else {
    return true;
  }
};

const allowEditPayment = () => {
  if (
    [salesRoles.SALES_ADMIN, salesRoles?.SALES_ADMIN_AUX].includes(
      authService?.getUserType()
    )
  ) {
    return false;
  } else {
    return true;
  }
};

export const AfiliationCard = props => {
  const { label, membership, reloadMembership } = props;
  const [open, setOpen] = useState(true);
  const [sellerOptions, setSellerOptions] = useState([]);
  const [salesChannelOptions, setSalesChannelOptions] = useState([]);
  const [openPayModal, setOpenPayModal] = useState(false);
  const [fileUploaded, setFileUploaded] = useState([]);
  const [dollarRate, setDollarRate] = useState('');
  const [bsDisabled, setbsDisabled] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [filteredMethods, setFilteredMethods] = useState([]);
  const [errorsDropzone, setErrorsDropzone] = useState({});
  const [cashBackImage, setCashBackImage] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [membershipOptions, setMembershipOptions] = useState([]);
  const [allPaymentTypesOptions, setAllPaymentTypesOptions] = useState([
    ...paymentTypeOptions,
    {
      name: paymentType?.CASHEA,
      _id: paymentType?.CASHEA,
    },
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(schemas.updateMembershipForm()),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
    watch: watch2,
  } = useForm({
    resolver: yupResolver(schemas.updatePaymentForm()),
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    setValue: setValue3,
    getValues: getValues3,
    resetField: resetField3,
    watch: watch3,
    control: control3,
    reset: reset3,
    setError: setError3,
  } = useForm({
    resolver: yupResolver(schemas.renewMembershipSchema()),
  });

  const preloadForm = () => {
    // DATOS DEL PLAN
    setValue('affiliationType', membership?.affiliationType);
    setValue('contractType', membership?.contractType);
    setValue('campaignAction', membership?.campaignAction);
    // FECHAS ASOCIADAS
    setValue(
      'createdAt',
      membership?.createdAt ? getCorrectDate(membership?.createdAt) : ''
    );
    setValue(
      'beginDate',
      membership?.beginDate ? getCorrectDate(membership?.beginDate) : ''
    );
    setValue(
      'rcvEmissionDate',
      membership?.beginDate ? getCorrectDate(membership?.rcvEmissionDate) : ''
    );
    setValue(
      'endDate',
      membership?.endDate ? getCorrectDate(membership?.endDate) : ''
    );
    setValue(
      'rcvBeginDate',
      membership?.rcvBeginDate ? getCorrectDate(membership?.rcvBeginDate) : ''
    );
    setValue(
      'rcvEndDate',
      membership?.rcvEndDate ? getCorrectDate(membership?.rcvEndDate) : ''
    );

    if (membership?.endDateFromLastMembership) {
      setValue(
        'endDateFromLastMembership',
        membership?.endDateFromLastMembership
          ? getCorrectDate(membership?.endDateFromLastMembership)
          : ''
      );
    }
    if (membership?.rcvEndDateFromLastMembership) {
      setValue(
        'rcvEndDateFromLastMembership',
        membership?.rcvEndDateFromLastMembership
          ? getCorrectDate(membership?.rcvEndDateFromLastMembership)
          : ''
      );
    }
    // DATOS DEL PAGO
    if (membership?.membershipStatus !== 'INCOMPLETO') {
      setValue2('discountCode', membership?.discountCode);
      setValue2(
        'discountByCode',
        membership?.priceInfo?.discountByCode
          ? membership?.priceInfo?.discountByCode + ' $'
          : ''
      );

      setValue2('giftcardCode', membership?.giftcardCode);
      setValue2(
        'discountByGiftcard',
        membership?.priceInfo?.discountByGiftcard
          ? membership?.priceInfo?.discountByGiftcard + ' $'
          : ''
      );
    }

    setValue2('paymentStatus', membership?.paymentStatus);
    setValue2(
      'finalPrice',
      parseFloat(membership?.priceInfo?.finalPrice?.toFixed(2))
    );
    setValue2(
      'finalPriceInBsS',
      parseFloat(membership?.priceInfo?.finalPriceInBsS?.toFixed(2))
    );
    setValue2('paymentType', membership?.paymentType);

    if (membership?.discountCode) {
      setValue2('code', membership?.discountCode);
      setValue2('discountCode', membership?.discountCode);
      setValue2(
        'discountByCode',
        membership?.priceInfo?.discountByCode
          ? membership?.priceInfo?.discountByCode + ' $'
          : ''
      );
    }

    if (membership?.giftcardCode) {
      setValue2('giftcardCode', membership?.giftcardCode);
      setValue2(
        'discountByGiftcard',
        membership?.priceInfo?.discountByGiftcard
          ? membership?.priceInfo?.discountByGiftcard + ' $'
          : ''
      );
    }

    setValue2('paymentMethodsQuantity', membership?.payments?.length);
    if (membership?.membershipStatus === 'INCOMPLETO') {
      setValue2('paymentMethodsQuantity', 1);
    }

    if (membership?.payments?.length > 0) {
      for (let i = 0; i < membership?.payments?.length; i++) {
        setValue2(`paymentAmount${i}`, membership?.payments[i]?.totalAmount);
        setValue2(
          `paymentAmountBsS${i}`,
          membership?.payments[i]?.totalAmountInBsS?.toFixed(2)
        );
        Promise.allSettled([getPaymentMethods()]).then(r => {
          setTimeout(() => {
            setValue2(`paymentMethod${i}`, membership?.payments[i]?.methodId);
          }, 2500);
        });
        setValue2(`paymentMethod${i}`, membership?.payments[i]?.methodId);
        setValue2(`paymentReference${i}`, membership?.payments[i]?.reference);
      }
    }

    if (membership?.km) {
      setValue('km', membership?.km);
      setValue('useExtendedTow', membership?.hasExtendedTow);
    }

    if ([planTypes?.F2]?.includes(membership?.membershipPlan)) {
      setValue('km', 10);
    }

    if ([planTypes?.CLASSIC]?.includes(membership?.membershipPlan)) {
      setValue('km', 25);
    }
    if (
      [planTypes?.PLUS, planTypes?.PLUSF4]?.includes(membership?.membershipPlan)
    ) {
      setValue('km', 50);
    }

    if ([planTypes?.GOLD]?.includes(membership?.membershipPlan)) {
      if (membership?.km) {
        setValue('km', membership?.km);
      } else {
        setValue('km', 200);
      }
    }

    Promise.allSettled([
      getUsers(),
      getSalesChannel(),
      getAvailableMemberships(),
    ]).then(r => {
      setTimeout(() => {
        if (
          membership?.membershipPlan !== membershipCodes?.FREE ||
          membership?.membershipStatus !== planStatus?.INCOMPLETED
        ) {
          setValue('code', membership?.membershipCode);
        }
        setValue('seller', membership?.seller);
        setValue('saleChannel', membership?.saleChannel);
        setValue('rcv', membership?.rcv ? membership?.rcv : rcvEnum?.NA);
        validatePaymentTypeOptions();
      }, 2500);
    });

    if (membership?.paymentType === paymentType?.FINANCIADO) {
      Promise.allSettled([getPaymentMethods(), getDollarRate()]).then(r => {
        setTimeout(() => {
          for (let i = 0; i < membership?.paymentDues?.length; i++) {
            let [selectedPayment] = membership?.payments?.filter(
              p => p?._id === membership?.paymentDues[i]?.payment[0]
            );

            if (selectedPayment) {
              setValue2(
                `paymentDues[${i}].amount`,
                selectedPayment.totalAmount
              );
              setValue2(
                `paymentDues[${i}].amountBs`,
                selectedPayment.totalAmountInBsS?.toFixed(2)
              );
              setValue2(
                `paymentDues[${i}].reference`,
                selectedPayment.reference
              );
              setValue2(
                `paymentDues[${i}].paymentMethod`,
                selectedPayment?.methodId
              );
            } else {
              setValue2(
                `paymentDues[${i}].amount`,
                membership?.paymentDues[i]?.amount?.toFixed(2)
              );
              setValue2(
                `paymentDues[${i}].amountBs`,
                (membership?.paymentDues[i]?.amount * dollarRate)?.toFixed(2)
              );
              setValue2(
                `paymentDues[${i}].paymentMethod`,
                membership?.paymentDues[i]?.paymentMethod
              );
            }

            if (selectedPayment?.length > 0) {
              setValue2(
                `paymentDues[${i}].reference`,
                selectedPayment[0]?.reference
              );
            }

            setValue2(
              `paymentDues[${i}].payment`,
              membership?.paymentDues[i]?.payment[0]
                ? 'Pago realizado'
                : 'Cuenta por cobrar'
              //Aqui colocar iuna funcion que ponga el estatus 'Pago realizado', 'Cuenta por cobrar' o pago vencido dependiendo de la fecha del pago
            );

            setValue2(
              `paymentDues[${i}].paymentDate`,
              membership?.paymentDues[i]?.paymentDate
                ? moment
                    .utc(membership?.paymentDues[i]?.paymentDate)
                    .format('DD/MM/YYYY')
                : ''
            );
            setValue2(
              `paymentDues[${i}].dueDate`,
              membership?.paymentDues[i]?.dueDate
                ? moment
                    .utc(membership?.paymentDues[i]?.dueDate)
                    .format('DD/MM/YYYY')
                : ''
            );
          }
        }, 1500);
      });
    }
  };

  let loadPaymentForms = (params, paymentMethods) => {
    let payments = [];
    // lOOP through every payment method
    for (let i = 1; i <= parseInt(paymentMethods); i++) {
      let data = {};

      if (i === 1 && params?.igtf1) {
        data.igtf = true;
      }
      if (i === 2 && params?.igtf2) {
        data.igtf = true;
      }
      let amount1IGTF = NaNtoNumber(parseFloat(watch3('amount1IGTF')));
      let amount1_2IGTF = NaNtoNumber(parseFloat(watch3('amount1_2IGTF')));
      let amount2IGTF = NaNtoNumber(parseFloat(watch3('amount2IGTF')));

      data.amountIGTF = parseFloat(params[`amount` + i.toString()]);

      // Add IGTF 1 and raise flag
      if (i === 1 && amount1IGTF > 0) {
        data.igtf = true;
        data.amountIGTF =
          amount1IGTF + parseFloat(params[`amount` + i.toString()]);
      }

      // Add IGTF 1 in 2 or 2 and raise flag
      if (i !== 1 && (amount1_2IGTF > 0 || amount2IGTF > 0)) {
        data.igtf = true;
        data.amountIGTF =
          amount2IGTF + parseFloat(params[`amount` + i.toString()]);

        if (watch3('mix')) {
          data.amountIGTF = data.amountIGTF + amount1_2IGTF;
        }
      }

      data.amountIGTF = data.amountIGTF?.toFixed(2);

      data.method = params[`method` + i.toString()];
      data.reference = params[`reference` + i.toString()];
      data.referenceCashea = params[`referenceCashea`.toString()];
      data.amount = params[`amount` + i.toString()];
      data.amountBsS = params[`amountBsS` + i.toString()];

      data.bank = params[`bank` + i.toString()];
      data.phone = params[`phone` + i.toString()];
      data.date = params[`date` + i.toString()];

      data = filterParams(data);

      payments.push({
        ...data,
      });
    }

    // "REmove unwanted params from JSON"
    let words = [
      'method',
      'reference',
      'amount',
      'amountBsS',
      'paymentMethod',
      'igtf',
      'amountIGTF',
      'bank',
      'phone',
      'date',
    ];
    for (const key in params) {
      for (var i = 0, ln = words.length; i < ln; i++) {
        if (key.indexOf(words[i]) !== -1) {
          delete params[key];
        }
      }
    }
    params.payments = payments;
    return params;
  };

  const validatePagoMovil = (params, index) => {
    let isValid = true;
    if (watch3(`phone${index}`)?.length !== 10) {
      setError3(`phone${index}`, {
        type: 'custom',
        message: 'Ingrese un numero de telefono valido: Ejemplo: 4143579865',
      });
      return false;
    } else {
      setError3(`phone${index}`, { type: 'custom', message: '' });
    }

    for (let i = 0; i < params?.length; i++) {
      if (!watch3(`${params[i]}${index}`)) {
        setError3(`${params[i]}${index}`, {
          type: 'custom',
          message: 'Obligatorio',
        });
        isValid = false;
      }
    }
    return isValid;
  };

  const appendFile = (data, paymentMethodsQuantity, tipodePago, price) => {
    const params = ['phone', 'bank', 'date'];

    const formData = new FormData();
    formData.append('paymentType', data?.paymentType);
    formData.append('membership', membership?._id);

    for (var i = 0; i < data?.payments.length; i++) {
      if (
        filterOption(paymentMethods, 'PAGO MÓVIL BANCAMIGA')?._id ===
        watch3(`method${i + 1}`)
      ) {
        const isPMValid = validatePagoMovil(params, i + 1);
        if (!isPMValid) {
          return null;
        }
      }

      formData.append(`payments[${i}][amount]`, data?.payments[i].amountIGTF);

      if (data?.payments[i]?.igtf) {
        formData.append(`payments[${i}][igtf]`, data?.payments[i]?.igtf);
      }

      if (data?.payments[i]?.bank) {
        formData.append(`payments[${i}][phone]`, data?.payments[i]?.phone);
        formData.append(`payments[${i}][bank]`, data?.payments[i]?.bank);
        formData.append(`payments[${i}][date]`, data?.payments[i]?.date);
      }

      if (data?.paymentType === paymentType?.CASHEA) {
        formData.append(
          `payments[${i}][referenceCashea]`,
          data?.payments[i].referenceCashea
        );
      }

      formData.append(
        `payments[${i}][amountBsS]`,
        (data?.payments[i].amountIGTF * dollarRate)?.toFixed(2)
      );
      formData.append(`payments[${i}][method]`, data?.payments[i].method);
      formData.append(`payments[${i}][reference]`, data?.payments[i].reference);
    }

    // Load as many files as payment methods we have
    if (
      fileUploaded.length !== parseFloat(paymentMethodsQuantity) &&
      parseFloat(paymentMethodsQuantity) > 0 &&
      MethodValidation(data?.payments, fileUploaded, paymentMethods)
    ) {
      notify('Ingrese comprobantes de pago', 'info');
      return null;
    }

    if (parseFloat(price) && tipodePago !== paymentType?.FINANCIADO) {
      let totalSum = 0;
      for (let i = 0; i < data?.payments.length; i++) {
        totalSum = totalSum + parseFloat(data?.payments[i].amount);
      }
      if (
        totalSum < parseFloat(price) &&
        watch2('paymentType') !== paymentType?.CASHEA
      ) {
        notify(
          'Monto a pagar por el cliente y suma de los pagos no coinciden',
          'info'
        );
        return null;
      }
    }

    if (
      watch2('paymentType') === paymentType?.CASHEA &&
      !/^\d+$/.test(watch3('reference1'))
    ) {
      notify('Ingrese un número de referencia válido', 'info');
      return null;
    }

    for (let i = 0; i < parseInt(paymentMethodsQuantity); i++) {
      if (fileUploaded[i]) {
        formData.append(`paymentEvidence`, fileUploaded[i]);
      }
    }

    for (let j = 0; j < parseFloat(watch3('cashbackMethodsQuantity')); j++) {
      formData.append(`paymentChanges[${j}][paid]`, data[`paid`]);
      formData.append(
        `paymentChanges[${j}][method]`,
        data[`returnedMethod${j + 1}`]
      );
      formData.append(
        `paymentChanges[${j}][amount]`,
        data[`returnedAmount${j + 1}`]
      );
      formData.append(
        `paymentChanges[${j}][amountBsS]`,
        data[`returnedAmountBsS${j + 1}`]
      );
      if (data[`returnedReference${j + 1}`]) {
        formData.append(
          `paymentChanges[${j}][ref]`,
          data[`returnedReference${j + 1}`]
        );
      }
      formData.append(
        `paymentChanges[${j}][hasEvidence]`,
        data[`returnedReference${j + 1}`] ? true : false
      );
      if (cashBackImage[`image${j + 1}`]) {
        formData.append(
          `paymentChangeEvidence`,
          cashBackImage[`image${j + 1}`]
        );
      }
    }

    return formData;
  };

  const specialPayment = () => {
    const formData = new FormData();
    formData.append('paymentType', watch2('paymentType'));
    formData.append('membership', membership?._id);

    if (!isLoading) {
      setIsLoading(true);
      paymentsService
        .createMembershipPayment(formData)
        .then(response => {
          if ([200, 201].includes(response.status)) {
            approveMembership();
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
        });
    }
  };

  const filterPlanType = plans => {
    if ([planTypes?.PLUSF4]?.includes(membership?.membershipPlan)) {
      plans = plans?.filter(
        p =>
          ![membershipCodes?.FREE, membershipCodes?.MOTORIDERAV].includes(
            p?.code
          )
      );
      return plans;
    }

    if ([planStatus?.INCOMPLETED].includes(membership?.membershipStatus)) {
      plans = plans?.filter(
        p =>
          ![
            membershipCodes?.FREE,
            membershipCodes?.RCV,
            membershipCodes?.RCV_ELITE,
          ].includes(p?.code)
      );
      return plans;
    } else return plans;
  };

  const filterKmOptions = options => {
    let [filteredOptions] = membershipOptions?.filter(
      o => o?._id === watch('code')
    );

    let km = watch('km');

    if ([planTypes?.F2]?.includes(filteredOptions?.code)) {
      return options?.filter(o => [10]?.includes(o?._id));
    }

    if ([planTypes?.CLASSIC]?.includes(filteredOptions?.code)) {
      return options?.filter(o => [25]?.includes(o?._id));
    }
    if ([planTypes?.PLUS, planTypes?.PLUSF4]?.includes(filteredOptions?.code)) {
      return options?.filter(o => [50]?.includes(o?._id));
    }
    if ([planTypes?.GOLD]?.includes(filteredOptions?.code)) {
      let list = options?.filter(o => [200]?.includes(o?._id));
      if (km === 300) {
        list = options?.filter(o => [200, 300]?.includes(o?._id));
      }
      return list;
    }
    if (
      [planTypes?.MOTORIDER, planTypes?.MOTORIDERAV, planTypes?.FREE]?.includes(
        filteredOptions?.code
      )
    ) {
      return [];
    } else return options;
  };

  const calculateCashBack = () => {
    if (parseFloat(watch3('paid')) > parseFloat(watch3('amount1'))) {
      setValue3(
        'paidBsS',
        (parseFloat(watch3('paid')) * dollarRate)?.toFixed(2)
      );

      if (!watch3('cashbackMethodsQuantity')) {
        setValue3('cashbackMethodsQuantity', 1);
        let amountReturned =
          parseFloat(watch3(`amount1`)) - parseFloat(watch3('paid'));
        if (parseFloat(watch3(`amount1IGTF`))) {
          amountReturned = amountReturned + parseFloat(watch3(`amount1IGTF`));
        }
        amountReturned = amountReturned?.toFixed(2);
        setValue3('amountReturned', Math.abs(amountReturned));
        setValue3(
          'amountReturnedBsS',
          (parseFloat(watch3('amountReturned')) * dollarRate)?.toFixed(2)
        );
        setValue3(`returnedAmount1`, Math.abs(amountReturned));
        setValue3(
          `returnedAmountBsS1`,
          (parseFloat(watch3('amountReturned')) * dollarRate)?.toFixed(2)
        );
      }
    }
  };

  const handleDrop = (event, index) => {
    let file = '';
    // File added on drop
    if (event?.dataTransfer?.files) {
      file = Array?.from(event?.dataTransfer?.files)[0];
    }

    let maxWeight = 5; //Peso en MB

    // File added on Click
    if (event?.target?.files?.length > 0) {
      file = event?.target?.files[0];
    }

    if (
      !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
        file.type
      )
    ) {
      notify('Agregar un formato valido de imagen', 'error');
      return null;
    }
    if (file.size * 0.000001 > maxWeight) {
      notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
    }
    setCashBackImage(prevState => {
      return {
        ...prevState,
        [`image${index}`]: file,
      };
    });
  };

  const removeFile = index => {
    setCashBackImage(prevState => {
      return {
        ...prevState,
        [`image${index}`]: '',
      };
    });
  };

  const handleCashbackBs = index => {
    let cashbackMethodsQuantity = parseFloat(watch3('cashbackMethodsQuantity'));
    let amountReturned = parseFloat(watch3(`amountReturned`));

    setValue3(
      `returnedAmountBsS${index}`,
      (parseFloat(watch3(`returnedAmount${index}`)) * dollarRate)?.toFixed(2)
    );

    // Automatically set the amount for the last method
    if (index === cashbackMethodsQuantity - 1) {
      let paid = 0;
      for (let i = 1; i < cashbackMethodsQuantity; i++) {
        paid = paid + parseFloat(watch3(`returnedAmount${i}`));
      }

      if (amountReturned - paid <= 0) {
        notify(
          'La suma de los montos no puede superar el vuelto a entregar',
          'error'
        );
        for (let j = 1; j <= cashbackMethodsQuantity; j++) {
          setValue3(`returnedAmount${j}`, 0);
          setValue3(`returnedAmountBsS${j}`, 0);
        }
        return null;
      }

      setValue3(
        `returnedAmount${cashbackMethodsQuantity}`,
        (amountReturned - paid)?.toFixed(2)
      );
      setValue3(
        `returnedAmountBsS${cashbackMethodsQuantity}`,
        (
          parseFloat(watch3(`returnedAmount${cashbackMethodsQuantity}`)) *
          dollarRate
        )?.toFixed(2)
      );
    }
  };

  const handleCashbackMethodsQuantity = () => {
    for (let i = 1; i <= 3; i++) {
      setValue3(`returnedMethod${i}`, '');
      setValue3(`returnedAmount${i}`, '');
      setValue3(`returnedAmountBsS${i}`, '');
      setCashBackImage(prevState => {
        return {
          ...prevState,
          [`image${i}`]: '',
        };
      });
    }

    if (watch3('cashbackMethodsQuantity') === '1') {
      setValue3(`returnedAmount1`, watch3('amountReturned'));
      setValue3(
        `returnedAmountBsS1`,
        (parseFloat(watch3('amountReturned')) * dollarRate)?.toFixed(2)
      );
    }
  };

  const validateCashBackTotal = () => {
    let cashbackMethodsQuantity = parseFloat(watch3('cashbackMethodsQuantity'));
    let amountReturned = parseFloat(watch3(`amountReturned`));
    let total = 0;
    for (let i = 1; i <= cashbackMethodsQuantity; i++) {
      total = total + parseFloat(watch3(`returnedAmount${i}`));
    }

    if (parseFloat(total?.toFixed(2)) !== amountReturned) {
      notify(
        'La suma de los montos debe ser igual al monto a entregar',
        'error'
      );
      return false;
    }
    if (!watch3('returnedMethod1')) {
      // setError3(`returnedMethod1`, { type: 'custom', message: 'Obligatorio' });
      return false;
    } else {
      return true;
    }
  };

  const validateCashBackImages = () => {
    let cashbackMethodsQuantity = parseFloat(watch3('cashbackMethodsQuantity'));
    let canPost = true;

    setErrorsDropzone(prevState => {
      return {
        ...prevState,
        error1: false,
        error2: false,
        error3: false,
      };
    });

    for (let i = 1; i <= cashbackMethodsQuantity; i++) {
      const requiredMetods = paymentMethods?.filter(method =>
        [
          metodosDePago?.CASH_BS,
          metodosDePago?.CASH_EUR,
          metodosDePago?.CASH_USD,
          metodosDePago?.PUNTO_DE_VENTA,
        ]?.includes(method?.name)
      );
      const method = filterOptionById(
        requiredMetods,
        watch3('returnedMethod' + i)
      );

      if (method && !watch3(`returnedReference${i}`)) {
        setError3(`returnedReference${i}`, {
          type: 'custom',
          message: 'Obligatorio',
        });
        canPost = false;
      }

      if (!cashBackImage[`image${i}`] && method) {
        notify(
          `Adjunte todos los comprobantes de pago de ${method?.name}`,
          'error'
        );
        canPost = false;
        setErrorsDropzone(prevState => {
          return {
            ...prevState,
            [`error${i}`]: true,
          };
        });
      }
    }
    return canPost;
  };

  const payRewenMembership = handleSubmit3(async data => {
    let isCash =
      watch3('method1') ===
      filterOption(paymentMethods, metodosDePago?.CASH_USD)?._id;

    if (isCash) {
      calculateCashBack();

      if (parseFloat(watch3('cashbackMethodsQuantity')) > 0) {
        // Validate the total of the cashback
        // Validate that all images are up
        if (!validateCashBackTotal() || !validateCashBackImages()) {
          return null;
        }
      }
    }
    let dataToPost = { ...data };
    dataToPost = loadPaymentForms(dataToPost, watch3('paymentMethods'));
    let fileToPost = appendFile(
      dataToPost,
      getValues3('paymentMethods'),
      watch3('paymentType'),
      watch3('price')
    );
    if (!isLoading && fileToPost) {
      setIsLoading(true);
      paymentsService
        .createMembershipPayment(fileToPost)
        .then(response => {
          if ([200, 201].includes(response.status)) {
            notify(`Pago registrado con exito`, 'success');
            closePaymentModal();
            // history.push('/services');
            setIsLoading(false);
            reloadMembership();
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
        });
    }
  });

  const payPendingPayment = handleSubmit3(async data => {
    let dataToPost = { ...data };
    // delete dataToPost?.price;
    dataToPost = loadPaymentForms(dataToPost, watch3('paymentMethods'));
    let fileToPost = appendFile(
      dataToPost,
      watch3('paymentMethods'),
      watch3('paymentType'),
      watch3('price')
    );
    if (!isLoading && fileToPost) {
      setIsLoading(true);
      paymentsService
        .createPendingPayment(fileToPost)
        .then(response => {
          if ([200, 201].includes(response.status)) {
            notify(`Pago pendiente registrado con exito`, 'success');
            closePaymentModal();
            reloadMembership();
            // history.push('/services');
            setIsLoading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
        });
    }
  });

  const validateIfCanPay = () => {
    if (membership?.membershipStatus !== 'INCOMPLETO') {
      return null;
    }

    let label = 'Realizar Pago';
    let action = openPaymentModal;

    if (
      [paymentType?.COURTESY, paymentType?.PENDING].includes(
        watch2('paymentType')
      )
    ) {
      label = `Pago ${watch2('paymentType')}`;
      action = specialPayment;
    }

    if (
      watch2('paymentType') &&
      (watch2('finalPrice') || watch2('finalPrice') !== 0) &&
      watch2('finalPriceInBsS') &&
      watch2('paymentMethodsQuantity')
    ) {
      {
        return (
          <div className="buttons-center-container">
            <ActionButton label={label} action={() => action()} />
          </div>
        );
      }
    }
    // Realizar ago
    if (
      watch2('paymentType') &&
      (watch2('finalPrice') || watch2('finalPrice') === 0) &&
      watch2('finalPriceInBsS') &&
      watch2('paymentMethodsQuantity')
    ) {
      action = updateStatus;
      label = 'Realizar Renovación ';
      {
        return (
          <div className="buttons-center-container">
            <ActionButton label={label} action={() => action()} />
          </div>
        );
      }
    } else {
      return null;
    }
  };

  const handleTypeChange = () => {
    const [selectedOption] = membershipOptions?.filter(
      p => p?._id === watch('code')
    );
    setTimeout(() => {
      if (selectedOption?.code === planTypes?.CLASSIC) {
        setValue('km', 25);
      }
      if (
        [planTypes?.PLUS, planTypes?.PLUSF4]?.includes(selectedOption?.code)
      ) {
        setValue('km', 50);
      }
      if (selectedOption?.code === planTypes?.GOLD) {
        setValue('km', 150);
      }
    }, 1000);
  };

  const restorePaymentMethods = () => {
    if (parseFloat(watch3('price')) === 0) {
      for (let i = 0; i < parseInt(watch3('paymentMethods')); i++) {
        resetField3(`method${i}`);
        resetField3(`reference${i}`);
        resetField3(`paymentMethod${i}`);
        resetField3(`amount${i}`);
        resetField3(`amountBsS${i}`);
      }
      resetField3('paymentMethods');
    }
    // setValue3()
  };

  const handleAmountChange = (e, index) => {
    const { value } = e?.target;

    setValue3(`amountBsS${index}`, (value * dollarRate)?.toFixed(2));

    if (watch3('paymentType') === paymentType?.PENDING) {
      let quota2USD = parseFloat(watch3('price'));

      if (watch3('paymentMethods') > 1) {
        let amount2 = quota2USD - NaNtoNumber(parseFloat(watch3(`amount1`)));
        if (amount2 > 0 && amount2 < parseFloat(watch3('price'))) {
          setValue3('amount2', amount2);
          setValue3('amountBsS2', (amount2 * dollarRate)?.toFixed(2));
        }
      }

      for (let i = 0; i < parseFloat(watch3('paymentMethods')); i++) {
        quota2USD =
          quota2USD - NaNtoNumber(parseFloat(watch3(`amount${i + 1}`)));
      }

      if (
        NaNtoNumber(parseFloat(watch3(`amount1`))) > parseFloat(watch3('price'))
      ) {
        notify('El montos excede el costo de la membresia', 'info');
        setValue3('amount1', '');
        setValue3('amountBsS1', '');
        return null;
      }

      if (quota2USD >= 0) {
        setValue3('quota2USD', quota2USD);
        setValue3('quota2BsS', (quota2USD * dollarRate)?.toFixed(2));
      } else {
        setValue3(
          `amount${index}`,
          NaNtoNumber(parseFloat(watch3('price'))) - 1
        );
        setValue3('quota2USD', 0);
        setValue3('quota2BsS', 0);
        setValue3('amount1', '');
        setValue3('amountBsS1', '');
        notify('La suma de los montos excede el costo de la membresia', 'info');
      }
    }

    if (watch3('paymentType') === paymentType?.DE_CONTADO) {
      let quota2USD = parseFloat(watch3('price'));

      setValue3(
        'amount2',
        quota2USD - NaNtoNumber(parseFloat(watch3(`amount1`)))
      );
      setValue3('amountBsS2', (watch3(`amount2`) * dollarRate)?.toFixed(2));

      for (let i = 0; i < parseFloat(watch3('paymentMethods')); i++) {
        quota2USD =
          quota2USD - NaNtoNumber(parseFloat(watch3(`amount${i + 1}`)));
      }

      if (quota2USD >= 0) {
        setValue3('quota2USD', quota2USD);
        setValue3('quota2BsS', (quota2USD * dollarRate)?.toFixed(2));
      } else {
        setValue3(
          `amount${index}`,
          NaNtoNumber(parseFloat(watch3('price'))) - 1
        );
        setValue3('quota2USD', 0);
        setValue3('quota2BsS', 0);
        setValue3('amount1', '');
        setValue3('amountBsS1', '');
        notify('La suma de los montos excede el costo de la membresia', 'info');
      }
    }

    if (
      watch3('paymentType') === paymentType?.FINANCIADO &&
      [paymentModalTypes?.RENOVACION]?.includes(watch3('modal'))
    ) {
      let quota2USD = parseFloat(watch3('price'));

      for (let i = 0; i < parseFloat(watch3('paymentMethods')); i++) {
        quota2USD =
          quota2USD - NaNtoNumber(parseFloat(watch3(`amount${i + 1}`)));
      }

      if (quota2USD > 0) {
        setValue3('quota2USD', quota2USD);
        setValue3('quota2BsS', (quota2USD * dollarRate)?.toFixed(2));
      } else {
        setValue3(
          `amount${index}`,
          NaNtoNumber(parseFloat(watch3('price'))) - 1
        );
        setValue3('amount1', '');
        setValue3('amountBsS1', '');
        setValue3('quota2USD', 0);
        setValue3('quota2BsS', 0);
        notify('El monto de la segunda cuota debe ser mayor a 0$', 'info');
      }
    }

    if (
      watch3('paymentType') === paymentType?.FINANCIADO &&
      [paymentModalTypes?.PENDIENTE]?.includes(watch3('modal'))
    ) {
      let price = parseFloat(watch3('price'));

      setValue3(`amount2`, price - NaNtoNumber(parseFloat(watch3('amount1'))));
      setValue3(`amountBsS2`, (watch3('amount2') * dollarRate)?.toFixed(2));
    }
  };

  const handlePaymentMethodsChange = () => {
    if (parseFloat(watch3('paymentMethods')) === 2) {
      setValue3('mix', true);
      setValue3('method1', '');
      setValue3('amount1', '');
      setValue3('amountBsS1', '');
      setValue3('method2', '');
      setValue3('amount2', '');
      setValue3('amountBsS2', '');
    } else {
      setValue3('mix', false);
    }
  };

  const handleSecondAmountChange = (e, index) => {
    let method = paymentMethods?.filter(
      o => o?._id === watch3(`method${index}`)
    )[0];

    if (method && method?.currency !== 'BsS' && watch3('mix')) {
      setValue3(
        'amount2IGTF',
        ((watch3('amount2') * returnIGTF()) / 100)?.toFixed(2)
      );
    }

    setValue3('amountBsS2', (watch3('amount2') * dollarRate)?.toFixed(2));

    if (watch3('paymentType') === paymentType?.PENDING) {
      let quota2USD = parseFloat(watch3('price'));

      if (watch3('paymentMethods') > 1) {
        let amount1 = quota2USD - NaNtoNumber(parseFloat(watch3(`amount2`)));
        if (amount1 > 0 && amount1 < parseFloat(watch3('price'))) {
          setValue3('amount1', amount1);
          setValue3('amountBsS1', (watch3(`amount1`) * dollarRate)?.toFixed(2));
        }
      }

      for (let i = 0; i < parseFloat(watch3('paymentMethods')); i++) {
        quota2USD =
          quota2USD - NaNtoNumber(parseFloat(watch3(`amount${i + 1}`)));
      }

      if (
        NaNtoNumber(parseFloat(watch3(`amount2`))) > parseFloat(watch3('price'))
      ) {
        notify('El montos excede el costo de la membresia', 'info');
        setValue3('amount2', '');
        setValue3('amountBsS2', '');
        return null;
      }

      if (quota2USD >= 0) {
        setValue3('quota2USD', quota2USD);
        setValue3('quota2BsS', (quota2USD * dollarRate)?.toFixed(2));
      } else {
        setValue3(
          `amount${index}`,
          NaNtoNumber(parseFloat(watch3('price'))) - 1
        );
        setValue3('quota2USD', 0);
        setValue3('quota2BsS', 0);
        setValue3('amount1', '');
        setValue3('amountBsS1', '');
        notify('La suma de los montos excede el costo de la membresia', 'info');
      }
    }

    if (watch3('paymentType') === paymentType?.DE_CONTADO) {
      let quota2USD = parseFloat(watch3('price'));

      for (let i = 0; i < parseFloat(watch3('paymentMethods')); i++) {
        quota2USD =
          quota2USD - NaNtoNumber(parseFloat(watch3(`amount${i + 1}`)));
      }

      if (quota2USD >= 0) {
        setValue3('quota2USD', quota2USD);
        setValue3('quota2BsS', (quota2USD * dollarRate)?.toFixed(2));
      } else {
        setValue3(
          `amount${index}`,
          NaNtoNumber(parseFloat(watch3('price'))) - 1
        );
        setValue3('quota2USD', 0);
        setValue3('quota2BsS', 0);
        setValue3('amount2', '');
        setValue3('amountBsS2', '');
        notify('La suma de los montos excede el costo de la membresia', 'info');
      }
    }

    if (
      watch3('paymentType') === paymentType?.FINANCIADO &&
      [paymentModalTypes?.RENOVACION]?.includes(watch3('modal'))
    ) {
      let quota2USD = parseFloat(watch3('price'));

      for (let i = 0; i < parseFloat(watch3('paymentMethods')); i++) {
        quota2USD =
          quota2USD - NaNtoNumber(parseFloat(watch3(`amount${i + 1}`)));
      }

      if (quota2USD > 0) {
        setValue3('quota2USD', quota2USD);
        setValue3('quota2BsS', (quota2USD * dollarRate)?.toFixed(2));
      } else {
        setValue3(
          `amount${index}`,
          NaNtoNumber(parseFloat(watch3('price'))) - 1
        );
        setValue3('amount2', '');
        setValue3('amountBsS2', '');
        notify('El monto de la segunda cuota debe ser mayor a 0$', 'info');
      }
    }

    if (
      watch3('paymentType') === paymentType?.FINANCIADO &&
      [paymentModalTypes?.PENDIENTE]?.includes(watch3('modal'))
    ) {
      let quota2USD = parseFloat(watch3('price'));

      for (let i = 0; i < parseFloat(watch3('paymentMethods')); i++) {
        quota2USD =
          quota2USD - NaNtoNumber(parseFloat(watch3(`amount${i + 1}`)));
      }

      if (quota2USD < 0) {
        setValue3(
          `amount${index}`,
          NaNtoNumber(parseFloat(watch3('price'))) - 1
        );
        setValue3('quota2USD', 0);
        setValue3('quota2BsS', 0);
        setValue3('amount2', '');
        setValue3('amountBsS2', '');
        notify('La suma de los montos supera el costo a facturar', 'info');
      }
    }
  };

  const applyDiscount = handleSubmit2(async data => {
    let dataToPost = {
      // amount: data?.finalPrice,
      // amountBsS: data?.finalPriceInBsS,
      code: data?.code,
    };

    if (membership?.discountCode) {
      notify(`La membresia ya tiene un descuento aplicado`, 'info');

      return null;
    }

    if (!isLoading) {
      setIsLoading(true);
      membershipService
        .upgradeMembershipPaymet(filterParams(dataToPost), membership?._id)
        .then(response => {
          if ([200, 201].includes(response.status)) {
            notify(`Código Aplicado`, 'success');
            reloadMembership();
            setIsLoading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
        });
    }
  });

  const removeDiscount = handleSubmit2(async data => {
    if (!isLoading) {
      setIsLoading(true);
      membershipService
        .removeCode(membership?._id)
        .then(response => {
          if ([200, 201].includes(response.status)) {
            notify(`Código removido`, 'success');
            setValue2('code', '');
            setValue2('discountByCode', '');
            setValue2('giftcardCode', '');
            setValue2('discountByGiftcard', '');
            reloadMembership();
            setIsLoading(false);
            // window.location.reload();
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
        });
    }
  });

  const getAvailableMemberships = () => {
    let params = {};

    params = defineLangindType(params, membership);
    params.class = membership?.vehicle?.classId;
    params.year = membership?.vehicle?.year;

    membershipCatalogService
      .getAvailableMemberships(filterParams(params))
      .then(response => {
        if (response.status === 200) {
          let data = [...response?.data];

          if (membership?.membershipStatus === planStatus?.INCOMPLETED) {
            data = data?.filter(
              d => ![membershipCodes?.FREE]?.includes(d?.code)
            );
          }

          data = data?.filter(
            d =>
              ![membershipCodes?.RCV, membershipCodes?.RCV_ELITE]?.includes(
                d?.code
              )
          );

          for (let i = 0; i < data.length; i++) {
            data[i].name = data[i]?.code;
          }

          setMembershipOptions(filterPlanType(data));
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => {});
  };

  function dateDiffInDays(firstDate, secondDate) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(
      Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay)
    );
    return diffDays;
  }

  const validateCreatedDate = dataToPost => {
    const firstDate = new Date();
    const secondDate = new Date(dataToPost?.createdAt);
    const daysDifference = dateDiffInDays(firstDate, secondDate);

    if (
      [
        salesRoles?.SALES_SUPERVISOR,
        salesRoles?.SALES_HUNTER,
        salesRoles?.SALES_FARMER,
        salesRoles?.SALES_COUNTER,
      ].includes(authService.getUserType()) &&
      daysDifference > 5
    ) {
      delete dataToPost?.createdAt;
      return dataToPost;
    } else return dataToPost;
  };

  const editMembership = handleSubmit(async data => {
    let dataToPost = { ...data };

    dataToPost = validateCreatedDate(dataToPost);
    // delete dataToPost?.createdAt;
    delete dataToPost?.contractType;
    delete dataToPost?.affiliationType;
    delete dataToPost?.useExtendedTow;
    dataToPost = filterParams(dataToPost);

    if (!isLoading) {
      setIsLoading(true);
      membershipService
        .updateMembership(dataToPost, membership?._id)
        .then(response => {
          if ([200, 201].includes(response.status)) {
            handleCreationDateChange();
            notify(`Datos actualizados`, 'success');
            reloadMembership();
            setIsLoading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
        });
    }
  });

  const getDollarRate = async () => {
    rateService
      .getDollarRate()
      .then(response => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
          setbsDisabled(true);

          if (membership?.paymentType === paymentType?.FINANCIADO) {
            setValue2(
              `paymentDues[0].amountBs`,
              (
                response?.data?.rate * membership?.paymentDues[0]?.amount
              )?.toFixed(2)
            );
            setValue2(
              `paymentDues[1].amountBs`,
              (
                response?.data?.rate * membership?.paymentDues[1]?.amount
              )?.toFixed(2)
            );
          }
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => {});
  };

  const getUsers = async () => {
    let params = {
      limit: 1000000,
      offset: 0,
      module: 'Ventas',
      status: 'Activo',
    };
    usersService
      .getUsers(params)
      .then(response => {
        if (response.status === 200) {
          let data = [];
          for (let i = 0; i < response?.data?.users?.length; i++) {
            data?.push({
              _id: response?.data?.users[i]?._id,
              name:
                response?.data?.users[i]?.firstName +
                '  ' +
                response?.data?.users[i]?.lastName,
            });
          }

          setSellerOptions(data);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => {});
  };

  const getSalesChannel = () => {
    saleChannelService
      .getSalesChannel()
      .then(response => {
        if (response.status === 200) {
          setSalesChannelOptions(response?.data?.saleChannels);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => {});
  };

  const getPaymentMethods = () => {
    productService
      .getPaymentMethods('sales')
      .then(response => {
        if (response.status === 200) {
          setPaymentMethods(response?.data);

          let filtered = response?.data?.filter(method =>
            [paymentMethods?.CASHEA]?.includes(method?.name)
          );
          setFilteredMethods(filtered);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => {});
  };

  const openPaymentModal = () => {
    if (!watch('code')) {
      notify('Seleccione un Tipo de Plan', 'error');
      return null;
    }

    setValue3('modal', paymentModalTypes?.RENOVACION);
    setValue3('price', parseFloat(watch2('finalPrice'))?.toFixed(2));
    setValue3('paymentType', watch2('paymentType'));
    setValue3('paymentMethods', watch2('paymentMethodsQuantity'));

    setOpenPayModal(true);
    // Here we preload the payment form depending on the Payment Type
    handlePaymentTypeForm();
    setValue3('mix', false);

    if (watch2('paymentType') === paymentType?.CASHEA) {
      let cashea = filterOption(paymentMethods, 'CASHEA');
      setValue3('method1', cashea?._id);
    } else {
      let filteredOptions = [...paymentMethods];
      filteredOptions = filteredOptions?.filter(o => o?.name !== 'CASHEA');
      setPaymentMethods(filteredOptions);
    }
  };

  const handleCasheaAmount = () => {
    setValue3(`amount1`, (watch2('finalPrice') * watch3('initial')) / 100);
    setValue3(
      `amountBsS1`,
      ((dollarRate * watch2('finalPrice') * watch3('initial')) / 100)?.toFixed(
        2
      )
    );
  };

  const openMembershipPendingPaymentModal = () => {
    setOpenPayModal(true);
    setValue3('modal', paymentModalTypes?.PENDIENTE);
    setValue3('price', parseFloat(watch2('finalPrice'))?.toFixed(2));
    setValue3('paymentType', watch2('paymentType'));
    setValue3('paymentMethods', 1);

    // Here we preload the payment form depending on the Payment Type
    handlePaymentTypeForm();
    setValue3('mix', false);

    if (membership?.paymentType === paymentType?.FINANCIADO) {
      setValue3('amount1', membership?.paymentDues[1]?.amount?.toFixed(2));
      setValue3('price', membership?.paymentDues[1]?.amount?.toFixed(2));
      setValue3(
        'amountBsS1',
        (membership?.paymentDues[1]?.amount * dollarRate)?.toFixed(2)
      );
    }

    if (membership?.paymentType === paymentType?.PENDING) {
      setValue3('amount1', watch2('finalPrice')?.toFixed(2));
      setValue3('amountBsS1', watch2('finalPrice') * dollarRate)?.toFixed(2);
    }
  };

  const updateStatus = () => {
    if (!isLoading) {
      setIsLoading(true);
      membershipService
        .updateMembershipStatus(membership?._id)
        .then(response => {
          if ([200, 201].includes(response?.status)) {
            setIsLoading(false);
            // notify(`Membresia aprobada`, 'success');
            window.location.reload();
          } else {
            setIsLoading(false);
            notify(`${response.message}`, 'error');
          }
        })
        .catch(() => {});
    }
  };

  const updatePaymentTypeInRenewal = () => {
    if (!isLoading) {
      setIsLoading(true);
      membershipService
        .updatePaymentTypeInRenewal(membership?._id, {
          paymentType: watch2('paymentType'),
        })
        .then(response => {
          if ([200, 201].includes(response?.status)) {
            setIsLoading(false);
            reloadMembership();
          } else {
            setIsLoading(false);
            notify(`${response.message}`, 'error');
          }
        })
        .catch(() => {});
    }
  };

  const changePaymentType = () => {
    if (watch2('paymentType') === paymentType?.FINANCIADO) {
      setValue2('paymentMethodsQuantity', 1);
    }

    updatePaymentTypeInRenewal();
  };

  const handlePaymentTypeForm = () => {
    if (watch3('paymentType') === paymentType?.DE_CONTADO) {
      setValue3('paymentMethods', 1);
      setValue3('amount1', watch3('price'));
      setValue3(
        'amountBsS1',
        (NaNtoNumber(parseFloat(watch3('price'))) * dollarRate)?.toFixed(2)
      );
    }

    if (watch3('paymentType') === paymentType?.FINANCIADO) {
      setValue3('paymentMethods', 1);
    }
  };

  const handleBsAmount = () => {
    setValue2(
      'finalPriceInBsS',
      (parseFloat(watch2('finalPrice')) * dollarRate)?.toFixed(2)
    );
  };

  const closePaymentModal = () => {
    getPaymentMethods();
    setOpenPayModal(false);
    setFileUploaded([]);
    reset3();
  };

  const approveMembership = () => {
    if (!isLoading) {
      setIsLoading(true);
      membershipService
        .approveMembership(membership?._id)
        .then(response => {
          if ([200, 201].includes(response?.status)) {
            setIsLoading(false);
            notify(`Membresia aprobada`, 'success');
            window.location.reload();
          } else {
            setIsLoading(false);
            notify(`${response.message}`, 'error');
          }
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    getDollarRate();
    getPaymentMethods();
    getAvailableMemberships();
    preloadForm();
  }, [open, membership]);

  const validateDisabled = inputName => {
    if (
      ['rcvBeginDate', 'rcvEndDate']?.includes(inputName) &&
      membership?.membershipPlan === planTypes?.PLUSF4
    ) {
      return true;
    }

    if (
      [
        salesRoles.SALES_ADMIN,
        salesRoles?.SALES_DEVELOPER,
        salesRoles?.SALES_SUPERVISOR,
      ].includes(authService.getUserType())
    ) {
      return false;
    }
    if (
      [salesRoles.SALES_ADMIN_AUX].includes(authService.getUserType()) &&
      membership?.membershipStatus === planStatus?.PENDING_FOR_APPROBAL
    ) {
      return false;
    }
    if (
      [
        salesRoles?.SALES_SUPERVISOR,
        salesRoles.SALES_HUNTER,
        salesRoles.SALES_FARMER,
      ].includes(authService.getUserType()) &&
      [planStatus?.PENDING_FOR_APPROBAL, planStatus?.TO_BE_RENEW].includes(
        membership?.membershipStatus
      )
    ) {
      return false;
    } else return true;
  };

  const determinValidDate = () => {
    let createdAt = new Date(watch('createdAt'));
    let minDate = createdAt;
    minDate.setDate(minDate.getDate() - 5);

    if ([salesRoles?.SALES_ADMIN].includes(authService.getUserType())) {
      return false;
    }
    if (
      [
        salesRoles?.SALES_SUPERVISOR,
        salesRoles?.SALES_HUNTER,
        salesRoles?.SALES_FARMER,
        salesRoles?.SALES_COUNTER,
      ].includes(authService.getUserType()) &&
      membership?.membershipStatus === planStatus?.PENDING_FOR_APPROBAL
    ) {
      return minDate;
    } else return false;
  };

  const validateCreationDate = () => {
    if ([salesRoles?.SALES_ADMIN].includes(authService.getUserType())) {
      return false;
    }

    if (
      [
        salesRoles?.SALES_HUNTER,
        salesRoles?.SALES_FARMER,
        salesRoles?.SALES_COUNTER,
      ].includes(
        authService.getUserType() &&
          [planStatus?.ACTIVE, planStatus?.TO_BE_RENEW]?.includes(
            membership?.membershipStatus
          )
      )
    ) {
      return true;
    }

    if (
      [
        salesRoles.SALES_ADMIN_AUX,
        salesRoles?.SALES_HUNTER,
        salesRoles?.SALES_FARMER,
        salesRoles?.SALES_COUNTER,
      ].includes(authService.getUserType()) &&
      membership?.membershipStatus === planStatus?.PENDING_FOR_APPROBAL
    ) {
      return false;
    } else return true;
  };

  const justRenovation = () => {
    if (membership?.membershipStatus === planStatus?.INCOMPLETED) {
      return true;
    }

    if (membership?.membershipPlan === planTypes?.PLUSF4) {
      return true;
    }

    if (
      [
        salesRoles.SALES_DEVELOPER,
        salesRoles.SALES_ADMIN,
        salesRoles.SALES_SUPERVISOR,
      ].includes(authService.getUserType())
    ) {
      return false;
    } else return true;
  };

  const justAdminAndSupervisor = () => {
    if (
      [salesRoles?.SALES_ADMIN, salesRoles?.SALES_SUPERVISOR]?.includes(
        authService.getUserType()
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  // const editPayment = (i) => {
  //   const formData = new FormData();
  //   formData.append('membership', membership?._id);
  //   formData.append(`payments[0][id]`, membership?.payments[i]?._id);
  //   formData.append(`payments[0][method]`, watch2(`paymentMethod${i}`));
  //   formData.append(`payments[0][reference]`, watch2(`paymentReference${i}`));
  //   formData.append(`payments[0][amount]`, watch2(`paymentAmount${i}`));
  //   formData.append(`payments[0][amountBsS]`, watch2(`paymentAmountBsS${i}`));

  //   if (!isLoading && formData) {
  //     setIsLoading(true);
  //     paymentsService
  //       .editPayment(formData)
  //       .then((response) => {
  //         if ([200, 201].includes(response.status)) {
  //           notify(`Pago editado con exito`, 'success');
  //           reloadMembership();
  //           setIsLoading(false);
  //         } else {
  //           notify(`${response.message}`, 'error');
  //           setIsLoading(false);
  //         }
  //       })
  //       .catch((error) => {
  //         setIsLoading(false);
  //       });
  //   }
  // };

  const editAllPaymentBs = rate => {
    const formData = new FormData();

    formData.append(`membership`, membership?._id);

    for (let i = 0; i < membership?.payments?.length; i++) {
      formData.append(`payments[${i}][id]`, membership?.payments[i]?._id);
      formData.append(
        `payments[${i}][method]`,
        membership?.payments[i]?.methodId
      );
      formData.append(
        `payments[${i}][reference]`,
        membership?.payments[i]?.reference
      );
      formData.append(
        `payments[${i}][amount]`,
        membership?.payments[i]?.totalAmount
      );
      formData.append(
        `payments[${i}][amountBsS]`,
        membership?.payments[i]?.totalAmount * rate
      );
    }

    if (!isLoading && formData) {
      setIsLoading(true);
      paymentsService
        .editPayment(formData)
        .then(response => {
          if ([200, 201].includes(response.status)) {
            // notify(`Pago editado con exito`, 'success');
            reloadMembership();
            setIsLoading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
        });
    }
  };

  const validatePlanType = membershipStatus => {
    if ([salesRoles.SALES_ADMIN].includes(authService?.getUserType())) {
      return false;
    }
    if (![planStatus?.PENDING_FOR_APPROBAL].includes(membershipStatus)) {
      return true;
    } else return false;
  };

  // const paymentAmountChange = (paymentAmount, i) => {
  //   setValue2(`paymentAmountBsS${i}`, (paymentAmount * dollarRate).toFixed(2));
  // };

  // This needs to change

  // const editPendingPayment = (i) => {
  //   const formData = new FormData();
  //   formData.append('membership', membership?._id);
  //   formData.append(`payments[0][id]`, membership?.paymentDues[i]?.payment[0]);
  //   formData.append(`payments[0][amount]`, watch2(`paymentDues[${i}].amount`));
  //   formData.append(
  //     `payments[0][amountBsS]`,
  //     watch2(`paymentDues[${i}].amountBs`)
  //   );
  //   formData.append(
  //     `payments[0][method]`,
  //     watch2(`paymentDues[${i}].paymentMethod`)
  //   );
  //   formData.append(
  //     `payments[0][reference]`,
  //     watch2(`paymentDues[${i}].reference`)
  //   );

  //   if (!isLoading && formData) {
  //     setIsLoading(true);
  //     paymentsService
  //       .editPayment(formData)
  //       .then((response) => {
  //         if ([200, 201].includes(response.status)) {
  //           notify(`Pago editado con exito`, 'success');
  //           reloadMembership();
  //           setIsLoading(false);
  //         } else {
  //           notify(`${response.message}`, 'error');
  //           setIsLoading(false);
  //         }
  //       })
  //       .catch((error) => {
  //         setIsLoading(false);
  //       });
  //   }
  // };

  // const handleDueAmountChange = (value, status, i) => {
  //   if (value && status !== 'Pago realizado') {
  //     setValue2(`paymentDues[${i}].amountBs`, (parseFloat(value) * dollarRate)?.toFixed(2));
  //   }
  // };

  const Paymentaction = () => {
    if (watch3('modal') === paymentModalTypes?.PENDIENTE) {
      return payPendingPayment;
    }
    if (watch3('modal') === paymentModalTypes?.RENOVACION) {
      return payRewenMembership;
    }
  };

  // Internal counter cant see affiliation cards
  if (
    [salesRoles?.SALES_COUNTER].includes(authService?.getUserType()) &&
    membership?.membershipStatus === planStatus?.INCOMPLETED &&
    authService?.getUser()?.type === userType?.INTERNO
  ) {
    return null;
  }

  // Admin Aux cant see affiliation card
  if (
    [salesRoles?.SALES_ADMIN_AUX].includes(authService?.getUserType()) &&
    membership?.membershipStatus === planStatus?.INCOMPLETED
  ) {
    return null;
  }

  const getCurrencyRates = () => {
    let createdAt = getCorrectDate(membership?.createdAt);
    let date1 = moment(createdAt).format('YYYY-MM-DD');
    let date2 = moment(watch('createdAt')).format('YYYY-MM-DD');

    if (date1 !== date2) {
      let params = {
        currency: 'USD',
        date: watch('createdAt')
          ? moment(watch('createdAt')).format('YYYY-MM-DD')
          : '',
        limit: 1,
      };

      currencyRateService
        .getCurrencyRates(params)
        .then(response => {
          if (response.status === 200) {
            let rate = response?.data?.result[0]?.rate;
            editAllPaymentBs(rate);
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => {});
      return true;
    }
  };

  const handleCreationDateChange = () => {
    getCurrencyRates();
  };

  const validaterRCVEmissionDateDisabled = () => {
    if ([salesRoles?.SALES_ADMIN]?.includes(authService?.getUserType())) {
      return false;
    }
    if (
      [
        salesRoles?.SALES_FARMER,
        salesRoles?.SALES_COUNTER,
        salesRoles?.SALES_HUNTER,
      ]?.includes(
        authService?.getUserType() &&
          membership?.membershipStatus === planStatus?.PENDING_FOR_APPROBAL
      )
    ) {
      return false;
    } else return true;
  };

  const validatePaymentTypeOptions = () => {
    const user = authService?.getUser();

    let filterOptions = [...allPaymentTypesOptions];

    if (
      ![
        salesRoles?.SALES_ADMIN,
        salesRoles?.SALES_HUNTER,
        salesRoles?.SALES_FARMER,
        salesRoles?.SALES_HUNTER,
      ]?.includes(authService?.getUserType()) ||
      (user?.roles[0]?.code === salesRoles?.SALES_COUNTER &&
        user?.type !== userType?.INTERNO)
    ) {
      filterOptions = filterOptions.filter(
        o => ![paymentType?.CASHEA]?.includes(o?._id)
      );
    }
    setAllPaymentTypesOptions(filterOptions);
  };

  return (
    <>
      <LoadSpinner show={isLoading} />

      <PaymentModal
        show={openPayModal}
        register={register3}
        openPayModal={openPayModal}
        handleClose={() => closePaymentModal()}
        payRewenMembership={Paymentaction()}
        errors={errors3}
        setValue={setValue3}
        control={control3}
        options={{
          paymentMethods: paymentMethods,
          paymentTypesOptions: allPaymentTypesOptions,
          dollarRate: dollarRate,
          filteredMethods: filteredMethods,
        }}
        onChange={{
          handlePaymentTypeForm: handlePaymentTypeForm,
          restorePaymentMethods: restorePaymentMethods,
          handleAmountChange: handleAmountChange,
          handleSecondAmountChange: handleSecondAmountChange,
          handlePaymentMethodsChange: handlePaymentMethodsChange,
          handleCasheaAmount: handleCasheaAmount,
          handleCashbackBs: handleCashbackBs,
          handleCashbackMethodsQuantity: handleCashbackMethodsQuantity,
        }}
        membership={membership}
        watch={watch3}
        setFileUploaded={setFileUploaded}
        fileUploaded={fileUploaded}
        bsDisabled={bsDisabled}
        handleDrop={handleDrop}
        cashBackImage={cashBackImage}
        removeFile={removeFile}
        errorsDropzone={errorsDropzone}
      />

      <div className="order-card">
        <OderCardHeader open={open} setOpen={setOpen} label={label} />

        {open && (
          <>
            <br />
            <div style={{ width: '100%' }} className="row">
              <div className="col-12 col-md-4">
                <div className="buttons-container">
                  <h6 style={{ margin: '6px 12px 12px 0px' }}>
                    DATOS DEL PLAN
                  </h6>
                </div>
                <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>

                <div className="row">
                  <Input
                    bootstrap={'col-12'}
                    label={'Tipo de afiliación '}
                    type={'text'}
                    name={'affiliationType'}
                    register={register}
                    placeholder={''}
                    errors={errors?.affiliationType?.message}
                    disabled={true}
                  />{' '}
                  <Input
                    bootstrap={'col-12'}
                    label={'Canal '}
                    type={'select'}
                    name={'saleChannel'}
                    options={salesChannelOptions}
                    register={register}
                    placeholder={''}
                    errors={errors?.saleChannel?.message}
                  />{' '}
                  <Input
                    bootstrap={'col-12'}
                    label={'Tipo de contrato '}
                    type={'text'}
                    name={'contractType'}
                    register={register}
                    placeholder={''}
                    errors={errors?.contractType?.message}
                    disabled={true}
                  />{' '}
                  <Input
                    bootstrap={'col-12'}
                    label={'RCV '}
                    type={'select'}
                    name={'rcv'}
                    options={rcvOptions}
                    register={register}
                    placeholder={''}
                    errors={errors?.rcvs?.message}
                    disabled={justRenovation()}
                  />{' '}
                  <Input
                    bootstrap={'col-12'}
                    label={'Tipo de Plan '}
                    type={'select'}
                    name={'code'}
                    options={membershipOptions}
                    register={register}
                    placeholder={''}
                    errors={errors?.code?.message}
                    onChange={handleTypeChange}
                    disabled={validatePlanType(membership?.membershipStatus)}
                  />
                  <Input
                    bootstrap={'col-12'}
                    label={'Km de servicio de grúa'}
                    type={'select'}
                    name={'km'}
                    options={filterKmOptions(KMOderOptions)}
                    register={register}
                    placeholder={''}
                    errors={errors?.code?.km}
                    disabled={
                      ![salesRoles.SALES_ADMIN].includes(
                        authService?.getUserType()
                      )
                    }
                  />
                  {membership?.membershipPlan === planTypes?.GOLD && (
                    <Input
                      bootstrap={'col-12'}
                      subLabel={'Añadir un (1) servicio de grúa ilimitado'}
                      type={'checkbox'}
                      name={'useExtendedTow'}
                      // options={filterKmOptions(KMOderOptions)}
                      register={register}
                      placeholder={''}
                      errors={errors?.code?.useExtendedTow}
                      disabled={true}
                    />
                  )}
                  <Input
                    bootstrap={'col-12'}
                    label={'Asesor '}
                    type={'select'}
                    name={'seller'}
                    options={sellerOptions}
                    register={register}
                    placeholder={''}
                    errors={errors?.seller?.message}
                    disabled={justAdminAndSupervisor()}
                  />{' '}
                  <Input
                    bootstrap={'col-12'}
                    label={'Acción de campañas '}
                    type={'select'}
                    options={campaignActionOptions}
                    capitalizeOff={true}
                    name={'campaignAction'}
                    register={register}
                    placeholder={''}
                    errors={errors?.campaignAction?.message}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="buttons-container">
                  <h6 style={{ margin: '6px 12px 12px 0px' }}>
                    FECHAS ASOCIADAS
                  </h6>
                  <ActionButton label={'Guardar'} action={editMembership} />
                </div>
                <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>

                <div className="row">
                  {watch('endDateFromLastMembership') && (
                    <Input
                      bootstrap={'col-12'}
                      label={'Fin de la ultima Membresia'}
                      type={'date'}
                      name={'endDateFromLastMembership'}
                      register={register}
                      placeholder={''}
                      control={control}
                      errors={errors2?.endDateFromLastMembership?.message}
                      disabled={true}
                    />
                  )}
                  {watch('rcvEndDateFromLastMembership') && (
                    <Input
                      bootstrap={'col-12'}
                      label={'Fin del ultimo RCV'}
                      type={'date'}
                      name={'rcvEndDateFromLastMembership'}
                      register={register}
                      placeholder={''}
                      control={control}
                      errors={errors2?.rcvEndDateFromLastMembership?.message}
                      disabled={true}
                    />
                  )}
                  <Input
                    bootstrap={'col-12'}
                    label={'Fecha de creación'}
                    type={'date'}
                    name={'createdAt'}
                    register={register}
                    placeholder={''}
                    control={control}
                    errors={errors2?.createdAt?.message}
                    disabled={validateCreationDate()}
                    minDate={determinValidDate()}
                    onChange={handleCreationDateChange}
                  />
                  <Input
                    bootstrap={'col-12'}
                    label={'Inicio del Plan'}
                    type={'date'}
                    name={'beginDate'}
                    register={register}
                    placeholder={''}
                    control={control}
                    errors={errors2?.beginDate?.message}
                    disabled={validateDisabled()}
                  />
                  <Input
                    bootstrap={'col-12'}
                    label={'Fin del Plan'}
                    type={'date'}
                    name={'endDate'}
                    register={register}
                    placeholder={''}
                    control={control}
                    errors={errors2?.endDate?.message}
                    disabled={validateDisabled()}
                  />
                  <Input
                    bootstrap={'col-12'}
                    label={'Fecha de emisión RCV'}
                    type={'date'}
                    name={'rcvEmissionDate'}
                    register={register}
                    placeholder={''}
                    control={control}
                    errors={errors2?.rcvEmissionDate?.message}
                    disabled={validaterRCVEmissionDateDisabled()}
                  />
                  <Input
                    bootstrap={'col-12'}
                    label={'Inicio RCV'}
                    type={'date'}
                    name={'rcvBeginDate'}
                    register={register}
                    placeholder={''}
                    control={control}
                    errors={errors2?.rcvBeginDate?.message}
                    disabled={validateDisabled('rcvBeginDate')}
                  />
                  <Input
                    bootstrap={'col-12'}
                    label={'Fin RCV'}
                    type={'date'}
                    name={'rcvEndDate'}
                    register={register}
                    placeholder={''}
                    control={control}
                    errors={errors2?.rcvEndDate?.message}
                    disabled={validateDisabled('rcvEndDate')}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="buttons-container">
                  <h6 style={{ margin: '6px 12px 12px 0px' }}>
                    DATOS DEL PAGO
                  </h6>
                </div>
                {!membership?.fleetLoad && (
                  <>
                    <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
                    <div className="row">
                      <Input
                        bootstrap={'col-12'}
                        label={'Estatus de Pago *'}
                        type={'text'}
                        name={'paymentStatus'}
                        register={register2}
                        placeholder={''}
                        errors={errors2?.paymentStatus?.message}
                        onChange={changePaymentType}
                        disabled={true}
                      />{' '}
                      <Input
                        bootstrap={'col-12'}
                        label={'Tipo de pago *'}
                        type={'select'}
                        options={allPaymentTypesOptions}
                        name={'paymentType'}
                        register={register2}
                        placeholder={''}
                        errors={errors2?.paymentType?.message}
                        onChange={changePaymentType}
                        disabled={membership?.membershipStatus !== 'INCOMPLETO'}
                      />{' '}
                      {membership?.membershipStatus ===
                        planStatus?.INCOMPLETED && (
                        <>
                          <Input
                            bootstrap={'col-12'}
                            label={'Costo del plan USD *'}
                            type={'number'}
                            name={'finalPrice'}
                            register={register2}
                            placeholder={''}
                            onChange={handleBsAmount}
                            errors={errors2?.finalPrice?.message}
                            min={0}
                            disabled={
                              ![salesRoles.SALES_ADMIN].includes(
                                authService?.getUserType()
                              )
                            }
                          />{' '}
                          <Input
                            bootstrap={'col-12'}
                            label={'Costo del plan BsS *'}
                            type={'number'}
                            name={'finalPriceInBsS'}
                            register={register2}
                            placeholder={''}
                            errors={errors2?.finalPriceInBsS?.message}
                            min={0}
                            disabled={
                              ![salesRoles.SALES_ADMIN].includes(
                                authService?.getUserType()
                              )
                            }
                          />
                        </>
                      )}
                      {membership?.membershipStatus === 'INCOMPLETO' && (
                        <>
                          {!membership?.giftcardCode &&
                            !membership?.discountCode && (
                              <>
                                <Input
                                  bootstrap={'col-8'}
                                  label={'Código (Descuento o Tarjeta)'}
                                  type={'text'}
                                  name={'code'}
                                  register={register2}
                                  placeholder={''}
                                  errors={errors2?.code?.message}
                                  min={0}
                                />
                                <Input
                                  bootstrap={'col-4'}
                                  label={''}
                                  type={'text'}
                                  name={'discountByCode'}
                                  register={register2}
                                  placeholder={''}
                                  errors={errors2?.discountByCode?.message}
                                  min={0}
                                  disabled={true}
                                />
                              </>
                            )}

                          {membership?.discountCode && (
                            <>
                              <Input
                                bootstrap={'col-8'}
                                label={'Código (Descuento)'}
                                type={'text'}
                                name={'code'}
                                register={register2}
                                placeholder={''}
                                errors={errors2?.code?.message}
                                min={0}
                                disabled={membership?.discountCode}
                              />
                              <Input
                                bootstrap={'col-4'}
                                label={''}
                                type={'text'}
                                name={'discountByCode'}
                                register={register2}
                                placeholder={''}
                                errors={errors2?.discountByCode?.message}
                                min={0}
                                disabled={true}
                              />
                            </>
                          )}
                          {membership?.giftcardCode && (
                            <>
                              <Input
                                bootstrap={'col-8'}
                                label={'Código (Tarjeta)'}
                                type={'text'}
                                name={'giftcardCode'}
                                register={register2}
                                placeholder={''}
                                errors={errors2?.giftcardCode?.message}
                                min={0}
                                disabled={membership?.giftcardCode}
                              />
                              <Input
                                bootstrap={'col-4'}
                                label={''}
                                type={'text'}
                                name={'discountByGiftcard'}
                                register={register2}
                                placeholder={''}
                                errors={errors2?.discountByGiftcard?.message}
                                min={0}
                                disabled={true}
                              />
                            </>
                          )}
                        </>
                      )}
                      {membership?.membershipStatus === 'INCOMPLETO' &&
                        !membership?.discountCode &&
                        !membership?.giftcardCode && (
                          <div className="buttons-center-container">
                            <ActionButton
                              label={'Aplicar código'}
                              action={applyDiscount}
                            />
                          </div>
                        )}
                      {membership?.membershipStatus === 'INCOMPLETO' &&
                        (membership?.discountCode ||
                          membership?.giftcardCode) && (
                          <div className="buttons-center-container">
                            <ActionButton
                              label={'Remover código'}
                              action={removeDiscount}
                            />
                          </div>
                        )}
                      {/* {membership?.payments?.length > 0 &&
                        membership?.paymentType !== paymentType?.FINANCIADO &&
                        membership?.payments?.map((m, i) => {
                          return (
                            <>
                              <Input
                                bootstrap={'col-6'}
                                label={`Pago ${i + 1} ${membership?.payments[i]?.upgrade
                                  ? '(Upgrade)'
                                  : ''
                                  } USD`}
                                type={'number'}
                                name={`paymentAmount${i}`}
                                register={register2}
                                placeholder={''}
                                errors={errors2[`paymentAmount${i}`]?.message}
                                min={0}
                                onChange={() =>
                                  paymentAmountChange(
                                    watch2(`paymentAmount${i}`),
                                    i
                                  )
                                }
                                disabled={allowEditPayment()}
                              />
                              <Input
                                bootstrap={'col-6'}
                                label={`Pago ${i + 1} ${membership?.payments[i]?.upgrade
                                  ? '(Upgrade)'
                                  : ''
                                  } BsS`}
                                type={'number'}
                                name={`paymentAmountBsS${i}`}
                                register={register2}
                                placeholder={''}
                                errors={
                                  errors2[`paymentAmountBsS${i}`]?.message
                                }
                                min={0}
                                disabled={allowEditPayment()}
                              />
                              <Input
                                bootstrap={'col-6'}
                                label={`Método ${i + 1}`}
                                type={'select'}
                                options={paymentMethods}
                                name={`paymentMethod${i}`}
                                register={register2}
                                placeholder={''}
                                errors={errors2[`paymentMethod${i}`]?.message}
                                min={0}
                                disabled={allowEditPayment()}
                              />
                              <Input
                                bootstrap={'col-6'}
                                label={`Referencia ${i + 1}`}
                                type={'text'}
                                name={`paymentReference${i}`}
                                register={register2}
                                placeholder={''}
                                errors={
                                  errors2[`paymentReference${i}`]?.message
                                }
                                min={0}
                                disabled={allowEditPayment()}
                              />
                              <div className="col-6">
                                <div className="ref-image-container">
                                  {membership?.payments[i]?.referenceImg && (
                                    <a
                                      target="_blank"
                                      href={
                                        membership?.payments[i]?.referenceImg
                                      }
                                    >
                                      <p>Ver Comprobante</p>
                                    </a>
                                  )}
                                </div>
                              </div>
                              {[
                                salesRoles.SALES_ADMIN,
                                salesRoles?.SALES_ADMIN_AUX,
                              ].includes(authService?.getUserType()) && (
                                  <div
                                    style={{ marginBottom: '12px' }}
                                    className="col-6"
                                  >
                                    <ActionButton
                                      label={'Editar Pago'}
                                      action={() => editPayment(i)}
                                    />
                                  </div>
                                )}
                              <hr />
                            </>
                          );
                        })} */}
                      {membership?.membershipStatus !== 'INCOMPLETO' && (
                        <>
                          {watch2('discountCode') && (
                            <>
                              <Input
                                bootstrap={'col-12 col-md-6'}
                                label={'Descuento'}
                                type={'text'}
                                name={'discountCode'}
                                register={register2}
                                placeholder={''}
                                errors={errors2?.discountCode?.message}
                                disabled={
                                  membership?.membershipStatus !== 'INCOMPLETO'
                                }
                              />
                              <Input
                                bootstrap={'col-12 col-md-6'}
                                label={''}
                                type={'text'}
                                name={'discountByCode'}
                                register={register2}
                                placeholder={''}
                                errors={errors2?.discountByCode?.message}
                                disabled={
                                  membership?.membershipStatus !== 'INCOMPLETO'
                                }
                              />
                            </>
                          )}
                          {watch2('giftcardCode') && (
                            <>
                              <Input
                                bootstrap={'col-12 col-md-6'}
                                label={'Tarjeta'}
                                type={'text'}
                                name={'giftcardCode'}
                                register={register2}
                                placeholder={''}
                                errors={errors2?.giftcardCode?.message}
                                disabled={
                                  membership?.membershipStatus !== 'INCOMPLETO'
                                }
                              />
                              <Input
                                bootstrap={'col-12 col-md-6'}
                                label={''}
                                type={'text'}
                                name={'discountByGiftcard'}
                                register={register2}
                                placeholder={''}
                                errors={errors2?.discountByGiftcard?.message}
                                disabled={
                                  membership?.membershipStatus !== 'INCOMPLETO'
                                }
                              />
                            </>
                          )}
                        </>
                      )}
                      <h6>
                        Nivel de Siniestralidad: {membership?.accidentRate}
                      </h6>
                      <h6>
                        Cantidad de Servicios de Grua:{' '}
                        {membership?.towServices.length}
                      </h6>
                      <br />
                      {membership?.paymentType === paymentType?.PENDING &&
                        membership?.paymentStatus !== 'Pago realizado' && (
                          <ActionButton
                            label={'Realizar pago pendiente'}
                            action={openMembershipPendingPaymentModal}
                          />
                        )}
                    </div>
                    {validateIfCanPay()}

                    {/* {
                      watch2('paymentType') === paymentType?.FINANCIADO && (
                        <FinancedPaymentForm
                          membership={membership}
                          register2={register2}
                          errors2={errors2}
                          watch2={watch2}
                          options={{ paymentMethods: paymentMethods }}
                          action={openMembershipPendingPaymentModal}
                          editPendingPayment={editPendingPayment}
                          handleDueAmountChange={handleDueAmountChange}
                        />
                      )
                    } */}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const FinancedPaymentForm = props => {
  const {
    membership,
    register2,
    errors2,
    watch2,
    options,
    action,
    editPendingPayment,
    handleDueAmountChange = { handleDueAmountChange },
  } = props;

  const getPaymentsRef = (membership, i) => {
    let selectedRef = membership?.payments?.filter(
      p => p?._id === membership?.paymentDues[i]?.payment[0]
    );

    if (selectedRef?.length > 0) {
      return (
        <div className="row">
          <Input
            bootstrap={'col-6'}
            label={'Referencia'}
            type={'text'}
            options={options?.paymentMethods}
            name={`paymentDues[${i}].reference`}
            register={register2}
            placeholder={''}
            errors={errors2?.[`paymentDues[${i}].reference`]?.message}
            disabled={allowEditPayment()}
          />
          <div className="col-6">
            <div className="ref-image-container">
              {selectedRef[0]?.referenceImg && (
                <a
                  target="_blank"
                  href={selectedRef[0]?.referenceImg}
                  rel="noreferrer"
                >
                  <h6>Ver Comprobante</h6>
                </a>
              )}
            </div>
          </div>
          {allowAdmin && (
            <div style={{ marginBottom: '12px' }} className="col-6">
              <ActionButton
                label={'Editar Pago'}
                action={() => editPendingPayment(i)}
              />
            </div>
          )}
        </div>
      );
    } else return null;
  };

  if (membership?.paymentDues?.length > 0) {
    return (
      <>
        {membership?.paymentDues?.map((m, i) => (
          <div className="row">
            <div className="light-gray-line"></div>
            <h6>
              Cuota ({i + 1}){' '}
              {membership?.paymentDues[i].dueDate
                ? moment
                    .utc(membership?.paymentDues[i].dueDate)
                    .format('DD/MM/YYYY')
                : ''}
            </h6>
            <Input
              bootstrap={'col-6'}
              label={'Monto'}
              type={'number'}
              name={`paymentDues[${i}].amount`}
              register={register2}
              placeholder={''}
              errors={errors2?.[`paymentDues[${i}].amount`]?.message}
              min={1}
              max={2}
              onChange={handleDueAmountChange(
                watch2(`paymentDues[${i}].amount`),
                watch2(`paymentDues[${i}].payment`),
                i
              )}
              disabled={allowEditPayment()}
            />{' '}
            <Input
              bootstrap={'col-6'}
              label={'Monto bolivares'}
              type={'number'}
              name={`paymentDues[${i}].amountBs`}
              register={register2}
              placeholder={''}
              errors={errors2?.[`paymentDues[${i}].amountBs`]?.message}
              min={1}
              max={2}
              disabled={allowEditPayment()}
            />
            <Input
              bootstrap={'col-6'}
              label={'Metodo de Pago'}
              type={'select'}
              options={options?.paymentMethods}
              name={`paymentDues[${i}].paymentMethod`}
              register={register2}
              placeholder={''}
              errors={errors2?.[`paymentDues[${i}].paymentMethod`]?.message}
              disabled={allowEditPayment()}
            />{' '}
            <Input
              bootstrap={'col-6'}
              label={'Estatus de Pago'}
              type={'text'}
              options={options?.paymentMethods}
              name={`paymentDues[${i}].payment`}
              register={register2}
              placeholder={''}
              errors={errors2?.[`paymentDues[${i}].payment`]?.message}
              disabled={true}
            />
            {getPaymentsRef(membership, i)}
            <Input
              bootstrap={'col-6'}
              label={'Fecha de Pago'}
              type={'text'}
              options={options?.paymentMethods}
              name={`paymentDues[${i}].paymentDate`}
              register={register2}
              placeholder={''}
              errors={errors2?.[`paymentDues[${i}].paymentDate`]?.message}
            />
            <Input
              bootstrap={'col-6'}
              label={'Fecha de Corte'}
              type={'text'}
              options={options?.paymentMethods}
              name={`paymentDues[${i}].dueDate`}
              register={register2}
              placeholder={''}
              errors={errors2?.[`paymentDues[${i}].dueDate`]?.message}
              disabled={true}
            />
            {(m?.payment?.length === 0 || !m?.payment) && (
              <ActionButton label={'Realizar pago pendiente'} action={action} />
            )}
            <br />
          </div>
        ))}
      </>
    );
  } else return null;
};

import { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as schemas from '../../../../assets/schemas/schemas';
import { CashTable } from "../../../common/Tables/Tables"
import { ChangeTableHeader, } from "../../../../assets/helpers/tables.js"
import { EditChangeModal } from '../../../common/Modals/Modals';
import { paymentsService } from '../../../../services/paymentsService';
import { rateService } from '../../../../services/rateService';
import { productService } from '../../../../services/productService';
import { notify } from '../../../../assets/helpers/toast';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';
import { OderCardHeader } from './OrderComponents';
import { useDropzone } from 'react-dropzone';
import {
  paymentTypeOptions as paymentTypesOptions,
} from '../../../../assets/helpers/options';
import authService from '../../../../services/authService';

import './OrderComponent.scss';


export const ChangeCard = (props) => {
  const { label, membership, reloadMembership } = props;
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openEditPaymentModal, setOpenEditPaymentModal] = useState({
    isOpen: false,
    payment: ""
  });
  const [dollarRate, setDollarRate] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);


  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    control
  } = useForm({
    resolver: yupResolver(schemas.editCashSchema()),
  });


  const getDollarRate = async () => {
    rateService
      .getDollarRate()
      .then((response) => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };



  const getPaymentMethods = () => {
    productService
      .getPaymentMethods('sales')
      .then((response) => {
        if (response.status === 200) {
          setPaymentMethods(response?.data);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };




  const handleEditChangeModal = (payment) => {

    setOpenEditPaymentModal((prevState) => {
      return {
        ...prevState,
        isOpen: !openEditPaymentModal?.isOpen,
        payment: payment ? payment : "",
      };
    });

    if (payment) {
      setValue("paid", payment?.paid)
      setValue("amount", payment?.amount)
      setValue("amountBs", payment?.amountBs)
      setValue("method", payment?.method)
      setValue("ref", payment?.ref)
      if (payment?.date) {
        setValue("date", new Date(payment?.date))
      }
    } else {
      reset()
    }
  };



  const appendFile = (data) => {

    const formData = new FormData();
    formData.append('amount', data?.amount);
    formData.append('amountBs', data?.amountBs);
    formData.append('method', data?.method);
    formData.append('ref', data?.ref);
    formData.append('date', data?.date);

    if (fileUploaded) {
      formData.append('paymentEvidence', fileUploaded);
    }
    return formData;
  };


  useEffect(() => {
    getDollarRate();
    getPaymentMethods();
  }, [open, membership]);

  const editPayment = handleSubmit(async (data) => {

    let fileToPost = appendFile(data)

    if (!isLoading) {
      setIsLoading(true);
      paymentsService
        .editCash(fileToPost, openEditPaymentModal?.payment?._id)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Pago editado con exito`, 'success');
            handleEditChangeModal()
            reloadMembership();
            setIsLoading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }

  });

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        // Do whatever you want with the file contents
      };
      reader.readAsArrayBuffer(file);

      if (
        !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          file.type
        )
      ) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        setFileUploaded(file);
      }
    });
  }, []);


  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const [fileUploaded, setFileUploaded] = useState('');


  if (membership?.paymentChanges?.length === 0) {
    return null
  }


  return (
    <>

      <LoadSpinner show={isLoading} />
      <EditChangeModal
        modal={openEditPaymentModal}
        register={register}
        action={editPayment}
        handleClose={() => handleEditChangeModal()}
        errors={errors}
        setValue={setValue}
        control={control}
        options={{
          paymentMethods: paymentMethods,
          paymentTypesOptions: paymentTypesOptions,
          dollarRate: dollarRate,
        }}
        watch={watch}
        fileUploaded={fileUploaded}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        setFileUploaded={setFileUploaded}
      />

      <div className="order-card">
        <OderCardHeader open={open} setOpen={setOpen} label={label} />

        {open &&
          <>
            <CashTable role={authService?.getUserType()} header={ChangeTableHeader} membership={membership} handleEditChangeModal={handleEditChangeModal} />
          </>
        }
      </div >
    </>
  );
};



import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from '../../../services/authService';
import './ProtectedRoute.scss';

export const ProtectedRoute = ({
  level,
  path,
  component: Component,
  render,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!level.some((l) => l === auth.getUserType()) && auth.getToken()) {
          return <Redirect to="/not-found" />;
        }
        if (!auth.getToken()) {
          return <Redirect to="/" />;
        }
        return Component ? (
          <Component path={path} {...props} {...rest} />
        ) : (
          render(props)
        );
      }}
    />
  );
};

import { useState, useEffect, useRef, useCallback } from 'react';
import { ActionButton } from '../../common/Buttons/Buttons';
import { Header } from '../../common/Header/Header.jsx';
import { SelectPerPage } from '../../common/Forms/Input/Input';
import { FleetsDetailTable } from '../../common/Tables/Tables';
import { fleetDetailTableHeader } from '../../../assets/helpers/tables';
import { notify } from '../../../assets/helpers/toast';
import { fleetLoadService } from '../../../services/fleetLoadService';
import { productService } from '../../../services/productService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CancelFleetLoadModal,
  DeleteFleetLoadModal,
  ActivateFleetLoadModal,
  FleetLoadStatusModal,
  FleetPaymentPlanModal,
  ReportFleetPaymentPlanModal,
} from '../../common/Modals/Modals';
import * as schemas from '../../../assets/schemas/schemas';
import { useParams, useHistory } from 'react-router-dom';
import { capitalize } from 'lodash';
import { filterParams } from '../../../assets/helpers/funciones';
import { useDropzone } from 'react-dropzone';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import './FleetRegister.scss';

const itemsPerpage = [10, 25, 50, 100, 500];

export const FleetDetail = () => {
  const tableRef = useRef(null);
  const history = useHistory();
  const [isLoading, setIsloading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schemas.registerFleetLoadPaymentSchema()),
  });

  const { fleetId } = useParams();

  const [fleetStausInfo, setFleetStausInfo] = useState('');

  const [cancelFleetModal, setCancelFleetModal] = useState({
    isOpen: false,
    selected: '',
  });

  const [deleteFleetModal, setDeleteFleetModal] = useState({
    isOpen: false,
    selected: '',
  });

  const [activateFleetModal, setActivateFleetModal] = useState({
    isOpen: false,
    selected: '',
  });

  const [statusModal, setStatusModal] = useState({
    isOpen: false,
    selected: '',
  });

  const [paymentPlanModal, setPaymentPlanModal] = useState({
    isOpen: false,
    selected: '',
  });

  const [reportPaymentPlanModal, setReportPaymentPlanModal] = useState({
    isOpen: false,
    selected: '',
  });

  const [dataTable, setDataTable] = useState({
    limit: itemsPerpage[2],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    sinceDate: '',
    untilDate: '',
    isLoading: true,
  });

  const selectitemsPerpage = (event) => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * dataTable?.limit,
      };
    });
  };

  const redirectToOrder = (orderId) => {
    history.push(`/afiliations/order/${orderId}`);
  };

  const getFleets = () => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    let params = {
      limit: dataTable?.limit,
      offset: dataTable?.offset,
    };

    fleetLoadService
      .getFleetsLoads(params, fleetId)
      .then((response) => {
        if (response.status === 200) {
          setDataTable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.fleetData?.fleetLoads,
              count: response?.data?.total,
              name: response?.data?.fleetData?.name,
              code: response?.data?.fleetData?.code,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setDataTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setDataTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
      };
      reader.readAsArrayBuffer(file);

      if (
        !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          file.type
        )
      ) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        setFileUploaded(file);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const [fileUploaded, setFileUploaded] = useState('');

  const appendFile = (data) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    formData.append('paymentEvidence', fileUploaded);

    return formData;
  };

  const onSubmit = handleSubmit(async (data) => {
    let dataToPost = { ...data };

    delete dataToPost?.date;

    if (!fileUploaded) {
      notify('Adjunte comprobante de pago', 'info');
      return null;
    }

    let fileTopost = appendFile(filterParams(dataToPost));

    if (!isLoading) {
      setIsloading(true);
      fleetLoadService
        .createFleetLoadPayment(fileTopost, paymentPlanModal?.selected?._id)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Pago registrado`, 'success');
            reset();
            setIsloading(false);
            handleReportPaymentPlanModal();
            setFileUploaded('');
            getFleets();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch(() => {
          setIsloading(false);
        });
    }
  });

  const deleteFleetLoad = () => {
    if (!isLoading) {
      setIsloading(true);
      fleetLoadService
        .deleteFleet(deleteFleetModal?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(
              `Carga de flotas  ${deleteFleetModal?.selected?.fleetLoadId} eliminada`,
              'success'
            );
            handleDeleteFleetLoadForm();
            getFleets();
            setIsloading(false);
          } else {
            notify(`${response.message}` || 'error', 'error');
            setIsloading(false);
          }
        })
        .catch(() => {
          setIsloading(false);
        });
    }
  };

  const activateFleetLoad = () => {
    if (!isLoading) {
      setIsloading(true);
      fleetLoadService
        .reactivateFleet(activateFleetModal?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(
              `Carga de flotas  ${activateFleetModal?.selected?.fleetLoadId} reactivada`,
              'success'
            );
            handleActivateFleetLoadForm();
            getFleets();
            setIsloading(false);
          } else {
            notify(`${response.message}` || 'error', 'error');
            setIsloading(false);
          }
        })
        .catch(() => {
          setIsloading(false);
        });
    }
  };

  const getFleetById = (fleet) => {
    fleetLoadService
      .getById(fleet?._id)
      .then((response) => {
        if (response.status === 200) {
          setFleetStausInfo(response?.data);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const redirectToFleetLoadForm = () => {
    history.push(`/modules/fleet-register/fleet-load-form/${fleetId}`);
  };

  const handleCancelFleetLoadForm = (fleetLoad) => {
    setCancelFleetModal((prevState) => {
      return {
        ...prevState,
        isOpen: !cancelFleetModal?.isOpen,
        selected: fleetLoad ? fleetLoad : '',
      };
    });
  };

  const handleDeleteFleetLoadForm = (fleetLoad) => {
    setDeleteFleetModal((prevState) => {
      return {
        ...prevState,
        isOpen: !deleteFleetModal?.isOpen,
        selected: fleetLoad ? fleetLoad : '',
      };
    });
  };

  const handleActivateFleetLoadForm = (fleetLoad) => {
    setActivateFleetModal((prevState) => {
      return {
        ...prevState,
        isOpen: !activateFleetModal?.isOpen,
        selected: fleetLoad ? fleetLoad : '',
      };
    });
  };

  const handleStatusModal = (fleetLoad) => {
    if (fleetLoad) {
      getFleetById(fleetLoad);
    }

    setStatusModal((prevState) => {
      return {
        ...prevState,
        isOpen: !statusModal?.isOpen,
        selected: fleetLoad ? fleetLoad : '',
      };
    });
  };

  const getPaymentMethods = () => {
    productService
      .getPaymentMethods('sales')
      .then((response) => {
        if (response.status === 200) {
          setPaymentMethods(response?.data);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const handlePaymentPlanModal = (fleetLoad) => {
    if (fleetLoad) {
      getFleetById(fleetLoad);
    }

    setPaymentPlanModal((prevState) => {
      return {
        ...prevState,
        isOpen: !paymentPlanModal?.isOpen,
        selected: fleetLoad ? fleetLoad : '',
      };
    });
  };

  const handleReportPaymentPlanModal = (fleetLoad, index) => {
    reset();

    setPaymentPlanModal((prevState) => {
      return {
        ...prevState,
        isOpen: false,
      };
    });

    setReportPaymentPlanModal((prevState) => {
      setValue('date', new Date());
      setValue('dueNumber', index + 1);

      return {
        ...prevState,
        isOpen: !reportPaymentPlanModal?.isOpen,
        selected: fleetLoad ? fleetLoad : '',
      };
    });
  };

  const redirectToAffiliationLink = (link) => {
    history.push(`/affiliation/${link}`);
  };

  const redirectToLoadDetail = (loadId) => {
    history.push(`/modules/fleet-register/${fleetId}/load-detail/${loadId}`);
  };

  useEffect(() => {
    getFleets();
    getPaymentMethods();
  }, [dataTable?.offset, dataTable?.limit]);

  return (
    <>
      <LoadSpinner show={isLoading} />
      <CancelFleetLoadModal
        modal={cancelFleetModal}
        handleModal={handleCancelFleetLoadForm}
        getFleets={getFleets}
      />
      <DeleteFleetLoadModal
        modal={deleteFleetModal}
        handleModal={handleDeleteFleetLoadForm}
        getFleets={getFleets}
        deleteFleetLoad={deleteFleetLoad}
      />
      <ActivateFleetLoadModal
        modal={activateFleetModal}
        handleModal={handleActivateFleetLoadForm}
        getFleets={getFleets}
        activateFleetLoad={activateFleetLoad}
      />
      <FleetLoadStatusModal
        modal={statusModal}
        handleModal={handleStatusModal}
        data={fleetStausInfo}
      />
      <FleetPaymentPlanModal
        modal={paymentPlanModal}
        handleModal={handlePaymentPlanModal}
        data={fleetStausInfo}
        handleReportPaymentPlanModal={handleReportPaymentPlanModal}
      />
      <ReportFleetPaymentPlanModal
        modal={reportPaymentPlanModal}
        handleModal={handleReportPaymentPlanModal}
        data={fleetStausInfo}
        register={register}
        errors={errors}
        control={control}
        options={{ paymentMethods: paymentMethods }}
        fileUploaded={fileUploaded}
        setFileUploaded={setFileUploaded}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        action={onSubmit}
      />

      <Header />
      <div className="service-with-container container">
        <div className="buttons-container">
          <div>
            <h1 className="title-component">
              {capitalize(dataTable?.name)} ({dataTable?.code}) <br />
              Cargas de Flota: {dataTable?.count}{' '}
            </h1>
          </div>
          <ActionButton
            action={() => redirectToFleetLoadForm()}
            label={'+ Nueva Carga'}
          />
        </div>
        <div className="breadcrumb-container">
          <div className="exportables-buttons-container">
            <div style={{ marginTop: '12px' }}>
              <SelectPerPage
                label={'Registros por página'}
                options={itemsPerpage}
                value={dataTable?.limit}
                onChange={selectitemsPerpage}
              />
            </div>
          </div>
          <span></span>
        </div>
        <FleetsDetailTable
          header={fleetDetailTableHeader}
          data={dataTable}
          handlePageChange={handlePageChange}
          redirectToOrder={redirectToOrder}
          tableRef={tableRef}
          handleCancelFleetLoadForm={handleCancelFleetLoadForm}
          handleDeleteFleetLoadForm={handleDeleteFleetLoadForm}
          handleActivateFleetLoadForm={handleActivateFleetLoadForm}
          handleStatusModal={handleStatusModal}
          handlePaymentPlanModal={handlePaymentPlanModal}
          redirectToAffiliationLink={redirectToAffiliationLink}
          redirectToLoadDetail={redirectToLoadDetail}
        />
      </div>
    </>
  );
};

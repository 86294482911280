import { fixResponseJSON } from '../assets/helpers/funciones';
import { authInstance } from './axios';
let url = process.env.REACT_APP_API_URL;

export const servicesService = {
  createAVService: async (data) => {
    const myInit = {
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'POST',
      body: data,
    };

    const response = await fetch(`${url}/services/createAv`, myInit)
      .then(async (response) => {
        return fixResponseJSON(response);
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getFinishedServices: async (service) => {
    const response = await authInstance
      ?.get(`${url}/services/${service}/finishedServices`)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  uploadAVImage: async (file, serviceId) => {
    const myInit = {
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'PUT',
      body: file,
    };

    const response = await fetch(
      `${url}/services/${serviceId}/uploadAvImage`,
      myInit
    )
      .then(async (response) => {
        return fixResponseJSON(response);
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  uploadTowImage: async (file, serviceId) => {
    const myInit = {
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'PUT',
      body: file,
    };

    const response = await fetch(
      `${url}/services/${serviceId}/uploadTowImages`,
      myInit
    )
      .then(async (response) => {
        return fixResponseJSON(response);
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getUploadedImages: async (serviceId) => {
    const myInit = {
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'GET',
    };

    const response = await fetch(
      `${url}/services/${serviceId}/getUploadedImages`,
      myInit
    )
      .then(async (response) => {
        return fixResponseJSON(response);
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
  updateProvider: async (data, serviceId) => {
    const myInit = {
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'PUT',
      body: JSON.stringify(data),
    };

    const response = await fetch(
      `${url}/services/${serviceId}/updateProvider`,
      myInit
    )
      .then(async (response) => {
        return fixResponseJSON(response);
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  createTowService: async (data) => {
    const myInit = {
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'POST',
      body: data,
    };

    const response = await fetch(`${url}/services/createTow`, myInit)
      .then(async (response) => {
        return fixResponseJSON(response);
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
};

import { filterParams } from '../assets/helpers/funciones';
import { authInstance } from './axios';
let url = process.env.REACT_APP_API_URL;

export const discountCodesServices = {
  getDiscountCodes: async (params) => {
    const response = await authInstance
      .get(`${url}/discountCodes?` + new URLSearchParams(filterParams(params)))
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  createDiscountCodes: async (data) => {
    const response = await authInstance
      .post(`${url}/discountCodes`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  editDiscountCodes: async (data, id) => {
    const response = await authInstance
      .put(`${url}/discountCodes/${id}`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
};

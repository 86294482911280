import { authInstance } from './axios';
let url = process.env.REACT_APP_API_URL;

export const customerService = {
  createCustomer: async (data) => {
    const response = await authInstance
      .post(`${url}/customers/create`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getCustomerById: async (identification, identificationType) => {
    const response = await authInstance
      .get(
        `${url}/customers/${identification}?identificationType=${identificationType}`
      )
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  updateCustomer: async (data, customerId) => {
    const response = await authInstance
      .put(`${url}/customers/${customerId}/update`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
};

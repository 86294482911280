import { fixResponseJSON, filterParams } from '../assets/helpers/funciones'
import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const giftCardService = {
  validateCode: async (data) => {
    const response = await authInstance
      .post(`${url}/giftcards/validate`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },
  validateDiscount: async (discountType, data) => {
    const response = await authInstance
      .post(`${url}/${discountType}/validate`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getGiftCards: async (params) => {
    const response = await authInstance
      .get(
        `${url}/giftCardSales/list?` + new URLSearchParams(filterParams(params))
      )
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  deleteGiftCardSales: async (id) => {
    const response = await authInstance
      .delete(`${url}/giftCardSales/${id}/delete`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  createGiftcardSale: async (data) => {
    const response = await authInstance
      .post(`${url}/giftCardSales`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  editGiftcardSale: async (data, saleId) => {
    const response = await authInstance
      .put(`${url}/giftCardSales/${saleId}/update`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  getActiveGiftcards: async (params) => {
    const response = await authInstance
      .get(
        `${url}/giftcards/active?` + new URLSearchParams(filterParams(params))
      )
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  getGiftcards: async (params) => {
    const response = await authInstance
      .get(`${url}/giftcards/?` + new URLSearchParams(filterParams(params)))
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  activateGiftcard: async (data) => {
    const response = await authInstance
      .put(`${url}/giftcards/activate`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  blockGiftcard: async (data) => {
    const response = await authInstance
      .put(`${url}/giftcards/block`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  uploadGiftcardCodes: async (file) => {
    const response = await authInstance
      .post(`${url}/giftcards/upload`, file)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },
}

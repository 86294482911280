import { useState, useEffect, useCallback } from 'react';
import { Input } from '../../../common/Forms/Input/Input';
import { notify } from '../../../../assets/helpers/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as schemas from '../../../../assets/schemas/schemas';
import { ActionButton, AltButton } from '../../../common/Buttons/Buttons';
import {
  idCodes,
  phoneCodes,
  genderOptions,
  civilStateOptions,
  planTypes
} from '../../../../assets/helpers/options';
import { broadCastService } from '../../../../services/broadCastService';
import { customerService } from '../../../../services/customerService';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';
import {
  SalesDropzone,
  FileComponent,
} from '../../../common/Dropzone/Dropzone';
import { useDropzone } from 'react-dropzone';
import './LandingForms.scss';
import { capitalize, filterParams } from '../../../../assets/helpers/funciones';

export const LinkPersonalInfoForm = (props) => {
  const { setcurentStep, membership, setCustomer, curentStep, selectedMembership } = props;

  const [idUploaded, setIdUploaded] = useState('');
  const [isLoading, setIsloading] = useState(false);
  const [licenceUploaded, setLicenceUploaded] = useState('');
  const [broadcastOptions, setBroadcastOptions] = useState([]);
  const onDrop1 = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents

      };
      reader.readAsArrayBuffer(file);

      if (
        !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          file.type
        )
      ) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        setIdUploaded(file);
      }
    });
  }, []);

  const onDrop2 = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents

      };
      reader.readAsArrayBuffer(file);

      if (
        !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          file.type
        )
      ) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        setLicenceUploaded(file);
      }
    });
  }, []);

  const getCustomerById = () => {
    if (watch('identificationType') && watch('identification')) {
      customerService
        .getCustomerById(watch('identification'), watch('identificationType'))
        .then((response) => {
          if (response.status === 200) {
            setValue('firstName', response?.data?.customer?.firstName);
            setValue('lastName', response?.data?.customer?.lastName);
            setValue('gender', response?.data?.customer?.gender);
            setValue('maritalStatus', response?.data?.customer?.maritalStatus);
            setValue('birthday', new Date(response?.data?.customer?.birthday));
          }
        })
        .catch(() => { });
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(schemas.CreateCustomerLinkSchema()),
  });

  const onSubmit = handleSubmit(async (data) => {
    data.firstName = capitalize(data?.firstName);
    data.lastName = capitalize(data?.lastName);
    data.email = data?.email?.toLowerCase();
    delete data.code

    if (!idUploaded && watch("code") !== planTypes?.PLUSF4) {
      notify('Adjunte su documento de identidad', 'info');
      return null;
    }
    if (!licenceUploaded && watch("code") !== planTypes?.PLUSF4) {
      notify('Adjunte su licencia de conducir', 'info');
      return null;
    }

    let fileTopost = appendFile(filterParams(data));
    let user = { ...data };

    if (!isLoading) {
      setIsloading(true);
      customerService
        .createCustomer(fileTopost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            setIsloading(false);
            localStorage.setItem('personalData', JSON.stringify(user));
            setCustomer(response?.data?._id);
            setcurentStep(3);
          } else {
            setIsloading(false);
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const getBroadcastChannels = () => {
    broadCastService
      .getBroadcastChannels()
      .then((response) => {
        if (response.status === 200) {
          setBroadcastOptions(response?.data?.broadcastChannels);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const appendFile = (data) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    formData.append('membership', membership);
    if (idUploaded) {
      formData.append('dni', idUploaded);
    }
    if (licenceUploaded) {
      formData.append('licenses', licenceUploaded);
    }

    return formData;
  };

  const prelaodData = () => {
    let personalData = localStorage.getItem('personalData');
    personalData = JSON.parse(personalData);
    setValue('code', selectedMembership?.code);

    if (personalData) {
      setValue('firstName', personalData?.firstName);
      setValue('lastName', personalData?.lastName);
      setValue('phonePrefix', personalData?.phonePrefix);
      setValue('phoneNumber', personalData?.phoneNumber);
      setValue('identificationType', personalData?.identificationType);
      setValue('identification', personalData?.identification);
      setValue('email', personalData?.email);
      setValue('birthday', new Date(personalData?.birthday));
      setValue('identificationType', personalData?.identificationType);
      setValue('gender', personalData?.gender);
      setValue('maritalStatus', personalData?.maritalStatus);

      Promise.allSettled([getBroadcastChannels()]).then((r) => {
        setTimeout(() => {
          setValue('broadcastChannel', personalData?.broadcastChannel);
        }, 500);
      });
      setValue('identificationType', personalData?.identificationType);
    } else {
      getBroadcastChannels();
    }
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDrop1 });
  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({
      onDrop: onDrop2,
    });

  useEffect(() => {
    prelaodData();
  }, []);

  let validateDisabled = () => {
    return !watch('identificationType') || !watch('identification');
  };

  return (
    <>
      <LoadSpinner show={isLoading} />
      <div className="landing-form-container">
        <h5>Información Personal</h5>
        <p>(*) Campos obligatorios</p>
        <br />
        <form>
          <div className="row">
            <Input
              bootstrap={'col-4 col-sm-2 col-md-2'}
              label={'C.I / RIF*'}
              type={'select'}
              name={'identificationType'}
              register={register}
              placeholder={''}
              options={idCodes}
              errors={errors?.identificationType?.message}
              onChange={getCustomerById}
            />{' '}
            <Input
              bootstrap={'col-8 col-sm-4 col-md-4'}
              label={''}
              type={'number'}
              name={'identification'}
              register={register}
              placeholder={''}
              errors={errors?.identification?.message}
              onChange={getCustomerById}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Correo Electrónico *'}
              type={'text'}
              name={'email'}
              register={register}
              placeholder={''}
              errors={errors?.email?.message}
              disabled={validateDisabled()}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Nombre *'}
              type={'text'}
              name={'firstName'}
              register={register}
              placeholder={''}
              errors={errors?.firstName?.message}
              disabled={validateDisabled()}
            />
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Apellido *'}
              type={'text'}
              name={'lastName'}
              register={register}
              placeholder={''}
              errors={errors?.lastName?.message}
              disabled={validateDisabled()}
            />{' '}
            <Input
              bootstrap={'col-4 col-sm-2 col-md-2'}
              label={'Teléfono*'}
              type={'select'}
              name={'phonePrefix'}
              register={register}
              placeholder={''}
              options={phoneCodes}
              errors={errors?.phonePrefix?.message}
              disabled={validateDisabled()}
            />{' '}
            <Input
              bootstrap={'col-8 col-sm-4 col-md-4'}
              label={''}
              type={'number'}
              name={'phoneNumber'}
              register={register}
              placeholder={''}
              errors={errors?.phoneNumber?.message}
              disabled={validateDisabled()}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Fecha de Nacimiento *'}
              type={'date'}
              name={'birthday'}
              register={register}
              placeholder={''}
              control={control}
              errors={errors?.birthday?.message}
              disabled={validateDisabled()}
            />
            {watch("code") !== planTypes?.PLUSF4 && <>
              <Input
                bootstrap={'col-4 col-sm-3 col-md-3'}
                label={'Sexo'}
                type={'select'}
                name={'gender'}
                register={register}
                placeholder={''}
                options={genderOptions}
                errors={errors?.gender?.message}
                disabled={validateDisabled()}
              />{' '}
              <Input
                bootstrap={'col-4 col-sm-3 col-md-3'}
                label={'Estado Civil *'}
                type={'select'}
                name={'maritalStatus'}
                register={register}
                placeholder={''}
                options={civilStateOptions}
                errors={errors?.maritalStatus?.message}
                disabled={validateDisabled()}
              />{' '}
              <Input
                bootstrap={'col-12 col-md-6'}
                label={'¿Cómo te enteraste de nosotros? *'}
                type={'select'}
                options={broadcastOptions}
                name={'broadcastChannel'}
                placeholder={'Seleccione'}
                register={register}
                errors={errors?.broadcastChannel?.message}
                disabled={validateDisabled()}
              />{' '}
              <div className="col-12"></div>
              <div className="col-12 col-md-6">
                {idUploaded ? (
                  <FileComponent
                    file={idUploaded}
                    remove={() => setIdUploaded('')}
                  />
                ) : (
                  <SalesDropzone
                    instructions={'Adjuntar cédula de identidad / RIF *'}
                    maxWeight={'(JPG, PNG, PDF, máx. 3MB)'}
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    message="Arrastrar o buscar Documento"
                  />
                )}
              </div>
              <div className="col-12 col-md-6">
                {licenceUploaded ? (
                  <FileComponent
                    file={licenceUploaded}
                    remove={() => setLicenceUploaded('')}
                  />
                ) : (
                  <SalesDropzone
                    instructions={'Adjuntar licencia de conducir *'}
                    maxWeight={'(JPG, PNG, PDF, máx. 3MB)'}
                    getRootProps={getRootProps2}
                    getInputProps={getInputProps2}
                    message="Arrastrar o buscar Documento"
                  />
                )}
              </div></>}
          </div>

          <div
            style={{ margin: '24px 0px 48px 0px' }}
            className="buttons-center-container"
          >
            <AltButton
              label={'Regresar'}
              action={() => setcurentStep(curentStep - 1)}
            />
            <ActionButton label={'Siguiente'} action={onSubmit} />
          </div>
        </form>
      </div>
    </>
  );
};

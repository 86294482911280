import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'flag-icon-css/css/flag-icon.min.css';

import { BrowserRouter } from 'react-router-dom';
i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next) // .use(LanguageDetector)passes i18n down to react-i18next
  .init({
    supportedLngs: ['es', 'en', 'it', 'prt'],
    // lng: document.querySelector("html").lang || "en",
    fallbackLng: 'es',
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/translations/{{lng}}/global.json',
    },
    react: { useSuspense: false },
  });

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<h1>Loading...</h1>}>
      <App />
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);

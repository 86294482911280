import { filterParams } from '../assets/helpers/funciones'
import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const dbHistoryService = {
  getMembershipHistory: async (userId, params) => {
    const response = await authInstance
      .get(
        `${url}/dbHistory/membership/${userId}?` +
          new URLSearchParams(filterParams(params))
      )
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },
}

import { useState, useEffect, useRef } from 'react';
import { ActionButton } from '../../common/Buttons/Buttons';
import { Header } from '../../common/Header/Header.jsx';
import { SelectPerPage } from '../../common/Forms/Input/Input';
import {
  GiftcardTables,
  GiftcardParticularTables,
} from '../../common/Tables/Tables';
import {
  giftcardSalesheader,
  giftcardParticularSalesheader,
} from '../../../assets/helpers/tables';
import { notify } from '../../../assets/helpers/toast';
import { saleChannelService } from '../../../services/saleChannelService';
import { campaignService } from '../../../services/campaignService';
import { membershipCatalogService } from '../../../services/membershipCatalogService';
import { giftCardService } from '../../../services/giftCardService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../assets/schemas/schemas';
import { Select } from '../../common/Forms/Input/Input';
import { useHistory } from 'react-router-dom';
import authService from '../../../services/authService.js';
import { salesRoles } from '../../../assets/helpers/roles.js';
import {
  capitalize,
  filterParams,
  exportDataToExcel,
} from '../../../assets/helpers/funciones';
import {
  codeStatusOptions,
  discountTypeOptions,
  giftCardTypeOptions,
  idCodes,
  phoneCodes,
  giftCardType,
} from '../../../assets/helpers/options';
import {
  CreateGiftcardModal,
  DeleteGiftcardModal,
  EditGiftcardModal,
} from '../../common/Modals/Modals';
import moment from 'moment';
import './Giftcards.scss';

const itemsPerpage = [10, 25, 50, 100, 500];

export const Giftcards = () => {
  const tableRef = useRef(null);
  const history = useHistory();


  const [isLoading, setIsloading] = useState(false);
  const [limitGiftcard, setLimitGiftcard] = useState('');
  const [publicCodes, setPublicCodes] = useState([]);
  const [salesChannelOptions, setSalesChannelOptions] = useState([]);
  const [campaignsOptions, setCampaignsOptions] = useState([]);
  const [membershipOptions, setMembershipOptions] = useState([]);
  const [giftcardType, setGifcardType] = useState('Particular');
  const [createCodeModal, setCreateCodeModal] = useState({
    isOpen: false,
  });
  const [deleteCodeModal, setDeleteCodeModal] = useState({
    isOpen: false,
    selected: '',
  });
  const [editCodeModal, setEditCodeModal] = useState({
    isOpen: false,
    selected: '',
  });

  const exportTable = () => {
    let dataToPrint = [];

    if (giftcardType === giftCardType?.RETAIL) {
      for (let i = 0; i < dataTable?.data?.length; i++) {
        dataToPrint?.push({
          'Fecha de Venta': dataTable?.data[i]?.saleDate
            ? moment.utc(dataTable?.data[i]?.saleDate).format('DD/MM/YYYY')
            : '',
          Plan: capitalize(dataTable?.data[i]?.membership?.code),
          Canal: dataTable?.data[i]?.channel?.shortName,
          'Precio Plan': dataTable?.data[i]?.planAmount,
          'Cantidad Giftcards': dataTable?.data[i]?.saleQuantity,
          'Total Ventas':
            dataTable?.data[i]?.saleQuantity * dataTable?.data[i]?.planAmount,
          Creado: dataTable?.data[i]?.createdAt
            ? moment.utc(dataTable?.data[i]?.createdAt).format('DD/MM/YYYY')
            : '',
          Modificado: dataTable?.data[i]?.updatedAt
            ? moment.utc(dataTable?.data[i]?.updatedAt).format('DD/MM/YYYY')
            : '',
        });
      }
    }
    if (giftcardType === giftCardType?.PARTICULAR) {
      for (let i = 0; i < dataTable?.data?.length; i++) {
        dataToPrint?.push({
          'Fecha de Venta': dataTable?.data[i]?.saleDate
            ? moment.utc(dataTable?.data[i]?.saleDate).format('DD/MM/YYYY')
            : '',
          Plan: capitalize(dataTable?.data[i]?.membership?.code),
          Canal: dataTable?.data[i]?.channel?.shortName,
          'Precio Plan': dataTable?.data[i]?.planAmount,
          'Código Público': dataTable?.data[i]?.publicCode,
          'Nombre y Apellido':
            capitalize(dataTable?.data[i]?.customer?.firstName) +
            ' ' +
            capitalize(dataTable?.data[i]?.customer?.lastName),
          Teléfono:
            dataTable?.data[i]?.customer?.phonePrefix +
            '-' +
            dataTable?.data[i]?.customer?.phoneNumber,
          Correo: dataTable?.data[i]?.customer?.email?.toLowerCase(),
          Asesor:
            capitalize(dataTable?.data[i]?.seller?.firstName) +
            ' ' +
            capitalize(dataTable?.data[i]?.seller?.lastName),
        });
      }
    }

    exportDataToExcel(dataToPrint, 'ventas_de_giftcard.xlsx');
  };

  const [dataTable, setDataTable] = useState({
    limit: itemsPerpage[2],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    sinceDate: '',
    untilDate: '',
    isLoading: true,
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
    getValues: getValues2,
    resetField: resetField2,
    reset: reset2,
    watch: watch2,
    control: control2,
  } = useForm({
    resolver: yupResolver(schemas.editGiftcardSaleSchema()),
  });
  const onSubmit2 = handleSubmit2(async (data) => {
    let dataToPost = { ...data };
    // validateLimitGiftcards();

    delete dataToPost?.channel;
    delete dataToPost?.membership;
    delete dataToPost?.publicCode;

    if (dataToPost?.saleType === giftCardType?.PARTICULAR) {
      delete dataToPost.saleQuantity;
      dataToPost.customer = {
        firstName: dataToPost?.firstName,
        lastName: dataToPost?.lastName,
        identificationType: dataToPost?.identificationType,
        identification: dataToPost?.identification,
        phonePrefix: dataToPost?.phonePrefix,
        phoneNumber: dataToPost?.phoneNumber,
        email: dataToPost?.email,
      };
    }

    if (dataToPost?.saleType === giftCardType?.RETAIL) {
      delete dataToPost.publicCode;
      if (dataToPost.saleQuantity === '0') {
        notify(`No existen tarjetas activas dispobibles`, 'info');
        return null;
      }
    }

    delete dataToPost.firstName;
    delete dataToPost.lastName;
    delete dataToPost.identificationType;
    delete dataToPost.identification;
    delete dataToPost.phonePrefix;
    delete dataToPost.phoneNumber;
    delete dataToPost.email;

    if (!isLoading) {
      setIsloading(true);
      giftCardService
        .editGiftcardSale(
          filterParams(dataToPost),
          editCodeModal?.selected?._id
        )
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Giftcard editada con éxito`, 'success');
            setIsloading(false);
            handleEditCodeForm();
            reset2();
            getCodes();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const getActiveGiftcards = (saleChannelId, type, membership) => {
    setLimitGiftcard('');
    setValue2('saleQuantity', 0);
    setPublicCodes([]);

    let params = {
      saleChannel: saleChannelId,
      type: type,
      membership: membership,
    };

    if (saleChannelId && type) {
      if (type === 'Retail' && !membership) {
        return null;
      }

      giftCardService
        .getActiveGiftcards(params)
        .then((response) => {
          if ([200, 201].includes(response?.status)) {
            setLimitGiftcard(response?.data?.quantity);
            setValue2('saleQuantity', response?.data?.quantity);
            if (response?.data?.publicCodes?.length > 0) {
              let data = [];
              for (let i = 0; i < response?.data?.publicCodes.length; i++) {
                data.push({
                  name: `${response?.data?.publicCodes[i]?.publicCode}`,
                  _id: response?.data?.publicCodes[i]?.publicCode,
                  membershipId: response?.data?.publicCodes[i]?.membershipId,
                });
              }
              data = data?.filter((d) => d?.membershipId === membership);
              setPublicCodes(data);
            }
          } else {
            notify(`${response.message}` || 'error', 'error');
            setLimitGiftcard('0');
            setValue2('saleQuantity', 0);
            notify(`No existen tarjetas activas dispobibles`, 'info');
            console.log('aver:');
          }
        })
        .catch(() => { });
    }
  };

  const selectPublicCode = () => {
    let [filtered] = publicCodes?.filter((p) => p?._id == watch2('publicCode'));
    setValue2('membership', filtered?.membershipId);
  };

  const preloadForm = (code) => {
    setValue2('saleType', code?.saleType);
    setValue2('channel', code?.channel?.shortName);
    setValue2('membership', capitalize(code?.membership?.code));
    setValue2('publicCode', code?.publicCode);
    setValue2('planAmount', code?.planAmount);
    setValue2('saleQuantity', code?.saleQuantity);
    setValue2('status', code?.status);
    setValue2('saleDate', new Date(code?.saleDate));
    setValue2('firstName', code?.customer?.firstName);
    setValue2('lastName', code?.customer?.lastName);
    setValue2('identificationType', code?.customer?.identificationType);
    setValue2('identification', code?.customer?.identification);
    setValue2('phonePrefix', code?.customer?.phonePrefix);
    setValue2('phoneNumber', code?.customer?.phoneNumber);
    setValue2('email', code?.customer?.email);
  };

  const closeEditModal = () => {
    handleEditCodeForm();
    reset2();
  };

  const selectitemsPerpage = (event) => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const validateLimitGiftcards = () => {
    if (
      watch2('saleQuantity') &&
      limitGiftcard &&
      parseFloat(watch2('saleQuantity')) > limitGiftcard
    ) {
      notify('No puede superar el limite de tarjetas permitidas', 'info');
      setValue2('saleQuantity', limitGiftcard);
    }
    return null;
  };

  const handlePageChange = (event, value) => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * dataTable?.limit,
      };
    });
  };

  const redirectToOrder = (orderId) => {
    history.push(`/afiliations/order/${orderId}`);
  };

  const validateUserRole = () => {
    let user = authService?.getUser();

    if (
      user?.roles[0]?.code === salesRoles?.SALES_COUNTER &&
      user?.type === 'Externo'
    ) {
      history?.push('/dashboard/analytics');
    }
  };

  useEffect(() => {
    getCodes();
  }, [
    dataTable?.page,
    dataTable?.offset,
    dataTable?.limit,
    dataTable?.type,
    dataTable?.sinceDate,
    dataTable?.untilDate,
    giftcardType,
  ]);

  useEffect(() => {
    getCodes();
    getSalesChannel();
    getAvailableMemberships();
    getCampaigns();
    validateUserRole();
  }, []);

  const getSalesChannel = () => {
    saleChannelService
      .getSalesChannel()
      .then((response) => {
        if ([200, 201].includes(response?.status)) {
          setSalesChannelOptions(response?.data?.saleChannels);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const getCampaigns = () => {
    campaignService
      .getCampaigns()
      .then((response) => {
        if ([200, 201].includes(response?.status)) {
          setCampaignsOptions(response?.data);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const handleGiftcardType = (e) => {
    setGifcardType(e?.target?.value);
  };

  const getAvailableMemberships = () => {
    membershipCatalogService
      .getAvailableMemberships()
      .then((response) => {
        let data = [];
        for (let i = 0; i < response?.data?.length; i++) {
          data.push({
            name: response?.data[i]?.code,
            _id: response?.data[i]?._id,
          });
        }
        if ([200, 201].includes(response?.status)) {
          setMembershipOptions(data);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const deleteGiftcardLoad = () => {
    giftCardService
      .deleteGiftCardSales(deleteCodeModal?.selected?._id)
      .then((response) => {
        if ([200, 201].includes(response?.status)) {
          handleDeleteGiftcardModal();
          getCodes();
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const getCodes = () => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    let params = {
      limit: dataTable?.limit,
      offset: dataTable?.offset,
      saleType: giftcardType,
    };

    giftCardService
      .getGiftCards(params)
      .then((response) => {
        if (response.status === 200) {
          setDataTable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.giftCardSales,
              count: response?.data?.total,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setDataTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setDataTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };

  const openDetail = (fleet) => {
    history.push(`/modules/fleet-register/detail/${fleet?._id}`);
  };

  const handleCreateCodeForm = () => {
    setCreateCodeModal((prevState) => {
      return {
        ...prevState,
        isOpen: !createCodeModal?.isOpen,
      };
    });
  };

  const handleEditCodeForm = (code) => {
    setEditCodeModal((prevState) => {
      return {
        ...prevState,
        isOpen: !editCodeModal?.isOpen,
        selected: code ? code : '',
      };
    });
    if (code) {
      preloadForm(code);
    }
  };

  const handleDeleteGiftcardModal = (giftcard) => {
    setDeleteCodeModal((prevState) => {
      return {
        ...prevState,
        isOpen: !deleteCodeModal?.isOpen,
        selected: giftcard ? giftcard : '',
      };
    });
  };

  return (
    <>
      <CreateGiftcardModal
        modal={createCodeModal}
        handleModal={handleCreateCodeForm}
        getCodes={getCodes}
        options={{
          salesChannelOptions: salesChannelOptions,
          codeStatusOptions: codeStatusOptions,
          discountTypeOptions: discountTypeOptions,
          membershipOptions: membershipOptions,
          campaignsOptions: campaignsOptions,
          giftCardTypeOptions: giftCardTypeOptions,
          idCodes: idCodes,
          phoneCodes: phoneCodes,
        }}
      />

      <EditGiftcardModal
        modal={editCodeModal}
        handleModal={handleEditCodeForm}
        getCodes={getCodes}
        register={register2}
        setValue={setValue2}
        watch={watch2}
        control={control2}
        errors={errors2}
        reset={reset2}
        action={onSubmit2}
        close={closeEditModal}
        onChange={{
          getActiveGiftcards: getActiveGiftcards,
          validateLimitGiftcards: validateLimitGiftcards,
          selectPublicCode: selectPublicCode,
        }}
        options={{
          salesChannelOptions: salesChannelOptions,
          codeStatusOptions: codeStatusOptions,
          discountTypeOptions: discountTypeOptions,
          membershipOptions: membershipOptions,
          campaignsOptions: campaignsOptions,
          giftCardTypeOptions: giftCardTypeOptions,
          idCodes: idCodes,
          phoneCodes: phoneCodes,
          publicCodes: publicCodes,
          limitGiftcard: limitGiftcard,
        }}
      />

      <DeleteGiftcardModal
        modal={deleteCodeModal}
        handleModal={handleDeleteGiftcardModal}
        action={deleteGiftcardLoad}
      />

      <Header />
      <div className="service-with-container container">
        <div className="buttons-container">
          <h1 className="title-component">
            Ventas de Giftcard: {dataTable?.count}
          </h1>

          <ActionButton
            action={() => handleCreateCodeForm()}
            label={'+ Nueva Venta'}
          />
        </div>
        <div className="breadcrumb-container">
          {/* <div className="exportables-buttons-container"></div> */}
          <div className="row">
            <div className="col-12 col-md-2">
              <Select
                options={giftCardTypeOptions}
                label={'Tipo'}
                value={giftcardType}
                onChange={handleGiftcardType}
              />
            </div>
            <div className="col-12 col-md-2">
              <div className="exportables-buttons-container">
                <div style={{ marginTop: '12px' }}>
                  <SelectPerPage
                    label={'Registros por página'}
                    options={itemsPerpage}
                    value={dataTable?.limit}
                    onChange={selectitemsPerpage}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div style={{ marginTop: '12px' }}>
                <ActionButton label={'Exportar'} action={() => exportTable()} />
              </div>
            </div>
          </div>
          <span></span>
        </div>

        {giftcardType === giftCardType?.RETAIL && (
          <GiftcardTables
            header={giftcardSalesheader}
            data={dataTable}
            handlePageChange={handlePageChange}
            redirectToOrder={redirectToOrder}
            tableRef={tableRef}
            openDetail={openDetail}
            handleEditCodeForm={handleEditCodeForm}
            handleDeleteGiftcardModal={handleDeleteGiftcardModal}
          />
        )}

        {giftcardType === giftCardType?.PARTICULAR && (
          <GiftcardParticularTables
            header={giftcardParticularSalesheader}
            data={dataTable}
            handlePageChange={handlePageChange}
            redirectToOrder={redirectToOrder}
            tableRef={tableRef}
            openDetail={openDetail}
            handleEditCodeForm={handleEditCodeForm}
            handleDeleteGiftcardModal={handleDeleteGiftcardModal}
          />
        )}
      </div>
    </>
  );
};

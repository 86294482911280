import { filterParams } from '../assets/helpers/funciones';
import { authInstance } from './axios';
let url = process.env.REACT_APP_API_URL;

export const usersService = {
  getUsers: async (params) => {
    const response = await authInstance
      .get(`${url}/users?` + new URLSearchParams(filterParams(params)))
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getSalesUsers: async (params) => {
    const response = await authInstance
      .get(`${url}/users?` + new URLSearchParams(filterParams(params)))
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  createUsers: async (data) => {
    const response = await authInstance
      .post(`${url}/users`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  editUsers: async (data, userId) => {
    const response = await authInstance
      .put(`${url}/users/${userId}`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
};

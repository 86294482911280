import { useState, useEffect, useRef } from 'react';
import { ActionButton, AltButton } from '../../common/Buttons/Buttons';
import { Header } from '../../common/Header/Header.jsx';
import { SelectPerPage } from '../../common/Forms/Input/Input';
import {
  RCVsTable,
  SalesTable,
  ExportableAdminTable,
  UpgradeTable,
  RCV_CSTable,
  DueSalesTable,
} from '../../common/Tables/Tables';
import {
  RCVsTableHeader,
  UpgradesTableHeader,
  exportablesSalesTable,
  exportablesAdminTable,
  exportablesPendingAccounts,
  RCV_CS_TableHeader,
} from '../../../assets/helpers/tables';
import { notify } from '../../../assets/helpers/toast';
import { membershipService } from '../../../services/membershipService';
import { usersService } from '../../../services/usersService';
import authService from '../../../services/authService';
import { useHistory } from 'react-router-dom';
import {
  FilterDatePicker,
  Select,
  SearchInput,
} from '../../common/Forms/Input/Input';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner.jsx';
import moment from 'moment';
import {
  filterParams,
  capitalize,
  greatedThanDate,
} from '../../../assets/helpers/funciones';
import { salesRoles } from '../../../assets/helpers/roles';
import './Exportables.scss';

const itemsPerpage = [10, 25, 50, 100, 500];

const exportFilter = {
  UPGRADE: 'Upgrade',
  RCV_Report: 'Reporte RCV',
  RCV_CS: 'RCV CS',
  SALES: 'Ventas',
  CUENTAS_POR_COBRAR: 'CxC pagadas',
  ADMINISTRATION: 'Administración',
};

export const FilterButton = props => {
  const { label, type, action } = props;
  if (label === exportFilter?.RCV_Report && !type) {
    return <ActionButton label={label} action={() => action(label)} />;
  }
  if (label === type) {
    return <ActionButton label={label} action={() => action(label)} />;
  } else {
    return <AltButton label={label} action={() => action(label)} />;
  }
};

export const Exportables = () => {
  const tableRef = useRef(null);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [sellerOptions, setSellerOptions] = useState([]);
  const [seller, setSeller] = useState('');
  const [minDate, setMinDate] = useState('');

  const [membershipsDatatable, setMembershipsDatatable] = useState({
    limit: itemsPerpage[2],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    dateFrom: '',
    dateTo: '',
    isLoading: true,
    searchParam: '',
    type: exportFilter?.SALES,
  });

  const selectType = type => {
    if (type === membershipsDatatable?.type) {
      return null;
    }

    if (type === exportFilter?.RCV_CS) {
      handleLimitDates();
    } else {
      setMinDate('');
    }

    setMembershipsDatatable(prevState => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        data: [],
        count: 0,
        type: type,
        // isLoading: true,
      };
    });
  };

  const handleSinceDateChange = e => {
    let eDate = new Date(moment(e)?.format('DD/MM/YYYY')).getTime();
    let to = new Date(moment(dateTo)?.format('DD/MM/YYYY')).getTime();

    if (eDate > to && dateTo) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setDateFrom(e);
    }
  };

  const handleUntilDateChange = e => {
    let eDate = new Date(moment(e)?.format('DD/MM/YYYY')).getTime();
    let from = new Date(moment(dateFrom)?.format('DD/MM/YYYY')).getTime();

    if (eDate < from && dateFrom) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setDateTo(e);
    }
  };

  const selectitemsPerpage = event => {
    setMembershipsDatatable(prevState => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setMembershipsDatatable(prevState => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * membershipsDatatable?.limit,
      };
    });
  };

  const selectSeller = e => {
    const { value } = e?.target;
    setSeller(value);
  };

  const exportTable = () => {
    if (
      [exportFilter?.RCV_Report, exportFilter?.RCV_CS].includes(
        membershipsDatatable?.type
      )
    ) {
      exportRCVs();
    }

    if (
      [
        exportFilter?.SALES,
        exportFilter?.UPGRADE,
        exportFilter?.ADMINISTRATION,
      ]?.includes(membershipsDatatable?.type)
    ) {
      exportMembershipTable();
    }

    if (
      [exportFilter?.CUENTAS_POR_COBRAR]?.includes(membershipsDatatable?.type)
    ) {
      getAccountsReceivable(true);
    }
  };

  const downloadZip = () => {
    let params = {
      // status: validateIdLogic(),
      dateFrom: dateFrom ? moment(dateFrom).format('MM-DD-YYYY') : '',
      dateTo: dateTo ? moment(dateTo).format('MM-DD-YYYY') : '',
      limit: membershipsDatatable?.limit,
      offset: membershipsDatatable?.offset,
    };
    notify(`Descarga iniciada`, 'success');

    membershipService
      .ZipRCV(params)
      .then(response => {
        if ([200, 201].includes(response?.status)) {
          notify(`Descarga iniciada`, 'success');
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => {});
  };

  const redirectToEditOrder = orderId => {
    history.push(`/afiliations/edit-order/${orderId}`);
  };

  const redirectToOrderDetail = order => {
    history.push(
      `/afiliations/order-details/${order?._id}/vehicle/${order?.vehicle?._id}`
    );
  };

  const renewMembership = membership => {
    if (!isLoading) {
      setIsLoading(true);
      membershipService
        .reativateMembership(membership?._id)
        .then(response => {
          if ([200, 201].includes(response?.status)) {
            setIsLoading(false);
            notify(`Membresia reactivada`, 'success');
            redirectToEditOrder(membership?._id);
          } else {
            setIsLoading(false);
            notify(`${response.message}`, 'error');
          }
        })
        .catch(() => {});
    }
  };

  const handleLimitDates = () => {
    if (!greatedThanDate(dateFrom, '2024-03-01')) {
      setDateFrom(new Date('2024-03-02'));
    }
    setMinDate(new Date('2024-03-02'));
  };

  const preloadDates = () => {
    if (
      membershipsDatatable?.type === exportFilter?.RCV_CS ||
      [salesRoles?.RCV]?.includes(authService.getUserType())
    ) {
      setMinDate(new Date('2024-03-02'));
    }
    let from = new Date();
    from = from.setDate(from.getDate() - 7);
    from = new Date(from);
    setDateFrom(from);
    setDateTo(new Date());
    getUsers();
    getData();
  };

  const handleSearchChange = event => {
    setMembershipsDatatable(prevState => {
      return {
        ...prevState,
        searchParam: event?.target?.value,
        // page: 1,
        // offset: 0,
        // data: false,
      };
    });
  };

  const defineType = type => {
    if (type === exportFilter?.SALES) {
      return 'SALES';
    }
    if (type === exportFilter?.ADMINISTRATION) {
      return 'ADMIN';
    } else return '';
  };

  useEffect(() => {
    preloadDates();
  }, []);

  useEffect(() => {
    getData();
  }, [
    membershipsDatatable?.page,
    membershipsDatatable?.offset,
    membershipsDatatable?.type,
    membershipsDatatable?.limit,
    dateFrom,
    dateTo,
    seller,
  ]);

  const exportMembershipTable = () => {
    let params = {
      // status: validateIdLogic(),
      dateFrom: dateFrom ? moment(dateFrom).format('MM-DD-YYYY') : '',
      dateTo: dateTo ? moment(dateTo).format('MM-DD-YYYY') : '',
      limit: membershipsDatatable?.limit,
      offset: membershipsDatatable?.offset,
      search: [exportFilter?.RCV_CS]?.includes(membershipsDatatable?.type)
        ? membershipsDatatable?.searchParam
        : '',
      isExportable: true,
      seller:
        [exportFilter?.UPGRADE]?.includes(membershipsDatatable?.type) &&
        [salesRoles?.SALES_ADMIN_AUX]?.includes(authService?.getUserType())
          ? ''
          : seller,
      upgrades:
        membershipsDatatable?.type === exportFilter?.UPGRADE ? true : '',
      byMail: true,
      email: authService?.getUser()?.email,
      type: defineType(membershipsDatatable?.type),
    };

    notify(
      `Solicitud de exportable realizada, espere a recibir un correo con el archivo correspondiente`,
      'info'
    );

    membershipService
      .getMembershipsByStatus(filterParams(params))
      .then(response => {
        if (response.status === 200) {
        } else {
        }
      })
      .catch(error => {});
  };

  const getMembershipsByStatus = () => {
    setMembershipsDatatable(prevState => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    if (!dateFrom || !dateTo) {
      return null;
    }

    let params = {
      // status: validateIdLogic(),
      dateFrom: dateFrom ? moment(dateFrom).format('MM-DD-YYYY') : '',
      dateTo: dateTo ? moment(dateTo).format('MM-DD-YYYY') : '',
      limit: membershipsDatatable?.limit,
      offset: membershipsDatatable?.offset,
      searchParam: [exportFilter?.RCV_CS]?.includes(membershipsDatatable?.type)
        ? membershipsDatatable?.searchParam
        : '',
      isExportable: true,
      seller: seller,
      upgrades:
        membershipsDatatable?.type === exportFilter?.UPGRADE ? true : '',
      type: defineType(membershipsDatatable?.type),
    };

    membershipService
      .getMembershipsCountByCondition(filterParams(params))
      .then(response => {
        if (response.status === 200) {
          setMembershipsDatatable(prevState => {
            return {
              ...prevState,
              count: response?.data?.total,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => {});

    membershipService
      .getMembershipsByStatus(filterParams(params))
      .then(response => {
        if (response.status === 200) {
          setMembershipsDatatable(prevState => {
            return {
              ...prevState,
              data: response?.data?.memberships,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setMembershipsDatatable(prevState => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch(error => {
        setMembershipsDatatable(prevState => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };

  const getAccountsReceivable = byMail => {
    let params = {
      // status: validateIdLogic(),
      dateFrom: dateFrom ? moment(dateFrom).format('MM-DD-YYYY') : '',
      dateTo: dateTo ? moment(dateTo).format('MM-DD-YYYY') : '',
      limit: membershipsDatatable?.limit,
      byMail: byMail ? true : false,
      offset: membershipsDatatable?.offset,
    };

    membershipService
      .getAccountsReceivable(filterParams(params))
      .then(response => {
        if (response.status === 200) {
          if (!byMail) {
            setMembershipsDatatable(prevState => {
              return {
                ...prevState,
                data: response?.data?.memberships,
                count: response?.data?.total,
                isLoading: false,
              };
            });
          } else {
            notify('Enviado por correo', 'success');
          }
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => {});
  };

  const getUsers = async () => {
    let params = {
      limit: 1000000,
      offset: 0,
      module: 'Ventas',
      status: 'Activo',
    };
    usersService
      .getUsers(params)
      .then(response => {
        if (response.status === 200) {
          let data = [];
          data.push({
            _id: '',
            name: 'Todos',
          });
          for (let i = 0; i < response?.data?.users?.length; i++) {
            data?.push({
              _id: response?.data?.users[i]?._id,
              name:
                capitalize(response?.data?.users[i]?.firstName) +
                '  ' +
                capitalize(response?.data?.users[i]?.lastName),
            });
          }

          let user = localStorage.getItem('user');
          user = JSON.parse(user);
          let [selectedSeller] = data?.filter(s => s?._id === user?._id);

          // IF not admin can only see global and own metrics
          if (![salesRoles?.SALES_ADMIN].includes(authService?.getUserType())) {
            setSellerOptions([
              {
                _id: '',
                name: 'Todos',
              },
              selectedSeller,
            ]);
          } else {
            setSellerOptions(data);
          }

          if (
            selectedSeller &&
            ![salesRoles?.SALES_ADMIN].includes(authService?.getUserType())
          ) {
            setSeller(selectedSeller?._id);
          }
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => {});
  };

  const getData = () => {
    if ([salesRoles?.RCV]?.includes(authService.getUserType())) {
      setMembershipsDatatable(prevState => {
        return {
          ...prevState,
          page: 1,
          offset: 0,
          type: exportFilter?.RCV_CS,
        };
      });
      getRCVs();
      return null;
    }

    // getRCVs();
    if (
      [exportFilter?.CUENTAS_POR_COBRAR].includes(membershipsDatatable?.type)
    ) {
      getAccountsReceivable();
    }
    if (
      [exportFilter?.RCV_Report, exportFilter?.RCV_CS].includes(
        membershipsDatatable?.type
      )
    ) {
      getRCVs();
    }

    if (
      [
        exportFilter?.SALES,
        exportFilter?.UPGRADE,
        exportFilter?.ADMINISTRATION,
      ]?.includes(membershipsDatatable?.type)
    ) {
      getMembershipsByStatus();
    }
  };

  const exportRCVs = () => {
    let params = {
      dateFrom: dateFrom ? moment.utc(dateFrom).format('MM-DD-YYYY') : '',
      dateTo: dateTo ? moment.utc(dateTo).format('MM-DD-YYYY') : '',
      limit: membershipsDatatable?.limit,
      offset: membershipsDatatable?.offset,
      byEmail: true,
      rcvType: membershipsDatatable?.type === exportFilter?.RCV_CS ? 'sc' : '',
      email: authService?.getUser()?.email,
    };

    notify(
      `Solicitud de exportable realizada, espere a recibir un correo con el archivo correspondiente`,
      'info'
    );

    membershipService
      .getRCVs(filterParams(params))
      .then(response => {
        if (response.status === 200) {
        } else {
        }
      })
      .catch(error => {});
  };

  const getRCVs = () => {
    if (dateFrom && dateTo) {
      setMembershipsDatatable(prevState => {
        return {
          ...prevState,
          isLoading: true,
        };
      });

      if (!dateFrom || !dateTo) {
        return null;
      }

      let params = {
        dateFrom: dateFrom ? moment.utc(dateFrom).format('MM-DD-YYYY') : '',
        dateTo: dateTo ? moment.utc(dateTo).format('MM-DD-YYYY') : '',
        limit: membershipsDatatable?.limit,
        offset: membershipsDatatable?.offset,
        search: [exportFilter?.RCV_CS]?.includes(membershipsDatatable?.type)
          ? membershipsDatatable?.searchParam
          : '',
      };

      membershipService
        .getRCVs(filterParams(params))
        .then(response => {
          if (response.status === 200) {
            setMembershipsDatatable(prevState => {
              return {
                ...prevState,
                data: response?.data?.memberships,
                count: response?.data?.total,
                isLoading: false,
              };
            });
          } else {
            notify(`${response.message}` || 'error', 'error');
            setMembershipsDatatable(prevState => {
              return {
                ...prevState,
                isLoading: false,
              };
            });
          }
        })
        .catch(error => {
          setMembershipsDatatable(prevState => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        });
      return true;
    }
  };

  return (
    <>
      <LoadSpinner show={membershipsDatatable?.isLoading} />
      <Header />
      <div className="service-with-container container">
        <div className="buttons-container">
          <h1 className="title-component">
            Exportables: {membershipsDatatable?.count}
          </h1>

          <div className="buttons-center-container">
            {[exportFilter?.RCV_CS]?.includes(membershipsDatatable?.type) &&
              [salesRoles?.SALES_ADMIN].includes(
                authService?.getUserType()
              ) && (
                <ActionButton
                  label={'Descargar Zip'}
                  action={() => downloadZip()}
                />
              )}
            <ActionButton label={'Exportar'} action={() => exportTable()} />
          </div>
        </div>
        <div className="breadcrumb-container">
          <div></div>
          <div className="exportables-buttons-container">
            <div style={{ width: '100%' }} className="row">
              <div className="exportables-buttons-container">
                {![salesRoles?.RCV].includes(authService?.getUserType()) && (
                  <FilterButton
                    label={exportFilter?.SALES}
                    type={membershipsDatatable?.type}
                    action={selectType}
                  />
                )}
                {[
                  salesRoles?.SALES_ADMIN,
                  salesRoles?.SALES_DEVELOPER,
                  salesRoles?.SALES_SUPERVISOR,
                ].includes(authService?.getUserType()) && (
                  <FilterButton
                    label={exportFilter?.RCV_Report}
                    type={membershipsDatatable?.type}
                    action={selectType}
                  />
                )}
                {[
                  salesRoles?.SALES_ADMIN,
                  salesRoles?.SALES_DEVELOPER,
                  salesRoles?.SALES_SUPERVISOR,
                  salesRoles?.RCV,
                ].includes(authService?.getUserType()) && (
                  <FilterButton
                    label={exportFilter?.RCV_CS}
                    type={membershipsDatatable?.type}
                    action={selectType}
                  />
                )}
                {(![
                  salesRoles?.SALES_ADMIN,
                  salesRoles?.SALES_DEVELOPER,
                  salesRoles?.SALES_SUPERVISOR,
                  salesRoles?.RCV,
                ].includes(authService?.getUserType()) ||
                  ![salesRoles?.RCV].includes(authService?.getUserType())) && (
                  <FilterButton
                    label={exportFilter?.UPGRADE}
                    type={membershipsDatatable?.type}
                    action={selectType}
                  />
                )}
                {![
                  salesRoles?.RCV,
                  salesRoles?.SALES_COUNTER,
                  salesRoles?.SALES_MARKETING,
                ].includes(authService?.getUserType()) && (
                  <FilterButton
                    label={exportFilter?.CUENTAS_POR_COBRAR}
                    type={membershipsDatatable?.type}
                    action={selectType}
                  />
                )}
                {[
                  salesRoles?.SALES_ADMIN,
                  salesRoles?.SALES_ADMIN_AUX,
                ].includes(authService?.getUserType()) && (
                  <FilterButton
                    label={exportFilter?.ADMINISTRATION}
                    type={membershipsDatatable?.type}
                    action={selectType}
                  />
                )}
              </div>

              {[exportFilter?.RCV_CS]?.includes(membershipsDatatable?.type) && (
                <div className="col-12 col-md-2" style={{ marginTop: '30px' }}>
                  <SearchInput
                    placeholder={'Nombre o Apellido, Cédula o Placa'}
                    searchAction={getRCVs}
                    value={membershipsDatatable?.searchParam}
                    onChange={handleSearchChange}
                    width="50px"
                  />
                </div>
              )}

              {[exportFilter?.SALES]?.includes(membershipsDatatable?.type) && (
                <div className="col-12 col-md-2">
                  <Select
                    options={sellerOptions}
                    label={'Asesor'}
                    value={seller}
                    onChange={selectSeller}
                  />
                </div>
              )}

              {/* {!minDate && <FilterDatePicker
                bootstrap={'col-12 col-md-2'}
                label={'Desde'}
                type={'date'}
                name={'dateFrom'}
                placeholder={''}
                onChange={handleSinceDateChange}
                value={dateFrom}
                disabled={membershipsDatatable?.isLoading}
              />}

              {minDate && <FilterDatePicker
                bootstrap={'col-12 col-md-2'}
                label={'Desde'}
                type={'date'}
                name={'dateFrom'}
                placeholder={''}
                onChange={handleSinceDateChange}
                value={dateFrom}
                disabled={membershipsDatatable?.isLoading}
                minDate={minDate}
              />} */}

              <FilterDatePicker
                bootstrap={'col-12 col-md-2'}
                label={'Desde'}
                type={'date'}
                name={'dateFrom'}
                placeholder={''}
                onChange={handleSinceDateChange}
                value={dateFrom}
                disabled={membershipsDatatable?.isLoading}
                minDate={minDate}
              />

              <FilterDatePicker
                bootstrap={'col-12 col-md-2'}
                label={'Hasta'}
                type={'date'}
                name={'dateTo'}
                placeholder={''}
                onChange={handleUntilDateChange}
                value={dateTo}
                disabled={membershipsDatatable?.isLoading}
              />
              <div className="col-12 col-md-3" style={{ marginTop: '12px' }}>
                <SelectPerPage
                  label={'Registros por página'}
                  options={itemsPerpage}
                  value={membershipsDatatable?.limit}
                  onChange={selectitemsPerpage}
                />
              </div>
            </div>
          </div>
        </div>
        {membershipsDatatable?.type === exportFilter?.RCV_CS && (
          <RCV_CSTable
            header={RCV_CS_TableHeader}
            data={membershipsDatatable}
            handlePageChange={handlePageChange}
            redirectToEditOrder={redirectToEditOrder}
            redirectToOrderDetail={redirectToOrderDetail}
            renewMembership={renewMembership}
            tableRef={tableRef}
          />
        )}

        {membershipsDatatable?.type === exportFilter?.RCV_Report && (
          <RCVsTable
            header={RCVsTableHeader}
            data={membershipsDatatable}
            handlePageChange={handlePageChange}
            redirectToEditOrder={redirectToEditOrder}
            redirectToOrderDetail={redirectToOrderDetail}
            renewMembership={renewMembership}
            tableRef={tableRef}
          />
        )}
        {membershipsDatatable?.type === exportFilter?.UPGRADE && (
          <UpgradeTable
            header={UpgradesTableHeader}
            data={membershipsDatatable}
            handlePageChange={handlePageChange}
            redirectToEditOrder={redirectToEditOrder}
            redirectToOrderDetail={redirectToOrderDetail}
            renewMembership={renewMembership}
            tableRef={tableRef}
          />
        )}
        {membershipsDatatable?.type === exportFilter?.SALES && (
          <SalesTable
            header={exportablesSalesTable}
            data={membershipsDatatable}
            handlePageChange={handlePageChange}
            redirectToEditOrder={redirectToEditOrder}
            redirectToOrderDetail={redirectToOrderDetail}
            renewMembership={renewMembership}
            tableRef={tableRef}
          />
        )}
        {membershipsDatatable?.type === exportFilter?.CUENTAS_POR_COBRAR && (
          <DueSalesTable
            header={exportablesPendingAccounts}
            data={membershipsDatatable}
            handlePageChange={handlePageChange}
            redirectToEditOrder={redirectToEditOrder}
            redirectToOrderDetail={redirectToOrderDetail}
            renewMembership={renewMembership}
            tableRef={tableRef}
          />
        )}
      </div>
      {membershipsDatatable?.type === exportFilter?.ADMINISTRATION && (
        <ExportableAdminTable
          header={exportablesAdminTable}
          data={membershipsDatatable}
          handlePageChange={handlePageChange}
          redirectToEditOrder={redirectToEditOrder}
          redirectToOrderDetail={redirectToOrderDetail}
          renewMembership={renewMembership}
          tableRef={tableRef}
        />
      )}
    </>
  );
};

// Funcion que consigue el token del session storage

function getToken() {
  try {
    let token = localStorage.getItem('accessToken');
    if (token) {
      return token;
    }
  } catch (error) {
    return null;
  }
}

// Function that gets the user type from the sesion storage
function getUserType() {
  try {
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    if (user) {
      user = user?.roles[0]?.code;
    }
    return user;
  } catch (error) {
    return null;
  }
}

function getUser() {
  try {
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    return user;
  } catch (error) {
    return null;
  }
}

function logOut() {
  localStorage.clear();
  window.location.replace(`login`);
}

export default {
  getToken,
  getUserType,
  getUser,
  logOut,
};

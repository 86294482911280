import React from 'react';
import { NavLink } from 'react-router-dom';
import pageNotFound from '../../../images/miscellaneous/pageNotFound.png';
// import { useTranslation } from 'react-i18next';
import logo from '../../../images/logo.svg';
import { Header } from '../../common/Header/Header.jsx';
import './PageNotFound.scss';
export const PageNotFound = () => {
  // const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="container view-content">
        <div className="row">
          <div className="col-12 page-not-found-container">
            <img src={pageNotFound} alt="404" />
            <NavLink to="/login">
              <img src={logo} alt="logo" />
            </NavLink>
            <h4>Lo sentimos</h4>
            <h5>La página que buscas no pudo ser encontrada</h5>
          </div>
        </div>
      </div>
    </>
  );
};

import { useState, useEffect, useRef } from 'react';
import { ActionButton, AltButton } from '../../common/Buttons/Buttons';
import { Header } from '../../common/Header/Header.jsx';
import { SelectPerPage } from '../../common/Forms/Input/Input';
import { FleetLoadDetailTable } from '../../common/Tables/Tables';
import { fleetLoadDEtailTableHeader } from '../../../assets/helpers/tables';
import { notify } from '../../../assets/helpers/toast';
import { fleetLoadService } from '../../../services/fleetLoadService';
import { saleChannelService } from '../../../services/saleChannelService';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { capitalize, filterParams } from '../../../assets/helpers/funciones';
import * as schemas from '../../../assets/schemas/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { RenewFleetLoadModal, CSVErrorsModal } from "../../common/Modals/Modals.jsx"
import {
  planTypesOptions as membershipOptions,
  loadStrategyOptions,
  fleetTypeOptions,
  fleetPaymentTypeOptions,
  financingTypeOptions,
  financingType,
  fleetLoadRCVTypesOptions,
  planStatus
} from "../../../assets/helpers/options.js"

import './FleetRegister.scss';

const itemsPerpage = [10, 25, 50, 100, 500];

export const FleetLoadDetails = () => {
  const tableRef = useRef(null);
  const history = useHistory();
  const [selectedPlan, setSelectedPlan] = useState('');
  const [csvErrorModal, setCsvErrorModal] = useState({
    isOpen: false,
    selected: '',
  });


  const [isLoading, setIsloading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    control
  } = useForm({
    resolver: yupResolver(schemas.renewFleetLoadSchema()),
  });



  const [dataTable, setDataTable] = useState({
    limit: itemsPerpage[2],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    sinceDate: '',
    untilDate: '',
    isLoading: true,
  });

  const [renewModal, setRenewModal] = useState({
    selected: [],
    isOpen: false,
  });



  const { fleetId, loadId } = useParams();

  const [salesChannelOptions, setSalesChannelOptions] = useState([]);

  const [membershipsDatatable, setMembershipsDatatable] = useState({
    limit: itemsPerpage[2],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    dateFrom: '',
    dateTo: '',
    isLoading: true,
  });

  const getFleets = () => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    let params = {
      limit: dataTable?.limit,
      offset: dataTable?.offset,
    };

    fleetLoadService
      .getFleetsLoads(params, fleetId)
      .then((response) => {
        if (response.status === 200) {
          setDataTable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.fleetData?.fleetLoads,
              count: response?.data?.total,
              name: response?.data?.fleetData?.name,
              code: response?.data?.fleetData?.code,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setDataTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setDataTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };

  const exportFleetZip = () => {
    fleetLoadService
      .downloadDocuments(loadId)
      .then((response) => {
        if (response.status === 200) {
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
    return true;
  };

  const selectitemsPerpage = (event) => {
    setMembershipsDatatable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setMembershipsDatatable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * membershipsDatatable?.limit,
      };
    });
  };

  const handleCSVErrorModal = (errors) => {
    setCsvErrorModal((prevState) => {
      return {
        ...prevState,
        isOpen: !csvErrorModal?.isOpen,
        errors: errors ? errors : '',
      };
    });
  };


  const redirectToEditOrder = (orderId) => {
    history.push(`/afiliations/edit-order/${orderId}`);
  };

  const redirectToOrderDetail = (order) => {
    history.push(
      `/afiliations/order-details/${order?._id}/vehicle/${order?.vehicle?._id}`
    );
  };

  useEffect(() => {
    getMembershipsByStatus();
    getFleets();
    getSalesChannel()
  }, [
    membershipsDatatable?.page,
    membershipsDatatable?.offset,
    membershipsDatatable?.limit,
    membershipsDatatable?.type,
  ]);

  const getMembershipsByStatus = () => {
    setMembershipsDatatable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    let params = {
      limit: membershipsDatatable?.limit,
      offset: membershipsDatatable?.offset,
    };

    fleetLoadService
      .getFleetLoadDetails(params, loadId)
      .then((response) => {
        if (response.status === 200) {

          for (let i = 0; i < response?.data?.memberships?.length; i++) {
            if (!response.data.memberships[i]?.fleetRenewed) {
              response.data.memberships[i].selected = false;
            }
          }

          setMembershipsDatatable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.memberships,
              count: response?.data?.total,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setMembershipsDatatable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setMembershipsDatatable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };


  const getSalesChannel = () => {
    saleChannelService
      .getSalesChannel()
      .then((response) => {
        if ([200, 201].includes(response?.status)) {
          setSalesChannelOptions(response?.data?.saleChannels);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const handleRenewModal = () => {

    let data = membershipsDatatable?.data?.filter((option) => option?.selected === true)
    setRenewModal((prevState) => {
      return {
        ...prevState,
        isOpen: !renewModal?.isOpen,
        selected: !renewModal?.isOpen ? data : []
      };
    });
  };

  const selectAllGiftcards = () => {
    let datos = [...membershipsDatatable?.data];

    let none = '';

    // Validate if atleast one is selected
    for (let i = 0; i < datos.length; i++) {
      if (datos[i].selected) {
        none = true;
      }
    }

    if (!none) {
      for (let i = 0; i < datos.length; i++) {
        datos[i].selected = true;
      }
      none = true;
    } else {
      for (let i = 0; i < datos.length; i++) {
        datos[i].selected = false;
      }
      none = false;
    }

    for (let i = 0; i < datos.length; i++) {
      if (datos[i]?.fleetRenewed || ![planStatus?.EXPIRED, planStatus?.TO_BE_EXPIRED, planStatus?.TO_BE_RENEW]?.includes(datos[i]?.status))
        datos[i].selected = false;
    }

    setMembershipsDatatable((prevState) => {
      return {
        ...prevState,
        data: datos,
      };
    });
  };

  const selectGiftcard = (giftcard, index) => {
    let datos = [...membershipsDatatable?.data];
    datos[index].selected = !datos[index].selected;

    let none = '';

    for (let i = 0; i < datos.length; i++) {
      if (datos[i].selected) {
        none = giftcard?.status;
      }
    }

    setMembershipsDatatable((prevState) => {
      return {
        ...prevState,
        data: datos,
        selectedType: none,
      };
    });
  };

  const renewLoad = handleSubmit(async (data) => {

    let memberships = [
      { code: 'GOLD', selected: data?.GOLD, rcv: data?.GOLD_RCV },
      { code: 'PLUS', selected: data?.PLUS, rcv: data?.PLUS_RCV },
      { code: 'CLASSIC', selected: data?.CLASSIC, rcv: data?.CLASSIC_RCV },
      {
        code: 'MOTORIDER',
        selected: data?.MOTORIDER,
        rcv: data?.MOTORIDER_RCV,
      },
      {
        code: 'PLUSF4',
        selected: data?.PLUSF4,
        rcv: data?.PLUSF4_RCV,
      },
      {
        code: 'MOTORIDERAV',
        selected: data?.MOTORIDERAV,
        rcv: data?.MOTORIDERAV_RCV,
      },
    ];

    memberships = memberships.filter((m) => m?.selected);
    if (!handleFinancingChange()) {
      return null;
    }

    let dataToPost = { ...data };
    delete dataToPost?.GOLD;
    delete dataToPost?.PLUS;
    delete dataToPost?.CLASSIC;
    delete dataToPost?.MOTORIDER;

    delete dataToPost?.GOLD_RCV;
    delete dataToPost?.PLUS_RCV;
    delete dataToPost?.CLASSIC_RCV;
    delete dataToPost?.MOTORIDER_RCV;

    delete dataToPost?.PLUSF4;
    delete dataToPost?.PLUSF4_RCV;
    delete dataToPost?.MOTORIDERAV;
    delete dataToPost?.MOTORIDERAV_RCV;



    dataToPost.fleetId = fleetId;
    dataToPost.rcvStartDate = dataToPost.planStartDate
    dataToPost.rcvEndDate = dataToPost.planEndDate
    dataToPost.membershipsToRenew = []
    for (let i = 0; i < renewModal?.selected?.length; i++) {
      dataToPost.membershipsToRenew.push(renewModal?.selected[i]?._id)
    }

    dataToPost.rcv = memberships[0]?.rcv
    dataToPost.membership = memberships[0]?.code
    dataToPost.status = "Creada"

    if (!isLoading) {
      setIsloading(true);
      fleetLoadService
        .renewFleetLoad(filterParams(dataToPost))
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Renovacion de flotas realizada con exito`, 'success');
            reset();
            setIsloading(false);
            window.location.reload();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }


  });



  const handleFinancingChange = () => {
    let date1 = new Date(watch('planStartDate'));
    let date2 = new Date(watch('planEndDate'));
    let diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let diffMonths = diffDays / 30;

    if (watch('financingTerm') === financingType?.MONTHLY && diffMonths < 1) {
      notify('La vigencia del plan debe ser mayor a un mes', 'info');
      return false;
    }
    if (watch('financingTerm') === financingType?.QUARTER && diffMonths < 3) {
      notify('La vigencia del plan debe ser mayor a 3 meses', 'info');
      return false;
    }
    if (watch('financingTerm') === financingType?.SEMESTER && diffMonths < 6) {
      notify('La vigencia del plan debe ser mayor a 6 meses', 'info');
      return false;
    } else {
      return true;
    }
  };

  const handleCheckboxChange = (e) => {
    setSelectedPlan(e)
    setValue(e + '_RCV', "")
  };



  return (
    <>
      <CSVErrorsModal modal={csvErrorModal} handleModal={handleCSVErrorModal} />
      <RenewFleetLoadModal
        options={{
          salesChannelOptions: salesChannelOptions,
          loadStrategyOptions: loadStrategyOptions,
          fleetTypeOptions: fleetTypeOptions,
          fleetPaymentTypeOptions: fleetPaymentTypeOptions,
          financingTypeOptions: financingTypeOptions,
          membershipOptions: membershipOptions,
          fleetLoadRCVTypesOptions: fleetLoadRCVTypesOptions,
        }}
        onChange={{ handleCheckboxChange: handleCheckboxChange }}
        watch={watch}
        register={register}
        errors={errors}
        control={control}
        reset={reset}
        modal={renewModal}
        selectedPlan={selectedPlan}
        action={() => renewLoad()}
        close={() => handleRenewModal()} />
      <Header />
      <div className="service-with-container container">
        <div className="buttons-container">
          <h1 className="title-component">
            {capitalize(dataTable?.name)} ({dataTable?.code}): {membershipsDatatable?.count} <br />
          </h1>{' '}
          <div className='buttons-center-container'>
            {
              membershipsDatatable?.data?.filter((option) => option?.selected === true).length > 0 ?
                <ActionButton
                  label={'Renovar Afiliaciones'}
                  action={() => handleRenewModal()}
                />
                :
                <AltButton
                  label={'Renovar Afiliaciones'}
                  disabled={true}
                />
            }
            <ActionButton
              label={'Exportar Documentos'}
              action={() => exportFleetZip()}
            />
          </div>
        </div>

        <div className="breadcrumb-container">
          <div></div>
          <div className="exportables-buttons-container">
            <div style={{ width: '100%' }} className="row">
              <div className="col-12 col-md-3" style={{ marginTop: '12px' }}>
                <SelectPerPage
                  label={'Registros por página'}
                  options={itemsPerpage}
                  value={membershipsDatatable?.limit}
                  onChange={selectitemsPerpage}
                />
              </div>
            </div>
          </div>
        </div>
        <FleetLoadDetailTable
          header={fleetLoadDEtailTableHeader}
          data={membershipsDatatable}
          handlePageChange={handlePageChange}
          redirectToEditOrder={redirectToEditOrder}
          redirectToOrderDetail={redirectToOrderDetail}
          tableRef={tableRef}
          selectAllGiftcards={selectAllGiftcards}
          selectGiftcard={selectGiftcard}
        />
      </div>
    </>
  );
};

import { filterParams } from '../assets/helpers/funciones';
import { authInstance } from './axios';
let url = process.env.REACT_APP_API_URL;

export const fleetService = {
  getFleets: async (params) => {
    const response = await authInstance
      .get(`${url}/fleets?` + new URLSearchParams(filterParams(params)))
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  createFleet: async (data) => {
    const response = await authInstance
      .post(`${url}/fleets/create`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  editFleet: async (data, fleetId) => {
    const response = await authInstance
      .put(`${url}/fleets/${fleetId}/update`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
};

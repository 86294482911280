import { authInstance } from './axios';
let url = process.env.REACT_APP_API_URL;

export const productService = {
  getBroadCastChannels: async () => {
    const response = await authInstance
      .get(`${url}/broadcastChannels/search?description=&limit=1000`)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getPaymentMethods: async (module) => {
    const response = await authInstance
      .get(`${url}/paymentMethods?module=${module}`)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getAvailableProducts: async (originCityId) => {
    const response = await authInstance
      .get(`${url}/productsCatalog/listAvailable?originCityId=${originCityId}`)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getRCVs: async (membershipId) => {
    const response = await authInstance
      .get(`${url}/productsCatalog/listRcv?membership=${membershipId}`)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
};

import { useState, useEffect, useCallback } from 'react';
import { Input } from '../../../common/Forms/Input/Input';
import { notify } from '../../../../assets/helpers/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as schemas from '../../../../assets/schemas/schemas';
import { ActionButton, AltButton } from '../../../common/Buttons/Buttons';
import { idCodes, planTypes, } from '../../../../assets/helpers/options';
import { statesService } from '../../../../services/statesService';
import { vehicleService } from '../../../../services/vehicleService';
import { membershipService } from '../../../../services/membershipService';
import { armorTypesService } from '../../../../services/armorTypesService';
import { economicActivitiesService } from '../../../../services/economicActivitiesService';
import { clearObject, capitalize, filterSatesMotoriderAV, removeBlankSpacesAndUppercase, validateIfOther } from '../../../../assets/helpers/funciones';
import {
  SalesDropzone,
  FileComponent,
} from '../../../common/Dropzone/Dropzone';
import { useDropzone } from 'react-dropzone';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';
import './LandingForms.scss';

export const LinkCreateVehicleForm = (props) => {
  const { setcurentStep, curentStep, membership, vehicle, customer, selectedMembership } = props;


  const [statesOptions, setStatesOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [economicActivitiesOptions, setEconomicActivitiesOptions] = useState([]);
  const [ownercitiesOptions, setOwnerCitiesOptions] = useState([]);
  const [armorTypeOptions, setArmorTypeOptions] = useState([]);
  const [typesOptions, setTypesOptions] = useState([]);
  const [vehicleBrandOptions, setVehicleBrandOptions] = useState([]);
  const [vehicleModelOptions, setVehicleModelOptions] = useState([]);
  const [vehicleClassesOptions, setVehicleClassesOptions] = useState([]);
  const [municipalitiesOptions, setMunicipalitiesOptions] = useState([]);
  const [circulationCard, setcirculationCard] = useState('');
  const [isLoading, setIsloading] = useState(false);
  const [customerIsOwner, setcustomerIsOwner] = useState('SI');
  const onDrop1 = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents

      };
      reader.readAsArrayBuffer(file);

      if (
        !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          file.type
        )
      ) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        setcirculationCard(file);
      }
    });
  }, []);


  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
    setError
  } = useForm({
    resolver: yupResolver(schemas.RegisterLinkVehicleSchema()),
  });

  const handleRequired = (name) => {
    if (!watch(name)) {
      setError(name, { type: 'custom', message: 'Obligatorio' });
      return true
    } else return false
  }


  const onSubmit = handleSubmit(async (data) => {
    data.plate = data?.plate?.toUpperCase();
    data.bodySerial = data?.bodySerial?.toUpperCase();
    data.color = capitalize(data?.color);
    data.customer = customer;
    data.membership = membership;

    if (selectedMembership?.rcv !== "N/A" && (handleRequired("engineSerial") || handleRequired("economicActivity") || handleRequired("profession"))) {
      return null
    }

    if (validateIfOther(watch, economicActivitiesOptions) && !watch("otherEconomicActivity")) {
      setError("otherEconomicActivity", { type: 'custom', message: 'Obligatorio' });
      return null
    }


    if (watch("code") === planTypes?.PLUSF4) {
      data.armored = data.armored ? "true" : "false";
    }
    data.customerIsOwner = "true"
    delete data.code

    if (!circulationCard && watch("code") !== planTypes?.PLUSF4) {
      // notify('Adjunte su carnet de circulación', 'info');
      notify('Adjunte su título de propiedad del vehículo', 'info');
      return null;
    }

    data = clearObject(data);
    let vehicle = { ...data };

    if (vehicle?.armorType === 'ninguno') {
      delete vehicle.armorType;
    }

    let fileTopost = appendFile(vehicle);
    vehicle.brandName = capitalize(
      vehicleBrandOptions?.filter((c) => c?._id === vehicle?.brand)[0]?.name
    );
    vehicle.modelName = vehicleModelOptions?.filter(
      (c) => c?._id === vehicle?.model
    )[0]?.name;

    if (!isLoading) {
      setIsloading(true);

      if (watch("code") !== planTypes?.PLUSF4) {
        vehicleService
          .createVehicle(fileTopost)
          .then((response) => {
            if ([200, 201].includes(response.status)) {
              localStorage.setItem('vehicle', JSON.stringify(vehicle));
              updateMembershipStatus(membership);
            } else {
              setIsloading(false);
              notify(`${response.message}` || 'error', 'error');
            }
          })
          .catch((error) => {
            setIsloading(false);
          });
      } else {

        vehicleService
          .createVehicleF4(data)
          .then((response) => {
            if ([200, 201].includes(response.status)) {
              localStorage.setItem('vehicle', JSON.stringify(vehicle));
              updateMembershipStatus(membership);
            } else {
              setIsloading(false);
              notify(`${response.message}` || 'error', 'error');
            }
          })
          .catch((error) => {
            setIsloading(false);
          });
      }
    }
  });

  const updateMembershipStatus = (membership) => {
    membershipService
      .updateMembershipStatus(membership)
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          setcurentStep(curentStep + 1);
          setIsloading(false);
        } else {
          setIsloading(false);
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => {
        setIsloading(false);
      });
  };

  const getStates = () => {
    statesService
      .getStates()
      .then((response) => {
        if (response.status === 200) {

          let states = response?.data?.states

          if (selectedMembership?.code === planTypes?.MOTORIDERAV) {
            states = filterSatesMotoriderAV(states)
          }

          setStatesOptions(states);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const getCities = (state) => {
    statesService
      .getCities(state ? state : watch('state'))
      .then((response) => {
        if (response.status === 200) {
          setCitiesOptions(response?.data?.cities);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
    return true;
  };

  const getEconomicActivities = () => {
    economicActivitiesService
      .getEconomicActivities()
      .then((response) => {
        if (response.status === 200) {
          setEconomicActivitiesOptions(response?.data?.activities)
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const getOwnerCities = (ownerState) => {
    statesService
      .getCities(ownerState ? ownerState : watch('ownerState'))
      .then((response) => {
        if (response.status === 200) {
          setOwnerCitiesOptions(response?.data?.cities);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
    return true;
  };

  const appendFile = (rawData) => {
    let data = { ...rawData };

    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    if (circulationCard) {
      formData.append('circulation-card', circulationCard);
    }
    formData.append('armored', watch('armorType') ? 'true' : 'false');
    return formData;
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDrop1 });

  const getSalesVehicleClasses = async () => {
    vehicleService
      .getSalesVehicleClasses()
      .then((response) => {
        if (response.status === 200) {

          let clases = response?.data?.classes

          if (selectedMembership?.code === planTypes?.MOTORIDERAV) {
            clases = clases.filter(c => c.name === "MOTO")
          }
          setVehicleClassesOptions(clases);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };
  const getArmorTypes = () => {
    armorTypesService
      .getArmorTypes()
      .then((response) => {
        if (response.status === 200) {
          setArmorTypeOptions(response?.data);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const preloadValues = () => {
    let vehicleData = localStorage.getItem('vehicle');
    vehicleData = JSON.parse(vehicleData);
    setValue('code', selectedMembership?.code);


    if (vehicleData) {
      setValue('year', vehicleData?.year);
      setValue('bodySerial', vehicleData?.bodySerial);
      setValue('color', vehicleData?.color);
      setValue('plate', vehicleData?.plate);
      setValue('bodySerial', vehicleData?.bodySerial);
      setValue('km', vehicleData?.km);

      Promise.allSettled([
        getVehicleTypes(vehicleData?.class),
        getSalesVehicleClasses(),
        getVehicleBrands(),
        getVehicleModels(vehicleData?.brand),
        getArmorTypes(),
      ]).then((r) => {
        setTimeout(() => {
          setValue('armorType', vehicleData?.armorType);
          setValue('class', vehicleData?.class);
          setValue('type', vehicleData?.type);
          setValue('brand', vehicleData?.brand);
          setValue('model', vehicleData?.model);
          setValue('type', vehicleData?.type);
        }, 500);
      });

      if (vehicleData.customerIsOwner) {
        setValue('customerIsOwner', true);
        setcustomerIsOwner('SI');
      } else {
        setValue('customerIsOwner', false);
        setcustomerIsOwner('NO');
        setValue('ownerFirstName', vehicleData?.ownerFirstName);
        setValue('ownerLastName', vehicleData?.ownerLastName);
        setValue(
          'ownerIdentificationType',
          vehicleData?.ownerIdentificationType
        );
        setValue('ownerIdentification', vehicleData?.ownerIdentification);
        setValue('ownerAddress', vehicleData?.ownerAddress);

        Promise.allSettled([
          getStates(),
          getCities(vehicleData?.ownerState),
        ]).then((r) => {
          setTimeout(() => {
            setValue('ownerState', vehicleData?.ownerState);
            setValue('ownerCity', vehicleData?.ownerCity);
          }, 500);
        });
      }
    } else {
      setValue('year', vehicle?.year);
      setValue('customerIsOwner', true);
      Promise.allSettled([getSalesVehicleClasses(), getArmorTypes()]).then(
        (r) => {
          setTimeout(() => {
            setValue('armorType', vehicle?.armorType);
            setValue('class', vehicle?.class);
            setValue('type', vehicle?.type);
          }, 500);
        }
      );
    }
  };

  const getVehicleTypes = (clase) => {
    vehicleService
      .getVehicleTypes({ class: clase ? clase : watch('class') })
      .then((response) => {
        if (response.status === 200) {
          setTypesOptions(response?.data?.types);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const getVehicleBrands = () => {
    vehicleService
      .getVehicleBrands()
      .then((response) => {
        if (response.status === 200) {
          setVehicleBrandOptions(response?.data?.vehicleBrands);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const getMunicipalities = (city) => {
    statesService
      .getMunicipalities(city ? city : watch('city'))
      .then((response) => {
        if (response.status === 200) {
          setMunicipalitiesOptions(response?.data?.municipalities);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
    return true;
  };

  const getVehicleModels = (vehicleBrand) => {
    vehicleService
      .getVehicleModels(vehicleBrand ? vehicleBrand : watch('vehicleBrand'))
      .then((response) => {
        if (response.status === 200) {
          setVehicleModelOptions(response?.data?.models);
        } else {
          notify(`${response.message}`, 'error');
        }
      });
  };

  const handlecustomerIsOwner = (e) => {
    if (e?.target?.value === 'SI') {
      setValue('customerIsOwner', true);
      setcustomerIsOwner(e?.target?.value);
      clearTitularForm();
    }
    if (e?.target?.value === 'NO') {
      setValue('customerIsOwner', false);
      setcustomerIsOwner(e?.target?.value);
      clearTitularForm();
    }
  };

  const clearTitularForm = () => {
    setValue('ownerFirstName', '');
    setValue('ownerLastName', '');
    setValue('ownerState', '');
    setValue('ownerCity', '');
    setValue('ownerAddress', '');
    setValue('ownerIdentificationType', '');
    setValue('ownerIdentification', '');
  };

  useEffect(() => {
    getEconomicActivities()
    getStates();
    getVehicleBrands();
    preloadValues();
  }, []);

  return (
    <>
      <LoadSpinner show={isLoading} />
      <div className="landing-form-container">
        <h5>Información del Vehículo</h5>
        <p>(*) Campos obligatorios</p>
        <br />
        <form>
          <div className="row">
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Clase *'}
              type={'select'}
              name={'class'}
              register={register}
              placeholder={''}
              options={vehicleClassesOptions}
              onChange={(e) => getVehicleTypes(watch('class'))}
              errors={errors?.class?.message}
            />
            {watch("code") !== planTypes?.PLUSF4 &&
              <Input
                bootstrap={'col-12 col-md-6'}
                label={'Tipo *'}
                type={'select'}
                name={'type'}
                register={register}
                placeholder={''}
                options={typesOptions}
                errors={errors?.type?.message}
                disabled={!watch('class')}
              />
            }
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Placa *'}
              type={'text'}
              name={'plate'}
              register={register}
              placeholder={''}
              errors={errors?.plate?.message}
              onChange={(e) => removeBlankSpacesAndUppercase(watch('plate'), 'plate', setValue)}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Marca *'}
              type={'select'}
              options={vehicleBrandOptions}
              name={'brand'}
              register={register}
              placeholder={''}
              errors={errors?.brand?.message}
              onChange={() => getVehicleModels(watch('brand'))}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Modelo *'}
              type={'select'}
              name={'model'}
              register={register}
              placeholder={''}
              options={vehicleModelOptions}
              errors={errors?.model?.message}
              disabled={!watch('brand')}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Color *'}
              type={'text'}
              name={'color'}
              register={register}
              placeholder={''}
              errors={errors?.color?.message}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Año *'}
              type={'number'}
              name={'year'}
              register={register}
              placeholder={''}
              control={control}
              errors={errors?.year?.message}
            />
            {watch("code") !== planTypes?.PLUSF4 &&
              <>

                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Serial Carrocería* (en el carnet de circulación)'}
                  type={'text'}
                  name={'bodySerial'}
                  register={register}
                  placeholder={''}
                  errors={errors?.bodySerial?.message}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Kilometraje del vehículo'}
                  type={'number'}
                  name={'km'}
                  register={register}
                  placeholder={''}
                  errors={errors?.km?.message}
                  min={'0'}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Blindaje'}
                  type={'select'}
                  options={armorTypeOptions}
                  name={'armorType'}
                  register={register}
                  errors={errors?.armorType?.message}
                />{' '}
              </>
            }
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Estado *'}
              type={'select'}
              options={statesOptions}
              name={'state'}
              placeholder={'Seleccione'}
              register={register}
              errors={errors?.state?.message}
              onChange={(e) => getCities(watch('state'))}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Ciudad *'}
              type={'select'}
              options={citiesOptions}
              name={'city'}
              placeholder={'Seleccione'}
              register={register}
              errors={errors?.city?.message}
              onChange={(e) => getMunicipalities()}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Municipio *'}
              type={'select'}
              options={municipalitiesOptions}
              name={'municipality'}
              placeholder={'Seleccione'}
              register={register}
              errors={errors?.municipality?.message}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Dirección*'}
              type={'textarea'}
              name={'address'}
              placeholder={
                'Ejemplo: Chacao Av. Blandín, con Calle Santa Teresa de Jesús, C.C. San Ignacio, Sotano 1'
              }
              register={register}
              errors={errors?.address?.message}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Punto de referencia *'}
              type={'textarea'}
              name={'addressReference'}
              placeholder={
                'Ejemplo: Edificio blanco frente a la bomba de gasolina'
              }
              register={register}
              errors={errors?.addressReference?.message}
            />
            {watch("code") !== planTypes?.PLUSF4 && <>
              <div className="col-12 col-md-6 checbox-input-container">
                <label htmlFor="">¿Eres el titular del vehículo?</label>
                <div className="checbox-input-container-midle">
                  <div className="topping">
                    <input
                      type="checkbox"
                      id="topping"
                      name="customerIsOwner"
                      value={'SI'}
                      checked={customerIsOwner === 'SI'}
                      onChange={handlecustomerIsOwner}
                    />
                    Si
                  </div>{' '}
                  <div className="topping">
                    <input
                      type="checkbox"
                      id="topping"
                      name="customerIsOwner"
                      value={'NO'}
                      checked={customerIsOwner === 'NO'}
                      onChange={handlecustomerIsOwner}
                    />
                    No
                  </div>
                  <div className="result">
                    {/* Above checkbox is {isChecked ? 'checked' : 'un-checked'}. */}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6"></div>
              {customerIsOwner === 'NO' ? (
                <>
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Nombre del titular *'}
                    type={'text'}
                    name={'ownerFirstName'}
                    register={register}
                    placeholder={''}
                    errors={errors?.ownerFirstName?.message}
                  />
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Apellido del titular *'}
                    type={'text'}
                    name={'ownerLastName'}
                    register={register}
                    placeholder={''}
                    errors={errors?.ownerLastName?.message}
                  />{' '}
                  <Input
                    bootstrap={'col-4 col-sm-2 col-md-2'}
                    label={'C.I / RIF*'}
                    type={'select'}
                    name={'ownerIdentificationType'}
                    register={register}
                    placeholder={''}
                    options={idCodes}
                    errors={errors?.ownerIdentificationType?.message}
                  />{' '}
                  <Input
                    bootstrap={'col-8 col-sm-4 col-md-4'}
                    label={'del titular*'}
                    type={'number'}
                    name={'ownerIdentification'}
                    register={register}
                    placeholder={''}
                    errors={errors?.ownerIdentification?.message}
                  />{' '}
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Estado del titular *'}
                    type={'select'}
                    options={statesOptions}
                    name={'ownerState'}
                    placeholder={'Seleccione'}
                    register={register}
                    errors={errors?.ownerState?.message}
                    onChange={(e) => getOwnerCities(watch('ownerState'))}
                  />{' '}
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Ciudad del titular *'}
                    type={'select'}
                    options={ownercitiesOptions}
                    name={'ownerCity'}
                    placeholder={'Seleccione'}
                    register={register}
                    errors={errors?.ownerCity?.message}
                  />{' '}
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Dirección del titular *'}
                    type={'textarea'}
                    name={'ownerAddress'}
                    placeholder={''}
                    register={register}
                    errors={errors?.ownerAddress?.message}
                  />
                </>
              ) : null}

              {selectedMembership?.rcv !== "N/A" && <>
                <h5 style={{ textAlign: "center", marginTop: "24px", marginBottom: "12px" }}>Información para RCV</h5>
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Serial Motor *'}
                  type={'text'}
                  name={'engineSerial'}
                  register={register}
                  placeholder={''}
                  errors={errors?.engineSerial?.message}
                />
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Profesión/Ocupación *'}
                  type={'text'}
                  name={'profession'}
                  register={register}
                  placeholder={''}
                  errors={errors?.profession?.message}
                />
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Actividad Económica *'}
                  type={'select'}
                  options={economicActivitiesOptions}
                  name={'economicActivity'}
                  register={register}
                  placeholder={''}
                  errors={errors?.economicActivity?.message}
                />
                {validateIfOther(watch, economicActivitiesOptions) &&
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Otra Actividad Económica *'}
                    type={'text'}
                    name={'otherEconomicActivity'}
                    register={register}
                    placeholder={''}
                    errors={errors?.otherEconomicActivity?.message}
                  />
                }
              </>}
              <div className="col-12 col-md-3"></div>
              <div className="col-12 col-md-6">
                {circulationCard ? (
                  <FileComponent
                    file={circulationCard}
                    remove={() => setcirculationCard('')}
                  />
                ) : (
                  <SalesDropzone
                    // instructions={'Adjuntar carnet de circulación*'}
                    instructions={'Adjuntar título de propiedad del vehículo*'}
                    maxWeight={'(JPG, PNG, PDF, máx. 3MB)'}
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    message="Arrastrar o buscar Documento"
                  />
                )}
              </div>
              <div className="col-12 col-md-3"></div>
            </>}

          </div>

          <div
            style={{ margin: '24px 0px 48px 0px' }}
            className="buttons-center-container"
          >
            <AltButton
              label={'Regresar'}
              action={() => setcurentStep(curentStep - 1)}
            />
            <ActionButton label={'Siguiente'} action={onSubmit} />
          </div>
        </form>
      </div>
    </>
  );
};

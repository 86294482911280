import React, { useState, useEffect, useCallback } from 'react';
import { Header } from '../../common/Header/Header';
import { saleChannelService } from '../../../services/saleChannelService';
import { fleetLoadService } from '../../../services/fleetLoadService';
import { notify } from '../../../assets/helpers/toast';
import { capitalize, filterParams } from '../../../assets/helpers/funciones';
import { CreateFleetLoadForm } from '../../common/Forms/Form';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  loadStrategyOptions,
  fleetTypeOptions,
  fleetPaymentTypeOptions,
  financingTypeOptions,
  fleetLoadRCVTypesOptions,
  fleetLoadRCVTypes,
  planTypesOptions as membershipOptions,
  fleetPaymentType,
  loadStrategy,
  fleetType,
  financingType,
} from '../../../assets/helpers/options';
import * as schemas from '../../../assets/schemas/schemas';
import { useDropzone } from 'react-dropzone';
import { planTypes } from "../../../assets/helpers/options";
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import { CSVErrorsModal } from '../../common/Modals/Modals';
import './FleetRegister.scss';

const itemsPerpage = [10, 25, 50, 100, 500];
export const FleetLoadForm = () => {
  const history = useHistory();
  const { fleetId } = useParams();
  const [isLoading, setIsloading] = useState(false);
  const [salesChannelOptions, setSalesChannelOptions] = useState([]);

  const [csvErrorModal, setCsvErrorModal] = useState({
    isOpen: false,
    selected: '',
  });

  const [dataTable, setDataTable] = useState({
    limit: itemsPerpage[2],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    sinceDate: '',
    untilDate: '',
    isLoading: true,
  });

  const {
    register,
    control,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schemas.createFleetLoadSchema()),
  });

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
      };
      reader.readAsArrayBuffer(file);

      if (!['text/csv'].includes(file.type)) {
        notify('Agregar un archivo CSV', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        setFileUploaded(file);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const [fileUploaded, setFileUploaded] = useState('');

  const validateCSV = async (memberships) => {
    const formData = new FormData();

    for (var i = 0; i < memberships?.length; i++) {
      formData.append(`memberships[${i}][membership]`, memberships[i]?.code);
      formData.append(`memberships[${i}][rcv]`, memberships[i]?.rcv);
    }

    formData.append('file', fileUploaded);

    let res = fleetLoadService
      .validateCSV(formData)
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          return true;
        }
        if ([400].includes(response.status)) {
          handleCSVErrorModal(response?.data?.errors);
          return false;
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
    return res;
  };

  const appendFile = (data, memberships) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    formData.append('fleetId', fleetId);
    formData.append('status', 'Creada');
    formData.append('rcvStartDate', watch('planStartDate'));
    formData.append('rcvEndDate', watch('planEndDate'));

    if (data?.loadStrategy === loadStrategy?.ARCHIVO) {
      formData.append('file', fileUploaded);
    }

    for (var i = 0; i < memberships?.length; i++) {
      formData.append(`memberships[${i}][code]`, memberships[i]?.code);
      formData.append(`memberships[${i}][rcv]`, memberships[i]?.rcv);
    }

    return formData;
  };

  const onSubmit = handleSubmit(async (data) => {
    let memberships = [
      { code: 'GOLD', selected: data?.GOLD, rcv: data?.GOLD_RCV },
      { code: 'PLUS', selected: data?.PLUS, rcv: data?.PLUS_RCV },
      { code: 'CLASSIC', selected: data?.CLASSIC, rcv: data?.CLASSIC_RCV },
      {
        code: 'MOTORIDER',
        selected: data?.MOTORIDER,
        rcv: data?.MOTORIDER_RCV,
      },
      {
        code: 'PLUSF4',
        selected: data?.PLUSF4,
        rcv: data?.PLUSF4_RCV,
      },
      {
        code: 'MOTORIDERAV',
        selected: data?.MOTORIDERAV,
        rcv: data?.MOTORIDERAV_RCV,
      },
    ];

    memberships = memberships.filter((m) => m?.selected);
    if (!handleFinancingChange()) {
      return null;
    }

    let dataToPost = { ...data };
    let isFileValid = true;
    delete dataToPost?.GOLD;
    delete dataToPost?.PLUS;
    delete dataToPost?.CLASSIC;
    delete dataToPost?.MOTORIDER;

    delete dataToPost?.GOLD_RCV;
    delete dataToPost?.PLUS_RCV;
    delete dataToPost?.CLASSIC_RCV;
    delete dataToPost?.MOTORIDER_RCV;

    delete dataToPost?.PLUSF4;
    delete dataToPost?.PLUSF4_RCV;
    delete dataToPost?.MOTORIDERAV;
    delete dataToPost?.MOTORIDERAV_RCV;



    if (dataToPost?.loadStrategy === loadStrategy?.ARCHIVO) {
      delete dataToPost?.linkValidityFrom;
      delete dataToPost?.linkValidityTo;

      if (!fileUploaded) {
        notify('Suba un documento CSV de carga de flota', 'info');
        return null;
      } else {
        isFileValid = await validateCSV(memberships);
      }
    }

    let fileTopost = appendFile(filterParams(dataToPost), memberships);

    if (!isLoading && isFileValid) {
      setIsloading(true);
      fleetLoadService
        .createFleetLoad(fileTopost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Carga de flotas realizada con exito`, 'success');
            reset();
            cancelAndRedirect();
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const handleCSVErrorModal = (errors) => {
    setCsvErrorModal((prevState) => {
      return {
        ...prevState,
        isOpen: !csvErrorModal?.isOpen,
        errors: errors ? errors : '',
      };
    });
  };

  const getFleets = () => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    let params = {
      limit: dataTable?.limit,
      offset: dataTable?.offset,
    };

    fleetLoadService
      .getFleetsLoads(params, fleetId)
      .then((response) => {
        if (response.status === 200) {
          setDataTable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.fleetData?.fleetLoads,
              count: response?.data?.total,
              name: response?.data?.fleetData?.name,
              code: response?.data?.fleetData?.code,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setDataTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setDataTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };

  const getSalesChannel = () => {
    saleChannelService
      .getSalesChannel()
      .then((response) => {
        if ([200, 201].includes(response?.status)) {
          setSalesChannelOptions(response?.data?.saleChannels);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const handleCheckboxChange = (e) => {
    setValue(e, '');
    if (e === planTypes?.PLUSF4 + "_RCV" && watch(planTypes?.PLUSF4)) {
      setValue(e, fleetLoadRCVTypes?.N_A);
    }
  };

  const handlePaymentMethod = (e) => {
    if (e === fleetPaymentType?.DE_CONTADO) {
      setValue('financingTerm', '');
    }
  };

  const cancelAndRedirect = () => {
    history.push(`/modules/fleet-register/detail/${fleetId}`);
  };

  const handleFleetChange = () => {
    if (watch('type') === fleetType?.TECHNICAL_FLEET) {
      setValue('loadStrategy', loadStrategy?.ARCHIVO);
    } else {
      setValue('loadStrategy', '');
    }
  };

  const handleFinancingChange = () => {
    let date1 = new Date(watch('planStartDate'));
    let date2 = new Date(watch('planEndDate'));
    let diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let diffMonths = diffDays / 30;

    if (watch('financingTerm') === financingType?.MONTHLY && diffMonths < 1) {
      notify('La vigencia del plan debe ser mayor a un mes', 'info');
      return false;
    }
    if (watch('financingTerm') === financingType?.QUARTER && diffMonths < 3) {
      notify('La vigencia del plan debe ser mayor a 3 meses', 'info');
      return false;
    }
    if (watch('financingTerm') === financingType?.SEMESTER && diffMonths < 6) {
      notify('La vigencia del plan debe ser mayor a 6 meses', 'info');
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    getSalesChannel();
    getFleets();
  }, []);

  return (
    <>
      <Header />
      <CSVErrorsModal modal={csvErrorModal} handleModal={handleCSVErrorModal} />
      <LoadSpinner show={isLoading} />
      <div className="container">
        <div className="card incidence-card">
          <div className="title-center-component">
            <h4>Datos de la Carga</h4>
            <h5>
              {capitalize(dataTable?.name) + '  ' + '(' + dataTable?.code + ')'}
            </h5>
          </div>
          <br />
          <CreateFleetLoadForm
            register={register}
            errors={errors}
            action={onSubmit}
            control={control}
            options={{
              salesChannelOptions: salesChannelOptions,
              loadStrategyOptions: loadStrategyOptions,
              fleetTypeOptions: fleetTypeOptions,
              fleetPaymentTypeOptions: fleetPaymentTypeOptions,
              financingTypeOptions: financingTypeOptions,
              membershipOptions: membershipOptions,
              fleetLoadRCVTypesOptions: fleetLoadRCVTypesOptions,
            }}
            onChange={{
              handleCheckboxChange: handleCheckboxChange,
              handlePaymentMethod: handlePaymentMethod,
              cancelAndRedirect: cancelAndRedirect,
              handleFleetChange: handleFleetChange,
              handleFinancingChange: handleFinancingChange,
            }}
            watch={watch}
            fileUploaded={fileUploaded}
            setFileUploaded={setFileUploaded}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
          />
        </div>
      </div>
    </>
  );
};

import * as yup from 'yup';
import { salesRoles } from '../../assets/helpers/roles';
import {
  giftCardType,
  loadStrategy as loadStrategyEnum,
  fleetPaymentType,
  paymentType as tiposDePago,
  idEnums,
  enumAction,
  planTypes,
} from '../../assets/helpers/options';
import { vintageYear } from '../../assets/helpers/const';

const passwordRegex =
  /^(?:$|(?=.*[A-Z])(?=.*[0-9].*[0-9])(?=.*[!@#$%^&*()\-_+={}|;':",<.>/?`~]).{8,})$/;

const nameRegex =
  /^[a-zA-Z\sáéíóúÁÉÍÓÚñÑàèìòùÀÈÌÒÙãõÃÕâêîôûÂÊÎÔÛäëïöüÄËÏÖÜçÇ.]+$/;

// const nameRegex = /^[a-zA-Z\sáéíóúÁÉÍÓÚñÑ]+$/
const idRegex = /^[0-9]+$/;

// File that contains the validation schemas for all forms

export const LoginSchema = t => {
  return yup.object().shape({
    email: yup
      .string()
      .required('Obligatorio')
      .email(t('schema.invalid', { label: t('form.labels.email') })),
    password: yup
      .string()
      .min(
        3,
        t('schema.min_length', {
          label: t('form.labels.password'),
          characters: 3,
        })
      )
      .max(
        50,
        t('schema.max_length', {
          label: t('form.labels.password'),
          characters: 50,
        })
      )
      .required('Obligatorio'),
  });
};

export const UpdateTrackingSchema = t => {
  return yup.object().shape({
    type: yup.string().required('Obligatorio'),
    message: yup.string().required('Obligatorio'),
    note: yup.string(),
  });
};

export const linkRegisterStep1Schema = () => {
  return yup.object().shape({
    rcv: yup.string()?.required('Obligatorio'),
  });
};

export const landingRegisterStep1Schema = t => {
  return yup.object().shape({
    // year: yup
    //   .number()
    //   .nullable()
    //   .required('Obligatorio')
    //   .min(
    //     vintageYear,
    //     `El año del vehiculo debe ser mayor a ${vintageYear - 1}`
    //   )
    //   .max(new Date().getFullYear() + 1, 'Ingrese un año valido'),
    year: yup
      .string()
      .required('Obliatorio')
      .trim()
      .test('isNumeric', 'Year must be a number', value => {
        const yearNumber = parseInt(value, 10);
        return !isNaN(yearNumber) && yearNumber > 0;
      }),
    class: yup.string().required('Obligatorio'),
    armorType: yup.string().required('Obligatorio'),
    rcv: yup.string().required('Obligatiorio'),
  });
};

export const validateGiftcardSchema = t => {
  return yup.object().shape({
    code: yup
      .string()
      .when('codeType', {
        is: 'Giftcard',
        then: yup
          .string()
          .min(6, 'Ingrese una tarjeta valida')
          .max(6, 'Ingrese una tarjeta valida')
          .required('Obligatorio'),
      })
      .when('codeType', {
        is: 'Descuento',
        then: yup
          .string()
          .min(6, 'Ingrese un código valido')
          .max(6, 'Ingrese un código valido')
          .required('Obligatorio'),
      }),
    codeType: yup.string().required('Obligatorio'),
  });
};

export const validateFreeDiscountSchema = t => {
  return yup.object().shape({
    codeType: yup.string().required('Obligatorio'),
    code: yup
      .string()
      .when('codeType', {
        is: 'Giftcard',
        then: yup
          .string()
          .min(6, 'Ingrese una tarjeta valida')
          .max(6, 'Ingrese una tarjeta valida')
          .required('Obligatorio'),
      })
      .when('codeType', {
        is: 'Descuento',
        then: yup
          .string()
          .min(6, 'Ingrese un código valido')
          .max(6, 'Ingrese un código valido')
          .required('Obligatorio'),
      }),
  });
};

export const filterCasesSchema = t => {
  return yup.object().shape({
    sinceDate: yup.string().required('Obligatorio'),
    untilDate: yup.string().required('Obligatorio'),
  });
};

export const filterExportableSchema = t => {
  return yup.object().shape({
    sinceDate: yup.string().required('Obligatorio'),
    untilDate: yup.string().required('Obligatorio'),
  });
};

export const filterRenewalMembership = t => {
  return yup.object().shape({
    sinceMonth: yup.string().required('Obligatorio'),
    sinceYear: yup.string().required('Obligatorio'),
    untilMonth: yup.string().required('Obligatorio'),
    untilYear: yup.string().required('Obligatorio'),
  });
};

export const autorenovationSearchSchema = t => {
  return yup.object().shape({
    code: yup.string().required('Obligatorio'),
    rcv: yup.string().required('Obligatorio'),
    codeType: yup.string().optional(''),
    codigo: yup.string().max(6, 'Ingrese un código valido').optional(''),
    discountId: yup.string().max(6, 'Ingrese un código valido').optional(''),
    useExtendedTow: yup.boolean().optional(),
  });
};

export const ActivateGiftcardSchema = t => {
  return yup.object().shape({
    saleChannel: yup.string().required('Obligatorio'),
    saleType: yup.string().required('Obligatorio'),
  });
};

export const CancelServiceSchema = t => {
  return yup.object().shape({
    cancelationReason: yup.string().required('Obligatorio'),
    cancelationReasonDescription: yup.string().required('Obligatorio'),
  });
};

export const CancelMembershipSchema = t => {
  return yup.object().shape({
    annullationReason: yup.string().required('Obligatorio'),
  });
};

export const CreateFleetSchema = t => {
  return yup.object().shape({
    code: yup.string().required('Obligatorio'),
    name: yup.string().required('Obligatorio'),
    status: yup.boolean().required('Obligatorio'),
  });
};

export const CreateDiscountCodeSchema = () => {
  return yup.object().shape({
    code: yup
      .string()
      .required('Obligatorio')
      .min(6, 'El codigo debe tener min 6 caracteres')
      .max(60, 'El codigo debe tener 60 caracteres'),
    campaign: yup.string().optional(''),
    channel: yup.string().required('Obligatorio'),
    status: yup.string().required('Obligatorio'),
    discountNumber: yup.number().required('Obligatorio'),
    discountType: yup.string().required('Obligatorio'),
    appliesToRenewal: yup.boolean().required('Obligatorio'),
    appliesToNew: yup.boolean().required('Obligatorio'),
    validFrom: yup.string().required('Obligatorio'),
    fromTime: yup.string().required('Obligatorio'),
    validTo: yup.string().required('Obligatorio'),
    toTime: yup.string().required('Obligatorio'),
    quantity: yup.string().optional(''),
  });
};

export const EditDiscountCodeSchema = () => {
  return yup.object().shape({
    code: yup.string().required('Obligatorio'),
    campaign: yup.string().optional(''),
    channel: yup.string().required('Obligatorio'),
    status: yup.string().required('Obligatorio'),
    discountNumber: yup.number().required('Obligatorio'),
    discountType: yup.string().required('Obligatorio'),
    appliesToRenewal: yup.boolean().required('Obligatorio'),
    appliesToNew: yup.boolean().required('Obligatorio'),
    validFrom: yup.string().required('Obligatorio'),
    fromTime: yup.string().required('Obligatorio'),
    validTo: yup.string().required('Obligatorio'),
    toTime: yup.string().required('Obligatorio'),
    quantity: yup.string().optional(''),
  });
};

export const ChangeOperatorSchema = t => {
  return yup.object().shape({
    user: yup.string().required('Obligatorio'),
  });
};

export const FinishServiceSchema = t => {
  return yup.object().shape({
    providerFailureReason: yup.string().required('Obligatorio'),
    providerFailureDescription: yup.string().required('Obligatorio'),
  });
};

export const CreateProviderSchema = t => {
  return yup.object().shape({
    companyName: yup.string().required('Obligatorio'),
    contactName: yup.string().required('Obligatorio'),
    identification: yup.string().required('Obligatorio'),
    identificationType: yup.string().required('Obligatorio'),
    phonePrefix: yup.string().required('Obligatorio'),
    phoneNumber: yup
      .string()
      .min(7, 'Ingrese un telefono valido')
      .max(7, 'Ingrese un telefono valido')
      .required('Obligatorio'),
    type: yup.string().required('Obligatorio'),
    state: yup.string().required('Obligatorio'),
    isInternal: yup.boolean().required('Obligatorio'),
    isActive: yup.boolean().required('Obligatorio'),
  });
};

export const FleetLoadSchema = t => {
  return yup.object().shape({
    name1: yup.string(),
    name2: yup.string(),
    name3: yup.string(),
  });
};

export const CreateVehicularAssetSchema = t => {
  return yup.object().shape({
    class: yup.string().required('Obligatorio'),
    brand: yup.string().required('Obligatorio'),
    model: yup.string().required('Obligatorio'),
    plate: yup.string().required('Obligatorio'),
    color: yup.string().required('Obligatorio'),
    year: yup.string().required('Obligatorio'),
    purchaseDate: yup.string().required('Obligatorio'),
    description: yup.string(),
  });
};

export const CreateIncidenceSchema = t => {
  return yup.object().shape({
    originLocationStatus: yup.string().required('Obligatorio'),

    isOriginBasement: yup.boolean().required('Obligatorio'),
    basementLevel: yup.string().when('isOriginBasement', {
      is: true,
      then: yup.string().required('Obligatorio'),
    }),
    originState: yup.string().required('Obligatorio'),
    originCity: yup.string().required('Obligatorio'),
    originAddress: yup.string().required('Obligatorio'),

    contactNumberPrefix: yup.string().required('Obligatorio'),
    contactNumber: yup
      .string()
      .required('Obligatorio')
      .min(7, 'Ingrese un telefono valido')
      .max(7, 'Ingrese un telefono valido'),
    customerFailureReason: yup.string().required('Obligatorio'),
    originReferencePoint: yup.string(),
    customerFailureDescription: yup.string(),
    withOutDestinyAddress: yup.boolean(),
    destinyLocationStatus: yup.string().when('withOutDestinyAddress', {
      is: false,
      then: yup.string().required('Obligatorio'),
    }),
    destinyState: yup.string().when('withOutDestinyAddress', {
      is: false,
      then: yup.string().required('Obligatorio'),
    }),
    destinyCity: yup.string().when('withOutDestinyAddress', {
      is: false,
      then: yup.string().required('Obligatorio'),
    }),
    destinyAddress: yup.string().when('withOutDestinyAddress', {
      is: false,
      then: yup.string().required('Obligatorio'),
    }),
    caseKm: yup.string().when('withOutDestinyAddress', {
      is: false,
      then: yup.string().required('Debe calcular la distancia'),
    }),
    estimatedTime: yup.string().when('withOutDestinyAddress', {
      is: false,
      then: yup.string(),
    }),
  });
};

export const CreateUsersSchema = () => {
  return yup.object().shape({
    firstName: yup
      .string()
      .matches(nameRegex, 'Ingrese un nombre valido')
      .required('Obligatorio'),
    lastName: yup
      .string()
      .matches(nameRegex, 'Ingrese un apellido valido')
      .required('Obligatorio'),
    identification: yup.string().required('Obligatorio'),
    email: yup.string().email().required('Obligatorio'),
    password: yup
      .string()
      .trim()
      .matches(passwordRegex, 'Contraseña en formato invalido')
      .required('Obligatorio'),
    confirmPassword: yup
      .string()
      .matches(passwordRegex, 'Contraseña en formato invalido')
      .oneOf(
        [yup.ref('password'), null],
        'Contraseña  y confirmación deben coincidir'
      )
      .required(''),
    role: yup.string().required('Obligatorio'),
    saleChannel: yup.string().required('Obligatorio'),
    module: yup.string().required('Obligatorio'),
    status: yup.string().required('Obligatorio'),
    type: yup.string().when('role', {
      is: salesRoles?.SALES_COUNTER,
      then: yup.string().required('Obligatorio'),
    }),
  });
};

export const EditUsersSchema = () => {
  return yup.object().shape({
    firstName: yup.string().optional(''),
    lastName: yup.string().optional(''),
    identification: yup.string().optional(''),
    email: yup.string().email().optional(''),
    saleChannel: yup.string().required('Obligatorio'),
    password: yup
      .string()
      .trim()
      .matches(passwordRegex, 'Contraseña en formato invalido'),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        'Contraseña  y confirmación deben coincidir'
      ),
    status: yup.string().optional(''),
  });
};

export const CreateAVFormSchema = t => {
  return yup.object().shape({
    contact: yup.string(),
    vehicleYear: yup.string(),
    vehicleBrand: yup.string(),
    vehicleModel: yup.string(),
    vehiclePlate: yup.string(),
    vehicleColor: yup.string(),
    estimatedTime: yup.string(),
    pvp: yup.number().required('Obligatorio'),
    price: yup.number().required('Obligatorio'),
    paymentMethods: yup.number().when('pvp', {
      is: pvp => pvp > 0,
      then: yup
        .number('Obligatorio')
        .min(1, 'Ingrese almenos un metodo de pago')
        .max(5, 'Ha superado el limite de métodos  de pago')
        .required('Obligatorio'),
    }),
    method0: yup.string().when('paymentMethods', {
      is: paymentMethods => paymentMethods > 0,
      then: yup.string().required('Obligatorio'),
    }),
    reference0: yup.string().when('paymentMethods', {
      is: paymentMethods => paymentMethods > 0,
      then: yup.string().required('Obligatorio'),
    }),
    amount0: yup.string().when('paymentMethods', {
      is: paymentMethods => paymentMethods > 0,
      then: yup.string().required('Obligatorio'),
    }),
    amountBsS0: yup.string().when('paymentMethods', {
      is: paymentMethods => paymentMethods > 0,
      then: yup.string().required('Obligatorio'),
    }),
  });
};
export const ScheduleServiceSchema = t => {
  return yup.object().shape({
    date: yup.string().required('Obligatorio'),
    time: yup.string().required('Obligatorio'),
  });
};

export const payTowServiceModal = t => {
  return yup.object().shape({
    contact: yup.string(),
    vehicleYear: yup.string(),
    vehicleBrand: yup.string(),
    vehicleModel: yup.string(),
    vehiclePlate: yup.string(),
    vehicleColor: yup.string(),
    pvp: yup.number().required('Obligatorio'),
    price: yup.number().required('Obligatorio'),
    provider: yup.string().required('Obligatorio'),
    paymentMethods: yup.number().when('pvp', {
      is: pvp => pvp > 0,
      then: yup
        .number('Obligatorio')
        .min(1, 'Ingrese almenos un metodo de pago')
        .max(5, 'Ha superado el limite de métodos  de pago')
        .required('Obligatorio'),
    }),
    method0: yup.string().when('paymentMethods', {
      is: paymentMethods => paymentMethods > 0,
      then: yup.string().required('Obligatorio'),
    }),
    reference0: yup.string().when('paymentMethods', {
      is: paymentMethods => paymentMethods > 0,
      then: yup.string().required('Obligatorio'),
    }),
    amount0: yup.string().when('paymentMethods', {
      is: paymentMethods => paymentMethods > 0,
      then: yup.string().required('Obligatorio'),
    }),
    amountBsS0: yup.string().when('paymentMethods', {
      is: paymentMethods => paymentMethods > 0,
      then: yup.string().required('Obligatorio'),
    }),
  });
};

export const CreateParticularCustomer = t => {
  return yup.object().shape({
    firstName: yup
      .string()
      .matches(nameRegex, 'Ingrese un nombre valido')
      .required('Obligatorio'),
    lastName: yup
      .string()
      .matches(nameRegex, 'Ingrese un apellido valido')
      .required('Obligatorio'),
    phonePrefix: yup.string().required('Obligatorio'),
    phoneNumber: yup
      .string()
      .required('Obligatorio')
      .min(7, 'Ingrese un telefono valido')
      .max(7, 'Ingrese un telefono valido'),
    vehicleBrand: yup.string().required('Obligatorio'),
    vehicleModel: yup.string().required('Obligatorio'),
    vehicleYear: yup
      .number()
      .min(1940, 'El año del vehiculo debe ser mayor a 1940')
      .max(new Date().getFullYear() + 1, 'Ingrese un año valido')
      .required('Obligatorio'),
    vehicleColor: yup.string().required('Obligatorio'),
    vehiclePlate: yup.string().required('Obligatorio'),
  });
};

export const chargeSchema = t => {
  return yup.object().shape({
    name1: yup.string(),
    name2: yup.string(),
    name3: yup.string(),
    name4: yup.string(),
    name5: yup.string(),
    name6: yup.string(),
    name7: yup.string(),
    name8: yup.string(),
    name9: yup.string(),
  });
};

export const NewInternalProviderSchema = t => {
  return yup.object().shape({
    contactName: yup.string().required('Obligatorio'),
    identification: yup
      .string()
      .min(6, 'Ingrese una identificacion valida')
      .when('identificationType', {
        is: idEnums?.V,
        then: yup.string().max(8, 'Ingrese una identificacion valida'),
      })
      .required('Obligatorio'),
    identificationType: yup.string().required('Obligatorio'),
    phonePrefix: yup.string().required('Obligatorio'),
    phoneNumber: yup
      .string()
      .min(7, 'Ingrese un telefono valido')
      .max(7, 'Ingrese un telefono valido')
      .required('Obligatorio'),
    type: yup.string().required('Obligatorio'),
    state: yup.string().required('Obligatorio'),
    isInternal: yup.boolean().required('Obligatorio'),
    isActive: yup.boolean().required('Obligatorio'),
  });
};

export const RegisterSchema = t => {
  return yup.object().shape({
    firstName: yup
      .string()
      .matches(nameRegex, 'Ingrese un nombre valido')
      .required('Obligatiorio'),
    lastName: yup
      .string()
      .matches(nameRegex, 'Ingrese un apellido valido')
      .required('Obligatiorio'),
    email: yup
      .string()
      .required(t('schema.required', { label: t('form.labels.email') }))
      .email(t('schema.invalid', { label: t('form.labels.email') })),
    password: yup
      .string()
      .min(
        3,
        t('schema.min_length', {
          label: t('form.labels.password'),
          characters: 3,
        })
      )
      .max(
        50,
        t('schema.max_length', {
          label: t('form.labels.password'),
          characters: 50,
        })
      )
      .required(t('schema.required', { label: t('from.labels.password') })),
    confirmPassword: yup.string().oneOf([yup.ref('password'), 'as']),
  });
};

export const CreateEmployeeSchema = t => {
  return yup.object().shape({
    firstName: yup
      .string()
      .matches(nameRegex, 'Ingrese un nombre valido')
      .required('Obligatiorio'),
    lastName: yup
      .string()
      .matches(nameRegex, 'Ingrese un apellido valido')
      .required('Obligatiorio'),
    email: yup
      .string()
      .required(t('Obligatorio', { label: 'Obligatorio' }))
      .email('Formato invalido'),
    phone: yup.string().required('Obligatorio'),
    baseSalary: yup.number().required('Obligatorio'),
    cryptoAddress: yup.string().required('Obligatorio'),
  });
};

export const RegisterStepTwoSchema = t => {
  return yup.object().shape({
    companyName: yup.string().required('Obligatorio'),
    companySector: yup.string().required('Obligatorio'),
  });
};

export const CreatePayrollSchema = t => {
  return yup.object().shape({
    name: yup.string().required('Obligatorio'),
    description: yup.string().required('Obligatorio'),
  });
};

// Ventas

export const CreateCustomerSchema = () => {
  return yup.object().shape({
    firstName: yup
      .string()
      .matches(nameRegex, 'Ingrese un nombre valido')
      .required('Obligatorio'),
    lastName: yup.string().when('identificationType', {
      is: !idEnums?.J,
      then: yup
        .string()
        .matches(nameRegex, 'Ingrese un apellido valido')
        .max(8, 'Ingrese una identificacion valida')
        .required('Obligatorio'),
    }),
    phonePrefix: yup.string().required('Obligatorio'),
    phoneNumber: yup
      .string()
      .min(7, 'Ingrese un telefono valido')
      .max(7, 'Ingrese un telefono valido')
      .required('Obligatorio'),
    email: yup.string().required('Obligatorio').email('Formato invalido'),
    optionalEmail: yup.string().optional('').email('Formato invalido'),
    identificationType: yup.string().required('Obligatorio'),
    identification: yup
      .string()
      .min(6, 'Ingrese una identificacion valida')
      .when('identificationType', {
        is: idEnums?.V,
        then: yup
          .string()
          .matches(idRegex, 'Ingrese una identificacion valida')
          .max(8, 'Ingrese una identificacion valida'),
      })
      .when('identificationType', {
        is: identificationType =>
          [idEnums?.J, idEnums?.E, idEnums?.P].includes(identificationType),
        then: yup.string(),
      })
      .required('Obligatorio'),
    birthday: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .when('identificationType', {
        is: identificationType =>
          ![idEnums?.J, idEnums?.R, idEnums?.G].includes(identificationType),
        then: yup.string().required('Obligatorio'),
      }),
    gender: yup.string().when('identificationType', {
      is: identificationType =>
        ![idEnums?.J, idEnums?.R, idEnums?.G].includes(identificationType),
      then: yup.string().required('Obligatorio'),
    }),
    maritalStatus: yup.string().when('identificationType', {
      is: identificationType =>
        ![idEnums?.J, idEnums?.R, idEnums?.G].includes(identificationType),
      then: yup.string().required('Obligatorio'),
    }),
    broadcastChannel: yup.string().required('Obligatorio'),
  });
};

export const CreateCustomerLinkSchema = () => {
  return yup.object().shape({
    code: yup.string().optional('Obligatorio'),
    firstName: yup
      .string()
      .matches(nameRegex, 'Ingrese un nombre valido')
      .required('Obligatorio'),
    lastName: yup
      .string()
      .matches(nameRegex, 'Ingrese un apellido valido')
      .required('Obligatorio'),
    phonePrefix: yup.string().required('Obligatorio'),
    phoneNumber: yup
      .string()
      .min(7, 'Ingrese un telefono valido')
      .max(7, 'Ingrese un telefono valido')
      .required('Obligatorio'),
    email: yup.string().required('Obligatorio').email('Formato invalido'),
    optionalEmail: yup.string().optional('').email('Formato invalido'),
    identificationType: yup.string().required('Obligatorio'),
    identification: yup
      .string()
      .min(6, 'Ingrese una identificacion valida')
      .when('identificationType', {
        is: idEnums?.V,
        then: yup.string().max(8, 'Ingrese una identificacion valida'),
      })
      .required('Obligatorio'),
    birthday: yup.string().when(['identificationType', 'code'], {
      is: (identificationType, code) =>
        ![idEnums?.J, idEnums?.R, idEnums?.G].includes(identificationType) &&
        code !== planTypes?.PLUSF4,
      then: yup
        .string()
        .required('Obligatorio')
        .nullable()
        .transform((v, o) => (o === '' ? null : v)),
    }),
    gender: yup.string().when(['identificationType', 'code'], {
      is: (identificationType, code) =>
        ![idEnums?.J, idEnums?.R, idEnums?.G].includes(identificationType) &&
        code !== planTypes?.PLUSF4,
      then: yup.string().required('Obligatorio'),
    }),
    maritalStatus: yup.string().when(['identificationType', 'code'], {
      is: (identificationType, code) =>
        ![idEnums?.J, idEnums?.R, idEnums?.G].includes(identificationType) &&
        code !== planTypes?.PLUSF4,
      then: yup.string().required('Obligatorio'),
    }),
    broadcastChannel: yup.string().when(['code'], {
      is: code => code !== planTypes?.PLUSF4,
      then: yup.string().required('Obligatorio'),
    }),
  });
};

export const CreateFreeCustomerSchema = () => {
  return yup.object().shape({
    firstName: yup
      .string()
      .matches(nameRegex, 'Ingrese un nombre valido')
      .required('Obligatorio'),
    lastName: yup
      .string()
      .matches(nameRegex, 'Ingrese un apellido valido')
      .required('Obligatorio'),
    phonePrefix: yup.string().required('Obligatorio'),
    phoneNumber: yup
      .string()
      .min(7, 'Ingrese un telefono valido')
      .max(7, 'Ingrese un telefono valido')
      .required('Obligatorio'),
    email: yup.string().required('Obligatorio').email('Formato invalido'),
    identificationType: yup.string().required('Obligatorio'),
    identification: yup
      .string()
      .min(6, 'Ingrese una identificacion valida')
      .required('Obligatorio'),
  });
};

export const RegisterVehicleSchema = () => {
  return yup.object().shape({
    // New Vehicle Address
    state: yup.string().required('Obligatorio'),
    city: yup.string().required('Obligatorio'),
    municipality: yup.string().required('Obligatorio'),
    address: yup.string().required('Obligatorio'),
    addressReference: yup.string().required('Obligatorio'),
    // Vehicle Info
    class: yup.string().required('Obligatorio'),
    type: yup.string().required('Obligatorio'),
    plate: yup.string().required('Obligatorio'),
    brand: yup.string().required('Obligatorio'),
    model: yup.string().required('Obligatorio'),
    color: yup.string().required('Obligatorio'),
    year: yup
      .number()
      .required('Obligatorio')
      .max(new Date().getFullYear() + 1, 'Ingrese un año valido'),
    bodySerial: yup.string().required('Obligatorio'),
    km: yup.string(),
    armorType: yup.string(),
    customerIsOwner: yup.string().required('Obligatorio'),
    ownerFirstName: yup.string().when('customerIsOwner', {
      is: false || 'false',
      then: yup.string().required('Obligatorio'),
    }),
    ownerLastName: yup.string().when('customerIsOwner', {
      is: false || 'false',
      then: yup.string().required('Obligatorio'),
    }),
    ownerState: yup.string().when('customerIsOwner', {
      is: false || 'false',
      then: yup.string().required('Obligatorio'),
    }),
    ownerCity: yup.string().when('customerIsOwner', {
      is: false || 'false',
      then: yup.string().required('Obligatorio'),
    }),
    ownerAddress: yup.string().when('customerIsOwner', {
      is: false || 'false',
      then: yup.string().required('Obligatorio'),
    }),
    ownerIdentificationType: yup.string().when('customerIsOwner', {
      is: false || 'false',
      then: yup.string().required('Obligatorio'),
    }),
    ownerIdentification: yup.string().when('customerIsOwner', {
      is: false || 'false',
      then: yup.string().required('Obligatorio'),
    }),
  });
};

export const RegisterLinkVehicleSchema = () => {
  return yup.object().shape({
    // New Vehicle Address
    code: yup.string().optional('Obligatorio'),
    state: yup.string().required('Obligatorio'),
    city: yup.string().required('Obligatorio'),
    municipality: yup.string().required('Obligatorio'),
    address: yup.string().required('Obligatorio'),
    addressReference: yup.string().required('Obligatorio'),
    // Vehicle Info
    class: yup.string().required('Obligatorio'),
    type: yup.string().when(['code'], {
      is: code => code !== planTypes?.PLUSF4,
      then: yup.string().required('Obligatorio'),
    }),
    plate: yup.string().required('Obligatorio'),
    brand: yup.string().required('Obligatorio'),
    model: yup.string().required('Obligatorio'),
    color: yup.string().required('Obligatorio'),
    year: yup
      .number()
      .required('Obligatorio')
      .max(new Date().getFullYear() + 1, 'Ingrese un año valido'),
    bodySerial: yup.string().when(['code'], {
      is: code => code !== planTypes?.PLUSF4,
      then: yup.string().required('Obligatorio'),
    }),
    km: yup.string().optional('Optional'),
    armorType: yup.string().optional('Optional'),
    customerIsOwner: yup.string().when(['code'], {
      is: code => code !== planTypes?.PLUSF4,
      then: yup.string().required('Obligatorio'),
    }),
    ownerFirstName: yup.string().when(['customerIsOwner', 'code'], {
      is: (customerIsOwner, code) =>
        [false, 'false'].includes(customerIsOwner) &&
        code !== planTypes?.PLUSF4,
      then: yup.string().required('Obligatorio'),
    }),
    ownerLastName: yup.string().when(['customerIsOwner', 'code'], {
      is: (customerIsOwner, code) =>
        [false, 'false'].includes(customerIsOwner) &&
        code !== planTypes?.PLUSF4,
      then: yup.string().required('Obligatorio'),
    }),
    ownerState: yup.string().when(['customerIsOwner', 'code'], {
      is: (customerIsOwner, code) =>
        [false, 'false'].includes(customerIsOwner) &&
        code !== planTypes?.PLUSF4,
      then: yup.string().required('Obligatorio'),
    }),
    ownerCity: yup.string().when(['customerIsOwner', 'code'], {
      is: (customerIsOwner, code) =>
        [false, 'false'].includes(customerIsOwner) &&
        code !== planTypes?.PLUSF4,
      then: yup.string().required('Obligatorio'),
    }),
    ownerAddress: yup.string().when(['customerIsOwner', 'code'], {
      is: (customerIsOwner, code) =>
        [false, 'false'].includes(customerIsOwner) &&
        code !== planTypes?.PLUSF4,
      then: yup.string().required('Obligatorio'),
    }),
    ownerIdentificationType: yup.string().when(['customerIsOwner', 'code'], {
      is: (customerIsOwner, code) =>
        [false, 'false'].includes(customerIsOwner) &&
        code !== planTypes?.PLUSF4,
      then: yup.string().required('Obligatorio'),
    }),
    ownerIdentification: yup.string().when(['customerIsOwner', 'code'], {
      is: (customerIsOwner, code) =>
        [false, 'false'].includes(customerIsOwner) &&
        code !== planTypes?.PLUSF4,
      then: yup.string().required('Obligatorio'),
    }),
  });
};

export const RegisterFreeVehicleSchema = () => {
  return yup.object().shape({
    // New Vehicle Address
    state: yup.string().required('Obligatorio'),
    city: yup.string().required('Obligatorio'),
    municipality: yup.string().required('Obligatorio'),
    address: yup.string().required('Obligatorio'),
    addressReference: yup.string().required('Obligatorio'),
    // Vehicle Info
    class: yup.string().required('Obligatorio'),
    // type: yup.string().required('Obligatorio'),
    plate: yup.string().required('Obligatorio'),
    brand: yup.string().required('Obligatorio'),
    model: yup.string().required('Obligatorio'),
    color: yup.string().required('Obligatorio'),
    year: yup
      .number()
      .required('Obligatorio')
      .max(new Date().getFullYear() + 1, 'Ingrese un año valido'),
    customerIsOwner: yup.string().required('Obligatorio'),
    ownerFirstName: yup.string().when('customerIsOwner', {
      is: false || 'false',
      then: yup.string().required('Obligatorio'),
    }),
    ownerLastName: yup.string().when('customerIsOwner', {
      is: false || 'false',
      then: yup.string().required('Obligatorio'),
    }),
    ownerState: yup.string().when('customerIsOwner', {
      is: false || 'false',
      then: yup.string().required('Obligatorio'),
    }),
    ownerCity: yup.string().when('customerIsOwner', {
      is: false || 'false',
      then: yup.string().required('Obligatorio'),
    }),
    ownerAddress: yup.string().when('customerIsOwner', {
      is: false || 'false',
      then: yup.string().required('Obligatorio'),
    }),
    ownerIdentificationType: yup.string().when('customerIsOwner', {
      is: false || 'false',
      then: yup.string().required('Obligatorio'),
    }),
    ownerIdentification: yup.string().when('customerIsOwner', {
      is: false || 'false',
      then: yup.string().required('Obligatorio'),
    }),
  });
};

export const planPurchase = () => {
  return yup.object().shape({
    paymentType: yup.string().required('Obligatorio'),
    selectedMembership: yup.string().required('Obligatorio'),
    finalPrice: yup.string().required('Obligatorio'),
    paymentMethodsQuantity: yup.string().required('Obligatorio'),
    mix: yup.boolean(),
    paymentOption: yup.string(),
    bank1: yup.string().optional(''),
    phone1: yup.string().optional(''),
    date1: yup.string().optional(''),
    bank2: yup.string().optional(''),
    phone2: yup.string().optional(''),
    date2: yup.string().optional(''),
    amount1: yup.string().when('paymentMethodsQuantity', {
      is: (ownerIdentification, paymentType) =>
        ownerIdentification > 0 && paymentType !== paymentType?.CASHEA,
      then: yup.string().required('Obligatorio'),
    }),
    amountBsS1: yup.string().when('paymentMethodsQuantity', {
      is: (ownerIdentification, paymentType) =>
        ownerIdentification > 0 && paymentType !== paymentType?.CASHEA,
      then: yup.string().required('Obligatorio'),
    }),
    reference1: yup.string().when('paymentMethodsQuantity', {
      is: (ownerIdentification, paymentType) =>
        ownerIdentification > 0 && paymentType !== paymentType?.CASHEA,
      then: yup.string().required('Obligatorio'),
    }),
    referenceCashea1: yup.string().optional(),
    method1: yup.string().when('paymentMethodsQuantity', {
      is: (ownerIdentification, paymentType) => ownerIdentification > 0,
      then: yup.string().required('Obligatorio'),
    }),
    amount2: yup.string().when('paymentMethodsQuantity', {
      is: (ownerIdentification, paymentType) =>
        ownerIdentification > 1 && paymentType !== paymentType?.CASHEA,
      then: yup.string().required('Obligatorio'),
    }),
    amountBsS2: yup.string().when('paymentMethodsQuantity', {
      is: (ownerIdentification, paymentType) =>
        ownerIdentification > 1 && paymentType !== paymentType?.CASHEA,
      then: yup.string().required('Obligatorio'),
    }),
    reference2: yup.string().when('paymentMethodsQuantity', {
      is: (ownerIdentification, paymentType) =>
        ownerIdentification > 1 && paymentType !== paymentType?.CASHEA,
      then: yup.string().required('Obligatorio'),
    }),
    method2: yup.string().when('paymentMethodsQuantity', {
      is: (ownerIdentification, paymentType) =>
        ownerIdentification > 1 && paymentType !== paymentType?.CASHEA,
      then: yup.string().required('Obligatorio'),
    }),
    financialAmountBsS2: yup.string().optional(),
    financialAmount2: yup.string().optional(),
    amountReturned: yup.string().optional(),
    cashbackMethodsQuantity: yup.string().when('amountReturned', {
      is: amountReturned => amountReturned > 0,
      then: yup.string().required('Obligatorio'),
    }),
    returnedMethod1: yup.string().when('cashbackMethodsQuantity', {
      is: cashbackMethodsQuantity => cashbackMethodsQuantity > 0,
      then: yup.string().required('Obligatorio'),
    }),
    returnedAmount1: yup.string().when('cashbackMethodsQuantity', {
      is: cashbackMethodsQuantity => cashbackMethodsQuantity > 0,
      then: yup.string().required('Obligatorio'),
    }),
    returnedAmountBsS1: yup.string().when('cashbackMethodsQuantity', {
      is: cashbackMethodsQuantity => cashbackMethodsQuantity > 0,
      then: yup.string().required('Obligatorio'),
    }),
    // returnedReference1: yup.string().when('cashbackMethodsQuantity', {
    //   is: (cashbackMethodsQuantity) => cashbackMethodsQuantity > 0,
    //   then: yup.string().required('Obligatorio'),
    // }),
    returnedMethod2: yup.string().when('cashbackMethodsQuantity', {
      is: cashbackMethodsQuantity => cashbackMethodsQuantity > 1,
      then: yup.string().required('Obligatorio'),
    }),
    returnedAmount2: yup.string().when('cashbackMethodsQuantity', {
      is: cashbackMethodsQuantity => cashbackMethodsQuantity > 1,
      then: yup.string().required('Obligatorio'),
    }),
    returnedAmountBsS2: yup.string().when('cashbackMethodsQuantity', {
      is: cashbackMethodsQuantity => cashbackMethodsQuantity > 1,
      then: yup.string().required('Obligatorio'),
    }),
    // returnedReference2: yup.string().when('cashbackMethodsQuantity', {
    //   is: (cashbackMethodsQuantity) => cashbackMethodsQuantity > 1,
    //   then: yup.string().required('Obligatorio'),
    // }),
    returnedMethod3: yup.string().when('cashbackMethodsQuantity', {
      is: cashbackMethodsQuantity => cashbackMethodsQuantity > 2,
      then: yup.string().required('Obligatorio'),
    }),
    returnedAmount3: yup.string().when('cashbackMethodsQuantity', {
      is: cashbackMethodsQuantity => cashbackMethodsQuantity > 2,
      then: yup.string().required('Obligatorio'),
    }),
    returnedAmountBsS3: yup.string().when('cashbackMethodsQuantity', {
      is: cashbackMethodsQuantity => cashbackMethodsQuantity > 2,
      then: yup.string().required('Obligatorio'),
    }),
    // returnedReference3: yup.string().when('cashbackMethodsQuantity', {
    //   is: (cashbackMethodsQuantity) => cashbackMethodsQuantity > 2,
    //   then: yup.string().required('Obligatorio'),
    // }),
  });
};

export const orderClientInfoForm = t => {
  return yup.object().shape({
    firstName: yup
      .string()
      .matches(nameRegex, 'Ingrese un nombre valido')
      .required('Obligatorio'),
    lastName: yup
      .string()
      .matches(nameRegex, 'Ingrese un apellido valido')
      .required('Obligatorio'),
    identificationType: yup.string().required('Obligatorio'),
    identification: yup
      .string()
      .min(6, 'Ingrese una identificacion valida')
      .when('identificationType', {
        is: idEnums?.V,
        then: yup
          .string()
          .matches(idRegex, 'Ingrese una identificacion valida')
          .max(8, 'Ingrese una identificacion valida'),
      })
      .when('identificationType', {
        is: identificationType =>
          [idEnums?.J, idEnums?.E, idEnums?.P].includes(identificationType),
        then: yup.string(),
      })
      .required('Obligatorio'),
    gender: yup.string().required('Obligatorio'),
    birthday: yup.string().required('Obligatorio'),
    maritalStatus: yup.string().required('Obligatorio'),
    email: yup.string().required('Obligatorio'),
    optionalEmail: yup.string().optional(),
    phonePrefix: yup.string().required('Obligatorio'),
    phoneNumber: yup.string().required('Obligatorio'),
    optionalPhoneNumber: yup.string().optional(),
    optionalPhonePrefix: yup.string().optional(),
    broadcastChannel: yup.string().required('Obligatorio'),
  });
};

export const handleVehicleSchema = t => {
  return yup.object().shape({
    actionName: yup.string().required('Obligatorio'),
    brandName: yup.string().when('actionName', {
      is: actionName => [enumAction?.createBrand].includes(actionName),
      then: yup.string('Obligatorio').required('Obligatorio'),
    }),
    class0: yup.string().when('actionName', {
      is: actionName => [enumAction?.createBrand].includes(actionName),
      then: yup.string('Obligatorio').required('Obligatorio'),
    }),
    brandId: yup.string().when('actionName', {
      is: actionName => [enumAction?.createModel].includes(actionName),
      then: yup.string('Obligatorio').required('Obligatorio'),
    }),
  });
};

export const handleSaleChannelSchema = t => {
  return yup.object().shape({
    channel: yup.string().required('Obligatorio'),
    shortName: yup.string().required('Obligatorio'),
  });
};

export const upgradeOrderForm = t => {
  return yup.object().shape({
    // Datos del cliente
    firstName: yup
      .string()
      .matches(nameRegex, 'Ingrese un nombre valido')
      .required('Obligatorio'),
    lastName: yup
      .string()
      .matches(nameRegex, 'Ingrese un apellido valido')
      .required('Obligatorio'),
    identificationType: yup.string().required('Obligatorio'),
    identification: yup
      .string()
      .min(6, 'Ingrese una identificacion valida')
      .when('identificationType', {
        is: idEnums?.V,
        then: yup.string().max(8, 'Ingrese una identificacion valida'),
      })
      .required('Obligatorio'),
    email: yup.string().required('Obligatorio'),
    phonePrefix: yup.string().required('Obligatorio'),
    phoneNumber: yup.string().required('Obligatorio'),
    // Datos del plan
    seller: yup.string().required('Obligatorio'),
    previousPlan: yup.string().required('Obligatorio'),
    newCode: yup.string().required('Obligatorio'),
    amount: yup.number().required('Obligatorio'),
    amountBsS: yup.number().required('Obligatorio'),
    // Datos del pago
  });
};

export const orderVehicleInfoForm = t => {
  return yup.object().shape({
    class: yup.string().required('Obligatorio'),
    year: yup.number().required('Obligatorio'),
    km: yup.string().optional(''),
    type: yup.string().required('Obligatorio'),
    plate: yup.string().required('Obligatorio'),
    brand: yup.string().required('Obligatorio'),
    model: yup.string().required('Obligatorio'),
    color: yup.string().required('Obligatorio'),
    bodySerial: yup.string().required('Obligatorio'),
    armored: yup.boolean().required('Obligatorio'),
    ownerFirstName: yup.string().optional(),
    ownerLastName: yup.string().optional(),
    ownerState: yup.string().optional(),
    ownerCity: yup.string().optional(),
    ownerAddress: yup.string().optional(),
    ownerIdentificationType: yup.string().optional(),
    ownerIdentification: yup.string().optional(),
    customerIsOwner: yup.string().required(),
    // Address info
    address: yup.string().required('Obligatorio'),
    addressReference: yup.string().optional(''),
    state: yup.string().required('Obligatorio'),
    city: yup.string().required('Obligatorio'),
    municipality: yup.string().optional(''),
  });
};
export const updateMembershipForm = t => {
  return yup.object().shape({
    affiliationType: yup.string().required('Obligatorio'),
    code: yup.string().required('Obligatorio'),
    seller: yup.string().optional(),
    contractType: yup.string().required('Obligatorio'),
    contactInCampaign: yup.string().optional(),
    campaignAction: yup.string().required('Obligatorio'),
    saleChannel: yup.string().optional(),
    rcv: yup.string().optional(''),
    createdAt: yup
      .string()
      .optional('')
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    beginDate: yup
      .string()
      .optional('')
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    endDate: yup
      .string()
      .optional('')
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    rcvBeginDate: yup
      .string()
      .optional('')
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    rcvEndDate: yup
      .string()
      .optional('')
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
  });
};

export const registerFleetLoadPaymentSchema = t => {
  return yup.object().shape({
    date: yup.string().required('Obligatorio'),
    // method: yup.string().required('Obligatorio'),
    reference: yup.string().required('Obligatorio'),
    dueNumber: yup.number().required('Obligatorio'),
  });
};

export const updatePaymentForm = t => {
  return yup.object().shape({
    paymentType: yup.string(),
    finalPrice: yup.number(),
    finalPriceInBsS: yup.number(),
    discountCode: yup.string(),
    discountByCode: yup.string(),
    paymentMethodsQuantity: yup.number(),
    code: yup.string(),
  });
};

export const editPaymentSchema = () => {
  return yup.object().shape({
    amount: yup.string()?.required('Obligatorio'),
    amountBsS: yup.string()?.required('Obligatorio'),
    method: yup.string()?.required('Obligatorio'),
    reference: yup.string()?.required('Obligatorio'),
    referenceCashea: yup.string().optional(''),
    creationDate: yup.string()?.required('Obligatorio'),
  });
};

export const editCashSchema = () => {
  return yup.object().shape({
    amount: yup.string()?.required('Obligatorio'),
    amountBs: yup.string()?.required('Obligatorio'),
    method: yup.string()?.required('Obligatorio'),
    ref: yup.string()?.required('Obligatorio'),
    date: yup.string()?.required('Obligatorio'),
  });
};

export const changeCustomerModal = t => {
  return yup.object().shape({
    firstName: yup
      .string()
      .matches(nameRegex, 'Ingrese un nombre valido')
      .required('Obligatorio'),
    lastName: yup
      .string()
      .matches(nameRegex, 'Ingrese un apellido valido')
      .required('Obligatorio'),
    phonePrefix: yup.string().required('Obligatorio'),
    phoneNumber: yup
      .string()
      .min(7, 'Ingrese un telefono valido')
      .max(7, 'Ingrese un telefono valido')
      .required('Obligatorio'),
    optionalPhonePrefix: yup.string().optional(''),
    optionalPhoneNumber: yup.string().optional(''),
    email: yup.string().required('Obligatorio').email('Formato invalido'),
    optionalEmail: yup.string().optional('').email('Formato invalido'),
    identificationType: yup.string().required('Obligatorio'),
    identification: yup
      .string()
      .min(6, 'Ingrese una identificacion valida')
      .when('identificationType', {
        is: idEnums?.V,
        then: yup.string().max(8, 'Ingrese una identificacion valida'),
      })
      .required('Obligatorio'),
    birthday: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .when('identificationType', {
        is: identificationType =>
          ![idEnums?.J, idEnums?.R, idEnums?.G].includes(identificationType),
        then: yup.string().required('Obligatorio'),
      }),
    gender: yup.string().when('identificationType', {
      is: identificationType =>
        ![idEnums?.J, idEnums?.R, idEnums?.G].includes(identificationType),
      then: yup.string().required('Obligatorio'),
    }),
    maritalStatus: yup.string().when('identificationType', {
      is: identificationType =>
        ![idEnums?.J, idEnums?.R, idEnums?.G].includes(identificationType),
      then: yup.string().required('Obligatorio'),
    }),
    affiliationType: yup.string().required('Obligatorio'),
  });
};

export const cancelFleetLoad = t => {
  return yup.object().shape({
    annulationReason: yup.string().required('Obligatorio'),
  });
};

export const createFleetLoadSchema = () => {
  return yup.object().shape({
    saleChannel: yup.string().required('Obligatorio'),
    type: yup.string().required('Obligatorio'),
    planStartDate: yup.string().required('Obligatorio'),
    planEndDate: yup.string().required('Obligatorio'),
    paymentMethod: yup.string().required('Obligatorio'),
    financingTerm: yup.string().when('paymentMethod', {
      is: paymentMethod => paymentMethod === fleetPaymentType?.FINANCIADO,
      then: yup.string().required('Obligatorio'),
    }),
    loadStrategy: yup.string().required('Obligatorio'),
    contractPrice: yup.number().min(0).required('Obligatorio'),
    linkValidityFrom: yup.string().when('loadStrategy', {
      is: loadStrategy => loadStrategy === loadStrategyEnum?.LINK,
      then: yup.string().required('Obligatorio'),
    }),
    linkValidityTo: yup.string().when('loadStrategy', {
      is: loadStrategy => loadStrategy === loadStrategyEnum?.LINK,
      then: yup.string().required('Obligatorio'),
    }),
    GOLD: yup.boolean().optional(''),
    PLUS: yup.boolean().optional(''),
    CLASSIC: yup.boolean().optional(''),
    MOTORIDER: yup.boolean().optional(''),

    GOLD_RCV: yup.string().optional(''),
    PLUS_RCV: yup.string().optional(''),
    CLASSIC_RCV: yup.string().optional(''),
    MOTORIDER_RCV: yup.string().optional(''),
  });
};
export const renewFleetLoadSchema = () => {
  return yup.object().shape({
    saleChannel: yup.string().required('Obligatorio'),
    type: yup.string().required('Obligatorio'),
    planStartDate: yup.string().required('Obligatorio'),
    planEndDate: yup.string().required('Obligatorio'),
    paymentMethod: yup.string().required('Obligatorio'),
    financingTerm: yup.string().when('paymentMethod', {
      is: paymentMethod => paymentMethod === fleetPaymentType?.FINANCIADO,
      then: yup.string().required('Obligatorio'),
    }),
    contractPrice: yup.number().min(0).required('Obligatorio'),
    GOLD: yup.boolean().optional(''),
    PLUS: yup.boolean().optional(''),
    CLASSIC: yup.boolean().optional(''),
    MOTORIDER: yup.boolean().optional(''),

    GOLD_RCV: yup.string().optional(''),
    PLUS_RCV: yup.string().optional(''),
    CLASSIC_RCV: yup.string().optional(''),
    MOTORIDER_RCV: yup.string().optional(''),
  });
};

export const rateSchema = () => {
  return yup.object().shape({
    rate: yup.number().min(1).required('Obligatorio'),
  });
};

export const renewMembershipSchema = () => {
  return yup.object().shape({
    paymentType: yup.string().required('Obligatorio'),
    price: yup.number().required('Obligatorio'),
    paymentMethods: yup.number().when('pvp', {
      is: (pvp, paymentType) =>
        pvp > 0 &&
        ![tiposDePago?.COURTESY, tiposDePago?.PENDING].includes(paymentType),
      then: yup
        .number('Obligatorio')
        .min(1, 'Ingrese almenos un metodo de pago')
        .max(2, 'Ha superado el limite de métodos  de pago')
        .required('Obligatorio'),
    }),
    method1: yup.string().when('paymentMethods', {
      is: (paymentMethods, paymentType) =>
        paymentMethods > 0 &&
        ![tiposDePago?.COURTESY, tiposDePago?.PENDING].includes(paymentType),
      then: yup.string().required('Obligatorio'),
    }),
    reference1: yup.string().when('paymentMethods', {
      is: (paymentMethods, paymentType) =>
        paymentMethods > 0 &&
        ![tiposDePago?.COURTESY, tiposDePago?.PENDING].includes(paymentType),
      then: yup.string().required('Obligatorio'),
    }),
    amount1: yup.string().when('paymentMethods', {
      is: (paymentMethods, paymentType) =>
        paymentMethods > 0 &&
        ![tiposDePago?.COURTESY, tiposDePago?.PENDING].includes(paymentType),
      then: yup.string().required('Obligatorio'),
    }),
    amountBsS1: yup.string().when('paymentMethods', {
      is: (paymentMethods, paymentType) =>
        paymentMethods > 0 &&
        ![tiposDePago?.COURTESY, tiposDePago?.PENDING].includes(paymentType),
      then: yup.string().required('Obligatorio'),
    }),
  });
};

export const pendingPaymentSchema = () => {
  return yup.object().shape({
    paymentType: yup.string().required('Obligatorio'),
    price: yup.number().required('Obligatorio'),
    paymentMethods: yup.number().when('pvp', {
      is: pvp => pvp > 0,
      then: yup
        .number('Obligatorio')
        .min(1, 'Ingrese almenos un metodo de pago')
        .max(2, 'Ha superado el limite de métodos  de pago')
        .required('Obligatorio'),
    }),
    method0: yup.string().when('paymentMethods', {
      is: paymentMethods => paymentMethods > 0,
      then: yup.string().required('Obligatorio'),
    }),
    reference0: yup.string().when('paymentMethods', {
      is: paymentMethods => paymentMethods > 0,
      then: yup.string().required('Obligatorio'),
    }),
    amount0: yup.string().when('paymentMethods', {
      is: paymentMethods => paymentMethods > 0,
      then: yup.string().required('Obligatorio'),
    }),
    amountBsS0: yup.string().when('paymentMethods', {
      is: paymentMethods => paymentMethods > 0,
      then: yup.string().required('Obligatorio'),
    }),
  });
};

export const changeVehicleSchema = () => {
  return yup.object().shape({
    state: yup.string().required('Obligatorio'),
    city: yup.string().required('Obligatorio'),
    municipality: yup.string().required('Obligatorio'),
    address: yup.string().required('Obligatorio'),
    addressReference: yup.string().required('Obligatorio'),
    // Vehicle Info
    class: yup.string().required('Obligatorio'),
    type: yup.string().required('Obligatorio'),
    plate: yup.string().required('Obligatorio'),
    brand: yup.string().required('Obligatorio'),
    model: yup.string().required('Obligatorio'),
    color: yup.string().required('Obligatorio'),
    year: yup
      .number()
      .required('Obligatorio')
      .max(new Date().getFullYear() + 1, 'Ingrese un año valido'),
    bodySerial: yup.string().required('Obligatorio'),
    km: yup.string(),
    armorType: yup.string(),
    affiliationType: yup.string().required('Obligatorio'),
  });
};

export const upgradeMembershipSchema = () => {
  return yup.object().shape({
    // paymentType: yup.string().required('Obligatorio'),
    price: yup.number().required('Obligatorio'),
    paymentMethods: yup.number().when('pvp', {
      is: pvp => pvp > 0,
      then: yup
        .number('Obligatorio')
        .min(1, 'Ingrese almenos un metodo de pago')
        .max(2, 'Ha superado el limite de métodos  de pago')
        .required('Obligatorio'),
    }),
    method1: yup.string().when('paymentMethods', {
      is: paymentMethods => paymentMethods > 0,
      then: yup.string().required('Obligatorio'),
    }),
    reference1: yup.string().when('paymentMethods', {
      is: paymentMethods => paymentMethods > 0,
      then: yup.string().required('Obligatorio'),
    }),
    amount1: yup.string().when('paymentMethods', {
      is: paymentMethods => paymentMethods > 0,
      then: yup.string().required('Obligatorio'),
    }),
    amountBsS1: yup.string().when('paymentMethods', {
      is: paymentMethods => paymentMethods > 0,
      then: yup.string().required('Obligatorio'),
    }),
  });
};

export const createGiftcardSaleSchema = () => {
  return yup.object().shape({
    channel: yup.string().required('Obligatorio'),
    planAmount: yup.string().required('Obligatorio'),
    saleType: yup.string().required('Obligatorio'),
    saleDate: yup.string().required('Obligatorio'),
    membership: yup.string().required('Obligatorio'),
    publicCode: yup.string().when('saleType', {
      is: saleType => saleType === giftCardType?.PARTICULAR,
      then: yup.string().required('Obligatorio'),
    }),
    saleQuantity: yup.string().when('saleType', {
      is: saleType => saleType !== giftCardType?.PARTICULAR,
      then: yup.string().required('Obligatorio'),
    }),
    firstName: yup.string().when('saleType', {
      is: saleType => saleType === giftCardType?.PARTICULAR,
      then: yup.string().required('Obligatorio'),
    }),
    lastName: yup.string().when('saleType', {
      is: saleType => saleType === giftCardType?.PARTICULAR,
      then: yup.string().required('Obligatorio'),
    }),
    identificationType: yup.string().when('saleType', {
      is: saleType => saleType === giftCardType?.PARTICULAR,
      then: yup.string().required('Obligatorio'),
    }),
    identification: yup.string().when('saleType', {
      is: saleType => saleType === giftCardType?.PARTICULAR,
      then: yup.string().required('Obligatorio'),
    }),
    phonePrefix: yup.string().when('saleType', {
      is: saleType => saleType === giftCardType?.PARTICULAR,
      then: yup.string().required('Obligatorio'),
    }),
    phoneNumber: yup.string().when('saleType', {
      is: saleType => saleType === giftCardType?.PARTICULAR,
      then: yup
        .string()
        .min(7, 'Ingrese formato valido')
        .max(7, 'Ingrese formato valido')
        .required('Obligatorio'),
    }),
    email: yup.string().when('saleType', {
      is: saleType => saleType === giftCardType?.PARTICULAR,
      then: yup.string().required('Obligatorio'),
    }),
  });
};

export const editGiftcardSaleSchema = () => {
  return yup.object().shape({
    channel: yup.string().required('Obligatorio'),
    planAmount: yup.string().required('Obligatorio'),
    saleType: yup.string().required('Obligatorio'),
    saleDate: yup.string().required('Obligatorio'),
    membership: yup.string().required('Obligatorio'),
    publicCode: yup.string().when('saleType', {
      is: saleType => saleType === giftCardType?.PARTICULAR,
      then: yup.string().required('Obligatorio'),
    }),
    saleQuantity: yup.string().when('saleType', {
      is: saleType => saleType !== giftCardType?.PARTICULAR,
      then: yup.string().required('Obligatorio'),
    }),
    firstName: yup.string().when('saleType', {
      is: saleType => saleType === giftCardType?.PARTICULAR,
      then: yup.string().required('Obligatorio'),
    }),
    lastName: yup.string().when('saleType', {
      is: saleType => saleType === giftCardType?.PARTICULAR,
      then: yup.string().required('Obligatorio'),
    }),
    identificationType: yup.string().when('saleType', {
      is: saleType => saleType === giftCardType?.PARTICULAR,
      then: yup.string().required('Obligatorio'),
    }),
    identification: yup.string().when('saleType', {
      is: saleType => saleType === giftCardType?.PARTICULAR,
      then: yup.string().required('Obligatorio'),
    }),
    phonePrefix: yup.string().when('saleType', {
      is: saleType => saleType === giftCardType?.PARTICULAR,
      then: yup.string().required('Obligatorio'),
    }),
    phoneNumber: yup.string().when('saleType', {
      is: saleType => saleType === giftCardType?.PARTICULAR,
      then: yup
        .string()
        .min(7, 'Ingrese formato valido')
        .max(7, 'Ingrese formato valido')
        .required('Obligatorio'),
    }),
    email: yup.string().when('saleType', {
      is: saleType => saleType === giftCardType?.PARTICULAR,
      then: yup.string().required('Obligatorio'),
    }),
  });
};

export const updatePaymentDuesSchema = maxSum => {
  return yup
    .object()
    .shape({
      firstPaymentDue: yup
        .string()
        .matches(/^\d+(\.\d+)?$/, 'Debe ser un número entero o decimal')
        .required('Obligatorio'),
      firstPaymentDate: yup.string().required('Obligatorio'),
      secondPaymentDue: yup
        .string()
        .matches(/^\d+(\.\d+)?$/, 'Debe ser un número entero o decimal')
        .required('Obligatorio'),
      secondPaymentDate: yup.string().required('Obligatorio'),
      thirdPaymentDue: yup
        .string()
        .matches(/^\d+(\.\d+)?$/, 'Debe ser un número entero o decimal')
        .required('Obligatorio'),
      thirdPaymentDate: yup.string().required('Obligatorio'),
      finalPrice: yup.string(), // Asegúrate de que finalPrice esté registrado
    })
    .test(
      'sum-check',
      'La suma de los montos no debe exceder el valor permitido',
      function (values) {
        const { firstPaymentDue, secondPaymentDue, thirdPaymentDue } = values;
        const sum =
          parseFloat(firstPaymentDue) +
          parseFloat(secondPaymentDue) +
          parseFloat(thirdPaymentDue);
        if (sum > maxSum) {
          return this.createError({
            path: 'finalPrice', // Puedes elegir cualquier campo para mostrar el error
            message: 'La suma de los montos no debe exceder el valor permitido',
          });
        }
        return true;
      }
    );
};

import serviceTire from '../../../../images/serviceTire.svg';
import serviceAV from '../../../../images/serviceAV.svg';
import serviceBattery from '../../../../images/serviceBattery.svg';
import serviceChecking from '../../../../images/serviceChecking.svg';
import serviceGas from '../../../../images/serviceGas.svg';
import serviceDiscount from '../../../../images/serviceDiscount.svg';
import serviceHome from '../../../../images/serviceHome.svg';

import serviceAsistance from '../../../../images/serviceAsistance.svg';

import months from '../../../../images/4months.png';
import { ActionButton } from '../../../common/Buttons/Buttons';
import { planFreeYoutubeURL } from '../../../../assets/helpers/options';
import "./FreePlanInfo.scss";

const services = [
    { img: serviceAsistance, name: "Servicios ilimitados de hasta 50km" },
    {
        img: serviceAV, name: "Acompañamiento vial",
    },
    {
        img: serviceChecking, name: "Chequeo mecánico",

    },
    {
        img: serviceTire, name: "Cambio e inflado de caucho",
    },
    {
        img: serviceGas, name: "Auxilio de gasolina",
    },
    {
        img: serviceBattery, name: "Auxilio/venta de batería",
    },
    {
        img: serviceDiscount, name: "Descuento en Taller mecánico",
    },
    {
        img: serviceHome, name: "Mecánica a domicilio",
    },

]


export const FreePlanInfoComponent = (props) => {

    const { action } = props;


    return <div className="free-plan-info-component">

        <div className="free-plan-into-months row">
            <div className="free-plan-into-months-img-container col-12 col-md-6">
                <img src={months} alt="" />
            </div>
            <div className="col-12 col-md-6">
                <iframe class="elementor-video" frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" title="Plan Free - Tu Gruero" width="100%" height="360" src={planFreeYoutubeURL} id="widget2" data-gtm-yt-inspected-8="true"></iframe>

            </div>

        </div>
        <h5 style={{ textAlign: "center", margin: "24px" }} >
            Incluye los siguientes servicios:
        </h5>



        <div className="benefits-container">
            {services?.map((service, index) => <BenefitsComponent key={index} service={service} />)}
        </div>

        <h6 style={{ textAlign: "center", margin: "24px" }}>Haz click en el siguiente botón para registrarte y obtener tu plan gratuito
        </h6>


        <div className='benefits-optain-plan'>

            <ActionButton label="Obtener mi plan" action={action} />
            <p>Ciertas condiciones aplican</p>
        </div>

    </div>
}

export const BenefitsComponent = (props) => {

    const { service } = props;

    return <div className="benefits-component">
        <img src={service?.img} alt="s" />
        <h6>{service?.name}</h6>
    </div>

}
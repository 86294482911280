import { Header } from '../../common/Header/Header.jsx';
import { VehicleParametersCard } from './Cards/VehicleParametersCard.jsx';
import { SalesChannels } from './SalesChannels/SalesChannels.jsx';
import authService from '../../../services/authService.js';
import { salesRoles } from '../../../assets/helpers/roles.js';
import './Parameters.scss';

export const Parameters = () => {
  return (
    <>
      <Header />
      <div className="service-with-container  container">
        <div className="row">
          {[
            salesRoles?.SALES_ADMIN,
            salesRoles?.SALES_DEVELOPER,
            salesRoles?.SALES_SUPERVISOR,
          ]?.includes(authService?.getUserType()) && (
              <div className="col-12">
                <VehicleParametersCard label={'VEHICULOS'} />
              </div>
            )}
          {[
            salesRoles?.SALES_ADMIN,
            salesRoles?.SALES_DEVELOPER,
            salesRoles?.SALES_SUPERVISOR,
          ]?.includes(authService?.getUserType()) && (
              <div className="col-12">
                <SalesChannels label={'CANALES DE VENTA'} />
              </div>
            )}

        </div>
      </div>
    </>
  );
};

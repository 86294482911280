import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const saleChannelService = {
  getSalesChannel: async () => {
    const response = await authInstance
      .get(`${url}/salesChannels/list?limit=100000&offset=0`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  getSalesChannelList: async (params) => {
    const response = await authInstance
      .get(`${url}/salesChannels/list?` + new URLSearchParams(params))
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  createSaleChannel: async (data) => {
    const response = await authInstance
      .post(`${url}/salesChannels/create`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
}

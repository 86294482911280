import { fixResponseJSON } from '../assets/helpers/funciones';
let url = process.env.REACT_APP_API_URL;

export const stripeService = {
  paymentIntent: async (data) => {
    const myInit = {
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'POST',
      body: JSON.stringify(data),
    };

    const response = await fetch(`${url}/stripe/paymentIntent`, myInit)
      .then(async (response) => {
        return fixResponseJSON(response);
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
};

import checkboxChecked from '../../../images/checkboxChecked.svg';
import checkbox from '../../../images/checkbox.svg';
import { NavLink } from 'react-router-dom';
import copyImage from '../../../images/copyImage.svg';
import './Buttons.scss';

export const FilterCaseButton = (props) => {
  const { label, caseStatus, action, value } = props;

  if (caseStatus === value) {
    return <ActionButton label={label} action={() => action('')} />;
  } else {
    return <AltButton label={label} action={() => action(value)} />;
  }
};

export const LogoButton = (props) => {
  const { link, src } = props;
  return (
    <NavLink to={link}>
      <img className="logo" src={src} alt="logo" />
    </NavLink>
  );
};

export const Button = (props) => {
  const { button, action, selected } = props;

  let color = '';
  let fontColor = '';

  if (selected === button?.name) {
    color = '#f27730';
    fontColor = '#fff';
  }

  return (
    <div
      style={{ backgroundColor: color, color: fontColor }}
      onClick={() => action()}
      className="service-button"
    >
      <h6>{button?.name}</h6>
    </div>
  );
};

export const CheckBox = (props) => {
  const { button, action } = props;

  return (
    <div className="checkbox-button">
      {button.show ? (
        <img onClick={() => action()} src={checkboxChecked} />
      ) : (
        <img onClick={() => action()} src={checkbox} />
      )}
      <h6>{button?.name}</h6>
    </div>
  );
};

export const PlanComponent = (props) => {
  const { plan } = props;
  return (
    <div className="plan-component">
      <h1>
        Plan{' '}
        <span
          style={{ textTransform: 'capitalize' }}
          className={`plan-${plan}-color`}
        >
          {plan}
        </span>{' '}
      </h1>
    </div>
  );
};

export const FilterButton = (props) => {
  const { button, selected, selectButton } = props;

  let active = '';

  if (button.value === selected) {
    active = 'true';
  }

  return (
    <div
      className={`filter-button filter-button-${active}`}
      onClick={() => selectButton(button.value)}
    >
      {button?.image && <img src={button?.image} alt="image" />}
      <h5>{button?.name}</h5>
      {button.value === selected && (
        <div className="filter-button-active-bar"></div>
      )}
    </div>
  );
};

export const ActionButton = (props) => {
  const { label, action, color } = props;

  return (
    <div onClick={action} className={`action-button ${color}`}>
      <h6>{label}</h6>
    </div>
  );
};

export const AltButton = (props) => {
  const { label, action } = props;

  return (
    <div onClick={() => action()} className="action-button alt-action-button">
      <h6>{label}</h6>
    </div>
  );
};

export const DisabledButton = (props) => {
  const { label, action } = props;

  return (
    <div
      onClick={() => action()}
      className="action-button disabled-action-button"
    >
      <h6>{label}</h6>
    </div>
  );
};

export const TableActionButton = (props) => {
  const { label, action, src } = props;

  return (
    <div onClick={() => action()} className="table-action-button">
      <img src={src} alt="edit" />
    </div>
  );
};

export const CopyButton = (props) => {
  const { label } = props;
  return (
    <div className="copy-button-container">
      <button className="copy-button">
        <img src={copyImage} alt="copyImage" />
        <h6>{label}</h6>
      </button>
    </div>
  );
};

export const Breadcrumb = (props) => {
  const { links } = props;

  return (
    <div className="breadcrumbs">
      {links.map((l, i) => (
        <div>
          {l?.url ? (
            <NavLink to={l?.url}>
              {i === links.length - 1 ? <b>{l?.name}</b> : l?.name}
            </NavLink>
          ) : i === links.length - 1 ? (
            <b>{l?.name}</b>
          ) : (
            l?.name
          )}
          {i < links.length - 1 && <span>/</span>}
        </div>
      ))}
    </div>
  );
};

export const SalesButtons = (props) => {
  const { label, active, action } = props;
  if (active) {
    return (
      <div
        onClick={action}
        className={`sales-button-container sales-button-container-active`}
      >
        <h6>{label}</h6>
      </div>
    );
  } else {
    return (
      <div className={`sales-button-container sales-button-container-unactive`}>
        <h6>{label}</h6>
      </div>
    );
  }
};

export const CancelOrderButton = (props) => {
  const { label, action } = props;
  return (
    <div
      onClick={() => action()}
      className={`sales-button-container cancel-order-button`}
    >
      <h6>{label}</h6>
    </div>
  );
};

import { useState, useEffect, useRef } from 'react';
import { ActionButton } from '../../common/Buttons/Buttons';
import { Header } from '../../common/Header/Header.jsx';
import { SelectPerPage } from '../../common/Forms/Input/Input';
import { RenewalMembershipTable } from '../../common/Tables/Tables';
import { renewalTableHeader } from '../../../assets/helpers/tables';
import { notify } from '../../../assets/helpers/toast';
import { membershipService } from '../../../services/membershipService';
import { membershipCatalogService } from '../../../services/membershipCatalogService';
import { giftCardService } from '../../../services/giftCardService';
import { productService } from '../../../services/productService';
import { set, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../assets/schemas/schemas';
import { useParams, useHistory } from 'react-router-dom';
import { Input, SearchInputWithSelector } from '../../common/Forms/Input/Input';
import { RenewalExportTypeBreadcrumb } from '../../common/Dropzone/Dropzone';
import moment from 'moment';
import {
  planStatus,
  monthsOptions,
  LANDING,
  rcvEnum,
  codeTypeEnum,
  codeTypeOptions,
  validateCodeEnum,
  membershipCodes,
  KMServiceOptions,
} from '../../../assets/helpers/options';
import './AffiliationsDB.scss';
import {
  capitalize,
  filterParams,
  getPrevMonth,
  getNextMonth,
  exportDataToExcel,
  removeBlankSpacesAndUppercase,
} from '../../../assets/helpers/funciones';
import { RenewModal } from '../../common/Modals/Modals.jsx';
import authService from '../../../services/authService.js';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import { usersService } from '../../../services/usersService.js';
import { saleChannelService } from '../../../services/saleChannelService.js';

const itemsPerpage = [10, 25, 50, 100, 500];

export const RenewalDB = () => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const history = useHistory();

  const { statusId } = useParams();

  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemas.filterRenewalMembership(t)),
  });

  const {
    register: register2,
    formState: { errors: errors2 },
    reset: reset2,
    setValue: setValue2,
    watch: watch2,
    control: control2,
    handleSubmit: handleSubmit2,
  } = useForm({
    resolver: yupResolver(schemas.autorenovationSearchSchema(t)),
  });

  const campaignOptions = {
    contact: 'Contactar',
    scheduled: 'Agendado',
    pendingPayment: 'Pago pendiente',
    firstStep: 'Cierre 1',
    secondStep: 'Cierre 2',
    thirdStep: 'Cierre 3',
    credit: 'Crédito',
    traveling: 'De viaje',
    noRenew: 'No va a renovar',
    damaged: 'Averiado',
    notApproved: 'revisión no aprobada',
    notScheduled: 'revisión no agendada',
    noContact: 'No contactar',
  };

  const membershipCodes = {
    GOLDF4: 'GOLDF4',
    PLUSF4: 'PLUSF4',
    GOLD: 'GOLD',
    PLUS: 'PLUS',
    CLASSIC: 'CLASSIC',
    RIDERY: 'RIDERY',
    MOTORIDER: 'MOTORIDER',
    RETRO: 'RETRO',
    RCV_ELITE: 'RCV_ELITE',
    RCV: 'RCV',
    F2: 'F2',
  };

  const [statusOptions, setStatusOptions] = useState([]);
  const [statusSelected, setStatusSelected] = useState('');
  const [campaignAction, setCampaignAction] = useState('');
  const [yearOptions, setYearOptions] = useState([]);
  const [planOptions, setPlanOptions] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [rcvOptions, setRCVOptions] = useState([]);
  const [priceInfo, setPriceInfo] = useState('');
  const [discountId, setDiscountId] = useState('');
  const [showRenewModal, setShowRenewModal] = useState({
    isOpen: false,
    selected: '',
  });

  const [campaignActionOptions, setCampaignActionOptions] = useState([]);
  const [planSelectedOptions, setPlanSelectedOptions] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const [membershipStatusOptions, setMembershipStatusOptions] = useState([]);
  const [advisorOptions, setAdvisorOptions] = useState([]);
  const [searchCategoryOption, setSearchCategoryOption] = useState('name');
  const [membershipEndDate, setMembershipEndDate] = useState({});
  const [rcvEndDate, setRcvEndDate] = useState({});
  const [tableHeader, setTableHeader] = useState(renewalTableHeader);

  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [showExportBreadcrumb, setShowExportBreadcrumb] = useState(false);
  const [isVisible, setIsVisible] = useState(showExportBreadcrumb);

  useEffect(() => {
    if (showExportBreadcrumb) {
      setIsVisible(true);
    }
  }, [showExportBreadcrumb]);

  const handleAnimationEnd = () => {
    if (!showExportBreadcrumb) {
      setIsVisible(false);
    }
  };

  const [membershipsDatatable, setMembershipsDatatable] = useState({
    limit: itemsPerpage[2],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    dateFrom: '',
    dateTo: '',
    isLoading: true,
    searchParam: '',
  });
  const [advisors, setAdvisors] = useState([]);
  const [channels, setChannels] = useState([]);

  const handleDateChange = () => {
    getMembershipsByStatus();
  };

  const campaignActionList = [
    { name: 'Contactar', _id: 'Contactar' },
    { name: 'Agendado', _id: 'Agendado' },
    {
      name: 'Pago pendiente',
      _id: 'Pago pendiente',
      checked: false,
    },
    { name: 'Cierre 1', _id: 'Cierre 1' },
    { name: 'Cierre 2', _id: 'Cierre 2' },
    { name: 'Cierre 3', _id: 'Cierre 3' },
    { name: 'Crédito', _id: 'Crédito' },
    { name: 'De viaje', _id: 'De viaje' },
    {
      name: 'No va a renovar',
      _id: 'No va a renovar',
      checked: false,
    },
    { name: 'Averiado', _id: 'Averiado' },
    {
      name: 'revisión no aprobada',
      _id: 'revisión no aprobada',
      checked: false,
    },
    {
      name: 'revisión no agendada',
      _id: 'revisión no agendada',
      checked: false,
    },
    {
      name: 'No contactar',
      _id: 'No contactar',
      checked: false,
    },
  ];

  const selectitemsPerpage = event => {
    setMembershipsDatatable(prevState => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setMembershipsDatatable(prevState => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * membershipsDatatable?.limit,
      };
    });
  };

  const handleSearchChange = event => {
    setMembershipsDatatable(prevState => {
      return {
        ...prevState,
        searchParam: event?.target?.value,
        page: 1,
        offset: 0,
      };
    });
  };

  const exportTable = () => {
    setShowExportBreadcrumb(!showExportBreadcrumb);
    // exportRenewTable();
    // let dataToPrint = [];

    // for (let i = 0; i < membershipsDatatable?.data?.length; i++) {
    //   dataToPrint?.push({
    //     'Nro. Orden': membershipsDatatable?.data[i]?.membershipId,
    //     'Fecha Creación': membershipsDatatable?.data[i]?.createdAt
    //       ? moment
    //         .utc(membershipsDatatable?.data[i]?.createdAt)
    //         .format('DD/MM/YYYY')
    //       : '',
    //     'Asesor Asignado': capitalize(
    //       membershipsDatatable?.data[i]?.assignedAdvisor?.name
    //     ),
    //     'Condición Afiliación': capitalize(
    //       membershipsDatatable?.data[i]?.membershipStatus
    //     ),
    //     Plan: capitalize(membershipsDatatable?.data[i]?.membershipStatus),
    //     'Nivel siniestralidad': capitalize(
    //       membershipsDatatable?.data[i]?.accidentRate
    //     ),
    //     Cliente:
    //       capitalize(membershipsDatatable?.data[i]?.customer?.firstName) +
    //       ' ' +
    //       capitalize(membershipsDatatable?.data[i]?.customer?.lastName),
    //     Placa: membershipsDatatable?.data[i]?.vehicle?.plate,
    //     Cédula:
    //       membershipsDatatable?.data[i]?.customer?.identificationType +
    //       '-' +
    //       membershipsDatatable?.data[i]?.customer?.identification,
    //     Teléfono:
    //       membershipsDatatable?.data[i]?.customer?.phonePrefix +
    //       '-' +
    //       membershipsDatatable?.data[i]?.customer?.phoneNumber,
    //     Correo: membershipsDatatable?.data[i]?.customer?.email,
    //     Canal: membershipsDatatable?.data[i]?.saleChannel,
    //     Contrato: membershipsDatatable?.data[i]?.contractType,
    //     'Fecha fin plan': membershipsDatatable?.data[i]?.createdAt
    //       ? moment
    //         .utc(membershipsDatatable?.data[i]?.createdAt)
    //         .format('DD/MM/YYYY')
    //       : '',
    //     'Tipo de venta': membershipsDatatable?.data[i]?.contractType,

    //     Plan: capitalize(membershipsDatatable?.data[i]?.membershipCode),
    //     'Año Vehículo': membershipsDatatable?.data[i]?.vehicle?.year,
    //     'Nivel de Siniestralidad': membershipsDatatable?.data[i]?.accidentRate,
    //     'Fecha Fin de plan': membershipsDatatable?.data[i]?.affiliationType,
    //     'Fecha Fin RCV': membershipsDatatable?.data[i]?.rcvEndDate
    //       ? moment
    //         .utc(membershipsDatatable?.data[i]?.rcvEndDate)
    //         .format('DD/MM/YYYY')
    //       : 'N/A',
    //     'Acción de Campaña': membershipsDatatable?.data[i]?.contactInCampaign
    //       ? 'Contactar'
    //       : 'No Contactar',
    //   });
    // }

    // exportDataToExcel(dataToPrint, 'base_de_datos_de_renovacion.xlsx');
  };

  const exportRenewTable = type => {
    let params = {
      contactInCampaign: campaignAction,
      status: statusSelected,
      dateFrom: getDateFormat(watch('sinceMonth'), watch('sinceYear'), '1'),
      dateTo: getDateFormat(watch('untilMonth'), watch('untilYear'), '31'),
      limit: membershipsDatatable?.limit,
      offset: membershipsDatatable?.offset,
      searchParam: membershipsDatatable?.searchParam,
      searchCategory: searchCategoryOption,
      byMail: true,
      email: authService?.getUser()?.email,
    };

    let body = {
      campaignAction: campaignActionOptions,
      statusList: membershipStatusOptions,
      plans: planSelectedOptions,
      channels: channelOptions,
      advisors: advisorOptions,
      membershipEndDate: membershipEndDate ? membershipEndDate : '',
      rcvEndDate: rcvEndDate ? rcvEndDate : '',
    };

    if (!body.membershipEndDate.from || !body.membershipEndDate.until) {
      delete body.membershipEndDate;
    }
    if (!body.rcvEndDate.from || !body.rcvEndDate.until) {
      delete body.rcvEndDate;
    }

    if (body.statusList[0] === '') delete body.statusList;

    if (type) {
      params.type = type;
    }

    if (!isLoadingEmail) {
      notify(
        `Solicitud de exportable realizada, espere a recibir un correo con el archivo correspondiente`,
        'info'
      );
      setIsLoadingEmail(true);

      membershipService
        .getMembershipsToBeRenewed(filterParams(params), body)
        .then(response => {
          if (response.status === 200) {
            setIsLoadingEmail(false);
          } else {
            setIsLoadingEmail(false);
          }
        })
        .catch(error => {
          setIsLoadingEmail(false);
        });
    }
    setShowExportBreadcrumb(!showExportBreadcrumb);
  };

  const redirectToEditOrder = orderId => {
    history.push(`/afiliations/edit-order/${orderId}`);
  };

  const redirectToOrderDetail = order => {
    history.push(
      `/afiliations/order-details/${order?._id}/vehicle/${order?.vehicle?._id}`
    );
  };

  const actionOverMembership = membership => {
    getAvailableMemberships(membership);
  };

  const renewMembership = order => {
    if (!isLoading) {
      setIsloading(true);
      membershipService
        .renewMembership(order?._id)
        .then(response => {
          if (response.status === 200) {
            history.push(`/afiliations/edit-order/${response?.data?._id}`);
            setIsloading(false);
            return null;
          }
          if (response.status === 409 && response?.data?.data?.redirect) {
            history.push(`/afiliations/edit-order/${response?.data?.data?.id}`);
            setIsloading(false);
            return null;
          } else {
            setIsloading(false);
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(error => {
          setIsloading(false);
        });
    }
  };

  const rescueMembership = order => {
    if (!isLoading) {
      setIsloading(true);
      membershipService
        .rescueMembership(order?._id)
        .then(response => {
          if ([200, 201].includes(response?.status)) {
            history.push(`/afiliations/edit-order/${response?.data?._id}`);
            setIsloading(false);
          } else {
            setIsloading(false);
            notify(`${response.message}`, 'error');
          }
        })
        .catch(error => {
          setIsloading(true);
        });
    }
  };

  const preloadOptions = async currentYear => {
    let years = [];

    for (let i = currentYear + 1; i > 2000; i--) {
      years.push({
        name: i.toString(),
        _id: i.toString(),
      });
    }
    setYearOptions(years);
  };

  const preloadDates = () => {
    let currentYear = new Date()?.getFullYear();
    let currenMonth = new Date()?.getMonth();

    const monthsAgo = new Date(
      new Date().getTime() - 1 * 30 * 24 * 60 * 60 * 1000
    );
    const monthsFromNow = new Date(
      new Date().getTime() + 2 * 30 * 24 * 60 * 60 * 1000
    );

    let sinceYear = monthsAgo.getFullYear();
    let untilYear = moment(new Date()).add(4, 'months').year();

    preloadOptions(currentYear).then(r => {
      setTimeout(() => {
        setValue('sinceYear', sinceYear);
        setValue('untilYear', untilYear);
        setValue(
          'sinceMonth',
          monthsOptions[getPrevMonth(currenMonth, 1)]?._id
        );
        setValue(
          'untilMonth',
          monthsOptions[getNextMonth(currenMonth, +2)]?._id
        );
        getMembershipsByStatus();
      }, 1000);
    });
  };

  useEffect(() => {
    preloadDates();
    getStatus();
    getAvailableAdvisors();
    getChannelList();
  }, []);

  useEffect(() => {
    getMembershipsByStatus();
    advisorListOptions();
    channelListOptions();
  }, [
    membershipsDatatable?.page,
    membershipsDatatable?.offset,
    membershipsDatatable?.limit,
    membershipsDatatable?.type,
    statusSelected,
    campaignActionOptions,
    planSelectedOptions,
    channelOptions,
    advisorOptions,
    membershipStatusOptions,
    membershipEndDate,
    rcvEndDate,
  ]);

  const getDateFormat = (month, year, day) => {
    // if (month && year && day) {
    let date = new Date();
    if (month && year) {
      date = new Date(month + `${day},` + year);
      date = moment.utc(date)?.format('MM-DD-YYYY');
    }
    return date;
    // } else return undefined;
  };

  const advisorListOptions = arr => {
    let advisors = [];
    arr?.sort((a, b) => {
      if (a?.firstName < b?.firstName) {
        return -1;
      }
      if (a?.firstName > b?.firstName) {
        return 1;
      }
      return 0;
    });

    if (arr) {
      for (let i = 0; i < arr?.length; i++) {
        advisors.push({
          _id: arr[i]?._id,
          name:
            capitalize(arr[i]?.firstName) + ' ' + capitalize(arr[i]?.lastName),
        });
      }
    }
    return advisors;
  };

  const channelListOptions = arr => {
    let channels = [];
    arr?.sort((a, b) => {
      if (a?.name < b?.name) {
        return -1;
      }
      if (a?.name > b?.name) {
        return 1;
      }
      return 0;
    });

    if (arr) {
      for (let i = 0; i < arr?.length; i++) {
        channels.push({
          _id: arr[i]?._id,
          name: capitalize(arr[i]?.name),
        });
      }
    }
    return channels;
  };

  const handleCampaignActionOptions = arr => {
    setCampaignActionOptions(arr);
  };

  const filterMembershipCodes = obj => {
    let codes = [];
    for (let key in obj) {
      codes.push({
        _id: obj[key],
        name: key,
      });
    }
    return codes;
  };

  const handleChannelOptions = arr => {
    setChannelOptions(arr);
  };

  const handleMembershipEndDate = (from, until) => {
    if (from && until) {
      setMembershipEndDate({
        from: from,
        until: until,
      });
    }
  };

  const handleRcvEndDate = (from, until) => {
    if (from && until) {
      setRcvEndDate({
        from: from,
        until: until,
      });
    }
  };

  const handlePlansOptions = arr => {
    setPlanSelectedOptions(arr);
  };

  const handleMembershipStatusOptions = arr => {
    setMembershipStatusOptions(arr);
  };

  const handleAdvisorOptions = arr => {
    setAdvisorOptions(arr);
  };

  const getMembershipsByStatus = () => {
    if (
      watch('sinceMonth') &&
      watch('sinceYear') &&
      watch('untilMonth') &&
      watch('untilYear')
    ) {
      setMembershipsDatatable(prevState => {
        return {
          ...prevState,
          isLoading: true,
        };
      });

      let params = {
        contactInCampaign: campaignAction,
        status: statusSelected,
        dateFrom: getDateFormat(watch('sinceMonth'), watch('sinceYear'), '1'),
        dateTo: getDateFormat(watch('untilMonth'), watch('untilYear'), '31'),
        limit: membershipsDatatable?.limit,
        offset: membershipsDatatable?.offset,
        searchParam: membershipsDatatable?.searchParam,
        searchCategory: searchCategoryOption,
      };

      let body = {
        campaignAction: campaignActionOptions,
        statusList: membershipStatusOptions,
        plans: planSelectedOptions,
        channels: channelOptions,
        advisors: advisorOptions,
        membershipEndDate: membershipEndDate ? membershipEndDate : '',
        rcvEndDate: rcvEndDate ? rcvEndDate : '',
      };

      if (!body.membershipEndDate.from || !body.membershipEndDate.until) {
        delete body.membershipEndDate;
      }
      if (!body.rcvEndDate.from || !body.rcvEndDate.until) {
        delete body.rcvEndDate;
      }

      if (body.statusList[0] === '') delete body.statusList;

      membershipService
        .getMembershipsCountRenewal(filterParams(params), body)
        .then(response => {
          if (response.status === 200) {
            setMembershipsDatatable(prevState => {
              return {
                ...prevState,
                count: response?.data?.total,
              };
            });
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => {});

      setIsloading(true);
      membershipService
        .getMembershipsToBeRenewed(filterParams(params), body)
        .then(response => {
          if (response.status === 200) {
            setMembershipsDatatable(prevState => {
              setIsloading(false);
              return {
                ...prevState,
                data: response?.data?.memberships,
                isLoading: false,
              };
            });
          } else {
            notify(`${response.message}` || 'error', 'error');
            setMembershipsDatatable(prevState => {
              setIsloading(false);
              return {
                ...prevState,
                isLoading: false,
              };
            });
          }
        })
        .catch(error => {
          setMembershipsDatatable(prevState => {
            setIsloading(false);
            return {
              ...prevState,
              isLoading: false,
            };
          });
        });
      return true;
    }
  };

  const redirectToOtherStatus = e => {
    setStatusSelected(e?.target?.value);
  };
  const handleCampaingType = e => {
    setCampaignAction(e?.target?.value);
  };

  const getStatus = () => {
    membershipService
      .getStatuses()
      .then(response => {
        if (response.status === 200) {
          let data = [];
          for (let i = 0; i < response?.data.length; i++) {
            data.push({
              _id: response?.data[i]?._id,
              name: capitalize(response?.data[i]?.name),
              realName: response?.data[i]?.name,
            });

            if (response?.data[i]?._id === statusId) {
              setStatusSelected(response?.data[i]?._id);
            }
          }

          data = data?.filter(d =>
            [
              // planStatus?.PENDING_FOR_APPROBAL,
              planStatus?.TO_BE_RENEW,
              planStatus?.EXPIRED,
              planStatus?.TO_BE_EXPIRED,
            ].includes(d?.realName)
          );
          setStatusOptions([{ name: 'Todos', _id: '' }, ...data]);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(error => {});
    return true;
  };

  const getChannelList = () => {
    saleChannelService
      .getSalesChannelList({ limit: 100000, offset: 0, isActive: true })
      .then(response => {
        if (response.status === 200) {
          setChannels(response?.data.saleChannels);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => {});
  };

  const handleRenewModal = (membership, planOptions) => {
    if (!membership) {
      setRCVOptions([]);
      setPlanOptions([]);
      // setValue2("rcv", "")
      // setValue2("code", "")
      reset2();
      setPriceInfo('');
    }

    setShowRenewModal(prevState => {
      return {
        ...prevState,
        isOpen: !showRenewModal?.isOpen,
        selected: membership ? membership : '',
      };
    });

    if (membership) {
      const [option] = planOptions?.filter(
        p => p?.name === membership?.membershipCode
      );
      setValue2('code', option?._id);
      getRCVs(membership, planOptions);
    }
  };

  const getAvailableMemberships = membership => {
    let params = {
      year: membership?.vehicle?.year,
      class: membership?.vehicle?.class,
      landing: LANDING?.SELF_RENEWAL,
    };

    membershipCatalogService
      .getAvailableMemberships(filterParams(params))
      .then(response => {
        if (response.status === 200) {
          let data = [];

          for (let i = 0; i < response?.data.length; i++) {
            data?.push({
              name: response?.data[i]?.code,
              _id: response?.data[i]?._id,
            });
          }
          setPlanOptions(data);
          handleRenewModal(membership, data);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => {});
  };

  const getRCVs = (membership, planOptions) => {
    if (watch2('code')) {
      const [selectedOption] = planOptions?.filter(
        p => p?._id === watch2('code')
      );

      if ([membershipCodes?.GOLD]?.includes(selectedOption?.name)) {
        if (!watch2('km')) {
          setValue2('km', 150);
        }
      } else {
        setValue2('km', '');
      }

      productService
        .getRCVs(watch2('code'))
        .then(response => {
          if (response.status === 200) {
            let data = [{ _id: 'ninguno', name: 'N/A' }, ...response?.data];
            let [basico] = data?.filter(p => p?.name === rcvEnum?.RCV_BASIC);

            setRCVOptions(data);
            if (basico) {
              setValue2('rcv', basico?._id);
            }
            setValue2('codigo', '');
            setValue2('codeType', '');
            setDiscountId('');

            if (showRenewModal?.selected?._id || membership) {
              calculateRenewalPrice(membership, planOptions);
            }
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => {});
    }
  };

  const calculateRenewalPrice = (membership, planOptions) => {
    const params = {
      rcv: watch2('rcv') === 'ninguno' ? '' : watch2('rcv'),
      code: watch2('code'),
      giftcard: watch2('codeType') === codeTypeEnum?.GIFTCARD ? discountId : '',
      discountCode:
        watch2('codeType') === codeTypeEnum?.DISCOUNT ? discountId : '',
      useExtendedTow: watch2('hasExtendedTow')
        ? watch2('hasExtendedTow')
        : false,
    };

    const [selectedOption] = planOptions?.filter(
      p => p?._id === watch2('code')
    );

    if ([membershipCodes?.GOLD]?.includes(selectedOption?.name)) {
      if (!watch2('km')) {
        setValue2('km', 150);
        params.km = 150;
      } else {
        params.km = watch2('km');
      }
    }

    if (!isLoading) {
      setIsloading(true);
      membershipService
        .calculateRenewalPrice(
          filterParams(params),
          showRenewModal?.selected?._id || membership?._id
        )
        .then(response => {
          if (response.message === 'Success') {
            if (response.status === 200) {
              setPriceInfo(response?.data);
              setIsloading(false);
            }
            return null;
          }
          if (
            response.status === 400 &&
            [
              'discountCode contains an invalid value',
              'giftcard contains an invalid value',
            ]?.includes(response?.message)
          ) {
            notify(`Codigo Invalido`, 'info');
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch(() => {});
    }
  };

  const handleDiscountCode = () => {
    removeBlankSpacesAndUppercase(watch2('codigo'), 'codigo', setValue2);

    if (watch2('codigo').length === 6 && watch2('code')) {
      validateCode();
    }

    if (watch2('codigo').trim().length > 6) {
      notify('Ingrese un código válido', 'info');
      setValue2('codigo', watch2('codigo').slice(0, 6));
    }
  };

  const validateCode = () => {
    let discountType = '';

    if (watch2('codeType') === codeTypeEnum?.DISCOUNT) {
      discountType = 'discountCodes';
    }
    if (watch2('codeType') === codeTypeEnum?.GIFTCARD) {
      discountType = 'giftcards';
    }

    let params = {
      code: watch2('codigo'),
      type:
        watch2('codeType') === codeTypeEnum?.DISCOUNT
          ? validateCodeEnum?.RENEWAL
          : '',
    };

    if (params?.code) {
      giftCardService
        .validateDiscount(discountType, filterParams(params))
        .then(response => {
          if ([200, 201].includes(response.status)) {
            setDiscountId(response?.data?._id);
            calculateRenewalPrice('', planOptions);
          } else {
            if ([404].includes(response.status)) {
              notify(`Codigo no encontrado o expirado`, 'info');
              return null;
            }

            notify(`${response.message}`, 'error');
          }
        })
        .catch(() => {});
    }
  };

  const selfRenew = handleSubmit2(async data => {
    let params = {
      code: watch2('code'),
      rcvProduct: watch2('rcv') === 'ninguno' ? '' : watch2('rcv'),
      discountCodeId:
        watch2('codeType') === codeTypeEnum?.DISCOUNT ? discountId : '',
      giftcardId:
        watch2('codeType') === codeTypeEnum?.GIFTCARD ? discountId : '',
      km: watch2('km') ? watch2('km') : '',
      useExtendedTow: watch2('hasExtendedTow')
        ? watch2('hasExtendedTow')
        : false,
    };

    if (!isLoading) {
      setIsloading(true);
      membershipService
        .selfRenew(filterParams(params), showRenewModal?.selected?._id)
        .then(response => {
          if ([200, 201]?.includes(response.status)) {
            if (response?.message) {
              notify(`${response.message}`, 'info');
            }
            history.push(`/afiliations/edit-order/${response?.data?._id}`);
          } else {
            setIsloading(false);
            notify(`${response.message}`, 'error');
          }
        })
        .catch(() => {});
    }
  });

  const updateMembershipFromGrid = data => {
    const body = {};
    if (data?.observation) {
      body.observation = data?.observation;
    }
    if (data?.campaignAction) {
      body.campaignAction = data?.campaignAction;
    }
    if (data?.assignedAdvisor) {
      body.assignedAdvisor = data?.assignedAdvisor;
    }
    if (data.seller) {
      body.seller = data?.seller;
    }

    if (
      body?.observation ||
      body?.campaignAction ||
      body?.assignedAdvisor ||
      body?.seller
    ) {
      membershipService
        .updateFromGrid(data?.id, body)
        .then(response => {
          if ([200, 201].includes(response.status)) {
            notify('Actualización exitosa', 'info');
          } else {
            notify(`${response.message}`, 'error');
          }
        })
        .catch(() => {
          notify('Error al actualizar', 'error');
        });
    }
  };

  const getAvailableAdvisors = () => {
    usersService
      .getSalesUsers({
        status: 'Activo',
        module: 'Ventas',
      })
      .then(response => {
        if (response.status === 200) {
          if (response?.data?.length === 0) {
            notify('No hay asesores disponibles', 'info');
          }
          setAdvisors(response?.data.users);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => {
        notify('Error al consultar asesores', 'error');
      });
  };

  const handleCodeChange = () => {
    setValue2('codigo', '');
    setDiscountId('');
    calculateRenewalPrice('', planOptions);
  };

  const handleHeaderFilters = () => {
    renewalTableHeader.forEach(header => {
      if (header.name === 'Asesor Asignado') {
        header.filterOptions = advisorListOptions(advisors);
        header.filterSearch = handleAdvisorOptions;
        header.sortable = true;
      } else if (header.name === 'Acción de Campaña') {
        header.filterOptions = campaignActionList;
        header.filterSearch = handleCampaignActionOptions;
        header.sortable = true;
      } else if (header.name === 'Condición Afiliación') {
        header.filterOptions = statusOptions;
        header.filterSearch = handleMembershipStatusOptions;
        header.sortable = true;
      } else if (header.name === 'Canal') {
        header.filterOptions = channelListOptions(channels);
        header.filterSearch = handleChannelOptions;
        header.sortable = true;
      } else if (header.name === 'Plan') {
        header.filterOptions = filterMembershipCodes(membershipCodes);
        header.filterSearch = handlePlansOptions;
        header.sortable = true;
      } else if (header.name === 'Fecha fin RCV') {
        header.filterSearch = handleRcvEndDate;
        header.sortable = true;
      } else if (header.name === 'Fecha fin plan') {
        header.filterSearch = handleMembershipEndDate;
        header.sortable = true;
      } else {
        delete header.filterSearch;
      }
    });
    setTableHeader(renewalTableHeader);
  };

  useEffect(() => {
    handleHeaderFilters();
  }, [
    advisors,
    campaignActionOptions,
    statusOptions,
    channelOptions,
    membershipStatusOptions,
    planSelectedOptions,
  ]);

  return (
    <>
      <Header />
      <RenewModal
        modal={showRenewModal}
        control={control2}
        watch={watch2}
        register={register2}
        errors={errors2}
        close={actionOverMembership}
        options={{
          planOptions: planOptions,
          rcvOptions: rcvOptions,
          codeTypeOptions: codeTypeOptions,
          KMServiceOptions: KMServiceOptions,
        }}
        onChange={{
          getRCVs: getRCVs,
          handleCodeChange: handleCodeChange,
          handleDiscountCode: handleDiscountCode,
          calculateRenewalPrice: calculateRenewalPrice,
        }}
        priceInfo={priceInfo}
        action={selfRenew}
      />
      <LoadSpinner show={isLoading} />
      <div className="service-with-container container">
        <div className="buttons-container">
          <h1 className="title-component">
            Módulo de Renovación {membershipsDatatable?.count}
          </h1>
          <ActionButton label={'Exportar'} action={() => exportTable()} />
          {isVisible && (
            <div
              className={`breadcrumb ${
                showExportBreadcrumb ? 'fade-in' : 'fade-out'
              } breadcrumb-export-container`}
              onAnimationEnd={handleAnimationEnd}
            >
              <RenewalExportTypeBreadcrumb action={exportRenewTable} />
            </div>
          )}
        </div>
        <div className="breadcrumb-container">
          <div></div>
          <div
            className="exportables-buttons-container"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
              }}
              className=""
            >
              <Input
                bootstrap={'col-12 col-md-1'}
                label={'Desde Mes'}
                type={'select'}
                name={'sinceMonth'}
                options={monthsOptions}
                register={register}
                placeholder={''}
                onChange={() => handleDateChange()}
                errors={errors?.sinceMonth?.message}
              />
              <Input
                bootstrap={'col-12 col-md-1'}
                label={'Desde Año'}
                type={'select'}
                name={'sinceYear'}
                options={yearOptions}
                register={register}
                placeholder={''}
                onChange={() => handleDateChange()}
                errors={errors?.sinceYear?.message}
              />{' '}
              <Input
                bootstrap={'col-12 col-md-1'}
                label={'Hasta Mes'}
                type={'select'}
                options={monthsOptions}
                name={'untilMonth'}
                register={register}
                placeholder={''}
                onChange={() => handleDateChange()}
                errors={errors?.untilMonth?.message}
              />
              <Input
                bootstrap={'col-12 col-md-1'}
                label={'Hasta Año'}
                type={'select'}
                options={yearOptions}
                name={'untilYear'}
                register={register}
                placeholder={''}
                onChange={() => handleDateChange()}
                errors={errors?.untilYear?.message}
              />
              <div className="col-12 col-md-2" style={{ marginTop: '-10px' }}>
                <SearchInputWithSelector
                  placeholder={'Nombre o Apellido, Cédula, Teléfono o Placa'}
                  label={'Buscar'}
                  searchAction={getMembershipsByStatus}
                  value={membershipsDatatable?.searchParam}
                  onChange={handleSearchChange}
                  width="50px"
                  setCategory={setSearchCategoryOption}
                  category={searchCategoryOption}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
            }}
            className="row"
          >
            <div
              className="col-12 col-md"
              style={{ marginTop: '12px', padding: 0 }}
            >
              <SelectPerPage
                label={'Registros por página'}
                options={itemsPerpage}
                value={membershipsDatatable?.limit}
                onChange={selectitemsPerpage}
              />
              {/* div clickabke as  a button to clear all filters */}
            </div>
            <div
              className="clear-filters col-8 col-sm-1"
              style={{
                marginTop: '12px',
                backgroundColor: '#f27730',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                color: '#fff',
                cursor: 'pointer',
                border: '1px solid #f27730',
                borderRadius: '50px',
              }}
              onClick={() => {
                reset2();
                setCampaignActionOptions([]);
                setPlanSelectedOptions([]);
                setChannelOptions([]);
                setAdvisorOptions([]);
                setMembershipStatusOptions([]);
                setMembershipEndDate({});
                setRcvEndDate({});
              }}
            >
              <span>Limpiar filtros</span>
            </div>
          </div>
        </div>

        <RenewalMembershipTable
          header={tableHeader}
          advisors={advisors}
          data={membershipsDatatable}
          handlePageChange={handlePageChange}
          redirectToEditOrder={redirectToEditOrder}
          redirectToOrderDetail={redirectToOrderDetail}
          actionOverMembership={actionOverMembership}
          tableRef={tableRef}
          updateFromGrid={updateMembershipFromGrid}
          campaingActions={campaignOptions}
          renewalPrice={calculateRenewalPrice}
        />
      </div>
    </>
  );
};

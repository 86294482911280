import { useState, useEffect } from 'react';
import { Header } from '../../common/Header/Header.jsx';
import { useForm } from 'react-hook-form';
import pendiente from '../../../images/pendiente.svg';
import grayCheck from '../../../images/grayCheck.svg';
import calendarGray from '../../../images/calendarGray.svg';
import nuledGray from '../../../images/nuledGray.svg';
import { useTranslation } from 'react-i18next';
import { SalesCard } from '../../common/Cards/Cards';
import { FollowingModal } from '../../common/Modals/Modals';
import { Input } from '../../common/Forms/Input/Input';
import * as schemas from '../../../assets/schemas/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { membershipService } from '../../../services/membershipService.js';
import authService from '../../../services/authService.js';
import { notify } from '../../../assets/helpers/toast.js';
import { salesMembershipStatus } from '../../../assets/helpers/options.js';
import { SectionSpinner } from '../../common/LoadSpinner/LoadSpinner.jsx';
import { salesRoles } from '../../../assets/helpers/roles.js';
import {
  convertToHyphenatedLowerCase,
  renameMembreshipCrossStatus,
} from '../../../assets/helpers/funciones';
import './Analytics.scss';

export const Analytics = () => {
  const { t } = useTranslation();
  const [memberships, setMemberships] = useState([]);
  const [user, setUser] = useState('');
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schemas.LoginSchema(t)),
  });

  const [following, setFollowing] = useState(false);

  const redirectTo = (status) => {
    const membership = memberships?.data?.filter(
      (m) => m?.membershipStatus === status
    )[0];
    if (membership) {
      history.push(
        `/afiliations/status/${convertToHyphenatedLowerCase(
          renameMembreshipCrossStatus(membership?.membershipStatus)
        )}/${membership?.statusCatalogId}`
      );
    } else {
      history.push(`/afiliations/status/all/none`);
    }
  };

  const renewal = (status) => {
    const membershipCatalogId = memberships?.data?.filter(
      (m) => m?.membershipStatus === status
    )[0]?.statusCatalogId;
    history.push(`/dashboard/afiliations/renewal/${membershipCatalogId}`);
  };

  const getMembershipCountByStatus = () => {
    membershipService
      .getMembershipCountByStatus()
      .then((response) => {
        if (response.status === 200) {
          setMemberships((prevState) => {
            return {
              ...prevState,
              data: response?.data?.filteredMemberships,
              total: response?.data?.total,
              totalActive: response?.data?.totalActive,
            };
          });
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => {});
  };

  const getUser = () => {
    const response = authService?.getUser();
    setUser(response);
  };

  useEffect(() => {
    getUser();
    getMembershipCountByStatus();
  }, []);
  return (
    <>
      <Header />
      <div className="service-with-container container">
        <h1 className="title-component">Analíticas</h1>
        <div className="service-filter-button-container">
          <span></span>
          <div className="row">
            <Input
              bootstrap={'col-6'}
              label={'Desde'}
              type={'date'}
              name={'motorizado'}
              placeholder={''}
              register={register}
              control={control}
              // errors={l.errors}
            />{' '}
            <Input
              bootstrap={'col-6'}
              label={'Hasta'}
              type={'date'}
              name={'motorizado'}
              placeholder={''}
              register={register}
              control={control}
              // errors={l.errors}
            />
          </div>
        </div>
        <div className="gray-line"></div>

        <FollowingModal show={following} handleClose={setFollowing} />
        {memberships?.length === 0 && <SectionSpinner show={true} />}
        {memberships?.data?.length > 0 && (
          <div className="service-incidence-overflow-container">
            <div className="service-incidence-break-container">
              <div className="vertical-incidence-container">
                <SalesCard
                  redirectTo={() => redirectTo('PENDIENTE DE APROBACION')}
                  label={'Por Aprobacion'}
                  value={
                    memberships?.data?.filter(
                      (m) => m?.membershipStatus === 'PENDIENTE DE APROBACION'
                    )[0]?.count
                  }
                  src={pendiente}
                />
              </div>

              <div className="vertical-incidence-container">
                <SalesCard
                  label={'Base de afiliaciones'}
                  redirectTo={() => redirectTo('')}
                  src={pendiente}
                  value={memberships?.total}
                />
                {![
                  salesRoles?.SALES_SUPERVISOR,
                  salesRoles?.SALES_HUNTER,
                  salesRoles?.SALES_FARMER,
                  salesRoles?.SALES_COUNTER,
                  salesRoles?.SALES_ADMIN_AUX,
                ].includes(authService?.getUserType()) && (
                  <>
                    <SalesCard
                      redirectTo={() =>
                        redirectTo(salesMembershipStatus?.ACTIVO)
                      }
                      label={'Activos'}
                      value={
                        memberships?.data?.filter(
                          (m) =>
                            m?.membershipStatus ===
                            salesMembershipStatus?.ACTIVO
                        )[0]?.count
                      }
                      src={grayCheck}
                    />{' '}
                    <SalesCard
                      redirectTo={() =>
                        redirectTo(salesMembershipStatus?.POR_VENCER)
                      }
                      label={'Vencidas (Período de Salva) '}
                      value={
                        memberships?.data?.filter(
                          (m) =>
                            m?.membershipStatus ===
                            salesMembershipStatus?.POR_VENCER
                        )[0]?.count
                      }
                      src={nuledGray}
                    />
                    <SalesCard
                      redirectTo={() =>
                        redirectTo(salesMembershipStatus?.VENCIDO)
                      }
                      label={'Rescate'}
                      value={
                        memberships?.data?.filter(
                          (m) =>
                            m?.membershipStatus ===
                            salesMembershipStatus?.VENCIDO
                        )[0]?.count
                      }
                      src={calendarGray}
                    />
                    <SalesCard
                      redirectTo={() =>
                        redirectTo(salesMembershipStatus?.ANULADO)
                      }
                      label={'Anulados'}
                      value={
                        memberships?.data?.filter(
                          (m) =>
                            m?.membershipStatus ===
                            salesMembershipStatus?.ANULADO
                        )[0]?.count
                      }
                      src={calendarGray}
                    />{' '}
                    <SalesCard
                      redirectTo={() =>
                        redirectTo(salesMembershipStatus?.ACTIVO)
                      }
                      label={'Vigentes'}
                      value={memberships?.totalActive}
                      src={grayCheck}
                    />
                  </>
                )}
              </div>
              {[salesRoles?.SALES_COUNTER]?.includes(user?.roles[0]?.code) &&
              user?.type === 'Interno' ? (
                ''
              ) : (
                <div className="vertical-incidence-container">
                  <SalesCard
                    redirectTo={() =>
                      renewal(salesMembershipStatus?.POR_RENOVACION)
                    }
                    label={'Por Renovación'}
                    value={
                      memberships?.data?.filter(
                        (m) =>
                          m?.membershipStatus ===
                          salesMembershipStatus?.POR_RENOVACION
                      )[0]?.count
                    }
                    src={pendiente}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

import { useState, useEffect, useRef } from 'react';
import { SelectPerPage } from '../../../common/Forms/Input/Input';
import { SalesChannelsTable } from '../../../common/Tables/Tables';
import { saleChannelTableheader } from '../../../../assets/helpers/tables';
import { notify } from '../../../../assets/helpers/toast';
import { saleChannelService } from '../../../../services/saleChannelService';
import { useHistory } from 'react-router-dom';
import './SalesChannels.scss';

const itemsPerpage = [10, 25, 50, 100, 500];

export const ListSalesChannel = () => {
    const tableRef = useRef(null);
    const history = useHistory();

    const [dataTable, setDataTable] = useState({
        limit: itemsPerpage[2],
        offset: 0, //Elementos que ya pasaron la pagina
        data: [],
        count: 0, //Cantidad de elementos
        page: 1,
        isLoading: true,
    });


    const selectitemsPerpage = (event) => {
        setDataTable((prevState) => {
            return {
                ...prevState,
                page: 1,
                offset: 0,
                limit: parseInt(event.target.value),
            };
        });
    };

    const handlePageChange = (event, value) => {
        setDataTable((prevState) => {
            return {
                ...prevState,
                page: value,
                offset: (value - 1) * dataTable?.limit,
            };
        });
    };

    const redirectToOrder = (orderId) => {
        history.push(`/afiliations/order/${orderId}`);
    };


    const getSalesChannel = () => {


        let params = {
            limit: dataTable?.limit,
            offset: dataTable?.offset,
            isActive: true,
        }

        saleChannelService
            .getSalesChannelList(params)
            .then((response) => {
                if ([200, 201].includes(response?.status)) {

                    setDataTable((prevState) => {
                        return {
                            ...prevState,
                            data: response?.data?.saleChannels,
                            count: response?.data?.total,
                            isLoading: false,
                        };
                    });


                } else {
                    notify(`${response.message}` || 'error', 'error');
                }
            })
            .catch(() => { });
    };

    useEffect(() => {
        getSalesChannel();
    }, [
        dataTable?.page,
        dataTable?.offset,
        dataTable?.limit,
        dataTable?.type,
    ]);

    const openDetail = (fleet) => {
        history.push(`/modules/fleet-register/detail/${fleet?._id}`);
    };



    // Function that handles the logout request


    return (
        <>
            <div className="buttons-container">
                <h1 className="title-component">
                    Canales de venta {dataTable?.count}
                </h1>
            </div>
            <div className="breadcrumb-container">
                {/* <div className="exportables-buttons-container"></div> */}
                <div className="exportables-buttons-container">
                    <div style={{ marginTop: '12px' }}>
                        <SelectPerPage
                            label={'Registros por página'}
                            options={itemsPerpage}
                            value={dataTable?.limit}
                            onChange={selectitemsPerpage}
                        />
                    </div>
                </div>
                <span></span>
            </div>
            <SalesChannelsTable
                header={saleChannelTableheader}
                data={dataTable}
                handlePageChange={handlePageChange}
                redirectToOrder={redirectToOrder}
                tableRef={tableRef}
                openDetail={openDetail}
            />
        </>
    );
};

import axios from 'axios';

export const authInstance = axios.create({
  headers: {
    'Content-type': 'application/json',
    'x-api-key': process.env.REACT_APP_X_API_KEY,
  },
  validateStatus: (status) => {
    return status <= 505;
  },
});

authInstance.interceptors.request.use(async (req) => {
  //Do something before the request
  const Authorization = `Bearer ${localStorage?.getItem('accessToken')}`;
  if (Authorization) {
    req.headers.Authorization = Authorization;
  }
  return req;
});

authInstance.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  async function (response) {
    if (response?.status < 400) {
      response.data = response?.data?.data;
      response.message = response?.data?.message;
      return response;
    }
    if (response?.status >= 400) {
      // IF the token expired logout
      if (
        ['Token is not valid', 'Token is expired'].includes(
          response?.data?.message
        )
      ) {
        localStorage.clear();
        window.location.replace(`/login`);
        response.message = '';
        return response;
      }

      response.message = response?.data?.message;
      return response;
    }
  },
  // Any status code that lie within the range of 2xx cause this function to trigger
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

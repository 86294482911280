import { fixResponseJSON } from '../assets/helpers/funciones';
import { authInstance } from './axios';
let url = process.env.REACT_APP_API_URL;

export const vehicleService = {
  createBrand: async (data) => {
    const response = await authInstance
      .post(`${url}/vehicleBrands/create`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        return error;
      });
    return response;
  },

  createModel: async (data) => {
    const response = await authInstance
      .post(`${url}/vehicleModels/create`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        return error;
      });
    return response;
  },

  getVehicleBrands: async (vehicleClass) => {
    const response = await authInstance
      .get(
        `${url}/vehicles/getBrands?description=&limit=100000&offset=0${
          vehicleClass ? `&class=${vehicleClass}` : ''
        }`
      )
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        return error;
      });
    return response;
  },

  getVehicleModels: async (brandId) => {
    const response = await authInstance
      .get(`${url}/vehicles/getModels?brandId=${brandId}`)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getVehicleClasses: async (fleet) => {
    const response = await authInstance
      .get(`${url}/vehicles/getClasses/?fleet=${fleet}`)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getSalesVehicleClasses: async () => {
    const response = await authInstance
      .get(`${url}/vehicles/getClasses`)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getVehicleTypes: async (params) => {
    const response = await authInstance
      .get(`${url}/vehicles/getTypes?` + new URLSearchParams(params))
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  createVehicle: async (data) => {
    const myInit = {
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'POST',
      body: data,
    };

    const response = await fetch(`${url}/vehicles/create`, myInit)
      .then(async (response) => {
        return fixResponseJSON(response);
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  createVehicleF4: async (data) => {
    const response = await authInstance
      .post(`${url}/vehicles/createPlusf4`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        return error;
      });
    return response;
  },

  updateVehicle: async (data, vehicleId) => {
    const response = await authInstance
      .put(`${url}/vehicles/${vehicleId}/update`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getMemberships: async (vehicleId) => {
    const response = await authInstance
      .get(`${url}/vehicles/${vehicleId}/memberships`)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
};

import { fixResponseJSON } from '../assets/helpers/funciones';
let url = process.env.REACT_APP_API_URL;

export const broadCastService = {
  getBroadcastChannels: async () => {
    const myInit = {
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
      },
      method: 'GET',
    };

    const response = await fetch(
      `${url}/broadcastChannels/search?limit=10000`,
      myInit
    )
      .then(async (response) => {
        return fixResponseJSON(response);
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
};

import { filterParams } from '../assets/helpers/funciones';
import { authInstance } from './axios';
let url = process.env.REACT_APP_API_URL;

export const caseService = {
  getCasesByOperator: async (userId, params) => {
    const response = await authInstance
      .get(`${url}/cases/?` + new URLSearchParams(filterParams(params)))
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getCaseById: async (caseId) => {
    const response = await authInstance
      .get(`${url}/cases/${caseId}`)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  updateCaseTracking: async (data, caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/addTracking`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  cancelCase: async (data, caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/cancel`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  finishCase: async (data, caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/finish`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  createCase: async (data) => {
    const response = await authInstance
      .post(`${url}/cases/create`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
  editCase: async (data, caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/update`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  updateCaseStage: async (data, caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/updateStage`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
  scheduleCase: async (data, caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/schedule`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  changeOperator: async (data, caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/updateUser`, data)
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  getExportables: async (params) => {
    for (const key in params) {
      if (params[key] === undefined || params[key] === '') {
        delete params[key];
      }
    }

    const response = await authInstance
      .get(`${url}/cases/exportables?` + new URLSearchParams(params))
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
};

import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const membershipCatalogService = {
  getAvailableMemberships: async (params) => {
    for (const key in params) {
      if (params[key] === undefined || params[key] === '') {
        delete params[key]
      }
    }

    const response = await authInstance
      .get(`${url}/membershipCatalog?` + new URLSearchParams(params))
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  getPrices: async () => {
    const response = await authInstance
      .get(`${url}/membershipCatalog/prices`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  getPricesForRetail: async () => {
    const response = await authInstance
      .get(`${url}/membershipCatalog/pricesForRetail`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },

  getUpgradeMembership: async (membershipId) => {
    const response = await authInstance
      .get(`${url}/membershipCatalog/upgrades?membership=${membershipId}`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        console.log('error', error)
        error.data = []
        return error
      })
    return response
  },
}

import moment from 'moment';
import { exists, capitalize } from './funciones';

export const smsText = {
  greenGroup: (data, provider, service, _case) => {
    return `*SERVICIO DE TU/GRUERO*
-*FECHA*: ${moment.utc(data?.createdAt).format('DD/MM/YYYY')}
-*HORA*: ${moment.utc(data?.createdAt).format('LT')}
-*CLIENTE*: ${
      capitalize(
        data?.particularCustomer?.firstName ||
          data?.membership?.customer?.firstName
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.lastName ||
          data?.membership?.customer?.lastName
      )
    }
-*TELÉFONO*: ${
      exists(
        data?.particularCustomer?.phonePrefix ||
          data?.membership?.customer?.phonePrefix
      ) +
      '-' +
      exists(
        data?.particularCustomer?.phoneNumber ||
          data?.membership?.customer?.phoneNumber
      )
    }
-*PLACA*: ${exists(
      data?.particularCustomer?.vehiclePlate || data?.membership?.vehicle?.plate
    )}
-*VEHICULO*: ${
      capitalize(
        data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleModel?.model ||
          data?.membership?.vehicle?.model
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleYear || data?.membership?.vehicle?.year
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleColor ||
          data?.membership?.vehicle?.color
      )
    }
    -*AVERÍA*: ${capitalize(data?.fault?.description)}
    -*COMENTARIO AVERÍA*: ${capitalize(
      _case?.selected?.customerFailureDescription
        ? _case?.selected?.customerFailureDescription
        : 'Ninguno'
    )}
-*ORIGEN*: ${
      capitalize(data?.originState?.name) +
      ' ' +
      capitalize(data?.originCity?.name) +
      ' ' +
      capitalize(data?.originAddress)
    }\n-*OPERADOR*:${
      capitalize(data?.user?.firstName) + ' ' + capitalize(data?.user?.lastName)
    }
-*MOTORIZADO*:${capitalize(
      provider?.contactName
    )}\n-*SERVICIO SOLICITADO*:${capitalize(service?.product)}`;
  },

  messageToClient: (data, provider) => {
    return `¡Estimad@! Ya tienes un asistente vial asignado\nDatos del motorizado:
    \n\n*Nombre: ${capitalize(provider?.contactName)} * ${
      data?.estimatedTime
        ? '\n*Tiempo de espera estimado:' +
          exists(data?.estimatedTime) +
          'minutos*'
        : ''
    }\n\nProcura mantenerte en el lugar indicado para que nuestro asistente pueda ubicar tu posición.`;
  },

  yellowGroup: (data, provider, service) => {
    return `*SERVICIO DE TU/GRUERO*
-*FECHA*: ${moment.utc(data?.createdAt).format('DD/MM/YYYY')}
-*HORA*: ${moment.utc(data?.createdAt).format('LT')}
-*PLACA*: ${exists(
      data?.particularCustomer?.vehiclePlate || data?.membership?.vehicle?.plate
    )}
-*VEHICULO*: ${
      capitalize(
        data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleModel?.model ||
          data?.membership?.vehicle?.model
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleYear || data?.membership?.vehicle?.year
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleColor ||
          data?.membership?.vehicle?.color
      )
    }
-*AVERÍA*: ${capitalize(data?.fault?.description)}
-*ORIGEN*: ${
      capitalize(data?.originState?.name) +
      ' ' +
      capitalize(data?.originCity?.name) +
      ' ' +
      capitalize(data?.originAddress)
    }${data?.destinyState?.name ? `-*DESTINO*: ` : ''}${
      capitalize(data?.destinyState?.name) +
      ' ' +
      capitalize(data?.destinyCity?.name) +
      ' ' +
      capitalize(data?.destinyAddress)
    }\n-*OPERADOR*:${
      capitalize(data?.user?.firstName) + ' ' + capitalize(data?.user?.lastName)
    }
-*MOTORIZADO*:${capitalize(
      provider?.contactName
    )}\n-*SERVICIO SOLICITADO*:${capitalize(service?.product)}`;
  },

  towBroadcastMessage: (data) => {
    return `*SERVICIO DE TU/GRUERO*
-*FECHA*: ${moment.utc(data?.createdAt).format('DD/MM/YYYY')}
-*HORA*: ${moment.utc(data?.createdAt).format('LT')}
-*AVERÍA*: ${capitalize(data?.fault?.description)}
-*VEHICULO*: ${
      capitalize(
        data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleModel?.model ||
          data?.membership?.vehicle?.model
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleYear || data?.membership?.vehicle?.year
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleColor ||
          data?.membership?.vehicle?.color
      )
    }
-*ORIGEN*: ${
      capitalize(data?.originState?.name) +
      ' ' +
      capitalize(data?.originCity?.name) +
      ' ' +
      capitalize(data?.originAddress)
    }${data?.destinyState?.name ? `\n-*DESTINO*: ` : ''}${
      capitalize(data?.destinyState?.name) +
      ' ' +
      capitalize(data?.destinyCity?.name) +
      ' ' +
      capitalize(data?.destinyAddress)
    }\n\n*Interesados responder con el precio y el tiempo de espera*`;
  },

  towMesage: (data) => {
    return `*SERVICIO DE TU/GRUERO*
-*FECHA*: ${moment.utc(data?.createdAt).format('DD/MM/YYYY')}
-*HORA*: ${moment.utc(data?.createdAt).format('LT')}
-*CLIENTE*: ${
      exists(
        data?.particularCustomer?.firstName ||
          data?.membership?.customer?.firstName
      ) +
      ' ' +
      exists(
        data?.particularCustomer?.lastName ||
          data?.membership?.customer?.lastName
      )
    }
-*TELÉFONO*: ${
      exists(
        data?.particularCustomer?.phonePrefix ||
          data?.membership?.customer?.phonePrefix
      ) +
      '-' +
      exists(
        data?.particularCustomer?.phoneNumber ||
          data?.membership?.customer?.phoneNumber
      )
    }
-*PLACA*: ${exists(
      data?.particularCustomer?.vehiclePlate || data?.membership?.vehicle?.plate
    )}
-*VEHICULO*: ${
      capitalize(
        data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleModel?.model ||
          data?.membership?.vehicle?.model
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleYear || data?.membership?.vehicle?.year
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleColor ||
          data?.membership?.vehicle?.color
      )
    }
-*AVERÍA*: ${capitalize(data?.fault?.description)}
-*ORIGEN*: ${
      capitalize(data?.originState?.name) +
      ' ' +
      capitalize(data?.originCity?.name) +
      ' ' +
      capitalize(data?.originAddress)
    }${data?.destinyState?.name ? `-*DESTINO*: ` : ''}${
      capitalize(data?.destinyState?.name) +
      ' ' +
      capitalize(data?.destinyCity?.name) +
      ' ' +
      capitalize(data?.destinyAddress)
    }
${data?.caseKm ? `-*DISTANCIA*: ${exists(data?.caseKm)} Km` : ''}
\n-*OPERADOR*:${
      capitalize(data?.user?.firstName) + ' ' + capitalize(data?.user?.lastName)
    }\n\n-*Interesados  responder con el tiempo de espera*`;
  },

  towServiceMessage: (data) => {
    return `*SERVICIO DE TU/GRUERO*
-*FECHA*: ${moment.utc(data?.createdAt).format('DD/MM/YYYY')}
-*HORA*: ${moment.utc(data?.createdAt).format('LT')}
-*CLIENTE*: ${
      exists(
        data?.particularCustomer?.firstName ||
          data?.membership?.customer?.firstName
      ) +
      ' ' +
      exists(
        data?.particularCustomer?.lastName ||
          data?.membership?.customer?.lastName
      )
    }
-*TELÉFONO*: ${
      exists(
        data?.particularCustomer?.phonePrefix ||
          data?.membership?.customer?.phonePrefix
      ) +
      '-' +
      exists(
        data?.particularCustomer?.phoneNumber ||
          data?.membership?.customer?.phoneNumber
      )
    }
-*PLACA*: ${exists(
      data?.particularCustomer?.vehiclePlate || data?.membership?.vehicle?.plate
    )}
-*VEHICULO*: ${
      capitalize(
        data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleModel?.model ||
          data?.membership?.vehicle?.model
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleYear || data?.membership?.vehicle?.year
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleColor ||
          data?.membership?.vehicle?.color
      )
    }
-*AVERÍA*: ${capitalize(data?.fault?.description)}
-*ORIGEN*: ${
      capitalize(data?.originState?.name) +
      ' ' +
      capitalize(data?.originCity?.name) +
      ' ' +
      capitalize(data?.originAddress)
    }${data?.destinyState?.name ? `-*DESTINO*: ` : ''}${
      capitalize(data?.destinyState?.name) +
      ' ' +
      capitalize(data?.destinyCity?.name) +
      ' ' +
      capitalize(data?.destinyAddress)
    }
${data?.destinyState?.name ? `-*DESTINO*: ` : ''}${
      capitalize(data?.destinyState?.name) +
      ' ' +
      capitalize(data?.destinyCity?.name) +
      ' ' +
      capitalize(data?.destinyAddress)
    }
${data?.caseKm ? `-*DISTANCIA*: ${exists(data?.caseKm)} Km ` : ''}
${
  data?.membership?.customer?.homeCity
    ? `-*DOMICILIO*:  ${
        capitalize(data?.membership?.customer?.homeState) +
        capitalize(data?.membership?.customer?.homeCity) +
        capitalize(data?.membership?.customer?.homeMunicipality) +
        capitalize(data?.membership?.customer?.homeAddress)
      }`
    : ''
}
${
  data?.membership?.beginDate
    ? `-*VIGENCIA*: ${exists(
        moment.utc(data?.membership?.beginDate).format('DD/MM/YYYY')
      )}-${exists(moment.utc(data?.membership?.endDate).format('DD/MM/YYYY'))}`
    : ''
}
    \n-*OPERADOR*:${
      capitalize(data?.user?.firstName) + ' ' + capitalize(data?.user?.lastName)
    }`;
  },

  messageToClient: (data, provider) => {
    return `¡Estimad@! Ya tienes un asistente vial asignado\nDatos del motorizado:
    \n\n*Nombre: ${capitalize(provider?.contactName)} * ${
      data?.estimatedTime
        ? '\n*Tiempo de espera estimado:' +
          exists(data?.estimatedTime) +
          'minutos*'
        : ''
    }\n\nProcura mantenerte en el lugar indicado para que nuestro asistente pueda ubicar tu posición.`;
  },
};

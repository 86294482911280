import { useState, useEffect, useRef } from 'react';
import { ActionButton } from '../../common/Buttons/Buttons';
import { Header } from '../../common/Header/Header.jsx';
import { SelectPerPage } from '../../common/Forms/Input/Input';
import {
  SalesMembershipTable,
  PendinForApprovalTable,
  LostTable,
} from '../../common/Tables/Tables';
import {
  salesMembershipTableHeader,
  pendingForApprovalTableHeader,
  lostTableHeader,
} from '../../../assets/helpers/tables';
import { notify } from '../../../assets/helpers/toast';
import { salesRoles } from '../../../assets/helpers/roles.js';
import { membershipService } from '../../../services/membershipService';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import {
  FilterDatePicker,
  Select,
  SearchInput,
} from '../../common/Forms/Input/Input';
import moment from 'moment';
import {
  capitalize,
  filterParams,
  renameMembreshipCrossStatus,
  convertToHyphenatedLowerCase,
} from '../../../assets/helpers/funciones';
import { userType } from '../../../assets/helpers/options.js';

import './AffiliationsDB.scss';
import authService from '../../../services/authService.js';

const itemsPerpage = [10, 25, 50, 100, 500];

export const AffiliationsDB = () => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const { statusId, statusName } = useParams();


  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [statusSelected, setStatusSelected] = useState('');

  const [membershipsDatatable, setMembershipsDatatable] = useState({
    limit: itemsPerpage[2],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    dateFrom: '',
    dateTo: '',
    isLoading: false,
    searchParam: '',
  });

  const handleSinceDateChange = (e) => {
    if (e > dateTo && dateTo) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setDateFrom(e);
    }
  };

  const handleUntilDateChange = (e) => {
    if (e < dateFrom && dateFrom) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setDateTo(e);
    }
  };

  const selectitemsPerpage = (event) => {
    setMembershipsDatatable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setMembershipsDatatable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * membershipsDatatable?.limit,
      };
    });
  };

  const handleSearchChange = (event) => {
    setMembershipsDatatable((prevState) => {
      return {
        ...prevState,
        searchParam: event?.target?.value,
        page: 1,
        offset: 0,
        data: false,
      };
    });
  };

  const redirectToEditOrder = (orderId) => {
    history.push(`/afiliations/edit-order/${orderId}`);
  };

  const redirectToOrderDetail = (order) => {
    history.push(
      `/afiliations/order-details/${order?._id}/vehicle/${order?.vehicle?._id}`
    );
  };

  const renewMembership = (membership) => {
    if (!isLoading) {
      setIsLoading(true);
      membershipService
        .reativateMembership(membership?._id)
        .then((response) => {
          if ([200, 201].includes(response?.status)) {
            setIsLoading(false);
            notify(`Membresia reactivada`, 'success');
            redirectToEditOrder(membership?._id);
          } else {
            setIsLoading(false);
            notify(`${response.message}`, 'error');
          }
        })
        .catch(() => { });
    }
  };

  useEffect(() => {
    getStatus();
  }, []);

  useEffect(() => {
    getMembershipsByStatus();
  }, [
    membershipsDatatable?.page,
    membershipsDatatable?.offset,
    membershipsDatatable?.limit,
    membershipsDatatable?.type,
    dateFrom,
    dateTo,
  ]);

  const validateIdLogic = () => {
    let response = '';
    if (['all', 'todos'].includes(statusName)) {
      response = '';
    }
    if (statusId && statusId !== 'none') {
      return (response = statusId);
    }
    return response;
  };

  const getMembershipsByStatus = () => {
    let pedingForApproval = false;
    if (window.location.pathname.includes('pendiente-de-aprobacion')) {
      pedingForApproval = true;
    }

    if (
      membershipsDatatable?.searchParam ||
      pedingForApproval ||
      dateFrom ||
      dateTo
    ) {
      setMembershipsDatatable((prevState) => {
        return {
          ...prevState,
          isLoading: true,
        };
      });

      let params = {
        status: validateIdLogic(),
        dateFrom: dateFrom ? moment.utc(dateFrom).format('MM-DD-YYYY') : '',
        dateTo: dateTo ? moment.utc(dateTo).format('MM-DD-YYYY') : '',
        limit: membershipsDatatable?.limit,
        offset: membershipsDatatable?.offset,
        searchParam: membershipsDatatable?.searchParam,
      };

      membershipService
        .getMembershipsCountByCondition(filterParams(params))
        .then((response) => {
          if (response.status === 200) {
            setMembershipsDatatable((prevState) => {
              return {
                ...prevState,
                count: response?.data?.total,
              };
            });
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => { });

      membershipService
        .getMembershipsByStatus(filterParams(params))
        .then((response) => {
          if (response.status === 200) {
            setMembershipsDatatable((prevState) => {
              return {
                ...prevState,
                data: response?.data?.memberships,
                isLoading: false,
              };
            });
          } else {
            notify(`${response.message}` || 'error', 'error');
            setMembershipsDatatable((prevState) => {
              return {
                ...prevState,
                isLoading: false,
              };
            });
          }
        })
        .catch((error) => {
          setMembershipsDatatable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        });
      return true;
    }
  };

  const exportAffiliationTable = async () => {
    let params = {
      status: validateIdLogic(),
      dateFrom: dateFrom ? moment.utc(dateFrom).format('MM-DD-YYYY') : '',
      dateTo: dateTo ? moment.utc(dateTo).format('MM-DD-YYYY') : '',
      limit: membershipsDatatable?.limit,
      offset: membershipsDatatable?.offset,
      searchParam: membershipsDatatable?.searchParam,
      byMail: true,
      email: authService?.getUser()?.email
    };

    if (!isLoadingEmail) {
      notify(`Solicitud de exportable realizada, espere a recibir un correo con el archivo correspondiente`, 'info');

      membershipService
        .getMembershipsByStatus(filterParams(params))
        .then((response) => {
          if (response.status === 200) {
            setIsLoadingEmail(false);
          } else {
            notify(`${response.message}` || 'error', 'error');
            setIsLoadingEmail(false);
          }
        })
        .catch((error) => {
          setIsLoadingEmail(false);
        });
      return true;
    }
  };


  const redirectToOtherStatus = (e) => {
    const { value } = e?.target;

    if (value === 'all') {
      history.push(`/afiliations/status/all/none`);
      window.location.reload();
      return null;
    } else {
      let [selected] = statusOptions?.filter(
        (s) => s?._id === e?.target?.value
      );
      history.push(
        `/afiliations/status/${convertToHyphenatedLowerCase(
          renameMembreshipCrossStatus(selected?.name)
        )}/${selected?._id}`
      );
      window.location.reload();
    }
  };

  const getStatus = () => {
    membershipService
      .getStatuses()
      .then((response) => {
        if (response.status === 200) {
          let data = [];
          data.push({
            _id: 'all',
            name: 'Todos',
          });
          for (let i = 0; i < response?.data.length; i++) {
            if (response?.data[i]?.name !== 'DENEGADO') {
              data.push({
                _id: response?.data[i]?._id,
                name: capitalize(response?.data[i]?.name),
              });
            }

            if (response?.data[i]?._id === statusId) {
              setStatusSelected(response?.data[i]?._id);
            }
          }

          setStatusOptions(data);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
    return true;
  };

  const statusAndCount = (statusOptions) => {
    let name = statusOptions?.filter((s) => s?._id === statusSelected)[0]?.name;
    if (name) {
      name = ' (' + name + ') ';
    } else {
      name = ' ';
    }

    return (
      <span>
        :{name} {'   '} {membershipsDatatable?.count}{' '}
      </span>
    );
  };

  return (
    <>
      <Header />
      <div className="service-with-container container">
        <div className="buttons-container">
          <h1 className="title-component">
            Base de datos de afiliaciones
            {statusAndCount(statusOptions)}
          </h1>
          {![
            salesRoles?.SALES_HUNTER,
            salesRoles?.SALES_COUNTER,
            salesRoles?.SALES_ADMIN_AUX,
          ].includes(authService?.getUserType()) && (
              <ActionButton
                label={'Exportar'}
                action={() =>
                  exportAffiliationTable()
                }
              />
            )}
        </div>
        <div className="breadcrumb-container">
          <div></div>
          <div className="exportables-buttons-container">
            <div style={{ width: '100%' }} className="row">
              <div className="col-12 col-md-2" style={{ marginTop: '30px' }}>
                <SearchInput
                  placeholder={'Nombre o Apellido, Cédula o Placa'}
                  searchAction={getMembershipsByStatus}
                  value={membershipsDatatable?.searchParam}
                  onChange={handleSearchChange}
                  width="50px"
                />
              </div>
              <div
                style={{ marginTop: '6px', marginLeft: '12px' }}
                className="col-12 col-md-2 col-lg-1"
              >
                <Select
                  options={statusOptions}
                  label={'Tipo'}
                  value={statusSelected}
                  onChange={redirectToOtherStatus}
                />
              </div>
              {![
                salesRoles?.SALES_HUNTER,
                salesRoles?.SALES_COUNTER,
                salesRoles?.SALES_FARMER,
                salesRoles?.SALES_ADMIN_AUX,
              ].includes(authService?.getUserType()) ? (<>
                <FilterDatePicker
                  bootstrap={'col-12 col-md-2'}
                  label={'Desde'}
                  type={'date'}
                  name={'dateFrom'}
                  placeholder={''}
                  onChange={handleSinceDateChange}
                  value={dateFrom}
                  disabled={membershipsDatatable?.isLoading}
                />
                <FilterDatePicker
                  bootstrap={'col-12 col-md-2'}
                  label={'Hasta'}
                  type={'date'}
                  name={'dateTo'}
                  placeholder={''}
                  onChange={handleUntilDateChange}
                  value={dateTo}
                  disabled={membershipsDatatable?.isLoading}
                />
              </>
              ) : null}
              <div
                style={{
                  marginTop: '24px',
                  marginLeft: 'auto',
                  width: '350px',
                }}
              >
                {![
                  salesRoles?.SALES_FARMER,
                  salesRoles?.SALES_HUNTER,
                  salesRoles?.SALES_COUNTER,
                  salesRoles?.SALES_ADMIN_AUX,
                ].includes(authService?.getUserType()) && (
                    <SelectPerPage
                      label={'Registros por página'}
                      options={itemsPerpage}
                      value={membershipsDatatable?.limit}
                      onChange={selectitemsPerpage}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>

        {statusOptions?.filter((s) => s?._id === statusSelected)[0]?.name ===
          'Pendiente De Aprobacion' && (
            <PendinForApprovalTable
              header={pendingForApprovalTableHeader}
              data={membershipsDatatable}
              handlePageChange={handlePageChange}
              redirectToEditOrder={redirectToEditOrder}
              redirectToOrderDetail={redirectToOrderDetail}
              renewMembership={renewMembership}
              tableRef={tableRef}
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
          )}
        {statusOptions?.filter((s) => s?._id === statusSelected)[0]?.name ===
          'Vencido' && (
            <LostTable
              header={lostTableHeader}
              data={membershipsDatatable}
              handlePageChange={handlePageChange}
              redirectToEditOrder={redirectToEditOrder}
              redirectToOrderDetail={redirectToOrderDetail}
              renewMembership={renewMembership}
              tableRef={tableRef}
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
          )}
        {!['Pendiente De Aprobacion', 'Vencido'].includes(
          statusOptions?.filter((s) => s?._id === statusSelected)[0]?.name
        ) && (
            <SalesMembershipTable
              header={salesMembershipTableHeader}
              data={membershipsDatatable}
              handlePageChange={handlePageChange}
              redirectToEditOrder={redirectToEditOrder}
              redirectToOrderDetail={redirectToOrderDetail}
              renewMembership={renewMembership}
              tableRef={tableRef}
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
          )}
      </div>
    </>
  );
};

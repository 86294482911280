import { fixResponseJSON } from '../assets/helpers/funciones';
let url = process.env.REACT_APP_API_URL;

export const loginService = {
  login: async (data) => {
    const myInit = {
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
      },
      method: 'POST',
      body: JSON.stringify(data),
    };

    const response = await fetch(`${url}/login/basic`, myInit)
      .then(async (response) => {
        return fixResponseJSON(response);
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },

  signUp: async (data) => {
    const myInit = {
      headers: {
        'Content-type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    };

    const response = await fetch(`${url}/auth/sign-up`, myInit)
      .then((response) => response.json())
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
};
